import React, {useEffect, useState} from 'react';

import {
    Box,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    styled,
} from "@mui/material";
import {t} from "i18next";
import {getNotApprovedUser} from "../../api/getContentsApi/getNotApprovedUser";
import {Link} from "react-router-dom";
import useIsMobile from "../../helpers/useIsMobile";

const NotApprovedUserTable = () => {
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                setLoading(true);
                const data = await getNotApprovedUser(); // Передаем query параметр в запрос
                setUsers(data);
            } catch (error) {
                console.error("An error occurred while fetching users:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchUsers();
    }, []);


    // Пагинация данных
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({

        padding: '7px',
    }));

    const isMobile = useIsMobile()

    return (
        <Paper sx={{width: '100%'}}>
            { loading? <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh"}}>
                    <CircularProgress/>
                </Box>:
                <>
                    <TableContainer sx={{maxHeight: "60vh"}}>
                        <Table sx={isMobile ? {maxWidth: 370} : ""} aria-label="users table" >
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell sx={{width: 100}}>{t("User name")}</StyledTableCell>
                                    <StyledTableCell sx={{width: 80}}>{t("Status")}</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => (
                                    <TableRow key={user.id}>
                                        <StyledTableCell sx={{maxWidth: 150, wordWrap: 'break-word'}}>
                                            <Link to={`/owner/verification/user/${user.id}`}>
                                                {user.username}
                                            </Link>
                                        </StyledTableCell>
                                        <StyledTableCell sx={{maxWidth: 150, wordWrap: 'break-word'}}>
                                            {user.is_verified ? t('Verified') : t('Not verified')}
                                        </StyledTableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[5,10, 25, 50, 100]}
                        component="div"
                        count={users.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>}
        </Paper>
    );
};

export default NotApprovedUserTable;
