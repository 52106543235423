import React, {useState, useRef} from 'react';
import styles from './SearchUser.module.scss';
import {observer} from "mobx-react";
import {useNavigate} from "react-router-dom";
import AllUserTable from "./AllUserTable/AllUserTable";

const SearchUser = ({isOpen, setIsOpen}) => {
    const navigate = useNavigate();
    const modalRef = useRef(null);
    const [mess, setMess] = useState("")

    const handleCloseModal = () => {
        setIsOpen(false);
    };

    const handleClose = (ev) => {
        if (ev.target.id === "modalka") {
            setIsOpen(false);
        }
    }
    const isAccPage = window.location.pathname.includes('account-mobile')

    return (
        <div className={styles.mainContBlock}>
            {isOpen && (
                <div id="modalka" className={`${styles["modal-overlay"]}`} onClick={(ev) => handleClose(ev)}>
                    <div className={`${styles["modal-content"]} ${isAccPage && styles.bigModal}`} ref={modalRef}>
                        <div className={styles.rowBlockCont}>
                            <div className={styles.closeBtnConteiner}>
                                <button className={styles.buttonBlock} data-backdrop="transparent"
                                        data-size="medium" data-variant="neutral" type="button"
                                        aria-label="Close the dialog" data-test-id="modal-close-button"
                                        onClick={handleCloseModal}>Done
                                </button>
                            </div>
                        </div>
                        <AllUserTable/>
                    </div>
                </div>
            )}
            <div onClick={() => setIsOpen(true)}></div>
        </div>
    );
};

export default observer(SearchUser);
