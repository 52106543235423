import {makeAutoObservable} from 'mobx';

class systemStore {
    isOpenLoginModal = false;
    isLogin = false;
    isRegister = false;
    sizeWidth = null;
    isOpenPersonal = false;
    isOpenEditPhoneModal= false;
    languages = []
    navBarHidden = false
    IsOpenVidjet = true
    openMediaFull = false

    get(){
        return this.isOpenLoginModal;
    }

    constructor() {
        makeAutoObservable(this);
    }
    get openOrderVidjetState() {
        return this.openOrderVidjet
    }
    get openMediaState() {
        return this.openMediaFull
    }
    setOpenMediaState = () => {
        this.openMediaFull = !this.openMediaFull
    }
    setNavBarHidden = (state) => {
        this.navBarHidden = state
    }
    get getIsOpenPersonal() {
        return this.isOpenPersonal
    }
    get getIsOpenPersonalModal() {
        return this.isOpenLoginModal
    }
    get getIsOpenVidjet() {
        return this.IsOpenVidjet
    }
    setIsOpenVidjet = (state) => {
        this.IsOpenVidjet = state
    }

    setWidthSize = (width) => {
        this.sizeWidth = width
    }

    setIsOpenPersonal = (state) => {
        this.isOpenPersonal = state
    }

    openCloseL = (isLogin) => {
        this.isOpenLoginModal = !this.isOpenLoginModal;
        this.isLogin = isLogin
    }

    openCloseR = (isLogin) => {
        this.isOpenLoginModal = !this.isOpenLoginModal;
        this.isRegister = isLogin
    }

    setLogin = () => {
        this.isLogin = false;
        this.isRegister = true
    }
    setRegister = () => {
        this.isRegister = false;
        this.isLogin = true
    }
    setIsOpenLoginModal = () => {
        this.isOpenLoginModal = false
    }

    openCloseEP = () => {
        this.isOpenEditPhoneModal = !this.isOpenEditPhoneModal
    }
}

export default new systemStore();