import React from 'react';
import styles from './PersonalCard.module.scss'

const PersonalCard = ({tokens,  name}) => {

    return (
        <div className={styles.mainConteinder}>
            <div className={styles.mainBlock}>
                <h3 className={styles.title}>{name}</h3>
                <p className={styles.description}>Each token will get you a standard delivery with no delivery fee.</p>
                <div className={styles.fakeBlock}></div>
            </div>
            <div className={styles.downBlock}>
                <div className={styles.downCont}>
                    <span className={styles.downText}>{tokens} ×</span>
                    <svg viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg"
                         className={styles.img}>
                        <g fill="none" >
                            <path fill="none" d="M-6-6h72v72H-6z"></path>
                            <path
                                d="M30 0C13.458 0 0 13.458 0 30c0 16.545 13.458 30 30 30s30-13.455 30-30C60 13.458 46.542 0 30 0"
                                fill="#FBBE13"></path>
                            <path
                                d="M30 57C15.111 57 3 44.889 3 30 3 15.114 15.111 3 30 3s27 12.114 27 27c0 14.889-12.111 27-27 27zm0-3C16.765 54 6 43.235 6 30 6 16.768 16.765 6 30 6s24 10.768 24 24c0 13.235-10.765 24-24 24z"
                                fill="#F78E1F"></path>
                            <path
                                d="M41.056 21.108a1.497 1.497 0 00-1.947.834L34.498 33.46 29.89 21.942a1.496 1.496 0 00-1.947-.834 1.495 1.495 0 00-.834 1.947l1.275 3.195-2.886 7.212-4.608-11.52a1.498 1.498 0 00-2.781 1.113l6 15 .009.01c.099.242.273.443.489.605.054.042.105.075.162.111.063.03.105.084.174.111.18.072.372.108.555.108.186 0 .375-.036.558-.108.066-.027.11-.08.174-.11.057-.037.108-.07.162-.112a1.45 1.45 0 00.489-.606l.009-.009 3.108-7.767 3.11 7.767a1.49 1.49 0 001.39.945c.615 0 1.167-.372 1.392-.945l6-15a1.495 1.495 0 00-.834-1.947"
                                fill="#F78E1F"></path>
                        </g>
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default PersonalCard;