import { makeAutoObservable } from "mobx";
import api from "../api/api";

class CartOrderStore {
  carts = {};
  activeOrders = [];
  initialized = false;
  isClicked = false;
  openOrderAgine = false;
  targetOrderCheck = null;
  isBasketOpen = false;
  orderPanelTab = 0;

  constructor() {
    makeAutoObservable(this);
    this.lazyInitialize();
    // this.lazyInitialize2() comment for not auth user error
  }

  get getIsClicked() {
    return this.isClicked;
  }
  get getOrderPanelTab() {
    return this.orderPanelTab;
  }
  setOrderPanelTab = (state) => {
    this.orderPanelTab = state;
  };

  get activeOrdersData() {
    return this.activeOrders;
  }

  setIsClicked = (state) => {
    this.isClicked = state;
  };

  lazyInitialize = (setListItem) => {
    api.get("/cart/").then((response) => {
      this.carts = response.data;
      this.initialized = true;
      if (setListItem) {
        setListItem(response.data.data);
      }
    });
  };

  lazyInitialize2 = (setListItem) => {
    api.get("/active-orders/").then((response) => {
      this.activeOrders = response.data;
    });
  };

  mainInitialyze = () => {
    this.lazyInitialize();
    this.lazyInitialize2();
  };

  addProductToCartOrder = (id, count) => {
    api
      .post("/cart/", { action: "add", ProductID: id, quantity: count })
      .then((response) => {
        this.carts.data = this.carts.data.map((e) => {
          if (e.ProductID === id) {
            e.quantity++;
          }
          return e;
        });
        this.lazyInitialize();
      });
  };

  clearProductToCartOrder = (id) => {
    api.post("/cart/", { action: "clear" }).then((response) => {
      this.carts.data = [];
      this.lazyInitialize();
    });
  };

  minProductToCartOrder = (id, count) => {
    api
      .post("/cart/", { action: "minus", ProductID: id, quantity: count })
      .then((response) => {
        this.carts.data = this.carts.data.map((e) => {
          if (e.ProductID === id) {
            e.quantity--;
          }
          return e;
        });
        this.carts.data = this.carts.data.filter((e) => e.quantity > 1);
        this.lazyInitialize();
      });
  };

  delProductToCartOrder = (id) => {
    api.post("/cart/", { action: "remove", ProductID: id }).then((response) => {
      this.carts.data = this.carts.data.filter((e) => e.ProductID !== id);
      this.lazyInitialize();
    });
  };

  get cartsList() {
    return this.carts;
  }

  setOpenOrderAgine = () => {
    this.openOrderAgine = !this.openOrderAgine;
  };

  get getOrderAgine() {
    return this.openOrderAgine;
  }

  setTargetOrderCheck = (data) => {
    this.targetOrderCheck = data;
  };

  get getTargetOrderCheck() {
    return this.targetOrderCheck;
  }

  setBasketOpen = (arg) => {
    this.isBasketOpen = arg;
  };

  get getIsBasketOpen() {
    return this.isBasketOpen;
  }
}

const cartOrderStore = new CartOrderStore();
export default cartOrderStore;
