import React from "react";

export default function IconSupport(props)  {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-0.5 0 25 25"
            {...props}
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M6.723 5.48a5.251 5.251 0 118.265 4.802M7.44 3.353a8.71 8.71 0 006.26 2.65 8.706 8.706 0 003.347-.666M10.823 9.753a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM4.823 7.175v1.078a3 3 0 003 3h1.423M21.7 23.253a9.74 9.74 0 00-5.23-8.634M2.2 23.253a9.741 9.741 0 015.225-8.632"
            ></path>
        </svg>
    );
}
