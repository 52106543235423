const sortOrdersByDate = (orders) => {
    return orders.sort((a, b) => {
        if (Date.parse(a.created_at) < Date.parse(b.created_at)) {
            return -1;
        } 
        if (Date.parse(a.created_at) > Date.parse(b.created_at)) {
            return 1;
        }
        return 0;
    });
};

export const sortOrdersByStatus = (orderList, orderStatuses) => {
    const storeConfirmationOrders = [];

    const otherStatusesOrders = orderList.reduce((orders, currentOrder) => {
        if (currentOrder.status === orderStatuses.storeConfirmations) {
            storeConfirmationOrders.push(currentOrder);
        } else {
            orders.push(currentOrder);
        }
        return orders;
    }, []);

    const sortedConfirmationOrders = sortOrdersByDate(storeConfirmationOrders);
    const sortedOtherStatusesOrders = sortOrdersByDate(otherStatusesOrders);

    return sortedConfirmationOrders.concat(sortedOtherStatusesOrders);
};
