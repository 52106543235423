import React from 'react';

const DeleteIcon = () => {
    return (
        <span className="dQaVw _6bczw"><svg width="40" height="40" xmlns="http://www.w3.org/2000/svg"><g fill="none"
                                                                                                         fill-rule="evenodd"><path
            fill="#FFFFFF" d="M15.0141 22.6836L24.4571 22.5646 22.8501 34.4156 16.6181 34.4156z"></path><path
            fill="#BAB5BC"
            d="M28.1438 39.9147L11.3238 39.9147 8.4778 20.6997 4.9328 20.6997 4.9328 17.6997 11.0658 17.6997 13.9118 36.9147 25.5558 36.9147 28.4018 17.6997 34.1078 17.6997 34.1078 20.6997 30.9898 20.6997zM5.1301 15.362L3.9411 12.608 30.7241 1.039 31.9131 3.793z"></path><path
            fill="#FFFFFF"
            d="M29.696 8.1332L34.054 5.8502 38.135 6.3462 39.854 10.9872 36.646 14.2222 31.388 13.2712z"></path><path
            fill="#BAB5BC"
            d="M11.8908 10.4177L10.8668 4.3337 19.9538 0.4087 23.6798 5.3257 21.2888 7.1387 18.9828 4.0967 14.2158 6.1547 14.8498 9.9197z"></path></g></svg></span>
    );
};

export default DeleteIcon;