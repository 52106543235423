import React from 'react';
import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import CardIcon from "./CardIcon";
import DeleteIcon from "./DeleteIcon";
import styles from "./ModeratorBodyMenuTable.module.scss";
import {t} from "i18next";

const slideUp = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

const AnimatedBox = styled.div`
  position: fixed;
  bottom: 12%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  gap: 16px;
  animation: ${slideUp} 0.5s ease-out;
`;

const ActionButtons = ({ handleDelete, handleEdit, selectedProductsId }) => {
    return (
        <AnimatedBox>
            <div className={`${styles.button} ${styles.delete}`} onClick={handleDelete}>
                <DeleteIcon />
                <p>{t("Delete Product")}</p>
            </div>
            {selectedProductsId.length < 2 &&
                <div className={`${styles.button} ${styles.edit}`} onClick={handleEdit}>
                    <CardIcon />
                    <p>{t("Edit Product")}</p>
                </div>}
        </AnimatedBox>
    );
};

export default ActionButtons;
