import React, {useState} from 'react';
import {observer} from "mobx-react";
import styles from "./HeaderFantom.module.scss";
import {useNavigate} from "react-router-dom";
import rootStore from "../../store/RootStore";
import {t} from "i18next";
import LikeButton from '../Restoran/Buttons/LikeButton/LikeButton';
import { useTheme } from "../../helpers/ThemeContext";

const HeaderFantom = ({parent = false, shopName}) => {
    const { themeMode } = useTheme();

    const [isOpen, setIsOpen] = useState();
    const { setOpenMoreInfo } = rootStore.shopStore;
    const { clearProductToCartOrder } = rootStore.cartOrderStore;
    const navigate = useNavigate();
    const handleBack = () => {
        if (window.location.pathname.includes("/stores/")) {
            clearProductToCartOrder()
        }
        navigate(-1)
    }
    return (
        <>
            <div className={`${styles.mainConteiner} ${parent ? styles.killAn : ""}`}>
                <div className={`${styles.panelNavigate} ${parent ? styles.whiteBlock : ""}`}>
                    <div style={{
                        "width": "40px",
                        "height": "40px",
                    }} onClick={handleBack}>
                        <button className={`${themeMode === 'dark' ? styles.mainStyleDark :styles.mainStyle} ${parent && styles.backgroundNew}`}>
                            <div className={styles["IconBack__icon"]}>
                                <svg viewBox="0 0 24 24"
                                     width={20}
                                     height={20}
                                     color={"black"}
                                     className={styles.mainSvg}>
                                    <path className={styles.mainSvg}
                                          d="M22.533 10.526H5.422a.251.251 0 01-.165-.438l4.637-3.6a1.44 1.44 0 00-1.9-2.162L.813 10.165a2.4 2.4 0 000 3.6l7.179 5.837a1.44 1.44 0 001.9-2.161l-4.637-3.6a.251.251 0 01.165-.438h17.113a1.44 1.44 0 000-2.88v.003z"
                                    ></path>
                                </svg>
                            </div>
                        </button>
                    </div>
                    {
                        parent && (<div className={styles.shopName}>
                            {shopName}
                        </div>)
                    }
                    <div style={{
                        "width": "40px",
                        "height": "40px",
                    }} 
                    // onClick={() => setIsOpen(!isOpen)}>
                    >
                        <LikeButton/>
                        {/* <button className={`${styles.mainStyle2} ${parent && styles.backgroundNew}`}>
                            <div className={styles["IconBack__icon"]}>
                                <svg viewBox="0 0 400 400"
                                     width={24}
                                     height={20}
                                     color={"black"}
                                     className={styles.mainSvg}>
                                    <g xmlns="http://www.w3.org/2000/svg">
                                        <g id="keyboard-control">
                                            <path
                                                d="M51,153c-28.05,0-51,22.95-51,51s22.95,51,51,51s51-22.95,51-51S79.05,153,51,153z M357,153c-28.05,0-51,22.95-51,51    s22.95,51,51,51s51-22.95,51-51S385.05,153,357,153z M204,153c-28.05,0-51,22.95-51,51s22.95,51,51,51s51-22.95,51-51    S232.05,153,204,153z"/>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        </button> */}
                    </div>
                </div>
            </div>
            {
                isOpen && (
                    <div className={styles.modalInfoPanel}>
                        <button className={styles.btnMoreInfo}
                                onClick={() => setOpenMoreInfo(true)}>{t("More info")}</button>
                        {/*<button className={styles.btnMoreInfo}*/}
                        {/*        onClick={() => navigate("/checkout-mobile")}>{t("Order together")}</button>*/}
                    </div>
                )
            }
        </>
    );
};

export default observer(HeaderFantom);