import { makeAutoObservable } from 'mobx';
import api from "../api/api";
import {setAddress} from "../helpers";
import userStore from "./UserStore";
class AddressStore {
    adrresses = []
    currentAddress = {};


    constructor() {
        makeAutoObservable(this);
    }

    get adrressesList() {
        return this.adrresses;
    }

    get getCurrentAddress() {
        return this.currentAddress;
    }

    setAdrresses = (state) => {
        this.adrresses = state;
    }
    setCurrentAddress = (state) => {
        this.currentAddress = state;
    }

    loadAdressesList = () => {
        if (!userStore.user.authorized) return
        api.get('/user/profile/addresses/all/'
    ).then(response => {
            this.setAdrresses(response.data)
        });
    }

    loadCurrentAddress = (id) => {
        if (!userStore.user.authorized) return
        api.get(`/user/profile/addresses/${id}/`,
        ).then(response => {
            this.setCurrentAddress(response.data)
            setAddress(response.data)
        });
    }

    saveNewAddress = (data) => {
        if (!userStore.user.authorized) return
        api.post('/user/profile/addresses/create/', data
        ).then(response => {
        });
    }

    editAddressRequest = (id, data) => {
        if (!userStore.user.authorized) return
        api.put(`/user/profile/addresses/${id}/`, data
        ).then(response => {
        });
    }

    setUserAddresMain = (data) => {
        if (!userStore.user.authorized) return
        api.post(`/user/profile/addresses/${data}/set-country/`
        ).then(response => {
            setAddress(this.adrresses.filter(item => item.id === data)[0])
        });
    }

    deleteNewAddress = (id) => {
        if (!userStore.user.authorized) return
        api.delete(`/user/profile/addresses/${id}/`
        ).then(response => {
        });
    }
}

const addressStore = new AddressStore();
export default addressStore;