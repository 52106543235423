import React from 'react';
import styles from "./ModeratorBodyMenuTable.module.scss";
import {t} from "i18next";

const ProductSearch = ({ input, setInput }) => {
    return (
        <div className={styles.searchBlockMain}>
            <div className={styles.searchBlockContent}>
                <div data-error="false" id="productsSearch" className={styles.searchBlock} data-status="false">
                    <div className={styles.searchConteiner}>
                        <span className={styles.searchConteinerText}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                                <path fill="#827789" d="M12.5,11 L11.71,11 L11.43,10.73 C12.41,9.59 13,8.11 13,6.5 C13,2.91 10.09,0 6.5,0 C2.91,0 0,2.91 0,6.5 C0,10.09 2.91,13 6.5,13 C8.11,13 9.59,12.41 10.73,11.43 L11,11.71 L11,12.5 L16,17.49 L17.49,16 L12.5,11 L12.5,11 Z M6.5,11 C4.01,11 2,8.99 2,6.5 C2,4.01 4.01,2 6.5,2 C8.99,2 11,4.01 11,6.5 C11,8.99 8.99,11 6.5,11 Z" transform="translate(3 3)"></path>
                            </svg>
                        </span>
                        <input
                            autoComplete="off"
                            type="text"
                            className={styles.searchConteinerInput}
                            placeholder={t("Search product...")}
                            data-value=""
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductSearch;
