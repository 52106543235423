import React, { useEffect, useState } from 'react';

import { t } from "i18next";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";

import styles from "./OwnerNavBar.module.scss"

import MENU from "../../constants/constantMenu";
import rootStore from "../../store/RootStore";

import IconArrowBack from "../Icons/IconArrowBack";

import {getActiveNavOrder, setActiveNavOrder, } from "../../helpers";
import IconSupport from "../Icons/IconSupport";
import IconVerification from "../Icons/IconVerification";
import IconShops from "../Icons/IconShops";
import IconModerator from "../Icons/IconModerator";

const OwnerNavbar = () => {
    const navigate = useNavigate();

    const [buttonNum, setButtonNum] = useState(2);

    const { setIsOpenSettingShop,setActivePanel } = rootStore.moderatorPanelStore;

    const handeSetting = (prop, num) => {
        setButtonNum(num)
        setIsOpenSettingShop(MENU.Setting === prop)
        setActivePanel(prop)
        setActiveNavOrder(prop)
        navigate(prop.toLocaleLowerCase())
    }

    useEffect(() => {
        const stateModer = JSON.parse(getActiveNavOrder());

        if (stateModer === "Support") {
            handeSetting(MENU.support, 1)
        } else if (stateModer === "Verification") {
            handeSetting(MENU.verification, 2)
        } else if (stateModer === "Shops") {
            handeSetting(MENU.shops, 3)
        } else if (stateModer === "Moderators") {
            handeSetting(MENU.moderators, 4)
        }
    }, []);

    return (
        <>
            <div className={styles.navigation}>
                <div className={styles.navigationBody}>
                    <div className={`${styles.linkFirst} ${buttonNum === 1 && styles.activBtn}`}
                         onClick={() => handeSetting(MENU.support, 1)}>
                        <div className={styles.linkFirstText}>
                            <IconSupport/>
                            <span className={buttonNum === 1 && styles.activBtn}>{t("Support")}</span>
                        </div>
                    </div>
                    <div onClick={() => handeSetting(MENU.verification, 2)} id="productsNavigationTab"
                         data-navigation="true"
                         className={`${styles.linkFirst} ${buttonNum === 2 && styles.activBtn}`}>
                        <div className={styles.linkFirstText}>
                            <IconVerification/>
                            <span className={buttonNum === 2 && styles.activBtn}>{t("Verification")}</span>
                        </div>
                    </div>
                    <div className={`${styles.linkFirst} ${buttonNum === 3 && styles.activBtn}`}
                         onClick={() => handeSetting(MENU.shops, 3)}>
                        <div className={styles.linkFirstText}>
                            <IconShops/>
                            <span className={buttonNum === 3 && styles.activBtn}>{t("Shops")}</span>
                        </div>
                    </div>
                    <div className={`${styles.linkFirst} ${buttonNum === 4 && styles.activBtn}`}
                         onClick={() => handeSetting(MENU.moderators, 4)}
                         data-testid="settingsTab">
                        <div className={styles.linkFirstText}>
                            <IconModerator/>
                            <span className={buttonNum === 4 && styles.activBtn}>{t("Moderators")}</span>
                        </div>
                    </div>
                    <div className={styles.linkFirst} onClick={() => navigate("/discovery/me")}
                         data-testid="settingsTab">
                        <div className={styles.linkFirstText}>
                            <IconArrowBack/>
                            <span>{t("Back")}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default observer(OwnerNavbar);