import React from 'react';
import styles from "./Card.module.scss"
import PlusBtn from "../../Buttons/PlusBtn/PlusBtn";
import PopularButton from "../../Buttons/PopularButton/PopularButton";

const Card = ({item}) => {

    return (
        <div className={styles.mainConteiner}>
            <button className={styles.mainBlock}>
                <div className={styles.upBlock}>
                    <div className={styles.upContent}>
                        <h3 className={styles.titleUp}>{item.name}</h3>
                        <p className={styles.descriptionUp}>{item.description}</p>
                    </div>
                    <div className={styles.downContent}>
                        <div className={styles.downContentBlock}>
                            <p className={styles.costBlock}>
                                <span className={styles.newCost}>{item.newCost}</span>
                                <span className={styles.oldCost}>{item.oldCost}</span>
                            </p>
                            {item.isPopular ? (<PopularButton/>) : ""}
                        </div>
                    </div>
                </div>
                <div className={styles.downConteiner}>
                <div className={styles.downBlock}>
                        <div className={styles.downBlockImg}>
                            <img className={styles.img} src={"https://image-resizer-proxy.development.dev.woltapi.com/wolt-dev-development-restaurant-api-menu-images/menu-images/6239aad26db86e12db0d6a47/508e5f9e-e009-11ec-baeb-46f79e1bd9ec_1d94b612_d517_11ec_b241_3a910c5f2f74_22ac70a6_d45c_11ec_84c0_56fa8e13acd9_cc5acf90_d447_11ec_8fae_8262c1d569b9_1197_242_1440x800__0054_49_____________.jpeg"} alt />
                        </div>
                    </div>
                </div>
            </button>
            <PlusBtn/>
        </div>
    );
};

export default Card;