import React, {useEffect, useState} from 'react';
import { Outlet, useLocation, useNavigate} from "react-router-dom";
import DiscoveryIcon from "./static/DiscoveryIcon";
import RestaurantsIcon from "./static/RestaurantsIcon";
import StoresIcon from "./static/StoresIcon";
import useFooterMargin from "./useFootrerMargin";
import { observer, inject } from 'mobx-react';
import WithAuthUser from "../../helpers/WithAuthUser";
import ProfileIcon from "./static/ProfileIcon";
import SearchIcon from "./static/SearchIcon";
import {useTheme} from "../../helpers/ThemeContext";
import rootStore from "../../store/RootStore";
import {t} from "i18next";
import styles from "./DiscoveryWrapper.module.scss"
import NavBarDeskTop from "../../components/NavBarDeskTop/NavBarDeskTop";

const DiscoveryWrapper = inject('rootStore')(observer(() => {
    const { themeMode, toggleTheme } = useTheme();
    const { navBarHidden } = rootStore.systemStore;
    const {pathname} = useLocation();
    const [activeLink, setActiveLink] = useState(() => {
        if (pathname.includes( '/restaurants')) {
            return 'link2';
        } else if (pathname.includes('/stores')) {
            return 'link3';
        }
        else {
            return 'link1';
        }
    });
    const navigate = useNavigate();
    const isStores = pathname.includes('/stores')
    const isRestoran = pathname.includes('restaurants')
    const isDiscovery = pathname === "/discovery"

    useEffect(() => {
        let targetLink = window.location.pathname
        if (targetLink === "/discovery") {
            setActiveLink("link1")
        } else if (targetLink === "/discovery/restaurants"){
            setActiveLink('link2')
        } else if (targetLink === "/discovery/stores"){
            setActiveLink('link3')
        } else if (targetLink === "/discovery/search-store"){
            setActiveLink('link4')
        } else if (targetLink === "/discovery/me"){
            setActiveLink('link5')
        }
    }, []);
    const handleButtonClick = (link) => {
        setActiveLink(link);
        if (link === 'link1') {
            navigate("/discovery")
        } else if (link === 'link2'){
            navigate("/discovery/restaurants")
        } else if (link === 'link3'){
            navigate("/discovery/stores")
        } else if (link === 'link4'){
            navigate("/discovery/search-store")
        } else if (link === 'link5'){
            navigate("/discovery/me")
        }
    };

    useFooterMargin();

    return (
        <WithAuthUser>
            {isDiscovery && <NavBarDeskTop/>}
            <Outlet/>
            <div className={styles.navigation} style={window.location.pathname.includes("order-chat") ? {display: "none"} : {display: "flex"}}>
                <div className={styles.navigationBody}>
                    <div className={`${styles.linkFirst} ${isDiscovery && styles.activBtn}`}
                         onClick={() => handleButtonClick('link1')}>
                        <div className={styles.linkFirstText}>
                            <DiscoveryIcon currentColor={isDiscovery && "#02acf8"}/>
                            <span className={isDiscovery && styles.activBtn}>{t("Discovery")}</span>
                        </div>
                    </div>
                    <div onClick={() => handleButtonClick('link2')} id="productsNavigationTab"
                         data-navigation="true"
                         className={`${styles.linkFirst} ${isRestoran && styles.activBtn}`}>
                        <div className={styles.linkFirstText}>
                            <RestaurantsIcon currentColor={isRestoran && "#02acf8"}/>
                            <span className={isRestoran && styles.activBtn}>{t("Restaurants")}</span>
                        </div>
                    </div>
                    <div className={`${styles.linkFirst} ${isStores && styles.activBtn}`}
                         onClick={() => handleButtonClick('link3')}>
                        <div className={styles.linkFirstText}>
                            <StoresIcon currentColor={isStores && "#02acf8"}/>
                            <span className={isStores && styles.activBtn}>{t("Stores")}</span>
                        </div>
                    </div>
                    <div className={`${styles.linkFirst} ${activeLink === 'link4' && styles.activBtn}`}
                         onClick={() => handleButtonClick('link4')}>
                        <div className={styles.linkFirstText}>
                            <SearchIcon currentColor={activeLink === 'link4' && "#02acf8"}/>
                            <span className={activeLink === 'link4' && styles.activBtn}>{t("Search")}</span>
                        </div>
                    </div>
                    <div className={`${styles.linkFirst} ${activeLink === 'link5' && styles.activBtn}`}
                         onClick={() => handleButtonClick('link5')}
                         data-testid="settingsTab">
                        <div className={styles.linkFirstText}>
                            <ProfileIcon currentColor={activeLink === 'link5' && "#02acf8"}/>
                            <span className={activeLink === 'link5' && styles.activBtn}>{t("Profile")}</span>
                        </div>
                    </div>
                </div>
            </div>
        </WithAuthUser>
    );
}));

export default DiscoveryWrapper;