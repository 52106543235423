import api from "../api";

export const deleteOwnerCategory = async (id) => {
    if (id) {
        try {
            const apiResponse = await api.delete(`/owner_site/shops/categories/${id}/delete/`);
            return apiResponse;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }
};
