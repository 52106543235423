import React from 'react';
import styles from './DiscoveryBody.module.scss'
import DiscoveryHeader from "../../components/Discovery/DiscoveryHeader/DiscoveryHeader";
import DiscoveryBody from "../../components/Discovery/DiscoveryBody/DiscoveryBody";

const MainPage = () => {
    return (
        <div className={styles.mainPage}>
            <div>
                <h1 className={styles.title}>Discovery</h1>
                <div>
                    <DiscoveryHeader/>
                    <DiscoveryBody/>
                </div>
            </div>
        </div>
    );
};

export default MainPage;