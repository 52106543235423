import React from 'react';
import styles from './PaymentMethods.module.scss'
import IconBack from "../../Icons/IconBack";
import {useNavigate} from "react-router-dom";
import {useTheme} from "../../../helpers/ThemeContext";
import {t} from "i18next";

const PaymentMethods = () => {
    const navigate = useNavigate();
    const {themeMode} = useTheme()

    return (
        <>
            <div className={styles.backer} style={{
                "position": "fixed",
                "width": "32px",
                "height": "32px",
                "zIndex": 9999999,
                "top": "1%",
                "left": "3%",
            }}
                 onClick={() => navigate(-1)}><IconBack size={20} pure={true} fill={"#009de0"}/></div>
            <div className={styles.lvl1}>
                <div className={styles.lvl2}>
                    <div className={styles.lvl3}>
                        <div className={styles.lvl4}>
                            <div className={styles.lvl5}>
                                <div data-test-id="ServerDrivenPaymentMethodsList"></div>
                                <div></div>
                                <div className={styles.paymentMethods}>
                                    <div className={styles.paymentMethodsList}>
                                        <h1 className={styles.title}>{t("Payment methods")}</h1>
                                        <div className={styles.paymentGroup}>
                                            <h2 className={styles.groupTitle}>{t("Credit and debit cards")}</h2>
                                            <button className={styles.addButton} data-button-action="add_card">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" id="plus"
                                                     fill={themeMode === 'dark' ? 'white' : 'black'}>
                                                    <g fill={themeMode === 'dark' ? 'white' : 'black'}
                                                       stroke={themeMode === 'dark' ? 'white' : 'black'}
                                                       stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                                                        <path d="M8 1v14M1 8h14"></path>
                                                    </g>
                                                </svg>
                                                {t("Add new card")}
                                            </button>
                                        </div>
                                        <div className={styles.paymentGroup}>
                                            <h2 className={styles.groupTitle}>{t("Other methods")}</h2>
                                            <button className={styles.paymentMethod} data-payment-method-id="googlepay">
                                                <span className={styles.paymentMethodIcon}>
                                                    <svg viewBox="0 0 38 20"><path
                                                        d="M27.5119 0H9.96809C4.48564 0 0 4.48564 0 9.96809C0 15.4505 4.48564 19.9362 9.96809 19.9362H27.5119C32.9944 19.9362 37.48 15.4505 37.48 9.96809C37.48 4.48564 32.9944 0 27.5119 0Z"
                                                        fill="white"></path><path
                                                        d="M27.5119 0.807415C28.743 0.807415 29.9391 1.05163 31.0655 1.5301C32.157 1.99362 33.1339 2.6565 33.9812 3.4988C34.8235 4.3411 35.4864 5.32296 35.9499 6.41447C36.4284 7.54086 36.6726 8.73703 36.6726 9.96809C36.6726 11.1992 36.4284 12.3953 35.9499 13.5217C35.4864 14.6132 34.8235 15.5901 33.9812 16.4374C33.1389 17.2797 32.157 17.9426 31.0655 18.4061C29.9391 18.8845 28.743 19.1288 27.5119 19.1288H9.96809C8.73703 19.1288 7.54086 18.8845 6.41446 18.4061C5.32296 17.9426 4.34609 17.2797 3.4988 16.4374C2.65649 15.5951 1.99362 14.6132 1.5301 13.5217C1.05163 12.3953 0.807415 11.1992 0.807415 9.96809C0.807415 8.73703 1.05163 7.54086 1.5301 6.41447C1.99362 5.32296 2.65649 4.34609 3.4988 3.4988C4.3411 2.6565 5.32296 1.99362 6.41446 1.5301C7.54086 1.05163 8.73703 0.807415 9.96809 0.807415H27.5119ZM27.5119 0H9.96809C4.48564 0 0 4.48564 0 9.96809C0 15.4505 4.48564 19.9362 9.96809 19.9362H27.5119C32.9944 19.9362 37.48 15.4505 37.48 9.96809C37.48 4.48564 32.9944 0 27.5119 0Z"
                                                        fill="#3C4043"></path><path
                                                        d="M17.8728 10.6759V13.6912H16.9159V6.245H19.4527C20.0957 6.245 20.6439 6.45932 21.0925 6.88795C21.551 7.31659 21.7803 7.83991 21.7803 8.45794C21.7803 9.09092 21.551 9.61425 21.0925 10.0379C20.6489 10.4615 20.1007 10.6709 19.4527 10.6709H17.8728V10.6759ZM17.8728 7.16208V9.75879H19.4727C19.8515 9.75879 20.1704 9.6292 20.4196 9.37501C20.6738 9.12083 20.8034 8.81181 20.8034 8.46292C20.8034 8.11902 20.6738 7.81499 20.4196 7.56081C20.1704 7.29665 19.8564 7.16706 19.4727 7.16706H17.8728V7.16208Z"
                                                        fill="#3C4043"></path><path
                                                        d="M24.2823 8.42802C24.99 8.42802 25.5482 8.61742 25.9569 8.9962C26.3656 9.37499 26.57 9.89333 26.57 10.5512V13.6912H25.6579V12.9834H25.618C25.2243 13.5666 24.696 13.8556 24.0381 13.8556C23.4749 13.8556 23.0064 13.6912 22.6276 13.3572C22.2488 13.0233 22.0594 12.6096 22.0594 12.1112C22.0594 11.5829 22.2588 11.1643 22.6575 10.8553C23.0562 10.5413 23.5895 10.3868 24.2524 10.3868C24.8206 10.3868 25.2891 10.4914 25.6529 10.7007V10.4814C25.6529 10.1475 25.5233 9.86841 25.2592 9.63416C24.995 9.39991 24.686 9.28528 24.3321 9.28528C23.7989 9.28528 23.3752 9.50956 23.0662 9.96311L22.2239 9.4348C22.6874 8.76195 23.3752 8.42802 24.2823 8.42802ZM23.0463 12.1262C23.0463 12.3754 23.1509 12.5847 23.3652 12.7492C23.5746 12.9137 23.8238 12.9984 24.1079 12.9984C24.5116 12.9984 24.8704 12.8489 25.1844 12.5498C25.4984 12.2508 25.6579 11.9019 25.6579 11.4982C25.3589 11.2639 24.9452 11.1443 24.4119 11.1443C24.0231 11.1443 23.6992 11.239 23.44 11.4234C23.1758 11.6178 23.0463 11.8521 23.0463 12.1262Z"
                                                        fill="#3C4043"></path><path
                                                        d="M31.7732 8.59249L28.5834 15.9291H27.5965L28.7827 13.3623L26.6795 8.59249H27.7211L29.2363 12.2508H29.2562L30.7315 8.59249H31.7732Z"
                                                        fill="#3C4043"></path><path
                                                        d="M14.0666 10.0678C14.0666 9.75577 14.0386 9.45722 13.9868 9.17014H9.97565V10.8149L12.2858 10.8154C12.1921 11.3626 11.8905 11.8291 11.4285 12.1401V13.2072H12.8036C13.6065 12.4641 14.0666 11.3656 14.0666 10.0678Z"
                                                        fill="#4285F4"></path><path
                                                        d="M11.4289 12.1401C11.0462 12.3983 10.5532 12.5493 9.97658 12.5493C8.86265 12.5493 7.91767 11.7987 7.57926 10.7869H6.1608V11.8874C6.86355 13.282 8.30792 14.2389 9.97658 14.2389C11.1299 14.2389 12.0988 13.8596 12.804 13.2067L11.4289 12.1401Z"
                                                        fill="#34A853"></path><path
                                                        d="M7.44569 9.97058C7.44569 9.68649 7.49304 9.41187 7.57926 9.1537V8.05322H6.1608C5.87023 8.62987 5.70676 9.28079 5.70676 9.97058C5.70676 10.6604 5.87073 11.3113 6.1608 11.8879L7.57926 10.7875C7.49304 10.5293 7.44569 10.2547 7.44569 9.97058Z"
                                                        fill="#FABB05"></path><path
                                                        d="M9.97658 7.39134C10.6061 7.39134 11.1698 7.60814 11.6148 8.03179L12.8334 6.81418C12.0933 6.12489 11.1284 5.70175 9.97658 5.70175C8.30842 5.70175 6.86355 6.65868 6.1608 8.05322L7.57926 9.15369C7.91767 8.14193 8.86265 7.39134 9.97658 7.39134Z"
                                                        fill="#E94235"></path>
                                                    </svg>
                                                </span>
                                                <span className={styles.paymentMethodName}>Google Pay</span>
                                                <span className={styles.paymentMethodDetails}></span>
                                                <button type="button" aria-haspopup="true"
                                                        className={styles.profileActionsButton}>
                                                    <svg viewBox="0 0 24 24" className={styles.otherImg}>
                                                        <path
                                                            d="M6.5 12A3.25 3.25 0 1 1 0 12a3.25 3.25 0 0 1 6.5 0ZM24 12a3.25 3.25 0 1 1-6.5 0 3.25 3.25 0 0 1 6.5 0Zm-8.75 0a3.25 3.25 0 1 1-6.5 0 3.25 3.25 0 0 1 6.5 0Z"></path>
                                                    </svg>
                                                </button>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PaymentMethods;