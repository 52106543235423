import React, {useEffect, useState} from 'react';
import styles from "./CategoryStore.module.scss"
import StoreSerach from "../StoreSearch/StoreSerach";
import Category from "./Category/Category";
import {observer} from "mobx-react";
import rootStore from "../../../../store/RootStore";
import Box from "@mui/material/Box";
import {CircularProgress} from "@mui/material";

const defaultCategoryList =
    [{
        image: "https://imageproxy.wolt.com/menu/menu-images/63f8bffc06bbde74b8eec406/06cbe022-bf18-11ed-af2d-ee3742ebf0b4____________02.jpeg?w=200",
        CategoryName: "ALL",
        CategoryID:0,
        active: true,
    },]

const CategoryStore = ({categoryList}) => {
    const setCurrentCategoryID = rootStore.categoriesStore.setCurrentCategoryID;
    const {getLoader, setLoader} = rootStore.shopStore;
    const [categoryListData, setCategoryListData] = useState(defaultCategoryList);
    const [isSwitch, setSwitch] = useState(false);

    const updatedCategoryList = categoryList.map(item => ({
        ...item,
        active: false
    }));

    useEffect(() => {
        setCategoryListData(prevState => [...prevState, ...updatedCategoryList]);
        return () => setCategoryListData(defaultCategoryList);
    }, [categoryList]);

    const toggleActivity = (id) => {
        setCurrentCategoryID(id);
        setCategoryListData(prevState => prevState.map(el => {
            if (el.CategoryID === id) {
                return {
                    ...el,
                    active: true
                }
            } else {
                return {
                    ...el,
                    active: false
                }
            }
        }));
    };
    if(getLoader && !isSwitch){
        return (
            <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh"}}>
                <CircularProgress/>
            </Box>
        )
    }

    return (
        <aside className={styles.mainConteiner}>
            <div className={styles.mainBlock}>
                <StoreSerach/>
                <div className={styles.categoryBlock}>
                    <div className={styles.categoryMain}>
                        <div>
                            {
                                categoryListData.map((el, key) => (
                                    <Category el={el} key={key} toggleActivity={toggleActivity} state={setSwitch}/>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className={styles.downFakeBlock}>

                </div>
            </div>
        </aside>
    );
};

export default observer(CategoryStore);