import React, {useEffect} from 'react';
import styles from './SettingsTab.module.scss'
import "./SettingsTabMui.scss"
import {FormControl, MenuItem, OutlinedInput, Select} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {observer} from "mobx-react";
import rootStore from "../../../store/RootStore";
import EditPhone from "../../ModalWindows/EditPhone/EditPhone";


const ITEM_HEIGHT = 96;
const ITEM_PADDING_TOP = 4;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 150,
            borderRadius: 0,
        },
    },
};


function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.map((e)=>e.name).indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const SettingsTab = observer(() => {
    const { isOpenEditPhoneModal, openCloseEP } = rootStore.systemStore;
    const { getProfile, userData, countrysList, changeCountry } = rootStore.userStore;


    useEffect(() => {
        getProfile()
    }, []);

    const theme = useTheme();
    const [personName, setPersonName] = React.useState();

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        changeCountry(value);
        setPersonName(value)
    };

    return (
        <div className={styles.lvl1}>
            {
                isOpenEditPhoneModal ? (<EditPhone/>) : ""
            }
            <div className={styles.lvl2}>
                <div className={styles.lvl3}>
                    <div className={styles.mainConteiner}>
                        <section className={styles.selectRow}>
                            <div className={styles.contentRowBlock}>
                                <div className={styles.nameRowCountry}>
                                    <h3 className={styles.nameRowCountryTitle}>Country</h3>
                                    <span className={styles.nameRowCountryDesc}>The selected country determines the currency of your referral code</span>
                                </div>
                                <div>
                                    <FormControl sx={{ m: 1, width: 150 }}>
                                        <Select
                                            labelId="demo-multiple-name-label"
                                            id="demo-multiple-name"
                                            sx={{width: 150 , borderRadius: 2}}
                                            value={userData.profile.country}
                                            onChange={handleChange}
                                            input={userData.profile.country ? "" : <OutlinedInput label="Name" />}
                                            MenuProps={MenuProps}
                                        >
                                            {countrysList.map((element) => (
                                                <MenuItem
                                                    key={element.id}
                                                    value={element.name}
                                                    sx={{width: 150}}
                                                    style={getStyles(element.name, countrysList, theme)}
                                                    className={styles.checkHover}
                                                >
                                                    {element.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </section>
                        <section className={styles.selectRow}>
                            <div className={styles.contentRowBlock}>
                                <div className={styles.nameRow}>
                                    Email
                                </div>
                                <button type="button" className={styles.logOutBtn}>
                                    <span>{userData.profile.username + "@gmail.com"}</span>
                                </button>
                            </div>
                        </section>
                        <section className={styles.selectRow}>
                            <div className={styles.contentRowBlock}>
                                <div className={styles.nameRow}>
                                    Name
                                </div>
                                <button type="button" className={styles.logOutBtn}>
                                    <span>{userData.profile.username}</span>
                                </button>
                            </div>
                        </section>
                        <section className={styles.selectRow}>
                            <div className={styles.contentRowBlock}>
                                <div className={styles.nameRow}>
                                    Clear auto-translation settings
                                </div>
                                <button type="button" className={styles.logOutBtn}>
                                    <span>Reset</span>
                                </button>
                            </div>
                        </section>
                        <section className={styles.selectRow}>
                            <div className={styles.contentRowBlock}>
                                <div className={styles.nameRow}>
                                    Log out of Wolt
                                </div>
                                <button type="button" className={styles.logOutBtn}>
                                    <span>Log out</span>
                                </button>
                            </div>
                        </section>
                        <div className={styles.selectBlock}>
                            <div className={styles.downTitle}>Notifications</div>
                            <div>
                                <section className={styles.selectRow}>
                                    <div className={styles.contentRowBlock}>
                                        <div className={styles.nameRow}>
                                            Allow marketing notifications
                                        </div>
                                        <label className={styles.switch}>
                                            <input type="checkbox"/>
                                            <span className={`${styles.slider} ${styles.round}`}></span>
                                        </label>
                                    </div>
                                </section>
                            </div>
                        </div>
                        <div className={styles.finishRow}>
                            <span>You joined Wolt on April 8th 2024. You’ve been using Wolt for 9 days ‪🎉</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default SettingsTab;