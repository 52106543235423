import React, { useState, useRef } from 'react';
import styles from './PaymentMethods.module.scss';
import {observer} from "mobx-react";
import IconBack from "../../../components/Icons/IconBack";
import {useTheme} from "../../../helpers/ThemeContext";
import {t} from "i18next";



const PaymentMethods = ({isOpen, setIsOpen, method, setMethod}) => {
    const modalRef = useRef(null);
    const [text, setText] = useState("")
    const {themeMode} = useTheme()
    const [isClosing, setIsClosing] = useState(false);
    const handleCloseModal = () => {
        setIsOpen(false);
    };

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            setIsOpen(false);
            setIsClosing(false);
        }, 500);
    }

    return (
        <div>
            {isOpen && (
                <div id="modalka" className={styles["modal-overlay"]} onClick={(ev)=> handleClose()}>
                    <div className={`${styles["modal-content"]} ${isClosing ? styles.fadeOutAnimation : ""}`}
                         ref={modalRef}>
                        <div className={styles.closeBtnConteiner}>
                            <button className={`${styles.IconBack} ${styles.mainStyle}`} style={{
                                marginTop: "5px",
                                zIndex: 999999,
                            }} onClick={handleClose}>
                                <div className={styles["IconBack__icon"]}>
                                    <svg viewBox="0 0 24 24"
                                         width={24}
                                         height={24}
                                         color={"black"}
                                         className={styles.mainSvg}>
                                        <path className={styles.mainSvg}
                                              d="M22.533 10.526H5.422a.251.251 0 01-.165-.438l4.637-3.6a1.44 1.44 0 00-1.9-2.162L.813 10.165a2.4 2.4 0 000 3.6l7.179 5.837a1.44 1.44 0 001.9-2.161l-4.637-3.6a.251.251 0 01.165-.438h17.113a1.44 1.44 0 000-2.88v.003z"
                                        ></path>
                                    </svg>
                                </div>
                            </button>
                        </div>
                        <div>
                            <h2 className={styles.titleBody}>{t("Payment methods")}</h2>
                            <div className={styles.paymentGroup}>
                                <h2 className={styles.groupTitle}>{t("Balance or cash")}</h2>
                                <button className={styles.addButton} data-button-action="add_card"
                                        onClick={() => setMethod(1)}>
                                    {
                                        method === 1 ?
                                            (
                                                <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1"
                                                     viewBox="0 0 500 500" className={styles.iconPayYes}>
                                                    <g>
                                                        <polygon className="st0"
                                                                 points="434.8,49 174.2,309.7 76.8,212.3 0,289.2 174.1,463.3 196.6,440.9 196.6,440.9 511.7,125.8 434.8,49     "></polygon>
                                                    </g>
                                                </svg>
                                            ) :
                                            (
                                                <div className={styles.roundBlock}></div>
                                            )
                                    }
                                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                                         className={styles.paymentBtnLeftImg}>
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M24 10.75V17a3 3 0 0 1-2.824 2.995L21 20H3a3 3 0 0 1-2.995-2.824L0 17v-6.25a.25.25 0 0 1 .25-.25h23.5a.25.25 0 0 1 .25.25ZM8 14H5a1 1 0 1 0 0 2h3a1 1 0 1 0 0-2ZM21 3a3 3 0 0 1 2.995 2.824L24 6v1.25a.25.25 0 0 1-.25.25H.25A.25.25 0 0 1 0 7.25V6a3 3 0 0 1 2.824-2.995L3 3h18Z"></path>
                                    </svg>
                                    {t("Balance")}
                                </button>
                                <button className={styles.addButton} data-button-action="add_card"
                                        onClick={() => setMethod(2)}>
                                    {
                                        method === 2 ?
                                            (
                                                <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1"
                                                     viewBox="0 0 500 500" className={styles.iconPayYes}>
                                                    <g>
                                                        <polygon className="st0"
                                                                 points="434.8,49 174.2,309.7 76.8,212.3 0,289.2 174.1,463.3 196.6,440.9 196.6,440.9 511.7,125.8 434.8,49     "></polygon>
                                                    </g>
                                                </svg>
                                            ) :
                                            (
                                                <div className={styles.roundBlock}></div>
                                            )
                                    }
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         fill="#000000"
                                         height="24px" width="24px" version="1.1" id="Capa_1"
                                         viewBox="0 0 435 435">
                                        <g>
                                            <path
                                                d="M452.585,98.818H58.465c-5.247,0-9.5,4.253-9.5,9.5v14.98h-14.98c-5.247,0-9.5,4.253-9.5,9.5v14.979H9.5   c-5.247,0-9.5,4.253-9.5,9.5v196.49c0,5.247,4.253,9.5,9.5,9.5h394.117c5.247,0,9.5-4.253,9.5-9.5v-14.98h14.978   c5.247,0,9.5-4.253,9.5-9.5v-14.98h14.99c5.247,0,9.5-4.253,9.5-9.5v-196.49C462.085,103.071,457.832,98.818,452.585,98.818z    M46.808,166.777C43.341,180.385,32.607,191.118,19,194.586v-27.809H46.808z M394.117,194.586   c-13.609-3.467-24.343-14.201-27.811-27.809h27.811V194.586z M346.917,166.777c4.027,24.105,23.095,43.173,47.201,47.199v83.09   c-24.107,4.026-43.175,23.095-47.201,47.201H66.198C62.172,320.162,43.105,301.094,19,297.067v-83.091   c24.105-4.028,43.171-23.094,47.198-47.199H346.917z M19,316.457c13.608,3.468,24.342,14.202,27.808,27.811H19V316.457z    M366.306,344.267c3.467-13.609,14.202-24.344,27.811-27.811v27.811H366.306z M418.595,319.787h-5.478v-162.51   c0-5.247-4.253-9.5-9.5-9.5H43.484v-5.479h375.11V319.787z M443.085,295.307h-5.49v-162.51c0-5.247-4.253-9.5-9.5-9.5H67.965v-5.48   h375.12V295.307z"/>
                                            <path
                                                d="M161.564,311.56c11.817,15.934,27.797,24.708,44.994,24.708c17.198,0,33.178-8.775,44.995-24.708   c11.187-15.083,17.347-34.984,17.347-56.038c0-21.055-6.16-40.956-17.347-56.038c-11.817-15.934-27.797-24.708-44.995-24.708   c-17.197,0-33.177,8.775-44.994,24.708c-11.187,15.082-17.347,34.983-17.347,56.038   C144.218,276.576,150.378,296.478,161.564,311.56z M206.559,317.268c-9.053,0-17.463-3.981-24.423-10.77   c1.486-12.161,11.868-21.612,24.423-21.612c12.556,0,22.938,9.451,24.424,21.612C224.022,313.287,215.612,317.268,206.559,317.268z    M206.562,265.49c-8.022,0-14.549-6.526-14.549-14.548c0-8.022,6.526-14.549,14.549-14.549c8.021,0,14.548,6.526,14.548,14.549   C221.109,258.963,214.583,265.49,206.562,265.49z M206.559,193.776c23.898,0,43.342,27.699,43.342,61.747   c0,11.458-2.209,22.189-6.042,31.396c-3.208-5.279-7.508-9.82-12.579-13.322c5.479-5.973,8.83-13.929,8.83-22.655   c0-18.499-15.05-33.549-33.548-33.549c-18.499,0-33.549,15.05-33.549,33.549c0,8.725,3.35,16.68,8.829,22.653   c-5.072,3.502-9.374,8.044-12.582,13.324c-3.833-9.206-6.042-19.938-6.042-31.396C163.218,221.475,182.66,193.776,206.559,193.776z   "/>
                                        </g>
                                    </svg>
                                    {t("Cash")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div onClick={() => setIsOpen(true)}></div>
        </div>
    );
};

export default observer(PaymentMethods);
