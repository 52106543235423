import React, { useState } from "react";
import Draggable from "react-draggable";
import styles from "./RoundVidjet.module.scss";
import { observer } from "mobx-react";
import rootStore from "../../store/RootStore";

const RoundVidjet = () => {
    const { getIsOpenVidjet, setIsOpenVidjet } = rootStore.systemStore;
    const { getIsBasketOpen: basketOpen, setBasketOpen, setOrderPanelTab } = rootStore.cartOrderStore;

    const [draggingTime, setDraggingTime] = useState(0);
    const [dragStarted, setDragStarted] = useState(false);

    const handleCloseVidjet = () => {
        setIsOpenVidjet(false);
        console.log("Vidjet closed:", getIsOpenVidjet());
    };

    const handleOpenOrder = (e) => {
        console.log("Test");
        setOrderPanelTab(1)
        setBasketOpen(true);
    };

    const handleDragStart = (e) => {
        setDragStarted(true);
        setDraggingTime(0);  // Сбрасываем время на начало перетаскивания
    };

    const handleDragStop = (e) => {
        setDragStarted(false);
        const timeSpentDragging = new Date().getTime() - draggingTime;
        if (timeSpentDragging < 300) {
            handleOpenOrder(e);
        }
    };

    return (
        <div className={styles.container}>
            {getIsOpenVidjet && (
                <Draggable
                    cancel={`.${styles.closeButton}`}  // Отменяем перетаскивание при клике на кнопку закрытия
                    onStart={(e) => {
                        handleDragStart(e);
                        setDraggingTime(new Date().getTime());  // Начинаем отсчет времени
                    }}
                    onStop={handleDragStop}
                >
                    <div
                        className={styles.widget}
                        onClick={handleOpenOrder}  // Обрабатываем клик по самому виджету
                    >
                        <p>Orders</p>
                        <button
                            className={styles.closeButton}
                            onClick={(e) => {
                                e.stopPropagation();  // Останавливаем всплытие при клике на кнопку закрытия
                                handleCloseVidjet();
                            }}
                        >
                            ×
                        </button>
                    </div>
                </Draggable>
            )}
        </div>
    );
};

export default observer(RoundVidjet);
