import React, { useRef } from 'react';
import styles from './ItemsModal.module.scss';
import {observer} from "mobx-react";
import {useTheme} from "../../../helpers/ThemeContext";
import ProductRow from "../AddressInfo/ProductRow";
import {t} from "i18next";


const ItemsModal = ({isOpen, setIsOpen, items,currency}) => {
    const modalRef = useRef(null);

    const {themeMode} = useTheme()

    const handleClose = (ev) => {
        if (ev.target.id === "modalka"){
            setIsOpen(false);
        }
    }

    const handleSave = () => {
        setIsOpen(false);
    }

    return (
        <div>
            {isOpen && (
                <div id="modalka" className={styles["modal-overlay"]} onClick={(ev)=> handleClose(ev)}>
                    <div className={styles["modal-content"]} ref={modalRef}>
                        <div className={styles.closeBtnConteiner}>
                            <button className={`${styles.IconBack} ${styles.mainStyle}`} style={{
                                marginTop: "5px",
                                zIndex: 999999,
                            }} onClick={() => setIsOpen(false)}>
                                <div className={styles["IconBack__icon"]}>
                                    X
                                </div>
                            </button>
                        </div>
                        <div className={styles.productsTitle}>{t("Products list")}</div>
                        <div className={styles.itemsBlock}>
                            {
                                items.map((item) => (<ProductRow product={item} currency={currency}/>))
                            }
                        </div>
                    </div>
                </div>
            )}
            <div onClick={() => setIsOpen(true)}></div>
        </div>
    );
};

export default observer(ItemsModal);
