import React, {useEffect, useState} from 'react';
import styles from "./Category.module.scss"
import {getImageCategory} from "../../../../../api/getContentsApi/getImageCategory";

const Category = ({el, toggleActivity, state}) => {
    const [staticImage, setStaticImage] = useState('');
    const [image, setImage] = useState('');

    useEffect(() => {
        if(el.image){
            setStaticImage(el.image)
        }
        else {
            getImageCategory(el.CategoryID).then(response => setImage(response?.data?.ImageUrl));
        }
    }, [el]);

    const handleClick = () => {
        state(true)
        toggleActivity(el.CategoryID);
    };

    return (
        <div className={`${styles.mainConteiner} ${ el.active ? styles.active : ''}`} onClick={handleClick}>
            <a className={styles.mainBlock}>
                <div className={styles.mainContent}>
                    <div className={styles.imgBlock}>
                        <img src={staticImage || process.env.REACT_APP_MEDIA_URL + image} className={styles.img} alt/>
                    </div>
                </div>
                <div className={styles.fakeBlock}>
                    {el.CategoryName}
                </div>
            </a>
        </div>
    );
};

export default Category;