import rootStore from "../store/RootStore";
const CURRENCY_ARRAY = [
{ code: "USD", name: "$" },
{ code: "EUR", name: "€" },
{ code: "ILS", name: "₪" },
{ code: "RUB", name: "₽" },
{ code: "MXN", name: "₱" }
]
export const getCurrencySymbol = () => {
    const {currentCurrencyData} = rootStore.userStore
    if (currentCurrencyData) {
        return CURRENCY_ARRAY.find((item) => item.code === currentCurrencyData).name;
    }
    return CURRENCY_ARRAY.find((item) => item.code === "USD").name;
}