import React, {useRef, useState} from 'react';
import ButtonExitVideo from '../Buttons/ButtonExitVideo/ButtonExitVideo';
import styles from './VideoPlayerBlock.module.scss'

const VideoPlayerBlock = () => {
    const videoRef= useRef(null);
    const [isFullScreen, setIsFullScreen] = useState(false);

    const handleClick = () => {
        if (videoRef.current) {
            setIsFullScreen(!isFullScreen);
            videoRef.current.play();
        }
    };

    return (
        <div className={styles.videoPlayerBlock}>
            <div className={styles.videoPlayerConteiner}>
                <div className={styles.contentConteiner}>
                    <div className={styles.backgroundImgBlock}>
                        <div className={styles.shadowBlock}></div>
                    </div>
                    <div className={styles.leftContent2}>
                        <div className={styles.fakeBlock}></div>
                        <div className={styles.textContentBlock}>
                            <h2 className={styles.h2Title}>What is Wolt?</h2>
                            <p className={styles.helpText}>Delivered.</p>
                            <p className={styles.textMain}>Wolt makes it incredibly easy for you to discover and get what
                                you want. Delivered to you
                                – quickly, reliably and affordably.</p>

                            <button className={styles.playerBtn} tabIndex="0" aria-label="Show me" onClick={handleClick}>
                                <div className={styles.videoImgBlock}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" className={styles.playImg}>
                                        <path fill="currentColor"
                                              d="M23.987 22.973c0-1.109.792-1.604 1.775-1.103L39.1 28.667c.98.5.984 1.308 0 1.81l-13.338 6.796c-.98.5-1.775.004-1.775-1.103V22.973z"></path>
                                    </svg>
                                </div>
                                <span className={styles.playerBtnText}>Watch video</span>
                            </button>
                        </div>
                    </div>
                    <div className={styles.rightContent2}>
                        {!isFullScreen ? (<div className={styles.fakeBlock}></div>) : ""}
                        <video className={`${styles.video} ${isFullScreen ? styles.videoFull : ''}`} ref={videoRef}
                               poster="https://consumer-static-assets.wolt.com/frontpage-assets/video-cover-image-4.jpg"
                               tabIndex="-1"
                               src="https://consumer-static-assets.wolt.com/frontpage-assets/Wolt_Brand_Film_2020.mp4">
                        </video>
                    </div>
                    {isFullScreen ? <ButtonExitVideo seterVideoState={setIsFullScreen} refVideo={videoRef}/> : ""}
                </div>
            </div>
        </div>
    );
};

export default VideoPlayerBlock;

