import api from "../api";

export const getShopProducts = async (id) => {
    try {
        // if (id){
        //     return await api.get(`/product-images/${id}/`)
        // }
    } catch (error) {
        return "";
    }
}