import React, {useEffect, useState } from 'react';
import {observer} from "mobx-react";
import rootStore from "../../store/RootStore";
import styles from "./ModeratorSettings.module.scss"
import CreateNewShop from "../CreateNewShopModal/CreateNewShop";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "../TabPanel/TabPanel";
import useIsMobile from "../../helpers/useIsMobile";
import IconShop from "../Icons/IconShop";
import {t} from "i18next";
import {
    CircularProgress,
    Paper,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@mui/material";
import {getSuperUserShops} from "../../api/getContentsApi/getSuperUserShops";
import {Link, useNavigate} from "react-router-dom";

const ModeratorSetting = () => {
    const navigate = useNavigate();
    const { getIsOpenSettingShop,getIsOpenCreateShop } = rootStore.moderatorPanelStore;
    const [shops, setShops] = useState([]);

    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const isMobile = useIsMobile();
    useEffect(() => {
        const fetchShops = async () => {
            try {
                setLoading(true);
                const data = await getSuperUserShops();
                setShops(data);
            } catch (error) {
                console.error("An error occurred while fetching users:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchShops()
    },[])
    const handleChangeOpen = () => {
        // setIfOpenCreateShop(true);
        navigate('./create-edit-shop',{relative:true})
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({

        padding: '7px',
    }));

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            { getIsOpenSettingShop && getIsOpenCreateShop ?
                    (
                        <CreateNewShop />
                    ) :
                <div className={styles.mainConteiner}>
                    <div className={styles.mainBlock}>
                        <div className={styles.categoryImgBlock}>{t("Shop Settings")}
                            <div className={styles.createCategoryBlock}>
                                <button
                                    data-label="Create new Shop"
                                    id="createProductsGroup"
                                    className={styles.createCategoryBtn}
                                    onClick={handleChangeOpen}
                                >
                                <span className="dQaVw _8EVa2">
                                   <IconShop/>
                                </span>
                                    <span>{t("Create new Shop")}</span>
                                </button>
                            </div>
                        </div>
                        <div className={styles.fakeBlock}></div>
                        <div className={styles.bigCategoryBlock}>
                        </div>
                        <div className={styles.fakeBlock}></div>

                        <Box
                            sx={{
                                flexGrow: 1,
                                bgcolor: 'background.paper',
                                display: 'flex',
                                flexDirection: isMobile ? "column" : "row",
                                height: "100%",
                                padding: "10px"
                            }}
                        >
                            <Paper sx={{width: '100%'}}>
                                {loading ? <Box sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "50vh"
                                    }}>
                                        <CircularProgress/>
                                    </Box> :
                                    <>
                                        <TableContainer sx={{maxHeight: "60vh"}}>
                                            <Table sx={isMobile ? {maxWidth: 370} : {}} aria-label="users table">
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell
                                                            sx={{width: 100}}>{t("Shop name")}</StyledTableCell>
                                                        <StyledTableCell
                                                            sx={{width: 80}}>{t("Address")}</StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {shops.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((shop) => (
                                                        <TableRow key={shop.ShopID}>
                                                            <StyledTableCell
                                                                sx={{maxWidth: 150, wordWrap: 'break-word'}}>
                                                                <Link relative to={`./create-edit-shop/${shop.ShopID}`}>
                                                                    {shop.ShopName}
                                                                </Link>

                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                sx={{maxWidth: 150, wordWrap: 'break-word'}}>
                                                                {shop.Address}
                                                            </StyledTableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                            component="div"
                                            count={shops.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </>}
                            </Paper>
                            {/*<Tabs*/}
                            {/*    orientation={isMobile ? "horizontal" : "vertical"}*/}
                            {/*    variant="scrollable"*/}
                            {/*    value={value}*/}
                            {/*    onChange={handleChange}*/}
                            {/*    aria-label="Vertical tabs example"*/}
                            {/*    sx={{borderRight: 1, borderColor: 'divider', minWidth: isMobile ? 256 : 300}}*/}
                            {/*    TabIndicatorProps={{*/}
                            {/*        style: {transition: 'none'}*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    {shops.map((shop, index) => (*/}
                            {/*        <Tab label={shop.ShopName} {...a11yProps(shop.ShopID)} key={shop.ShopID}/>*/}
                            {/*    ))}*/}
                            {/*</Tabs>*/}
                            {/*{shops.map((shop, index) => (*/}
                            {/*    <TabPanel value={value} key={shop.ShopID} index={index} data={shop}/>*/}
                            {/*))}*/}
                        </Box>
                    </div>
                </div>
            }
        </>
    );
};

export default observer(ModeratorSetting);