import React, { useEffect, useMemo } from "react";

import { t } from "i18next";
import { observer } from "mobx-react";
import { IoInformationCircle } from "react-icons/io5";

import styles from "./CourierFinances.module.scss";

import rootStore from "../../../store/RootStore";
import FinanceCard from "./components/FinanceCard/FinanceCard";
import DiscoveryParent from "../../../components/DiscoveryParent/DiscoveryParent";

const CourierFinances = () => {
  const { getCourierEarnings, loadCourierEarnings } = rootStore.curierStore;
  const { earnings_by_day: earningsByDay } =  useMemo(() => getCourierEarnings, [getCourierEarnings]);

  useEffect(() => {
    loadCourierEarnings();
  }, []);

  return (
    <DiscoveryParent>
      <h1 className={styles.title}>{t("Finances")}</h1>
      <h2 className={styles.subTitle}>{t("Payments")}</h2>
      <p>{t("All payments are shown as gross amounts")}</p>
      <div className={styles.information}>
        <h3 className={styles.subSubTitle}>{t("Unpaid")}</h3>
        <IoInformationCircle fontSize="1.5em" />
      </div>

      <div className={styles.income}>
        <h3 className={styles.subSubTitle}>{t("Estimated income")}</h3>
        <p>{t("No unpaid income")}</p>
      </div>

      <div className={styles.information}>
        <h3 className={styles.subSubTitle}>{t("2024 payments")}</h3>
        <IoInformationCircle fontSize="1.5em" />
      </div>
      <div>
        {earningsByDay?.slice().sort((a, b) => new Date(b.date) - new Date(a.date)).map((earningsInfo, index) => (
          <FinanceCard earningsInfo={earningsInfo} key={index} />
        ))}
      </div>
      <button className={styles.loadPaymentButton}>
        <span>{t("Load more payments")}</span>
      </button>
    </DiscoveryParent>
  );
};

export default observer(CourierFinances);
