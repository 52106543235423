
import api from "../api";

export const getModeratorShopsInfo = async (id) => {
    try {
        const response = await api.get(`/admin_seller_shop/my-shop/${id}/details/`);
        return response.data
    } catch (error) {
        console.error("An error occurred:", error);
        throw error;
    }
}