import { useState, useEffect } from 'react';

const useIsMobile = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(windowWidth < 1024);
        };

        handleResize(); // Вызываем функцию при монтировании компонента

        window.addEventListener('resize', handleResize); // Добавляем слушатель события изменения размера окна

        return () => {
            window.removeEventListener('resize', handleResize); // Удаляем слушатель при размонтировании компонента
        };
    }, [windowWidth ]); // Пустой массив зависимостей означает, что эффект будет выполнен только один раз после монтирования компонента

    return isMobile;
};

export default useIsMobile;