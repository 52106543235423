import React from 'react';
import styles from './CountryMainBody.module.scss'
import CountryButton from "../Buttons/CountryButton/CountryButton";

const COUNTRY_LIST = [
    {
        "title": "Australia",
        "link": "https://www.megaflag.ru/sites/default/files/styles/h_100/public/images/directory_names/flag_avstraliya_enl.jpg?itok=acwUv1lf"
    },
    {
        "title": "Australia",
        "link": "https://www.megaflag.ru/sites/default/files/styles/h_100/public/images/directory_names/flag_avstraliya_enl.jpg?itok=acwUv1lf"
    },
    {
        "title": "Australia",
        "link": "https://www.megaflag.ru/sites/default/files/styles/h_100/public/images/directory_names/flag_avstraliya_enl.jpg?itok=acwUv1lf"
    },
    {
        "title": "Australia",
        "link": "https://www.megaflag.ru/sites/default/files/styles/h_100/public/images/directory_names/flag_avstraliya_enl.jpg?itok=acwUv1lf"
    },
    {
        "title": "Australia",
        "link": "https://www.megaflag.ru/sites/default/files/styles/h_100/public/images/directory_names/flag_avstraliya_enl.jpg?itok=acwUv1lf"
    },
    {
        "title": "Australia",
        "link": "https://www.megaflag.ru/sites/default/files/styles/h_100/public/images/directory_names/flag_avstraliya_enl.jpg?itok=acwUv1lf"
    },
    {
        "title": "Australia",
        "link": "https://www.megaflag.ru/sites/default/files/styles/h_100/public/images/directory_names/flag_avstraliya_enl.jpg?itok=acwUv1lf"
    },
    {
        "title": "Australia",
        "link": "https://www.megaflag.ru/sites/default/files/styles/h_100/public/images/directory_names/flag_avstraliya_enl.jpg?itok=acwUv1lf"
    },
    {
        "title": "Australia",
        "link": "https://www.megaflag.ru/sites/default/files/styles/h_100/public/images/directory_names/flag_avstraliya_enl.jpg?itok=acwUv1lf"
    },
    {
        "title": "Australia",
        "link": "https://www.megaflag.ru/sites/default/files/styles/h_100/public/images/directory_names/flag_avstraliya_enl.jpg?itok=acwUv1lf"
    },
    {
        "title": "Australia",
        "link": "https://www.megaflag.ru/sites/default/files/styles/h_100/public/images/directory_names/flag_avstraliya_enl.jpg?itok=acwUv1lf"
    },
    {
        "title": "Australia",
        "link": "https://www.megaflag.ru/sites/default/files/styles/h_100/public/images/directory_names/flag_avstraliya_enl.jpg?itok=acwUv1lf"
    },
    {
        "title": "Australia",
        "link": "https://www.megaflag.ru/sites/default/files/styles/h_100/public/images/directory_names/flag_avstraliya_enl.jpg?itok=acwUv1lf"
    },
    {
        "title": "Australia",
        "link": "https://www.megaflag.ru/sites/default/files/styles/h_100/public/images/directory_names/flag_avstraliya_enl.jpg?itok=acwUv1lf"
    },
    {
        "title": "Australia",
        "link": "https://www.megaflag.ru/sites/default/files/styles/h_100/public/images/directory_names/flag_avstraliya_enl.jpg?itok=acwUv1lf"
    },
    {
        "title": "Australia",
        "link": "https://www.megaflag.ru/sites/default/files/styles/h_100/public/images/directory_names/flag_avstraliya_enl.jpg?itok=acwUv1lf"
    },
    {
        "title": "Australia",
        "link": "https://www.megaflag.ru/sites/default/files/styles/h_100/public/images/directory_names/flag_avstraliya_enl.jpg?itok=acwUv1lf"
    },
    {
        "title": "Australia",
        "link": "https://www.megaflag.ru/sites/default/files/styles/h_100/public/images/directory_names/flag_avstraliya_enl.jpg?itok=acwUv1lf"
    },
    {
        "title": "Australia",
        "link": "https://www.megaflag.ru/sites/default/files/styles/h_100/public/images/directory_names/flag_avstraliya_enl.jpg?itok=acwUv1lf"
    },
    {
        "title": "Australia",
        "link": "https://www.megaflag.ru/sites/default/files/styles/h_100/public/images/directory_names/flag_avstraliya_enl.jpg?itok=acwUv1lf"
    },
    {
        "title": "Australia",
        "link": "https://www.megaflag.ru/sites/default/files/styles/h_100/public/images/directory_names/flag_avstraliya_enl.jpg?itok=acwUv1lf"
    },
    {
        "title": "Australia",
        "link": "https://www.megaflag.ru/sites/default/files/styles/h_100/public/images/directory_names/flag_avstraliya_enl.jpg?itok=acwUv1lf"
    },
    {
        "title": "Australia",
        "link": "https://www.megaflag.ru/sites/default/files/styles/h_100/public/images/directory_names/flag_avstraliya_enl.jpg?itok=acwUv1lf"
    },
    {
        "title": "Australia",
        "link": "https://www.megaflag.ru/sites/default/files/styles/h_100/public/images/directory_names/flag_avstraliya_enl.jpg?itok=acwUv1lf"
    },
    {
        "title": "Australia",
        "link": "https://www.megaflag.ru/sites/default/files/styles/h_100/public/images/directory_names/flag_avstraliya_enl.jpg?itok=acwUv1lf"
    },
    {
        "title": "Australia",
        "link": "https://www.megaflag.ru/sites/default/files/styles/h_100/public/images/directory_names/flag_avstraliya_enl.jpg?itok=acwUv1lf"
    },
    {
        "title": "Australia",
        "link": "https://www.megaflag.ru/sites/default/files/styles/h_100/public/images/directory_names/flag_avstraliya_enl.jpg?itok=acwUv1lf"
    }
]

const CountryMainBody = () => {
    return (
        <div className={styles.countryMainBody}>
            <div className={styles.titleBlock}>
                <h2 className={styles.title}>Explore Wolt countries</h2>
            </div>
            <div className={styles.countrysBlock}>
                <div className={styles.countrysConteiner}>
                    {COUNTRY_LIST.map((item, index) => (
                        <CountryButton
                            key={index}
                            text={item.title}
                            link={item.link}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CountryMainBody;

