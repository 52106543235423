import React from 'react';
import styles from "./ModeratorBodyMenuTable.module.scss";
import {t} from "i18next";

const CreateProductButton = ({onClick}) => {
    return (
        <div className={styles.createProductBtnBlock}>
            <button
                data-label="Создать категорию"
                id="createProductsGroup"
                className={styles.createCategoryBtn}
                onClick={onClick}>
                        <span className="dQaVw _8EVa2">
                            <svg width="18x" height="18px" viewBox="0 0 24 24" version="1.1"
                                 fill="#02acf8" stroke="#02acf8">

                                <g id="SVGRepo_bgCarrier" stroke-width="0"/>

                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC"
                                   stroke-width="1.344"/>

                                <g id="SVGRepo_iconCarrier"><title/>
                                    <g fill="none" fill-rule="evenodd" id="页面-1" stroke="none" stroke-width="1">
                                        <g id="导航图标" transform="translate(-325.000000, -80.000000)">
                                            <g id="编组" transform="translate(325.000000, 80.000000)">
                                                <polygon fill="#FFFFFF" fill-opacity="0.01" fill-rule="nonzero" id="路径"
                                                         points="24 0 0 0 0 24 24 24"/>
                                                <polygon id="路径" points="22 7 12 2 2 7 2 17 12 22 22 17" stroke="#02acf8"
                                                         stroke-linejoin="round" stroke-width="1.5"/>
                                                <line id="路径" stroke="#02acf8" stroke-linecap="round" stroke-linejoin="round"
                                                      stroke-width="1.5" x1="2" x2="12" y1="7" y2="12"/>
                                                <line id="路径" stroke="#02acf8" stroke-linecap="round" stroke-linejoin="round"
                                                      stroke-width="1.5" x1="12" x2="12" y1="22" y2="12"/>
                                                <line id="路径" stroke="#02acf8" stroke-linecap="round" stroke-linejoin="round"
                                                      stroke-width="1.5" x1="22" x2="12" y1="7" y2="12"/>
                                                <line id="路径" stroke="#02acf8" stroke-linecap="round" stroke-linejoin="round"
                                                      stroke-width="1.5" x1="17" x2="7" y1="4.5" y2="9.5"/>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </span>
                <span>{t("Create product")}</span>
            </button>

        </div>
    );
};

export default CreateProductButton;
