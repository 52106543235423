import React, {useEffect, useState} from 'react';
import styles from "./ProductCard.module.scss"
import ModalProduct from "../../../ModalProduct/ModalProduct";
import rootStore from "../../../../store/RootStore";
import { observer } from "mobx-react";
import {getUser} from "../../../../helpers";
import { getShopProducts } from "../../../../api/getContentsApi/getProductImage";
import Box from "@mui/material/Box";
import {CircularProgress} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {getCurrencySymbol} from "../../../../helpers/getCurrency";
import { useTheme } from "../../../../helpers/ThemeContext";

const ProductCard = observer(({ product, isStorePage = false }) => {
    const { themeMode } = useTheme();
    const { ProductID, product_images, ProductName, Price, price_in_user_currency } = product;
    const { setIsClicked } = rootStore.cartOrderStore;
    const navigate = useNavigate();
    const { addProductToCartOrder } = rootStore.cartOrderStore;
    const {getLoader, setLoader} = rootStore.shopStore;
    const [productImage, setProductImage] = useState("");
    const [isAnimation, setIsAnimation] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const currencySymbol = getCurrencySymbol();
    const handleAddProduct = (count) => {
        if(getUser()){
            addProductToCartOrder(ProductID, count);
            setIsClicked(true)
            setIsAnimation(true);
            setTimeout(() => {
                setIsAnimation(false);
            }, 1000)
        } else {
            navigate('/discovery/me')
        }
    }

    useEffect(() => {
        if (ProductID) {
            setLoader(true)
            getShopProducts(ProductID)
                .then(response => {
                    setLoader(false)
                    return setProductImage(response?.data?.ImageUrl)
                })
                .catch(error => console.log(error))
                .then(() => setIsLoad(true));
        }
    }, [ProductID]);

    if (getLoader) {
        return (
            <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh"}}>
                <CircularProgress/>
            </Box>
        )
    }

    return (
        <ModalProduct product={product}>
            {isLoad && (
                <div className={`${styles.mainConteiner} ${isAnimation && styles.animated}`}>
                    <div className={styles.mainBlock}>
                        <a role="button" aria-haspopup="dialog" aria-labelledby=":rvs:" className={styles.link}></a>
                        <div className={styles.mainContentBlock}>
                            {
                                product?.product_videos.length ?
                                    (
                                        <div className={styles.mainContentBlockImg}>
                                            <video className={styles.mainImg} loop="loop"
                                                   autoPlay="autoplay" muted="muted" playsInline={true}>
                                                <source src={process.env.REACT_APP_MEDIA_URL + product?.product_videos[0]?.VideoUrl}
                                                        type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
                                            </video>
                                        </div>
                                    ) :
                                    (
                                        <div className={styles.mainContentBlockImg}>
                                            <img
                                                src={process.env.REACT_APP_MEDIA_URL + product?.product_images[0]?.ImageUrl}
                                                className={styles.mainImg} alt={product_images[0]?.AltText || 'Image'}/>
                                        </div>
                                    )
                            }
                        </div>
                        <div className={styles.downContBlock}>
                            <div className={styles.downContMain}>
                                <span className={styles.cost}>{price_in_user_currency || Price} {currencySymbol}</span>
                            </div>
                            <div className={styles.downDownContMain}>
                                <h3 data-test-id="ImageCentricProductCard.Title" className={styles.titleDownBlock}>{ProductName}</h3>
                            </div>
                        </div>
                        <div id="add-product" className={`${styles.mainButton}`} onClick={() => handleAddProduct(1)}>
                            <div id="add-product" className={`${isStorePage ? 
                                (themeMode === 'dark' ? styles.storePageBtnBlockDark : styles.storePageBtnBlock) 
                                : styles.btnBlock}`}>
                                <button id="add-product" className={styles.btn}>
                                    <svg id="add-product" viewBox="0 0 24 24" className={`${isStorePage ? styles.storePageImg : styles.img}`}>
                                        <path id="add-product"
                                              d="M0 12a1.5 1.5 0 001.5 1.5h8.75a.25.25 0 01.25.25v8.75a1.5 1.5 0 003 0v-8.75a.25.25 0 01.25-.25h8.75a1.5 1.5 0 000-3h-8.75a.25.25 0 01-.25-.25V1.5a1.5 1.5 0 00-3 0v8.75a.25.25 0 01-.25.25H1.5A1.5 1.5 0 000 12z"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </ModalProduct>
    );
});

export default ProductCard;