import React, {useState} from 'react';
import styles from "./EditPhone.module.scss"
import {Modal} from "@mui/material";
import {observer} from "mobx-react";
import rootStore from "../../../store/RootStore";
import { ReactComponent as Logo} from './phone.svg';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const EditPhone = observer(callback => {
    const { isOpenEditPhoneModal, openCloseEP } = rootStore.systemStore;
    const {changePhoneNumber} = rootStore.userStore;

    const [phone, setPhone] = useState('+');
    const [valid, setValid] = useState(false);

    const handleChangePhone = (e) => {
        setPhone("+" + e.target.value.replace(/\D/g, '').replace(/^7/, '8').replace(" "))
        if(phone.length > 10 && phone.length < 15){
            setValid(true)
        } else {
            setValid(false)
        }
    }

    const handleSendPhone = () => {
        if (valid) {
            changePhoneNumber(phone).then(console.log('ready'))
            openCloseEP()
        }
    }

    return (
        <div>
            <Modal
                open={isOpenEditPhoneModal}
                onClose={() => openCloseEP()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className={styles.mainContainer}>
                    <div className={styles.mainBlock}>
                        <aside className={styles.container}>
                            <div className={styles.closeBtnConteiner}>
                                <button className={styles.buttonBlock} data-backdrop="transparent"
                                        data-size="medium" data-variant="neutral" type="button"
                                        aria-label="Close the dialog" data-test-id="modal-close-button"
                                onClick={() => openCloseEP()}>
                                    <div className={styles.fakeBtnBlock}></div>
                                    <div className={styles.closeImgBtn}>
                                        <svg viewBox="0 0 24 24" className={styles.closeImg}>
                                            <path
                                                d="M2.44 19.44a1.5 1.5 0 002.121 2.121l7.262-7.26a.25.25 0 01.354 0l7.262 7.262a1.5 1.5 0 102.122-2.12l-7.26-7.266a.25.25 0 010-.354l7.262-7.262a1.5 1.5 0 00-2.124-2.12L12.177 9.7a.25.25 0 01-.354 0L4.561 2.44A1.5 1.5 0 002.44 4.56L9.7 11.823a.25.25 0 010 .354L2.44 19.44z"></path>
                                        </svg>
                                    </div>
                                </button>
                            </div>
                            <div className={styles.block}>
                                <header className={styles.blockHeader}></header>
                                <Logo/>
                                <div className={styles.titleAfterImg}>Mobile number</div>
                                <div className={styles.editPhoneBlock}>
                                    <input name="phoneNumber"
                                         type={"tel"}
                                         placeholder=" " autoComplete="off"
                                         spellCheck="false"
                                         className={styles.phoneEdit}
                                         value={phone}
                                         onChange={(e) => handleChangePhone(e)}
                                         onFocus={()=>setPhone(phone+" ")}
                                    />

                                    {
                                        phone.length > 1 ? ""
                                            : <label htmlFor="change-phone-number-form-input" className={styles.phoneLabel}>Phone number</label>
                                    }
                                </div>
                                <footer className={styles.footer}>
                                    <button aria-disabled="false" role="button" type="button"
                                            className={styles.canselBtn}
                                            onClick={() => openCloseEP()}>
                                        <div className={styles.first}></div>
                                        <div className={styles.second}></div>
                                        <div className={styles.tree}>Cancel</div>
                                    </button>
                                    <button aria-disabled="false" role="button" type="submit"
                                            className={`${styles.saveBtn} ${valid ? styles.valid : ""}`}
                                            onClick={() => handleSendPhone()}>
                                        <div className={styles.first1}></div>
                                        <div className={styles.second2}></div>
                                        <div className={styles.tree3}>Save</div>
                                    </button>
                                </footer>
                            </div>
                        </aside>
                    </div>
                </div>
            </Modal>
        </div>
    );
});

export default EditPhone;