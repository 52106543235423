import React, { useState, useRef } from 'react';
import styles from './DeliveryTimes.module.scss';
import {observer} from "mobx-react";
import {useTheme} from "../../../helpers/ThemeContext";



const DeliveryTimes = ({isOpen, setIsOpen}) => {
    const modalRef = useRef(null);
    const [text, setText] = useState("")
    const {themeMode} = useTheme()
    const handleCloseModal = () => {
        setIsOpen(false);
    };

    const handleClose = (ev) => {
        if (ev.target.id === "modalka"){
            setIsOpen(false);
        }
    }

    return (
        <div>
            {isOpen && (
                <div id="modalka" className={styles["modal-overlay"]} onClick={(ev)=> handleClose(ev)}>

                    <div className={styles["modal-content"]} ref={modalRef}>
                        <div className={styles.closeBtnConteiner}>
                            <button className={styles.buttonBlock} data-backdrop="transparent"
                                    data-size="medium" data-variant="neutral" type="button"
                                    aria-label="Close the dialog" data-test-id="modal-close-button"
                                    onClick={handleCloseModal}>
                                <div className={styles.closeImgBtn}>
                                    Done
                                </div>
                            </button>
                        </div>
                        <div>
                            <h2 className={styles.titleBody}>Delivery time</h2>
                            <div className={styles.imagesBlock}>
                                <div className={styles.rowBlock}>
                                    <div className={styles.leftBlock}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#000000"
                                             className={styles.svgImage}
                                             version="1.1" id="Layer_1" viewBox="0 0 425 425">
                                            <g>
                                                <g>
                                                    <path
                                                        d="M86.557,278.614l-14.505,7.875c0.691,1.275,1.403,2.545,2.12,3.794c0.727,1.265,1.469,2.509,2.232,3.748l14.049-8.653    c-0.676-1.101-1.341-2.222-1.992-3.343C87.817,280.902,87.182,279.761,86.557,278.614z"/>
                                                    <path
                                                        d="M140.236,88.81c1.126-0.65,2.263-1.285,3.41-1.91l-7.875-14.505c-1.275,0.691-2.539,1.398-3.789,2.12    c-1.265,0.727-2.509,1.475-3.743,2.232l8.653,14.049C137.993,90.121,139.114,89.455,140.236,88.81z"/>
                                                    <path
                                                        d="M335.364,143.994l14.505-7.875c-0.691-1.275-1.403-2.545-2.12-3.794c-0.727-1.265-1.475-2.509-2.232-3.748l-14.049,8.653    c0.676,1.101,1.341,2.222,1.992,3.343C334.104,141.705,334.739,142.842,335.364,143.994z"/>
                                                    <path
                                                        d="M210.958,69.856c1.295,0,2.601,0.02,3.907,0.056l0.435-16.502c-1.449-0.036-2.903-0.056-4.342-0.056    c-1.459,0-2.908,0.02-4.362,0.061l0.466,16.497C208.357,69.871,209.663,69.856,210.958,69.856z"/>
                                                    <path
                                                        d="M74.172,132.33c-0.727,1.265-1.439,2.529-2.13,3.804l14.52,7.844c0.614-1.137,1.254-2.273,1.9-3.395    c0.65-1.126,1.321-2.243,2.002-3.359l-14.07-8.627C75.637,129.832,74.894,131.076,74.172,132.33z"/>
                                                    <path
                                                        d="M285.039,90.807l8.627-14.07c-1.239-0.758-2.483-1.5-3.732-2.222c-1.265-0.727-2.529-1.439-3.804-2.13l-7.844,14.52    c1.137,0.614,2.273,1.254,3.4,1.9C282.807,89.455,283.928,90.126,285.039,90.807z"/>
                                                    <path
                                                        d="M53.068,206.959c-0.036,1.449-0.056,2.903-0.056,4.342c0,1.459,0.021,2.908,0.061,4.357l16.497-0.466    c-0.036-1.29-0.051-2.596-0.051-3.891s0.021-2.601,0.056-3.907L53.068,206.959z"/>
                                                    <path
                                                        d="M368.849,206.944l-16.497,0.466c0.036,1.29,0.051,2.596,0.051,3.891s-0.02,2.601-0.056,3.907l16.502,0.435    c0.036-1.449,0.056-2.903,0.056-4.342C368.91,209.842,368.89,208.393,368.849,206.944z"/>
                                                    <path
                                                        d="M333.454,282.024c-0.65,1.126-1.321,2.243-2.002,3.359l14.07,8.627c0.758-1.239,1.5-2.483,2.222-3.732    c0.727-1.265,1.439-2.529,2.13-3.804l-14.52-7.844C334.744,279.761,334.104,280.902,333.454,282.024z"/>
                                                    <path
                                                        d="M136.882,331.795l-8.627,14.07c1.239,0.758,2.483,1.5,3.732,2.222c1.265,0.727,2.529,1.439,3.804,2.13l7.844-14.52    c-1.137-0.614-2.273-1.254-3.395-1.9C139.114,333.147,137.993,332.481,136.882,331.795z"/>
                                                    <path
                                                        d="M359.909,61.751c-82.335-82.335-215.823-82.335-298.158,0s-82.335,215.823,0,298.158s215.823,82.335,298.158,0    S442.244,144.086,359.909,61.751z M340.422,340.422c-71.572,71.572-187.612,71.572-259.185,0s-71.573-187.612,0-259.185    s187.612-71.573,259.185,0S411.99,268.85,340.422,340.422z"/>
                                                    <path
                                                        d="M210.958,352.746c-1.295,0-2.601-0.02-3.907-0.056l-0.435,16.502c1.449,0.041,2.903,0.056,4.342,0.056    c1.459,0,2.908-0.02,4.362-0.061l-0.466-16.497C213.564,352.731,212.259,352.746,210.958,352.746z"/>
                                                    <path
                                                        d="M281.686,333.797c-1.126,0.65-2.263,1.285-3.41,1.91l7.875,14.505c1.275-0.691,2.54-1.398,3.789-2.12    c1.265-0.727,2.509-1.475,3.743-2.232l-8.653-14.049C283.928,332.481,282.807,333.147,281.686,333.797z"/>
                                                    <path
                                                        d="M219.084,181.882V78.934c0-4.557-3.692-8.253-8.253-8.253s-8.253,3.697-8.253,8.253v102.948    c-6.154,1.756-11.464,5.371-15.34,10.25l-80.154-20.163c-6.625-1.674-13.353,2.355-15.027,8.98    c-1.669,6.63,2.36,13.358,8.986,15.022l80.092,20.152c2.514,14.162,14.812,24.94,29.696,24.94    c16.696,0,30.234-13.537,30.234-30.234C241.064,197.016,231.74,185.491,219.084,181.882z M210.83,226.728    c-8.781,0-15.898-7.117-15.898-15.898s7.117-15.898,15.898-15.898s15.898,7.117,15.898,15.898S219.611,226.728,210.83,226.728z"/>
                                                </g>
                                            </g>
                                        </svg>
                                        <div className={styles.textBlockImage}>
                                            <span className={styles.topText}>As soon as possible</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.IconNextBlock}>
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         id="Layer_1" viewBox="0 0 500 500" className={styles.IconNext}>
                                        <g>
                                            <polygon className="st0"
                                                     points="434.8,49 174.2,309.7 76.8,212.3 0,289.2 174.1,463.3 196.6,440.9 196.6,440.9 511.7,125.8 434.8,49     "/>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className={styles.imagesBlock2}>
                            <div className={styles.rowBlock2}>
                                <div className={styles.leftBlock}>
                                    <div className={styles.svgBlockCont}>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             fill="#000000" version="1.1" id="Capa_1" className={styles.svgImage2}
                                             viewBox="0 0 600 600">
                                            <g>
                                                <g>
                                                    <path
                                                        d="M159.567,0h-15.329c-1.956,0-3.811,0.411-5.608,0.995c-8.979,2.912-15.616,12.498-15.616,23.997v10.552v27.009v14.052    c0,2.611,0.435,5.078,1.066,7.44c2.702,10.146,10.653,17.552,20.158,17.552h15.329c11.724,0,21.224-11.188,21.224-24.992V62.553    V35.544V24.992C180.791,11.188,171.291,0,159.567,0z"/>
                                                    <path
                                                        d="M461.288,0h-15.329c-11.724,0-21.224,11.188-21.224,24.992v10.552v27.009v14.052c0,13.804,9.5,24.992,21.224,24.992    h15.329c11.724,0,21.224-11.188,21.224-24.992V62.553V35.544V24.992C482.507,11.188,473.007,0,461.288,0z"/>
                                                    <path
                                                        d="M539.586,62.553h-37.954v14.052c0,24.327-18.102,44.117-40.349,44.117h-15.329c-22.247,0-40.349-19.79-40.349-44.117    V62.553H199.916v14.052c0,24.327-18.102,44.117-40.349,44.117h-15.329c-22.248,0-40.349-19.79-40.349-44.117V62.553H70.818    c-21.066,0-38.15,16.017-38.15,35.764v476.318c0,19.784,17.083,35.764,38.15,35.764h468.763c21.085,0,38.149-15.984,38.149-35.764    V98.322C577.735,78.575,560.671,62.553,539.586,62.553z M527.757,557.9l-446.502-0.172V173.717h446.502V557.9z"/>
                                                    <path
                                                        d="M353.017,266.258h117.428c10.193,0,18.437-10.179,18.437-22.759s-8.248-22.759-18.437-22.759H353.017    c-10.193,0-18.437,10.179-18.437,22.759C334.58,256.074,342.823,266.258,353.017,266.258z"/>
                                                    <path
                                                        d="M353.017,348.467h117.428c10.193,0,18.437-10.179,18.437-22.759c0-12.579-8.248-22.758-18.437-22.758H353.017    c-10.193,0-18.437,10.179-18.437,22.758C334.58,338.288,342.823,348.467,353.017,348.467z"/>
                                                    <path
                                                        d="M353.017,430.676h117.428c10.193,0,18.437-10.18,18.437-22.759s-8.248-22.759-18.437-22.759H353.017    c-10.193,0-18.437,10.18-18.437,22.759S342.823,430.676,353.017,430.676z"/>
                                                    <path
                                                        d="M353.017,512.89h117.428c10.193,0,18.437-10.18,18.437-22.759c0-12.58-8.248-22.759-18.437-22.759H353.017    c-10.193,0-18.437,10.179-18.437,22.759C334.58,502.71,342.823,512.89,353.017,512.89z"/>
                                                    <path
                                                        d="M145.032,266.258H262.46c10.193,0,18.436-10.179,18.436-22.759s-8.248-22.759-18.436-22.759H145.032    c-10.194,0-18.437,10.179-18.437,22.759C126.596,256.074,134.838,266.258,145.032,266.258z"/>
                                                    <path
                                                        d="M145.032,348.467H262.46c10.193,0,18.436-10.179,18.436-22.759c0-12.579-8.248-22.758-18.436-22.758H145.032    c-10.194,0-18.437,10.179-18.437,22.758C126.596,338.288,134.838,348.467,145.032,348.467z"/>
                                                    <path
                                                        d="M145.032,430.676H262.46c10.193,0,18.436-10.18,18.436-22.759s-8.248-22.759-18.436-22.759H145.032    c-10.194,0-18.437,10.18-18.437,22.759S134.838,430.676,145.032,430.676z"/>
                                                    <path
                                                        d="M145.032,512.89H262.46c10.193,0,18.436-10.18,18.436-22.759c0-12.58-8.248-22.759-18.436-22.759H145.032    c-10.194,0-18.437,10.179-18.437,22.759C126.596,502.71,134.838,512.89,145.032,512.89z"/>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className={styles.textBlockImage}>
                                        <span className={styles.topText2}>Schedule for later</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div onClick={() => setIsOpen(true)}></div>
        </div>
    );
};

export default observer(DeliveryTimes);
