import React from 'react';

const StoresIcon = () => {
    return (
        <svg viewBox="0 0 550 550"
             width="1.4rem"
             height="1.4rem"
             fill="currentColor">
            <g xmlns="http://www.w3.org/2000/svg">
                <g id="Layer_8_4_">
                    <path
                        d="M124.835,209.569v14.836H338.19v-14.836h55.23v14.836h36.422c24.092,0,44.153-17.439,48.314-40.347V96.44H318.773V57.902    c0-5.01-4.066-9.079-9.079-9.079H169.479c-5.013,0-9.079,4.069-9.079,9.079V96.44H0v95.438    c6.833,18.938,24.932,32.532,46.198,32.532h23.407v-14.836L124.835,209.569L124.835,209.569z M178.559,66.975h122.057v29.459    H178.559V66.975z M393.421,274.638V236.51h36.422c19.636,0,37.096-9.327,48.314-23.74v216.564H0V215.35    c11.239,12.933,27.763,21.16,46.198,21.16h23.407v38.128h55.23V236.51H338.19v38.128H393.421z"/>
                </g>
            </g>
        </svg>);
};

export default StoresIcon;