import React from "react";
import styles from "./Slides.module.scss";

const Slides = ({ data }) => {
  return (
    <div className={styles.mainConteiner}>
      {/* <img className={styles.imgBody} src={process.env.REACT_APP_MEDIA_URL+data?.image}
                 alt={data.top_text ? data.top_text : ""}/>  -- СТАРАЯ РЕАЛИЗАЦИЯ*/}
      <picture>
        {/* для формата AVIF (если он вообще будет) */}
        {data?.image?.endsWith(".avif") && (
          <source
            srcSet={`${process.env.REACT_APP_MEDIA_URL}${data?.image}`}
            type="image/avif"
          />
        )}
        {/* для стандартных форматов */}
        <img
          className={styles.imgBody}
          src={`${process.env.REACT_APP_MEDIA_URL}${data?.image}`}
          alt={data?.top_text || ""}
        />
      </picture>
      <div className={styles?.textOverlay}>
      <div className={styles.topText}>{data?.top_text}</div>
      <div className={styles.bottomText}>{data?.bottom_text}</div>
      </div>
    </div>
  );
};

export default Slides;