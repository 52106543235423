import React, {useEffect, useRef, useState} from 'react';
import styles from "./MobileCategoryStore.module.scss"
import MobileSearchHeader from "../../../Restoran/RestoranBody/MobileSearchHeader/MobileSearchHeader";
import rootStore from "../../../../store/RootStore";
import {observer} from "mobx-react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const defaultCategoryList =
    [{
        image: "https://imageproxy.wolt.com/menu/menu-images/63f8bffc06bbde74b8eec406/06cbe022-bf18-11ed-af2d-ee3742ebf0b4____________02.jpeg?w=200",
        CategoryName: "ALL",
        CategoryID:0,
        active: true,
    },]

const MobileCategoryStore = ({data,categoryList}) => {
    const sliderRef = useRef(null);
    const settings = {
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        infinite: true,
        autoplay: false,
        speed: 500,
    };
    const setCurrentCategoryID = rootStore.categoriesStore.setCurrentCategoryID;
    const [categoryListData, setCategoryListData] = useState(defaultCategoryList);

    const isMobile = window.innerWidth < 641;

    const updatedCategoryList = categoryList.map(item => ({
        ...item,
        active: false
    }));

    useEffect(() => {
        setCategoryListData(prevState => [...prevState, ...updatedCategoryList]);
        return () => setCategoryListData(defaultCategoryList);
    }, [categoryList]);

    const toggleActivity = (id, index) => {
        setCurrentCategoryID(id);
        sliderRef.current.slickGoTo(index);
        setCategoryListData(prevState => prevState.map(el => {
            if (el.CategoryID === id) {
                return {
                    ...el,
                    active: true
                }
            } else {
                return {
                    ...el,
                    active: false
                }
            }
        }));
    };
    return (
        <div className={styles.mainConteiner}>
            <div className={`${styles.mainBlock}`}>
                <div className={styles.conteiner}>
                    {/*<MobileSearchHeader/>*/}
                    {
                        isMobile ? "" :
                            (
                                <div className={styles.categorysConteiner}>
                                    <div className={styles.categorysBlock}>
                                        <div className={styles.content}>
                                            <Slider {...settings} className={styles.slider} ref={sliderRef}>
                                                {categoryListData.map((category, index) => (
                                                    <div
                                                        className={`${styles.category} ${category.active ? styles['category__active'] : ''}`}
                                                        onClick={() => toggleActivity(category?.CategoryID, index)}
                                                        key={category?.CategoryID}>
                                                        <span>{category?.CategoryName}</span>
                                                    </div>
                                                ))}
                                            </Slider>
                                        </div>
                                    </div>
                                </div>
                            )
                    }
                </div>
            </div>
        </div>
    );
};

export default observer(MobileCategoryStore);