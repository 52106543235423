import * as React from "react";

function IconBasketFill(props) {
    return (
        <svg
            viewBox="0 0 16 16"
            height="1.4rem"
            width="1.4rem"
            {...props}
        >
            <path d="M5.071 1.243a.5.5 0 01.858.514L3.383 6h9.234L10.07 1.757a.5.5 0 11.858-.514L13.783 6H15.5a.5.5 0 01.5.5v2a.5.5 0 01-.5.5H15v5a2 2 0 01-2 2H3a2 2 0 01-2-2V9H.5a.5.5 0 01-.5-.5v-2A.5.5 0 01.5 6h1.717L5.07 1.243zM3.5 10.5a.5.5 0 10-1 0v3a.5.5 0 001 0v-3zm2.5 0a.5.5 0 10-1 0v3a.5.5 0 001 0v-3zm2.5 0a.5.5 0 10-1 0v3a.5.5 0 001 0v-3zm2.5 0a.5.5 0 10-1 0v3a.5.5 0 001 0v-3zm2.5 0a.5.5 0 10-1 0v3a.5.5 0 001 0v-3z" />
        </svg>
    );
}

export default IconBasketFill;