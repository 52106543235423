import { makeAutoObservable } from 'mobx';
import api from "../api/api";

class SuperUserStore {
    shops = [];
    initialized = false;
    currentShopId = null;
    currentShopData = {};
    loader = true;

    constructor() {
        makeAutoObservable(this);
    }

    updateShopsList = () => {
        api.get('/admin_seller_shop/get-my-shops/').then(response => {
            this.shops = response.data;
        });
    }

    lazyInitialize = () => {
        if (!this.initialized) {
            this.updateShopsList();
            this.initialized = true;
        }
    }

    get superUserShopsList() {
        if (this.shops.length){
            return this.shops;
        }
        else {
            return [];
        }
    }
    get getCurrentShopId() {
        return this.currentShopId;
    }

    filterShops = (id) => {
        this.shops = this.shops.filter(e => e.ShopID !== id)
    }
    setCurrentData = (data) => {
        this.currentShopData = data;
    }

    setCurrentShopId = (id) => {
        this.currentShopId = id;
    }
    addShopData(data) {
        this.currentShopData = data;
    }
    getGetCurrentShopData() {
        return this.currentShopData;
    }
    clearShopData() {
        this.currentShopData = {};
    }
}

const shopsStore = new SuperUserStore();
export default shopsStore;