import React from 'react';

const StoresIcon = ({currentColor = 'currentColor'}) => {
    return (
        <svg viewBox="0 0 52 52"
             width="1.5rem"
             height="1.5rem"
             fill={currentColor}>
            <path xmlns="http://www.w3.org/2000/svg"
                  d="M27.346,3.181c-13.325,0-24.165,10.84-24.165,24.163c0,13.324,10.84,24.165,24.165,24.165   c13.324,0,24.164-10.841,24.164-24.165C51.51,14.021,40.67,3.181,27.346,3.181z M27.346,12.507c4.328,0,7.836,3.509,7.836,7.836   c0,4.328-3.509,7.838-7.836,7.838c-4.329,0-7.837-3.51-7.837-7.838C19.509,16.015,23.017,12.507,27.346,12.507z M16.239,46.447   c0.239,0.139,0.48,0.274,0.725,0.403C16.719,46.721,16.478,46.586,16.239,46.447z M27.346,49.046   c-6.636,0-12.582-2.998-16.566-7.706c1.117-5.241,5.765-9.246,11.333-9.246h10.464c5.594,0,10.255,3.962,11.346,9.23   C39.94,46.042,33.989,49.046,27.346,49.046z M44.022,41.865c-0.001,0.001-0.001,0.002-0.002,0.004   C44.021,41.867,44.021,41.866,44.022,41.865L44.022,41.865z"/>
        </svg>);
};

export default StoresIcon;