import React, { useEffect, useState } from 'react';

import { t } from "i18next";
import { useNavigate } from "react-router-dom";

import { Tooltip } from "@mui/material";

import styles from './EarnWoltCreaditsTab.module.scss'

import img from "./img.svg"
import image from "./image.png"
import IconBack from "../../Icons/IconBack";
import rootStore from "../../../store/RootStore";
import { useTheme } from "../../../helpers/ThemeContext";

const EarnWoltCreaditsTab = () => {
    const { getProfile, userData } = rootStore.userStore;
    const navigate = useNavigate();
    const { themeMode } = useTheme()
    const [isOpenToolTip, setIsOpenToolTip] = useState(false)

    useEffect(() => {
        getProfile()
    }, []);

    const handeClick = () => {
        setIsOpenToolTip(prevState => !prevState)
        navigator.clipboard.writeText(userData.profile.referral_code)
        setTimeout(() => {
            setIsOpenToolTip(prevState => !prevState)
        }, 2000) // показать тултип на 2 секунды
    }

    const goBack = () => {
        navigate('/discovery/me', { replace: true });
    };

    return (
        <>
        <div className={styles.goBackButton} onClick={goBack}>
            <IconBack size={20} pure={true} fill={"#009de0"}/>
        </div>
    <div className={styles.lvl1}>
        <div className={styles.lvl2}>
            <div className={styles.lvl3}>
                <div className={styles.mainConteiner}>
                    <div className={styles.mainBlock}>
                        <div className={styles.svgBlockCont}>
                        <div className={styles.svgBlockMain}>
                                    <div className={styles.svgBlockM}>
                                        <div className={styles.svgBlockB}>
                                            {themeMode === "light" ? <img src={img} alt="React Logo"/> : <img style={{width: "100%"}} src={image} alt="React Logo"/>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.middleConteiner}>
                                <h2 className={styles.middleConteinerTitle}>{t("Invite friends and get discounts")}</h2>
                                <div className={styles.middleBlock}>
                                    <div className={styles.middleBlockNum}>1</div>
                                    <span>{t("Your friends will get ¥600 in Wolt credits when they use your code for each of their first 3 delivery orders.")}</span>
                                </div>
                                <div className={styles.middleBlock}>
                                    <div className={styles.middleBlockNum}>2</div>
                                    <span>{t("You’ll get ¥200 in Wolt credits for each of your friend’s first 3 delivery orders.")}</span>
                                </div>
                            </div>
                            <div className={styles.downButton}>
                                {/*<button  type="button" className={styles.btn}>{t("How do referral codes work?")}</button>*/}
                            </div>
                                <div className={styles.mainButtonCont} onClick={handeClick}>
                                    <button className={styles.mainButton} >
                                        <div className={styles.mainButtonContent}>
                                            <div className={styles.spanCont}>
                                                <span className={styles.spanOne}>{t("Your referral code")}</span>
                                                <span className={styles.spanSecond}>{userData.profile.referral_code}</span>
                                            </div>
                                            <div className={styles.imgBlock}>
                                                <Tooltip title={t("Copy")} open={isOpenToolTip} >
                                                    <svg viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                                                         className={styles.imga}>
                                                        <path d="M18.5 9h-3a.5.5 0 000 1H18v9H1v-9h2.5a.5.5 0 000-1h-3a.5.5 0 00-.5.5v10a.5.5 0 00.5.5h18a.5.5 0 00.5-.5v-10a.5.5 0 00-.5-.5zM5.187 5.891a.502.502 0 01-.078-.704l4-5c.005-.005.014-.007.019-.014A.495.495 0 019.5 0c.15 0 .28.069.372.173.005.007.014.009.019.014l4 5A.502.502 0 0113.5 6a.5.5 0 01-.391-.188L10 1.926V14.5a.5.5 0 01-1 0V1.926L5.891 5.812a.502.502 0 01-.704.079z"
                                                              fill="currentColor" fill-opacity="0.64"></path>
                                                    </svg>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </button>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default EarnWoltCreaditsTab;