import React from 'react';
import {observer} from "mobx-react";
import styles from './IncludeContent.module.scss'
import {getCurrencySymbol} from "../../../helpers/getCurrency";


const IncludeContent = observer(({data, setOpen}) => {
    const currencySymbol = getCurrencySymbol();

    return (
        <>
            <div className={styles.mainBoxBlock}>
                <div className={styles.conteiner}>
                    <div className={styles.upRowBlock}>
                        <img
                            src={process.env.REACT_APP_MEDIA_URL + data['get_shop']['shop_images'][0].ImageUrl}
                            className={styles.mainImg}/>
                        <span className={styles.nameShopText}>{data['get_shop'].ShopName}</span>
                    </div>
                    <div className={styles.downRowBlock}>
                        {
                            data.data.map((element) =>
                                (
                                    <img src={process.env.REACT_APP_MEDIA_URL + element.product.product_images[0].ImageUrl} className={styles.middleImg}></img>
                                ))
                        }
                        <img
                            src={process.env.REACT_APP_MEDIA_URL + data['get_shop']['shop_images'][0].ImageUrl}
                            className={styles.mainImg}/>
                    </div>
                    <div className={styles.infoBlock}>
                        <span className={styles.textItemSub}>Item subtotal: {data.cart_total_price_converted} {currencySymbol}</span>
                        <button className={styles.viewBtn} onClick={() => setOpen(true)}>View cart</button>
                    </div>
                </div>
            </div>
        </>
    )
});

export default IncludeContent;