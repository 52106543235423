import React from 'react';

import {observer, Provider} from "mobx-react";
import {BrowserRouter, Route, Routes } from "react-router-dom";

import './App.scss';

import MainPage from "./pages/MainPage/MainPage";
import NotFount from "./components/NotFound/NotFount";
import MainBody from "./components/MainBody/MainBody";
import DiscoveryWrapper from "./pages/DiscoveryWrapper/DiscoveryWrapper";
import Restaurant from "./components/Restaurants/Restaurant";
import Stores from "./components/Stores/Stores";
import RestoranBody from "./pages/RestoranBody/RestoranBody";
import rootStore from "./store/RootStore";
import DiscoveryBody from "./pages/DiscoveryBody/DiscoveryBody";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StoresBody from "./pages/StoresBody/StoresBody";
import PersonalInfo from "./pages/PersonalInfo/PersonalInfo";
import PaymentMethods from "./components/PersonalLab/PaymentMethods/PaymentMethods";
import AddresTab from "./components/PersonalLab/AddresTab/AddresTab";
import OrderHistory from "./components/PersonalLab/OrderHistory/OrderHistory";
import PersonalInfoTab from "./components/PersonalLab/PersonalInfoTab/PersonalInfoTab";
import EarnWoltCreaditsTab from "./components/PersonalLab/EarnWoltCreaditsTab/EarnWoltCreaditsTab";
import RedeemCodeTab from "./components/PersonalLab/RedeemCodeTab/RedeemCodeTab";
import SettingsTab from "./components/PersonalLab/SettingsTab/SettingsTab";
import CheckoutBody from "./pages/CheckoutBody/CheckoutBody";
import ModeratorPanel from "./pages/ModeratorPanel/ModeratorPanel";
import SearchPage from "./pages/SearchPage/SearchPage";
import AccountMobile from "./pages/PersonalInfo/AccountMobile/AccountMobile";
import LoginRegister from "./pages/LoginRegister/LoginRegister";
import CreditsTokens from "./pages/CreditsTokens/CreditsTokens";
import CheckoutMobile from "./pages/CheckoutMobile/CheckoutMobile";
import {ThemeProvider} from "./helpers/ThemeContext";
import ChangeMobile from "./pages/ChangeMobile/ChangeMobile";
import AnimatedCircle from "./components/AnimatedCircle";
import OrderCard from "./pages/OrderCard/OrderCard";
import CurierWrapper from "./pages/CurierPanel/CurierWrapper";
import ActualHistory from "./pages/CurierPanel/ActualHistory/ActualHistory";
import WorkedHistory from "./pages/CurierPanel/WorkedHistory/WorkedHistory";
import CourierFinances from "./pages/CurierPanel/CourierFinances/CourierFinances";
import CourierDeliveries from "./pages/CurierPanel/CourierFinances/components/CourierDeliveries/CourierDeliveries";
import VerificationPage from "./pages/VerificationPage/VerificationPage";
import BalancePage from "./components/PersonalLab/BalancePage/BalancePage";
import AddresPage from "./pages/AddresPage/AddresPage";
import OrderCardMain from "./pages/OrderCard/OrderCardMain";
import TransactionsPage from "./pages/TransactionsPage/TransactionsPage";
import TransactionMainCard from "./pages/TransactionsPage/TransactionMainCard/TransactionMainCard";
import ChatCurier from "./components/Basket/ChatCurier/ChatCurier";
import OrderCardActual from "./pages/OrderCard/OrderCardActual";
import OrderCardWorked from "./pages/OrderCard/OrderCardWorked";
import InfoOrderCardWorked from "./components/InfoOrderCardWorked/InfoOrderCardWorked";
import VerificationPage2 from "./pages/VerificationPage2/VerificationPage2";
import VerificationPage3 from "./pages/VerificationPage3/VerificationPage3";
import StatisticPage from "./pages/StatisticPage/StatisticPage";
import CurierSettingPanel from "./components/CurierSettingPanel/CurierSettingPanel";
import OrdersConfirmPanel from "./components/OrdersConfirmPanel/OrdersConfirmPanel";
import ModeratorBodyMenu from "./components/ModeratorBody/ModeratorBodyMenu/ModeratorBodyMenu";
import ModeratorSetting from "./components/ModeratorSettings/ModeratorSetting";
import ModeratorSubscribe from "./components/ModeratorSubscribe/ModeratorSubscribe";
import CurrentShopPage from "./pages/StatisticPage/CurrentShopPage/CurrentShopPage";
import OwnerPage from "./pages/OwnerPage/OwnerPage";
import OwnerVerificationPage from "./pages/OwnerVerificationPage/OwnerVerificationPage";
import SupportWrapper from "./pages/SupportPage/SupportWrapper";
import SupportActivePage from "./pages/SupportPage/SupportActivePage";
import SupportNewPage from "./pages/SupportPage/SupportNewPage";
import VerificateUser from "./components/VerificateUser/VerificateUser";
import SupportUserChat from "./pages/SupportPage/SupportUserChat/SupportUserChat";
import AddModerator from "./pages/AddModerator/AddModerator";
import VerificateModerator from "./components/VerificateModerator/VerificateModerator";
import OwnerSupportPage from "./pages/OwnerSupportPage/OwnerSupportPage";
import SupportUser from "./pages/OwnerSupportPage/SupportUser/SupportUser";
import EditModerator from "./components/EditModerator/EditModerator";
import OwnerShopPage from "./pages/OwnerShopPage/OwnerShopPage";
import CategorySettingItem from "./pages/CategorySettingItem/CategorySettingItem";
import VerificateSupport from "./components/VerificateSupport/VerificateSupport";
import DiscoverySettingItem from "./pages/DiscoverySettingItem/DiscoverySettingItem";
import CreateNewShop from "./components/CreateNewShopModal/CreateNewShop";

function App() {
    const { userData } = rootStore.userStore;

    return (
      <Provider rootStore={rootStore}>
          <ThemeProvider>
              <BrowserRouter>
                  <AnimatedCircle>
                  <Routes>
                      <Route path="/" element={<MainPage />} >
                          <Route index element={<MainBody />} />
                          <Route path="discovery" element={<DiscoveryWrapper/>}>
                              <Route index element={<DiscoveryBody/>}/>
                              <Route index path="restaurants" element={<Restaurant/>}/>
                              <Route index path="stores" element={<Stores/>}/>
                              <Route index path="search-store" element={<SearchPage/>}/>
                              <Route path="me" element={userData.authorized ? <PersonalInfo/> : <LoginRegister/>}>
                                  <Route path="personal-info" element={<PersonalInfoTab/>} />
                                  <Route path="payments" element={<PaymentMethods />} />
                                  <Route path="addresses" element={<AddresTab />} />
                                  <Route path="order-history" element={<OrderHistory />} />
                                  <Route path="order-chat" element={<ChatCurier />} />
                                  <Route path="order-history/:id" element={<OrderCard />} />
                                  <Route path="earn-credits" element={<EarnWoltCreaditsTab />} />
                                  <Route path="balance" element={<TransactionsPage />} />
                                  <Route path="balance/:id" element={<TransactionMainCard />} />
                                  <Route path="balance/up" element={<BalancePage />} />
                                  <Route path="redeem-code" element={<RedeemCodeTab />} />
                                  <Route path="settings" element={<SettingsTab />} />
                                  <Route path="my-addreses" element={<AddresPage />} />
                                  <Route path="credits-tokens" element={<CreditsTokens />} />
                                  <Route path="account-mobile" element={<AccountMobile />} />
                                  <Route path="verification" element={<VerificationPage />} />
                                  <Route path="verification2" element={<VerificationPage2 />} />
                                  <Route path="verification3" element={<VerificationPage3 />} />
                                  <Route path="change-mobile" element={<ChangeMobile />} />
                              </Route>
                          </Route>
                          <Route path="/restoran" element={<RestoranBody/>}/>
                          <Route path="/stores/:id" element={<StoresBody/>}/>
                          <Route path="/checkout" element={<CheckoutBody/>}/>
                          <Route path="/support-panel" element={<SupportWrapper/>}>
                              <Route path={"new"} element={<SupportNewPage/>}/>
                              <Route path={"active"} element={<SupportActivePage/>}/>
                              <Route path={"chat/:id"} element={<SupportUserChat/>} />
                          </Route>
                          <Route path="/user-support-panel" element={<SupportUserChat/>}/>
                          <Route path="/moderator" element={<ModeratorPanel/>}>
                              <Route path={"statistics"} element={<StatisticPage/>}/>
                              <Route path={"statistics/current-shop"} element={<CurrentShopPage/>}/>
                              <Route path={"curiers"} element={<CurierSettingPanel/>}/>
                              <Route path={"orders"} element={<OrdersConfirmPanel/>}/>
                              <Route path={"menu"} element={<ModeratorBodyMenu/>}/>
                              <Route path={"setting"} element={<ModeratorSetting/>}/>
                              <Route path={"setting/create-edit-shop/:id?"} element={<CreateNewShop/>}/>
                              <Route path={"setting/subscribe"} element={<ModeratorSubscribe/>}/>
                          </Route>
                          <Route path="moderator/order/:id" element={<OrderCardMain />}/>
                          <Route path="/curier-panel" element={<CurierWrapper/>}>
                              <Route path="actual" element={<ActualHistory/>}/>
                              <Route path="actual/:id" element={<OrderCardActual/>}/>
                              <Route path="worked" element={<WorkedHistory/>}/>
                              <Route path="worked/:id" element={<OrderCardWorked />}/>
                              <Route path="worked/:id/info" element={<InfoOrderCardWorked />}/>
                              {/*AllHistory по пути all заменен на CourierFinances*/}
                              <Route path="all" element={<CourierFinances />}/>
                              {/* OrderCardMainOld по пути all/:id заменен на CourierDeliveries*/}
                              <Route path="all/:date" element={<CourierDeliveries />}/>
                          </Route>
                          <Route path="/owner" element={<OwnerPage/>}>
                              <Route path="support" element={<OwnerSupportPage/>}/>
                              <Route path="verification/" element={<OwnerVerificationPage/>}/>
                              <Route path="verification/user/:id" element={<VerificateUser/>}/>
                              <Route path="support/user/:id" element={<SupportUser/>}/>
                              <Route path="shops" element={<OwnerShopPage/>}/>
                              <Route path="slider" element={<DiscoverySettingItem/>}/>
                              <Route path="slider/:id?" element={<DiscoverySettingItem/>}/>
                              <Route path="category" element={<CategorySettingItem/>}/>
                              <Route path="category/:id?" element={<CategorySettingItem/>}/>
                              <Route path="moderators" element={<AddModerator/>}/>
                              <Route path="verification/moderator/:id" element={<VerificateModerator/>}/>
                              <Route path="verification/support/:id" element={<VerificateSupport/>}/>
                              <Route path="verification/edit-moderator/:id" element={<EditModerator/>}/>
                          </Route>
                          <Route path="/checkout-mobile" element={<CheckoutMobile/>}/>
                          <Route path="*" element={<NotFount/>}/>
                      </Route>
                  </Routes>
                  </AnimatedCircle>
              </BrowserRouter>
          </ThemeProvider>
      </Provider>
  );
}
export default observer(App);
