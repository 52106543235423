import {useEffect, useState} from 'react';

import {
    Box,
    CircularProgress,
    Paper,
    styled,
    Table, TableBody,
    TableCell,
    TableContainer,
    TableHead, TablePagination,
    TableRow
} from "@mui/material";
import {t} from "i18next";
import {getOwnerSlidesList} from "../../api/getContentsApi/getOwnerSlidesList";
import {Link} from "react-router-dom";
import styles from "../OwnerCategoryList/OwnerCategoryList.module.scss";
import useIsMobile from "../../helpers/useIsMobile";

export const OwnerDiscoveryList = () => {
    const [slides, setSlides] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                setLoading(true);
                const data = await getOwnerSlidesList(); // Передаем query параметр в запрос
                setSlides(data);
            } catch (error) {
                console.error("An error occurred while fetching users:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchUsers();
    }, []);


    // Пагинация данных
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({

        padding: '7px',
    }));

    const isMobile = useIsMobile();

    return (
        <Paper sx={{width: '100%'}}>
            { loading? <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh"}}>
                    <CircularProgress/>
                </Box>:
                <>
                    <Box sx={{right: "10px",display: "flex", justifyContent: "flex-end",marginBottom: "-50px"}}>
                        <Link relative to='../slider'>
                            <button className={styles.addButton} >{t("Add Slide")}</button>
                        </Link>
                    </Box>
                    <TableContainer sx={{maxHeight: "60vh",marginTop: "50px"}}>
                        <Table sx={isMobile ? {maxWidth: 370} : ""}>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell sx={{width: 100}}>{t("Top text")}</StyledTableCell>
                                    <StyledTableCell sx={{width: 80}}>{t("Display order")}</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {slides.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((slide) => (
                                    <TableRow key={slide.id}>
                                        <StyledTableCell sx={{maxWidth: 150, wordWrap: 'break-word'}}>
                                            <Link relative to={`../slider/${slide.id}`}>
                                                {slide.top_text}
                                            </Link>
                                        </StyledTableCell>
                                        <StyledTableCell sx={{maxWidth: 150, wordWrap: 'break-word'}}>
                                            {slide.display_order}
                                        </StyledTableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[5,10, 25, 50, 100]}
                        component="div"
                        count={slides.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>}
        </Paper>
    );
};
