import React, {useState} from 'react';
import styles from './NewPassword.module.scss';

import rootStore from "../../../store/RootStore";
import {observer} from "mobx-react";
import TextField from "@mui/material/TextField";
import {Alert, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput} from "@mui/material";
import {t} from "i18next";

const NewPassword = ({setState}) => {
    const {setIfOpenCreateCatalog} = rootStore.moderatorPanelStore;
    const {getCurrentShopId, updateShopsList} = rootStore.superUserStore;
    const {resetPassword, resetPasswordFinal, getErrorReset, getResetForEmail} = rootStore.userStore;

    const [username, setUsername] = useState("");
    const [isCheck, setIsCheck] = useState(false);

    const [newPassword, setNewPassword] = useState();
    const [newPasswordReppet, setNewPasswordReppet] = useState();
    const [code, setCode] = useState('');
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClose = () => {
        setIfOpenCreateCatalog(false);
        setState(false);
    };
    const handleSave = () => {
        resetPasswordFinal(username + "@gmail.com", code, newPassword, setState)
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSendCode = () => {
        resetPassword(username + "@gmail.com")
    }

    return (
        <div className={styles.modalContainer}>
            <div className={styles.modalContainerBlock}>
                <div className={styles.productsCategoryModal}>
                    <div className={styles.productsCategoryTextBlock}>
                        <span>{t("Reset password")}</span>
                        <span className={styles.productsCategoryTextImg} onClick={handleClose}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                            <path fill="#827789"
                                  d="M5.30963553,18.6903645 C4.8990815,18.2798104 4.90303703,17.6102153 5.30742781,17.2058245 L10.5132523,12 L5.30742781,6.79417547 C4.89809307,6.38484073 4.89621919,5.72305186 5.30963553,5.30963553 C5.72018956,4.8990815 6.38978469,4.90303703 6.79417547,5.30742781 L12,10.5132523 L17.2058245,5.30742781 C17.6151593,4.89809307 18.2769481,4.89621919 18.6903645,5.30963553 C19.1009185,5.72018956 19.096963,6.38978469 18.6925722,6.79417547 L13.4867477,12 L18.6925722,17.2058245 C19.1019069,17.6151593 19.1037808,18.2769481 18.6903645,18.6903645 C18.2798104,19.1009185 17.6102153,19.096963 17.2058245,18.6925722 L12,13.4867477 L6.79417547,18.6925722 C6.38484073,19.1019069 5.72305186,19.1037808 5.30963553,18.6903645 Z"></path>
                          </svg>
                        </span>
                    </div>
                    <div className={styles.infoBlock}>
                        <TextField id="outlined-basic" label={t("userName")} variant="outlined"
                                   sx={{width: "100%", marginBottom: "0.5rem"}}
                                   value={username}
                                   onChange={(e) => setUsername(e.target.value)}/>
                        <FormControl sx={{mb: "0.5rem", width: '100%'}} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">{t("Code")}:</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={'text'}
                                value={code} onChange={(e) => setCode(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {<button className={username ? styles.sendCode : styles.sendCodeBlock}
                                                     disabled={!username}
                                                     onClick={() => handleSendCode()}>{t("Send")}</button>}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                            />
                        </FormControl>
                        {getResetForEmail.state === 2 && (<Alert severity="error">{getResetForEmail.message}</Alert>)}
                        {
                            code && getResetForEmail.state === 1 &&
                            (
                                <>
                                    <TextField id="outlined-basic2" label={t("New Password")} variant="outlined"
                                               sx={{width: "100%", marginBottom: "0.5rem"}}
                                               value={newPassword} type={"password"}
                                               onChange={(e) => setNewPassword(e.target.value)}/>
                                    <TextField id="outlined-basic3" label={t("Repeat new password")} variant="outlined"
                                               sx={{width: "100%", marginBottom: "0.5rem"}}
                                               value={newPasswordReppet} type={"password"}
                                               onChange={(e) => setNewPasswordReppet(e.target.value)}/>
                                </>
                            )
                        }
                        {(getErrorReset.state === 1 || getErrorReset.state === 2) && (<Alert
                            severity={getErrorReset.state === 2 ? "error" : "success"}>{getErrorReset.message}</Alert>)}
                        <button
                            className={`${styles.createBtn} ${username && newPassword > 5 && newPassword === newPasswordReppet ? "" : styles.inactive}`}
                            onClick={() => handleSave()}>Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(NewPassword);
