import React from "react";

export default function IconShops(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 491.52 491.52"
            xmlSpace="preserve"
        >
            <path
                d="M471.04 358.4c11.305 0 20.48-9.155 20.48-20.48V112.64c0-11.325-9.175-20.48-20.48-20.48H102.4V30.72c0-11.325-9.175-20.48-20.48-20.48H20.48C9.175 10.24 0 19.395 0 30.72S9.175 51.2 20.48 51.2h40.96v389.12c0 11.325 9.175 20.48 20.48 20.48h87.593c7.107 12.186 20.173 20.48 35.287 20.48s28.18-8.294 35.287-20.48h175.186c7.107 12.186 20.173 20.48 35.287 20.48 22.63 0 40.96-18.33 40.96-40.96s-18.33-40.96-40.96-40.96c-15.114 0-28.18 8.294-35.287 20.48H240.087c-7.107-12.186-20.173-20.48-35.287-20.48s-28.18 8.294-35.287 20.48H102.4V358.4h368.64z"></path>
        </svg>
    );
}
