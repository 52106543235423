import React, {useEffect, useRef, useState} from 'react';
import styles from "./CategoryButton.module.scss"

const CONST_SIZE_TOP_HEADER = 72

const CategoryButton = ({headerRef, parentRef, item, listEl, isMobile}) => {
    const buttonRef = useRef(null);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const isActive = true
    const [elements, setListElements] = listEl;
    const scrollToAnchor = (anchorId, headerRef) => {
        const headerHeight = headerRef.current.offsetHeight;
        const anchorElement = document.getElementById(anchorId);

        if (anchorElement) {
            const yOffset = -headerHeight;
            const y = anchorElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y - CONST_SIZE_TOP_HEADER, behavior: 'smooth' });
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const header = parentRef.current;
        const button = buttonRef.current;
        if (header && button) {
            const isButtonOverflowing =
                button.offsetLeft + button.clientWidth > header.offsetLeft + header.clientWidth ||
                button.offsetTop + button.clientHeight > header.offsetTop + header.clientHeight;
            if(isButtonOverflowing !== isOverflowing){
                setIsOverflowing(isButtonOverflowing);
                if(!isMobile) {
                    if(isButtonOverflowing && !elements.find(el => el.id === item.id)){
                        setListElements([...elements, item])
                    }
                    if(!isButtonOverflowing) {
                        setListElements(elements.filter(el => el.id !== item.id))
                    }
                }
            }
        }
    }, [windowWidth]);
    return (
        <div style={{ opacity: isOverflowing && windowWidth > 641 ? '0' : '1' }} ref={buttonRef} id={`referens${item.id}`}>
            <a className={`${styles.mainConteiner} ${isActive ? styles.active : ""}`}  onClick={() => {scrollToAnchor(`category${item.id}`, headerRef)}}>
                {item.title}
            </a>
        </div>
    );
};

export default CategoryButton;