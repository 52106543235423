import React from "react";

import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";

import styles from "./FinanceCard.module.scss";

import { NextIcon } from "../../../../../assets/icons";
import { useTheme } from "../../../../../helpers/ThemeContext";

const FinanceCard = ({ earningsInfo }) => {
  const { themeMode } = useTheme();
  const navigate = useNavigate();

  const { date, earnings } = earningsInfo;
  const isCurier = window.location.pathname.includes("curier-panel");

  const handleClick = () => {
    if (isCurier) {
      navigate(`${date}`)
    }
  }

  return (
    <div className={styles.cardWrapper} onClick={() => handleClick()}>
      <div className={styles.content}>
        <div>
          <div className={styles.earningsDateLeft}>
            <p className={styles.earningsDate}>{date}</p>
          </div>
        </div>
        <div className={styles.earnings}>
          <p className={styles.earningsSum}>{`${earnings} $`}</p>
          <NextIcon themeMode={themeMode} />
        </div>
      </div>
    </div>
  );
};

export default observer(FinanceCard);
