import { useState, useEffect } from 'react';

const useFooterMargin = () => {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 640);
    const [footer, setFooter] = useState(null);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 640);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const footerElement = document.querySelector('.footer');
        setFooter(footerElement);
    }, []);

    useEffect(() => {
        if (footer && isSmallScreen) {
            footer.style.marginBottom = '60px';
        } else if (footer) {
            footer.style.marginBottom = '0';
        }
    }, [footer, isSmallScreen]);

    return { footer, isSmallScreen };
};

export default useFooterMargin;