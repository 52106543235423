import React, { useRef } from 'react';
import styles from './LocationAdress.module.scss';
import {observer} from "mobx-react";
import {t} from "i18next";



const LocationAdress = ({isOpen, setIsOpen}) => {
    const modalRef = useRef(null);

    const isCurier = window.location.pathname.includes('curier-panel')

    const handleClose = (ev) => {
        if (ev.target.id === "modalka"){
            setIsOpen(false);
        }
    }

    return (
        <div>
            {isOpen && (
                <div id="modalka" className={styles["modal-overlay"]} onClick={(ev)=> handleClose(ev)}>
                    <div className={styles["modal-content"]} ref={modalRef}>
                        <div className={styles.closeBtnConteiner}>
                            <button className={`${styles.IconBack} ${styles.mainStyle}`} style={{
                                marginTop: "5px",
                                zIndex: 999999,
                            }} onClick={() => setIsOpen(false)}>
                                <div className={styles["IconBack__icon"]}>
                                    X
                                </div>
                            </button>
                        </div>
                        <div>
                            <h2 className={styles.titleBody}>{t("Location")}</h2>
                            <div className={styles.imagesBlock}>
                                <div className={styles.rowBlock}>
                                    <div className={styles.leftBlock}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
                                             className={styles.svgImage} fill="currentColor">
                                            <path fill-rule="evenodd"
                                                  d="M12.166 8.94C12.696 7.867 13 6.862 13 6A5 5 0 0 0 3 6c0 .862.305 1.867.834 2.94.524 1.062 1.234 2.12 1.96 3.07A31.481 31.481 0 0 0 8 14.58l.208-.22a31.493 31.493 0 0 0 1.998-2.35c.726-.95 1.436-2.008 1.96-3.07zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
                                            <path fill-rule="evenodd"
                                                  d="M8 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                        </svg>
                                        <div className={styles.textBlockImage}>
                                            <span className={styles.topText}>Tel Aviv</span>
                                            <span className={styles.downText}>{t("Other")}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.IconNextBlock}>
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         id="Layer_1" viewBox="0 0 500 500" className={styles.IconNext}>
                                        <g><polygon className="st0" points="434.8,49 174.2,309.7 76.8,212.3 0,289.2 174.1,463.3 196.6,440.9 196.6,440.9 511.7,125.8 434.8,49     "/></g></svg>
                                </div>
                            </div>
                        </div>
                        {
                            !isCurier &&
                                (
                                    <div className={styles.addAdressBlock}>
                                        <span className={styles.plus}>+</span><span className={styles.plusText}>{t("Add new address")}</span>
                                    </div>
                                )
                        }
                    </div>
                </div>
            )}
            <div onClick={() => setIsOpen(true)}></div>
        </div>
    );
};

export default observer(LocationAdress);
