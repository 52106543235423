import React from 'react';
import styles from "./CountryButton.module.scss"
import arrowSvg from './static/right-arrow.svg';

const CountryButton = ({ text, link }) => {

    return (
        <a className={styles.countryButton}>
            <span className={styles.textBlockC}>
                <span className={styles.textConteiner}>
                    <span className={styles.imgBlockC}>
                        <img src={link} className={styles.countryImg}/>
                    </span>
                    <span>{text}</span>
                </span>
            </span>
            <span className={styles.arrowBlock}>
                <span className={styles.arrowConteiner}>
                    <img className={styles.arrowImg} src={arrowSvg}/>
                </span>
            </span>
        </a>
    );
};

export default CountryButton;