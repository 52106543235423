import {removeToken, removeUser} from "../../helpers";

export const checkAuthError = (data) => {
    if (data.detail === 'Ошибка аутентификации. Невозможно декодировать токеню'){
        removeToken()
        removeUser()
        window.location.reload();
        return 403
    } else {
        return 500
    }
}