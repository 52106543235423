import {useEffect, useState} from "react";
import styles from './OwnerShopPage.module.scss'
import {t} from "i18next";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import {StyledTabs} from "../../components/StyledTabs/StaledTabs";
import {CustomTabPanel} from "../../components/CustomTabPanel/CusmomTabPanel";
import {a11yProps} from "../../helpers/a11yProps";
import {OwnerShopList} from "../../components/OwnerShopList/OwnerShopList";
import {FiltredOwnerShopList} from "../../components/FiltredOwnerShopList/FiltredOwnerShopList";
import {OwnerCategoryList} from "../../components/OwnerCategoryList/OwnerCategoryList";
import {useLocation} from "react-router-dom";
import {OwnerDiscoveryList} from "../../components/OwnerDiscoveryList/OwnerDiscoveryList";



const OwnerShopPage = () => {
    const [value, setValue] = useState(0);
    const location = useLocation();
    useEffect(() => {
        if (location?.state && location?.state?.selectedTab) {
            setValue(location?.state?.selectedTab||0);
        }
    }, [location]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={styles.wrapper}>
            <h1>{t('Shops management')}</h1>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <StyledTabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label={t("All shops")} {...a11yProps(0)} />
                        <Tab label={t("Search")} {...a11yProps(1)} />
                        <Tab label={t("Category")} {...a11yProps(2)} />
                        <Tab label={t("Discovery")} {...a11yProps(3)} />
                    </StyledTabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <OwnerShopList/>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <FiltredOwnerShopList/>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    <OwnerCategoryList/>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3}>
                    <OwnerDiscoveryList/>
                </CustomTabPanel>
            </Box>
        </div>
    );
};

export default OwnerShopPage;