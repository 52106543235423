import React, {useEffect} from 'react';
import styles from './CurierTab.module.scss';

import {observer} from "mobx-react";
import {Avatar} from "@mui/material";
import rootStore from "../../../store/RootStore";


const CurierTab = ({curier}) => {

    return (
        <div className={styles.curierBlock}>
            <Avatar alt="Kurier Anton" src="/static/images/avatar/1.jpg"
                    sx={{width: 32, height: 32, bgcolor: "#A9A9A9E5"}}/>
            <span className={styles.nameCurier}>{curier.username}</span>
            <svg xmlns="http://www.w3.org/2000/svg" height="32" viewBox="0 0 32 32" width="32"
                 className={styles.svgBlock}>
                <g fill="none">
                    <path d="m0 0h32v32h-32z"/>
                    <path
                        d="m16 0c8.836556 0 16 7.163444 16 16s-7.163444 16-16 16-16-7.163444-16-16 7.163444-16 16-16zm-1.5857864 8.9-1.4142136 1.4142136 5.6782814 5.6790678-5.6782814 5.6777864 1.4142136 1.4142136 7.0710678-7.0710678-.021-.0209322.021-.0222136z"
                        fill="#A9A9A9E5"/>
                </g>
            </svg>
        </div>
    );
};

export default observer(CurierTab);
