import React, {useEffect, useState} from 'react';

import { t } from "i18next";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";

import styles from './BasketPanel.module.scss';

import img from "./order.png";
import img_dark from "./order_dark.png";
import emptyBag from "./emptyBag.png";
import rootStore from "../../store/RootStore";
import OrderItem from "../CheckoutBody/OrderItem/OrderItem";
import HistoryCart from "../HistoryCart/HistoryCart";
import { useTheme } from "../../helpers/ThemeContext";
import emptyBag_dark from "./emptyBag_dark.png";
import IncludeContent from "./IncludeContent/IncludeContent";
import ModalAddMessage from "../ModalAddMessage/ModalAddMessage";
import { getCurrencySymbol } from "../../helpers/getCurrency";
import SliderOrderRecomendation from "../SliderOrderRecomendation/SliderOrderRecomendation";

const BasketPanel = observer(({stateClose, isOpevView, setIsOpevView}) => {
    const navigation = useNavigate();
    const { 
        cartsList,
        lazyInitialize,
        lazyInitialize2,
        activeOrdersData, 
        setBasketOpen,
        getOrderPanelTab,
        setOrderPanelTab
    } = rootStore.cartOrderStore;
    const [isOpenAddMess, setIsOpenAddMess] = useState(false);

    const currencySymbol = getCurrencySymbol();
    const [activeTab, setActiveTab] = useState(getOrderPanelTab);
    const [listItem, setListItem] = useState(cartsList.data);
    const {themeMode} = useTheme()

    const handleTabClick = (index) => {
        setActiveTab(index);
    };
    const navigateToShop = () => {
        stateClose()
        navigation("/discovery/stores")
    }

    const handleGoToCheckout = () => {
        stateClose()
        navigation(window.innerWidth > 640 ? "/checkout" : "/checkout-mobile")
    }

    useEffect(() => {
        lazyInitialize()
        lazyInitialize2()
    }, [listItem]);

    const handleNavigateToStore = () => {
        setBasketOpen(false);
        navigation("/stores/" + cartsList['get_shop']['ShopID']);
    };

    const isList = listItem.length !==0 && cartsList?.data?.length !==0 || cartsList.cart_total_items.length > 0

    return (
        <>
            <div className={styles.mainBoxBlock}>
                <div className={styles.tabsContainer}>
                    <div className={styles.tabs}>
                        {/* Кнопки табов */}
                        <button
                            className={`${styles.tabButton} ${activeTab === 0 ? styles.active : ''}`}
                            onClick={() => handleTabClick(0)}
                            data-testid="shopping-cart-tab"
                        >
                            {t("Shopping carts")}
                        </button>
                        <button
                            className={`${styles.tabButton} ${activeTab === 1 ? styles.active : ''}`}
                            onClick={() => handleTabClick(1)}
                            data-testid="order-again-tab"
                        >
                            {t("Order again")}
                        </button>
                    </div>
                </div>
            </div>
            <div>
                {/* Проверяем, какой таб активен и отображаем соответствующий контент */}
                {activeTab === 0 && (
                    isList
                        ?
                        (
                            !isOpevView ?
                                (
                                    <IncludeContent data={cartsList} setOpen={setIsOpevView}/>
                                )
                                :
                                (
                                    <>
                                        <div className={styles.addMessBlock} onClick={() => setIsOpenAddMess(true)}>
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 fill="#02acf8"
                                                 height="26px" width="26px" version="1.1" id="Layer_1"
                                                 viewBox="0 0 458 458">
                                                <g>
                                                    <g>
                                                        <path
                                                            d="M428,41.534H30c-16.569,0-30,13.431-30,30v252c0,16.568,13.432,30,30,30h132.1l43.942,52.243    c5.7,6.777,14.103,10.69,22.959,10.69c8.856,0,17.258-3.912,22.959-10.69l43.942-52.243H428c16.568,0,30-13.432,30-30v-252    C458,54.965,444.568,41.534,428,41.534z M323.916,281.534H82.854c-8.284,0-15-6.716-15-15s6.716-15,15-15h241.062    c8.284,0,15,6.716,15,15S332.2,281.534,323.916,281.534z M67.854,198.755c0-8.284,6.716-15,15-15h185.103c8.284,0,15,6.716,15,15    s-6.716,15-15,15H82.854C74.57,213.755,67.854,207.039,67.854,198.755z M375.146,145.974H82.854c-8.284,0-15-6.716-15-15    s6.716-15,15-15h292.291c8.284,0,15,6.716,15,15C390.146,139.258,383.43,145.974,375.146,145.974z"/>
                                                    </g>
                                                </g>
                                            </svg>
                                            <div className={styles.blockContentAddMess}>
                                                <span
                                                    className={styles.titleAddMessUp}>Add a message for the restaurant</span>
                                                <span className={styles.titleAddMessDown}>Special requests, allergies, dietary restrictions, greeting card text</span>
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" height="20px" width="20px"
                                                 className={styles.arrowRight} version="1.1" id="Layer_1"
                                                 viewBox="0 0 330 330">
                                                <path id="XMLID_222_"
                                                      d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001  c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213  C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606  C255,161.018,253.42,157.202,250.606,154.389z"></path>
                                            </svg>
                                        </div>
                                        <div className={styles.upBtnBlock}>
                                            <span className={styles.titleOrder}>Order item</span>
                                            <button className={styles.addMoreBtn}
                                                    onClick={handleNavigateToStore}>+
                                                Add more
                                            </button>
                                        </div>
                                        <div className={styles.bodyCarts}>
                                            {
                                                cartsList.data.map(e => <OrderItem product={e} setListItem={setListItem}
                                                                                   listItem={listItem}
                                                                                   key={e.product.ProductID}/>)
                                            }
                                            <div className={styles.recomendationBlock}>
                                            <SliderOrderRecomendation/>
                                        </div>
                                        <footer className={styles.footer}>
                                            <div className={styles.footerConteiner}>
                                                <div className={styles.footerBlock}>
                                                    <div className={styles.footerBlockC}>
                                                        <button aria-disabled="false" type="button"
                                                                className={styles.footerBtn}
                                                                onClick={() => handleGoToCheckout()}>
                                                            <div className={styles.footerBtnCont}></div>
                                                            <div className={styles.footerBtnBlock}></div>
                                                            <div className={styles.footerBtnMain}>
                                                                <div className={styles.footerUpBlock}>
                                                                    <span
                                                                        className={styles.footerUpSpan}>{cartsList.cart_total_items}</span>
                                                                </div>
                                                                <div
                                                                    className={styles.footerDownBlock}>{t("Go to checkout")}
                                                                    <span
                                                                        className={styles.footerDownSpan}>{currencySymbol}&nbsp;{cartsList.cart_total_price_converted}</span>
                                                                </div>
                                                            </div>
                                                        </button>
                                                        <div aria-live="assertive"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </footer>
                                    </div>
                                </>
                            ))
                        : (<div className={`${styles.tabPanel} ${activeTab === 0 ? styles.active : ''}`}>
                        <div className={styles["image-wrapper"]}>
                                {themeMode === "light" ? <img src={emptyBag} alt="React Logo"/> :
                                    <img style={{width: "69%"}} src={emptyBag_dark} alt="React Logo"/>}
                            </div>
                            <h3 className={styles.title}>{t("Your cart is empty")}</h3>
                            <p className={styles.subtitle}>
                                {t("When you add items from a restaurant or store")}
                            </p>
                            <button onClick={navigateToShop} className={styles.button}>{t("Start shopping")}</button>
                        </div>)
                )}
                {activeTab === 1 && (
                    activeOrdersData.length ?
                        (
                            <div className={styles.conteiner}>
                                {
                                    activeOrdersData.map((element, index) => (
                                        <HistoryCart data={element} index={index} key={element.id}/>
                                    ))
                                }
                            </div>
                        ) :
                        (
                            <div className={`${styles.tabPanel} ${activeTab === 1 ? styles.active : ''}`}>
                                <div className={styles["image-wrapper"]}>
                                    {themeMode === "light" ? <img src={img} alt="React Logo"/> :
                                        <img style={{width: "100%"}} src={img_dark} alt="React Logo"/>}
                                </div>
                                <h3 className={styles.title}>{t("No orders yet")}</h3>
                                <p className={styles.subtitle}>
                                    {t("Your past orders will appear here, making it a breeze to order your favorites again.")}
                                </p>
                                <button onClick={navigateToShop}
                                        className={styles.button}>{t("Start shopping")}</button>
                            </div>
                        ))
                }
            </div>
            {
                isOpenAddMess &&
                (
                    <ModalAddMessage isOpen={isOpenAddMess} setIsOpen={setIsOpenAddMess}/>
                )
            }
        </>
    )
        ;
});

export default BasketPanel;