import React, {useState} from 'react';
import DiscoveryParent from "../../components/DiscoveryParent/DiscoveryParent";
import {observer} from "mobx-react";
import rootStore from "../../store/RootStore";
import FilterHeader from "../../components/FilterHeader/FilterHeader";
import SearchBody from "./SearchBody";
import SearchField from "../../components/Header/SearchField/SearchField";
import styles from "./SearchPage.module.scss"

const SearchPage = () => {
    const [isOpenAll, setIsOpenAll] = useState(false);
    return (
        <DiscoveryParent>
            <div className={styles.mainBlock}>
                <SearchField isOpenAll={isOpenAll} setIsOpenAll={setIsOpenAll}/>
                <SearchBody setIsOpenAll={setIsOpenAll} isOpenAll={isOpenAll}/>
            </div>
        </DiscoveryParent>
    );
};

export default observer(SearchPage);