import api from "../api";

export const getModeratorInfo = async (id) => {
    try {
        if(id) {
            const users = await api.get(`/owner_site/moderators/${id}/detail/`);
            return users.data;
        }

    } catch (error) {
        console.error("An error occurred:", error);
        throw error;
    }
}