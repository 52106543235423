import React from 'react';

const CardIcon = () => {
    return (
        <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fill-rule="evenodd">
                <path
                    d="M8,5 L8,34 C8,34.5522847 8.44771525,35 9,35 L32,35 C32.5522847,35 33,34.5522847 33,34 L33,5 L8,5 Z M8,2 L8,5 L33,5 L33,2 L36,2 L36,34 C36,36.209139 34.209139,38 32,38 L9,38 C6.790861,38 5,36.209139 5,34 L5,2 L8,2 Z"
                    fill="#BAB4BC" fill-rule="nonzero"></path>
                <path fill="#BAB4BC" d="M11 23H30V26H11zM11 29H25V32H11z"></path>
                <path fill="#FFFFFF" d="M11 2H30V20H11z"></path>
            </g>
        </svg>
    );
};

export default CardIcon;