import { makeAutoObservable } from 'mobx';
import api from "../api/api";

class CategoriesStore {
    categories = []
    initialized = false
    currentCategoryID = 0

    constructor() {
        makeAutoObservable(this);
        this.lazyInitialize();
        this.setCurrentCategoryID = this.setCurrentCategoryID.bind(this);
    }

    lazyInitialize = () => {
        if (!this.initialized) {
            api.get('/categories/').then(response => {
                this.categories = response.data;
                this.initialized = true;
            });
        }
    }

    updateCategories = () => {
        api.get('/categories/').then(response => {
            this.categories = response.data;
            this.initialized = true;
        });
    }

    get categoriesList() {
        return this.categories;
    }

    setCurrentCategoryID(id) {
        this.currentCategoryID = id;
    }
    get currentCategoryId() {
        return this.currentCategoryID;
    }

}

const categoriesStore = new CategoriesStore();
export default categoriesStore;