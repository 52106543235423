import React, {useEffect, useState} from 'react';
import styles from './CheckoutLeft.module.scss'
import OrderItem from "../OrderItem/OrderItem";
import rootStore from "../../../store/RootStore";
import {useIconColor} from "../../../helpers/useIconColor";
import {t} from "i18next";

const CheckoutLeft = () => {
    const iconColor = useIconColor()
    const [promo, setPromo] = useState();
    const {cartsList, lazyInitialize} = rootStore.cartOrderStore;
    const [listItem, setListItem] = useState([]);
    useEffect(() => {
        lazyInitialize(setListItem)
    }, []);

    return (
        <div>
            <ul className={styles.mainPage}>
                <div className={styles.upBlock}>
                    <h3 className={styles.titleBlock}>
                        <span>{t("Delivery method and time")}</span>
                    </h3>
                </div>
                <li className={styles.liBlock}>
                    <a className={styles.linkBlock} data-test-id="DeliverySummaryButton"
                       data-test-value="homedelivery" role="button" tabIndex="0" href="">
                        <div className={styles.middleBlock}>
                            <svg viewBox="0 0 24 24" className={styles.imgUp}>
                                <path
                                    d="M4.401 9.602c.281.281.666.439 1.062.439h.039a1.48 1.48 0 0 0 1.075-.496l3.157-3.507a1.505 1.505 0 0 0-.055-2.071L8.098 2.385A1.547 1.547 0 0 0 7 1.947c-.41.01-.802.191-1.076.495l-3.16 3.512a1.502 1.502 0 0 0 .054 2.066l1.583 1.582Zm6.321 2.132 1.451-1.659a8.276 8.276 0 0 0 4.655 1.42h.672a1.5 1.5 0 1 0 0-3h-.672a5.363 5.363 0 0 1-3.767-1.561 1.407 1.407 0 0 0-1.111-.438 1.5 1.5 0 0 0-1.079.511l-3.5 4a1.5 1.5 0 0 0 .3 2.236l.664.442a5.261 5.261 0 0 1 2.2 5.484 1.5 1.5 0 1 0 2.928.651 8.285 8.285 0 0 0-2.741-8.086ZM22 18.495a2.5 2.5 0 1 0-5 0 2.5 2.5 0 0 0 5 0Zm2 0a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm-17 0a2.5 2.5 0 1 0-5 0 2.5 2.5 0 0 0 5 0Zm2 0a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm3.125-14.5a2.375 2.375 0 1 1 4.75 0 2.375 2.375 0 0 1-4.75 0Z"></path>
                            </svg>
                        </div>
                        <div className={styles.middleDownBlock}>
                            <div className={styles.middleDownCont}>
                                <span>{t("Please add a delivery address")}</span>
                            </div>
                        </div>
                        <div className={styles.downBlock}>
                            <svg xmlns="http://www.w3.org/2000/svg" className={styles.downImg} viewBox="0 0 24 24">
                                <path fill="none" d="M0 0h24v24H0z"></path>
                                <path fill={iconColor}
                                      d="M8.5 20a.5.5 0 01-.365-.841L14.815 12 8.136 4.841a.5.5 0 11.729-.681l7 7.5c.18.192.18.49 0 .682l-7 7.5a.494.494 0 01-.365.16"></path>
                            </svg>
                        </div>
                    </a>
                </li>
            </ul>
            <div data-test-id="OrderItemsList">
                <div className={styles.itemConteiner}>
                    <div className={styles.itemConteinerBlock}>
                        <h3 className={styles.titleItemsBlock}>
                            <span data-localization-key="order.selected-items">{t("Selected items")}</span>
                        </h3>
                    </div>
                    <ul className={styles.listBlockCart}>
                        {
                            listItem.map(e =>
                                <OrderItem
                                product={e}
                                setListItem={setListItem}
                                listItem={listItem}
                                key={e.product.ProductID}
                                />
                            )
                        }
                    </ul>
                    <div className={styles.addMoreBlock}>
                        <ul className={styles.addMoreUl}>
                            <a font-family="default" className={styles.addMoreLink}>
                                <span className={styles.addMoreText}>
                                    <svg viewBox="0 0 24 24" className={styles.addMoreSvg}>
                                        <path
                                            d="M0 12a1.5 1.5 0 001.5 1.5h8.75a.25.25 0 01.25.25v8.75a1.5 1.5 0 003 0v-8.75a.25.25 0 01.25-.25h8.75a1.5 1.5 0 000-3h-8.75a.25.25 0 01-.25-.25V1.5a1.5 1.5 0 00-3 0v8.75a.25.25 0 01-.25.25H1.5A1.5 1.5 0 000 12z"></path></svg></span>Add
                                {t("more items")}</a>
                        </ul>
                    </div>
                    <div className={styles.titleMiddleBlock}>
                        <h3 className={styles.titleMiddle}>{t("Payment details")}</h3>
                    </div>
                    <ul className={styles.ulCash}>
                        <div></div>
                        <div></div>
                        <li className={styles.liCash}>
                            <a className={styles.linkBlockCash}
                               data-test-id="PaymentMethods.SelectedPaymentMethod" role="button" tabIndex="0" href="">
                                <div className={styles.upCashBlock}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                         className={styles.upCashBlockImg}>
                                        <path fill={iconColor}
                                              d="M13.115 13.957c1.039 0 1.885.841 1.885 1.874a1.88 1.88 0 01-1.858 1.874h-.103v.76a.537.537 0 01-1.074 0v-.76H10.91a.537.537 0 01-.537-.534c0-.294.241-.535.537-.535h2.206c.448 0 .81-.36.81-.805a.808.808 0 00-.81-.806h-1.23A1.882 1.882 0 0110 13.151c0-1.032.846-1.874 1.885-1.874h.076v-.743a.537.537 0 011.074 0v.743h1.016c.295 0 .537.24.537.535a.537.537 0 01-.537.534h-2.166c-.448 0-.81.36-.81.805 0 .445.362.806.81.806h1.23zm6.848-.964A12.405 12.405 0 0121 17.935C21 20.175 19.18 22 16.944 22H7.056A4.067 4.067 0 013 17.935c0-1.685.348-3.348 1.037-4.942a15.159 15.159 0 012.265-3.641 17.174 17.174 0 012.862-2.733L6.768 2.99a.528.528 0 01.061-.655c.815-.838 1.647-1.231 2.623-1.231.77 0 1.568.236 2.605.615a.66.66 0 00.248.044c.3 0 .67-.157 1.063-.319.496-.21 1.067-.445 1.69-.445.68 0 1.29.275 1.865.838.17.162.204.415.095.62l-2.221 4.13a16.891 16.891 0 012.901 2.764 15.023 15.023 0 012.265 3.641zM7.883 2.773l2.279 3.449h3.641l2.091-3.895c-.357-.253-.64-.288-.84-.288-.414 0-.841.18-1.29.367-.466.196-.954.397-1.463.397-.21 0-.414-.035-.606-.105-.928-.345-1.625-.554-2.243-.554-.58 0-1.063.192-1.569.629zm9.061 18.18c1.66 0 3.01-1.354 3.002-3.018 0-2.803-1.02-5.453-3.037-7.889-1.272-1.541-2.561-2.502-2.957-2.777H10.04a16.004 16.004 0 00-2.958 2.777c-2.013 2.436-3.037 5.09-3.037 7.89a3.017 3.017 0 003.01 3.016h9.89z"></path>
                                    </svg>
                                </div>
                                <div className={styles.miggleCashBLock}>
                                    <div className={styles.miggleCashConteiner}>
                                        <span className={styles.miggleCashConteinerText}>{t("Cash")}</span>
                                    </div>
                                    <div className={styles.miggleCashBLockFake}>
                                        {t("The chosen payment method will be charged")}
                                    </div>
                                    <div></div>
                                </div>
                                <div className={styles.downCashBLock}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className={styles.downCashImg}
                                         viewBox="0 0 24 24">
                                        <path fill="none" d="M0 0h24v24H0z"></path>
                                        <path fill={iconColor}
                                              d="M8.5 20a.5.5 0 01-.365-.841L14.815 12 8.136 4.841a.5.5 0 11.729-.681l7 7.5c.18.192.18.49 0 .682l-7 7.5a.494.494 0 01-.365.16"></path>
                                    </svg>
                                </div>
                            </a>
                            <div className={styles.fakeCashBlock}></div>
                        </li>
                    </ul>
                    <div className={styles.promoBlock}>
                        <h3 className={styles.promoBlockTitle}>
                            <span>{t("Promo code")}</span>
                        </h3>
                        <p>
                            <span>{t("If you have a Wolt promo code, enter it below to claim your benefits.")}</span>
                        </p>
                        <form data-test-id="consume-credit-code.form" className={styles.formBlock}>
                            <div className={styles.formConteiner}>
                                <div className={styles.formConteiner}>
                                    <input name="code"
                                           data-test-id="consume-credit-code.code-input"
                                           id="consume-credit-code-input"
                                           placeholder={t("Enter promo code...")}
                                           autoComplete="off"
                                           spellCheck="false"
                                           className={styles.formInput}
                                           value={promo}
                                           onChange={(target) => setPromo(target.target.value)}/>
                                </div>
                            </div>
                            <button aria-disabled="false" role="button" type="submit"
                                    data-test-id="consume-credit-code.submit"
                                    className={styles.formBtn}>
                                <div className={styles.formBtnBlock1}></div>
                                <div className={styles.formBtnBlock2}></div>
                                <div className={styles.formBtnBlock3}><span
                                    data-localization-key="credit-codes.submit">{t("Submit")}</span></div>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CheckoutLeft;