export const FULL_DASH_ARRAY = 283;
export const START_OFFSET = FULL_DASH_ARRAY / 12;
export const CIRCLE_DASHARRAY = `${
  FULL_DASH_ARRAY - START_OFFSET
} ${FULL_DASH_ARRAY}`;

export const LIGHT_THEME_CIRCLE_COLORS = {
  BLUE: "#0185cf",
  RED: "#ce3b13",
  ORANGE: "#faae22",
  GREEN: "#24c312",
};

export const DARK_THEME_CIRCLE_COLORS = {
  BLUE: "#0185cf",
  RED: "#ce3b13",
  ORANGE: "#faae22",
  GREEN: "#24c312",
};
