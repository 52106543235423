import React, {useEffect, useRef, useState} from 'react';
import styles from './CheckoutRight.module.scss'
import rootStore from "../../../store/RootStore";
import {observer} from "mobx-react";
import SuccessCheckout from "../../ModalWindows/SuccessCheckout/SuccessCheckout";
import {useNavigate} from "react-router-dom";
import ErrorCheckout from "../../ModalWindows/ErrorCheckout/ErrorCheckout";
import {t} from "i18next";


const CheckoutRight = observer(() => {
    const navigate = useNavigate();
    const { carts, lazyInitialize } = rootStore.cartOrderStore;
    const { sendCheckout } = rootStore.userStore;
    const [isCheckout, setCheckout] = useState(false)
    const [isErrorCash, setErrorCash] = useState(false)

    const [date, setDate] = useState(new Date());
    const [firstDate, setFirstDate] = useState(new Date());

    useEffect(() => {
        const now = Date.now();

        const timeout = setTimeout(
            queueMicrotask(() => {
                setDate(new Date());
            }),
            now % 1000,
        );

        if(isCheckout && (date - firstDate)/1000 > 2){
            navigate('/me/order-history')
            lazyInitialize()
        }
        if(isErrorCash && (date-firstDate)/ 1000 > 2){
            setErrorCash(false)
        }

        return () => clearTimeout(timeout);
    }, [date]);

    const handleSubmit = async () => {
        await sendCheckout(setCheckout, setFirstDate, setErrorCash)
    }

    return (
        <>
        {isCheckout ? (<SuccessCheckout/>) : ''}
        {isErrorCash ? (<ErrorCheckout/>) : ''}
        <div className={styles.mainBlock}>
            <div className={styles.mainBlockOne}>
                <div className={styles.mainBlockSecond}>
                    <span className={styles.mainBlockText}>
                        <div>{t("Prices in GEL")}
                            <div className={styles.addField}>{t("incl. taxes (if applicable)")}
                            </div>
                        </div>
                        <button font-family="default" type="button" className={styles.btnField}>
                            <svg viewBox="0 0 24 24" className={styles.imgField}>
                                <path  d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C23.9928 5.37555 18.6244 0.00716485 12 0ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C21.9939 17.5203 17.5203 21.9939 12 22ZM10 18.5C9.44771 18.5 9 18.0523 9 17.5C9 16.9477 9.44771 16.5 10 16.5H10.75C10.8881 16.5 11 16.3881 11 16.25V11.75C11 11.6119 10.8881 11.5 10.75 11.5H10C9.44771 11.5 9 11.0523 9 10.5C9 9.94771 9.44771 9.5 10 9.5H11C12.1046 9.5 13 10.3954 13 11.5V16.25C13 16.3881 13.1119 16.5 13.25 16.5H14C14.5523 16.5 15 16.9477 15 17.5C15 18.0523 14.5523 18.5 14 18.5H10ZM13.25 6.5C13.25 5.67157 12.5784 5 11.75 5C10.9216 5 10.25 5.67157 10.25 6.5C10.25 7.32843 10.9216 8 11.75 8C12.5784 8 13.25 7.32843 13.25 6.5Z"></path>
                            </svg>
                        </button>
                    </span>
                    <dl className={styles.middleBlock}>
                        <div data-test-id="Order.Subtotal" className={styles.upMiddleBlock}>
                            <dt className={styles.upMiddleBlockText}>Item subtotal ({carts.cart_total_items} items)</dt>
                            <dd className={styles.upMiddleBlockText}><span>{carts.cart_total_price}</span></dd>
                        </div>
                        {/*<div className={styles.preMiddleBlock}>
                            <dt className={styles.upMiddleBlockText}><span >Delivery</span></dt>
                            <dd className={styles.upMiddleBlockText}><span><span>1.00</span></span></dd>
                        </div>*/}
                        <div className={styles.preMiddleBlock}>
                            <dd className={styles.upMiddleBlockText}>
                                <p className={styles.upMiddleBlockP}>
                                    {t("The climate is changing, and so are we.")}
                                    <a font-family="default"
                                        href="/responsibility/co2"
                                        target="_blank"
                                        className={styles.link}
                                    >
                                        {t("More")}
                                    </a>
                                </p>
                            </dd>
                        </div>
                        <div className={styles.preMiddleBlock}>
                            <dt className={styles.upMiddleBlockText}>{t("Service fee")}</dt>
                            <dd className={styles.upMiddleBlockText}><span>0.94</span></dd>
                        </div>
                    </dl>
                    <dl className={styles.discountBlock}>
                        <div className={styles.OrderDiscount}>
                            <dt className={styles.OrderDiscount1}>{t("Discounts")}</dt>
                            <dd className={styles.OrderDiscount2}>
                                <span>-1.00</span>
                            </dd>
                        </div>
                        <div className={styles.OrderDiscountItem}>
                            <dt className={styles.OrderDiscountItem1}>
                                <span>{t("New users enjoy 14 days of no delivery fees!")}</span>
                            </dt>
                        </div>
                        <div className={styles.downOrderDiscount}>
                            <dt className={styles.downOrderDiscountDt}>
                                <button font-family="default" type="button" className={styles.downOrderDiscountBtn}>
                                    {("Choose an offer (1 available)")}
                                </button>
                            </dt>
                        </div>
                    </dl>
                    <dl className={styles.downConteiner}>
                        <div className={styles.downConteinerMain}>
                            <dt className={styles.defText}>Total sum</dt>
                            <dd className={styles.defText}>
                                <span>GEL&nbsp;{carts.cart_total_price}</span>
                            </dd>
                        </div>
                        <div className={styles.fakeBlock}></div>
                        <div className={styles.fakeBlock}></div>
                        <div className={styles.fakeBlockNo}>
                            <dd className={styles.defTextTwo}>
                                <p className={styles.defTextTwoText}>
                                    {t("You’ll pay this amount in cash when the order is delivered.")}
                                </p>
                            </dd>
                        </div>
                        <div className={styles.btnMainBlock}>
                            <dd className={styles.btnConteiner}>
                                <div className={styles.btnUpBlock}>
                                    <div className={styles.btnUpConteiner}>
                                        <button aria-disabled="false" role="button" type="button"
                                                className={styles.btn}
                                                onClick={() => handleSubmit()}
                                        >
                                            <div className={styles.fakeOne}></div>
                                            <div className={styles.fakeOne}></div>
                                            <div className={styles.notFake}>
                                                <div className={styles.testBlock}>
                                                    <span className={styles.testTextOne}>{t("Click to confirm your order")}</span>
                                                    <span className={styles.testTextSecond}>{t("Checkout")}</span></div>
                                            </div>
                                        </button>
                                    </div>
                                </div>

                            </dd>
                        </div>
                    </dl>
                </div>
                <div className={styles.fantomBlock}>
                    <svg viewBox="0 0 50 50" className={styles.fantomImg}>
                        <circle className="bgPath" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
                        <circle className="mainPath" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
                    </svg>
                    <span>{t("Waiting for authorisation")} –&nbsp; 0 {t("sec remaining")}.</span>
                </div>
            </div>
        </div>
        </>
    );
});

export default CheckoutRight;