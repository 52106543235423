import React from 'react';
import styles from "./SupportDiscoveryParent.module.scss"

const SupportDiscoveryParent = ({ children }) => {
    return (
        <div className={styles.mainConteiner}>
            <div>
                <div className={styles.conteiner}>
                    { children }
                </div>
            </div>
        </div>
    );
};

export default SupportDiscoveryParent;