import React from 'react';
import styles from "../OwnerVerificationPage/OwnerVerificationPage.module.scss";
import {t} from "i18next";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import AllModeratorsTable from "../../components/AllModeratorsTable/AllModeratorsTable";
import FiltredModeratorTable from "../../components/FiltredModerators/FiltredModeratorTable";
import {StyledTabs} from "../../components/StyledTabs/StaledTabs";
import {CustomTabPanel} from "../../components/CustomTabPanel/CusmomTabPanel";
import {a11yProps} from "../../helpers/a11yProps";

const AddModerator = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={styles.wrapper}>
            <h1>{t('Moderator')}</h1>
            <Box sx={{width: '100%'}}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <StyledTabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label={t("Moderators")} {...a11yProps(0)} />
                        <Tab label={t("All users")} {...a11yProps(1)} />
                    </StyledTabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <AllModeratorsTable/>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <FiltredModeratorTable/>
                </CustomTabPanel>
            </Box>

        </div>
    );
};

export default AddModerator;