import {makeAutoObservable} from 'mobx';
import {getToken} from "../helpers";

class SupportStore {
    messages = [];
    socket = null;

    constructor() {
        makeAutoObservable(this);
    }

    setSocket = (socket) => {
        this.socket = socket
        console.log(socket)
    }

    connect = (id) => {
        const token = JSON.parse(getToken());

        this.socket = new WebSocket(`wss://wqdqwdqd214.xyz/ws/support_chat/${id}/?token=${token}`);

        this.socket.onopen = () => {
            console.log('WebSocket connection established');
        };

        this.socket.onmessage = (event) => {
            try {
                const message = JSON.parse(event.data);
                if (JSON.parse(event.data).command === "new_message") {
                    this.messages.push(message);
                }
                if (JSON.parse(event.data).command === "chat_history") {
                    this.messages.push(...JSON.parse(event.data).messages)
                }
            } catch (e) {
                console.error('Error parsing message:', e);
            }
        };

        this.socket.onclose = (event) => {
            console.log('WebSocket connection closed', event);
            if (!event.wasClean) {
                console.error('Connection died with code:', event.code, 'and reason:', event.reason);
            }
        };

        this.socket.onerror = (error) => {
            console.log('WebSocket error', error);
        };
    }

    disconnect = () => {
        if (this.socket) {
            this.socket.close();
            this.socket = null;
        }
    }

    clearMessages = () => {
        this.messages = []
    }

    getHistoryMessage = (id) => {
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            const payload = JSON.stringify({
                order_id: id,
                command: "fetch_history",
            });
            this.socket.send(payload);
        } else {
            console.error('WebSocket is not connected');
        }
    }

    sendMessage = (message, id) => {
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            const payload = JSON.stringify({
                order_id: id,
                command: "send_message",
                message: message
            })
            this.socket.send(payload);
        } else {
            console.error('WebSocket is not connected');
        }
    }

    closeChat = () => {
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            const payload = JSON.stringify({
                command: "close_chat",
            })
            this.socket.send(payload);
        } else {
            console.error('WebSocket is not connected');
        }
    }
}

const supportStore = new SupportStore();
export default supportStore;