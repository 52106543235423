import api from "../api";

export const updateShop = async (formData,id) => {
    if(formData) {
        try {
            const apiResponse = await api.patch(`/admin_seller_shop/my-shop/${id}/update/`,formData).then(response => response);
            return apiResponse;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }
}