import React, {useEffect, useState} from 'react';
import styles from "./StoresCardBlock.module.scss"
import StoresCard from "./StoresCard/StoresCard";
import rootStore from "../../store/RootStore";
import {t} from "i18next";

const StoresCardBlock = ({title, shops}) => {
    const [shopsList, setShopsList] = useState([]);
    const {destroySearchCategory} = rootStore.searchStore;

    useEffect(() => {
        setShopsList(shops)
    }, [shops]);

    const [route,setRoute] = useState('discovery')

    useEffect(() => {
        if(window.location.pathname.includes("/restaurants")){
            setRoute('restaurants')
        } else if(window.location.pathname.includes("/stores")) {
            setRoute('stores')
        } else if(window.location.pathname.includes("/discovery")){
            setRoute('discovery')
        }
    }, [window.location.pathname]);

    return (
        <div className={styles.mainConteiner}>
            <div className={styles.titleBlock}>
                <h2 className={styles.title}>{title}</h2>
                <button className={styles.btnMoreInfo} onClick={() => destroySearchCategory(route)}>{t("See all")}</button>
            </div>
            {shopsList.length > 0 && <div className={styles.cardConteiner}>
                {
                    shopsList.map((shop, index) =>
                        <StoresCard shop={shop} key={index}/>
                    )
                }
            </div>}
        </div>
    );
};

export default StoresCardBlock;