import React, {useEffect, useState} from 'react';
import {t} from "i18next";
import DiscoveryParent from "../DiscoveryParent/DiscoveryParent";
import StoresCardBlock from "../StoresCardBlock/StoresCardBlock";
import FilterHeader from "../FilterHeader/FilterHeader";
import CategorySliderParent from "../CategorySliderParent/CategorySliderParent";
import {observer} from "mobx-react";
import rootStore from "../../store/RootStore";
import styles from "./Stores.module.scss"
import useScrollPosition from "../../helpers/useScrollPosition";
import {getSearchCategoryShop} from "../../api/getContentsApi/getSearchCategoryShop";
import NavBarDeskTop from "../NavBarDeskTop/NavBarDeskTop";

const Stores = () => {
    const shops = rootStore?.shopStore?.shopsList;
    const [filteredShop,setFilteredShop] = useState(null)
    const [filteredTitle,setFilteredTitle] = useState('')
    const {getSearchCategoryStoreName} = rootStore.searchStore;
    useScrollPosition("Store")

    useEffect(() => {
        if (window.location.pathname.includes("/stores")) {
            try {
                const { id: searchId, name: searchName } = JSON.parse(localStorage.getItem("searchCategoryStores"));
                setFilteredTitle(searchName);
                (async () => {
                    const filteredShop = await getSearchCategoryShop(searchId);
                    setFilteredShop(filteredShop);
                })();
            } catch (e) {
                setFilteredShop(null);
                setFilteredTitle("");
            }
        }
    }, [getSearchCategoryStoreName]);

    return (
        <DiscoveryParent>
            <NavBarDeskTop/>
            <div className={styles.main}>
                <FilterHeader title={t("Stores")}/>
                <CategorySliderParent names={t("Let's shop for:")}/>
                <StoresCardBlock shops={filteredShop && filteredTitle  ? filteredShop : shops}
                                 title={filteredTitle ? filteredTitle : t("All stores")}/>
            </div>
        </DiscoveryParent>
    );
};

export default observer(Stores);