import {makeAutoObservable} from 'mobx';
import MENU from "../constants/constantMenu";

class ModeratorPanelStore {
    activePanel = MENU.Menu;
    ifOpenCreateCatalog = false;
    ifOpenCreateProduct = false;
    ifOpenEditProduct = false;
    ifOpenSettingShop = false;
    // ifOpenCreateShop = false;
    ifOpenCreateCurier = false;
    currentEditProductID = null;

    constructor() {
        makeAutoObservable(this);
    }

    get getActivePanel() {
        return this.activePanel;
    }
    get getIfOpenCreateCatalog() {
        return this.ifOpenCreateCatalog;
    }
    get getIfOpenCreateProduct() {
        return this.ifOpenCreateProduct;
    }
    get getIsOpenEditProduct() {
        return this.ifOpenEditProduct;
    }
    get getIsOpenSettingShop() {
        return this.ifOpenSettingShop;
    }
    get getIsOpenCreateCurier() {
        return this.ifOpenCreateCurier;
    }
    // get getIsOpenCreateShop() {
    //     return this.ifOpenCreateShop;
    // }

    setIfOpenCreateCatalog = (state) => {
        this.ifOpenCreateCatalog = state;
    }

    setIfOpenCreateProtuct = (state) => {
        this.ifOpenCreateProduct = state;
    }
    setIsOpenEditProduct = (state) => {
        this.ifOpenEditProduct = state;
    }
    setCurrentEditProductId = (state) => {
        this.currentEditProductID = state;
    }
    setIsOpenSettingShop = (state) => {
        this.ifOpenSettingShop = state;
    }
    // setIfOpenCreateShop = (state) => {
    //     this.ifOpenCreateShop = state;
    // }
    setIfOpenCreateCurier = (state) => {
        this.ifOpenCreateCurier = state
    }
    get getCurrentEditProductID() {
        return this.currentEditProductID;
    }

    setActivePanel = (element) => {
        this.activePanel = element;
    }
}

export default new ModeratorPanelStore();