import React from 'react';
import styles from './InfoOrderCardWorked.module.scss';
import {useLocation, useNavigate, } from "react-router-dom";
import IconBack from "../../components/Icons/IconBack";
import {observer} from "mobx-react";
import {t} from "i18next";
import {PiHandbagFill} from "react-icons/pi";
import {TbMessageChatbot} from "react-icons/tb";


const InfoOrderCardWorked = () => {
const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;
    const { productData,shopData } = state || {};

    return (
        <>
            <div className={styles.backer}
                 onClick={() => navigate(-1)}>
                <IconBack size={20} pure={true} fill={"#009de0"} marg={0}/>
            </div>
            <div className={styles.wrapper}>
                <h1 className={styles.title}>{t("Last delivery information")}</h1>
                <h2 className={styles.subTitle}>{t("Place of departure")}</h2>
                <p className={styles.shopName}>| {shopData?.shop_name}</p>
                <h3 className={styles.orderDetails}>{t("Order details")}</h3>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <div>
                        <p className={styles.orderDetailsDescription}>{t("Order ID")}</p>
                        <p className={styles.orderDetailsValue}># {productData?.product?.ProductID}</p>
                    </div>
                    <PiHandbagFill fontSize="1.4rem"/>
                </div>
                <hr style={{opacity: 0.2, margin: "0.5rem 0", position: "relative", width: "100%"}}/>

            </div>
            <div className={styles.orderProduct}>
                <p className={styles.orderProductValue}>
                    {productData?.product?.ProductName}
                </p>
                <p className={styles.orderProductValue}>
                    {`x ${productData?.quantity}`}
                </p>
            </div>
            <h4 className={styles.titleDelivery}>{t("Delivery problems?")}</h4>
            <div style={{display: "flex", justifyContent: "center"}}>
                <button className={styles.supportButton}>
                    {t("Chat with support")}
                </button>
                <TbMessageChatbot style={{color: "var(--blue-color)", fontSize: "1.8rem"}}/>
            </div>
            <hr style={{opacity: 0.2, marginTop: "3rem", position: "relative", width: "100%"}}/>
        </>

    );
};

export default observer(InfoOrderCardWorked);
