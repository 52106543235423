import api from "../api";

export const deleteModeratorShop = async (id) => {
    if (id) {
        try {
            const apiResponse = await api.delete(`/admin_seller_shop/my-shop/${id}/delete/`);
            return apiResponse;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }
};
