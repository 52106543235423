import React, {useEffect, useRef, useState} from 'react';
import { motion } from 'framer-motion';
import { useTheme } from '../helpers/ThemeContext';
import { useLocation } from 'react-router-dom';
import useIsMobile from "../helpers/useIsMobile";
import useAnimateVariant from "../helpers/useAnimateVariant";

const AnimatedCircle = ({ children }) => {
    const location = useLocation();
    const variant = useAnimateVariant(location);
    const isMobile = useIsMobile ();
    const { themeMode } = useTheme();
    const [isVisible, setIsVisible] = useState(true);
    const prevLocationPathname = useRef(location.pathname);
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(false);
        }, 700);
        if (prevLocationPathname.current !== location.pathname) {
            setIsVisible(true);
        }
        prevLocationPathname.current = location.pathname;
        return () => clearTimeout(timer);
    }, [location.pathname]);

    const backgroundColor = themeMode === 'dark' ? 'black' : 'white';
    if(!isMobile) {
        return (
            <>{children}</>
        );
    }
    return (
        <div style={{ position: 'relative' }}>
            {isVisible && (
                <motion.div
                    key={location.pathname}
                    initial="initial"
                    animate="animate"
                    variants={variant.current}
                    style={{
                        position: 'fixed',
                        bottom: '80vh',
                        width: '250vw',
                        height: '250vh',
                        background: `radial-gradient(circle at right, ${backgroundColor} 0%, ${backgroundColor} 50%)`, // Градиент для видимой правой части
                        borderRadius: '50%',
                        zIndex: 1,
                    }}
                />
            )}
            <div style={{ position: 'relative', zIndex: 0 }}>{children}</div>
        </div>
    );
};

export default AnimatedCircle;
