import React from 'react';

import {t} from "i18next";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";

const SuccessModal = ({ open, onClose ,edit = false}) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{t("Success")}</DialogTitle>
            <DialogContent>
                {!edit?
                    <p>{t("Product created successfully!")}</p>
                    : <p> {t("Product updated successfully!")}</p>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{t("Close")}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default SuccessModal;
