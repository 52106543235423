import React, {useEffect} from 'react';
import "./ButtonShared.scss"

const ButtonShared = () => {
    // Функция для обработки клика на кнопке
    const handleClick = () => {
        // Проверка поддержки геолокации в браузере
        // if (navigator.geolocation) {
        //     // Запрос текущей геопозиции у пользователя
        //     navigator.geolocation.getCurrentPosition(
        //         (position) => {
        //             // В случае успеха передаем полученные координаты в callback
        //             const { latitude, longitude } = position.coords;
        //             console.log('Геопозиция:' + '\nLatitude: ' + latitude + '\nLongitude: ' + longitude);
        //         },
        //         (error) => {
        //             // В случае ошибки выводим сообщение об ошибке
        //             console.error('Geolocation error:', error.message);
        //         }
        //     );
        // } else {
        //     // Если геолокация не поддерживается, выводим сообщение
        //     console.error('Geolocation is not supported.');
        // }
    };

    useEffect(() => {
        handleClick();
    }, []);

    return (

        <button onClick={handleClick} className={"buttonShared"}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" className={"img-shared"}>
                <path
                      d="M15.333 7.333h-1.082a.333.333 0 0 1-.328-.28 6.011 6.011 0 0 0-4.976-4.976.333.333 0 0 1-.28-.328V.667a.667.667 0 0 0-1.334 0v1.082c0 .163-.119.302-.28.328a6.011 6.011 0 0 0-4.976 4.976.333.333 0 0 1-.328.28H.667a.667.667 0 0 0 0 1.334h1.082c.163 0 .302.119.328.28a6.011 6.011 0 0 0 4.976 4.976c.161.025.28.165.28.328v1.082a.667.667 0 1 0 1.334 0v-1.082c0-.163.119-.303.28-.328a6.012 6.012 0 0 0 4.976-4.976.333.333 0 0 1 .328-.28h1.082a.667.667 0 1 0 0-1.334ZM8 12.667a4.667 4.667 0 1 1 0-9.334 4.667 4.667 0 0 1 0 9.334Zm0-2a2.667 2.667 0 1 0 0-5.334 2.667 2.667 0 0 0 0 5.334Z">

                </path>
            </svg>
            Share location
        </button>
    );
};

export default ButtonShared;