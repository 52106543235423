import React, {useRef} from 'react';
import styles from './SliderProducts.module.scss'
import {observer} from "mobx-react";
import rootStore from "../../store/RootStore";
import ProductSearchCard from "./ProductSearchCard/ProductSearchCard";
import {t} from "i18next";

const SliderProducts = ({names, setIsOpenAll}) => {
    const {getSearchData} = rootStore.searchStore;
    const sliderContainerRef = useRef(null);
    const handleClickPrevious = () => {
        if (sliderContainerRef.current) {
            sliderContainerRef.current.scrollLeft -= 300; // Измените значение на ваше усмотрение
        }
    };
    const handleClickNext = () => {
        if (sliderContainerRef.current) {
            sliderContainerRef.current.scrollLeft += 300; // Измените значение на ваше усмотрение
        }
    };

    return (
        <div className={styles.mainConteiner}>
            <div className={styles.mainBlock}>
                <div className={styles.upBlockInfoProduct}>
                    <div className={styles.titleProduct}>{t("Food and products")}</div>
                    <button className={styles.btnMoreInfo} onClick={() => setIsOpenAll(true)}>{t("See all")}</button>
                </div>
                <div className={styles.sliderBar}>
                    <div className={styles.sliderBarBlock}>
                        <div className={styles.sliderConteiner} ref={sliderContainerRef}>
                            {
                                getSearchData.map((product) => (
                                    <ProductSearchCard product={product} key={product.ProductID}/>
                                ))
                            }
                            <div className={styles.fakeElem}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(SliderProducts);