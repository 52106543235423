import React, {useEffect, useState} from 'react';
import SupportDiscoveryParent from "../../components/DiscoveryParent/SupportDiscoveryParent";
import {observer} from "mobx-react";
import styles from "./SupportPage.module.scss"
import SupportChat from "./SupportChat";
import {Box, CircularProgress} from "@mui/material";
import {getUnusageChats} from "../../api/getContentsApi/getUnusageChats";

const SupportNewPage = () => {
    const [loading, setLoading] = useState(false);
    const [chats, setChats] = useState([]);

    useEffect(() => {
        const fetchChats = async () => {
            try {
                setLoading(true);
                const data = await getUnusageChats(); // Передаем query параметр в запрос
                setChats(data);
            } catch (error) {
                console.error("An error occurred while fetching users:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchChats();
    }, []);

    return (
        <SupportDiscoveryParent>
            <div className={styles.mainBlock}>
                <div className={styles.titlePage}>{"New chats"}</div>
                <div className={styles.chats}>
                    { loading? <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh"}}>
                            <CircularProgress/>
                        </Box>:
                        <>
                            {
                                chats.map(chat =>
                                (
                                    <SupportChat chat={chat}/>
                                ))
                            }
                        </>
                    }
                </div>
            </div>
        </SupportDiscoveryParent>
    );
};

export default observer(SupportNewPage);