import { makeAutoObservable } from 'mobx';
import api from "../api/api";

class ShopStore {
    shops = [];
    initialized = false;
    currentShopData = {};
    loader = true;
    openMoreInfos = false;

    constructor() {
        makeAutoObservable(this);
        this.lazyInitialize();
    }

    lazyInitialize = () => {
        if (!this.initialized) {
            api.get('/shops/').then(response => {
                this.shops = response.data;
                this.initialized = true;
            });
        }
    }

    get getLoader(){
        return this.loader;
    }
    get getOpenMoreInfo() {
        return this.openMoreInfos
    }

    setOpenMoreInfo = (data) => {
        this.openMoreInfos = data
    }

    setLoader = (state) => {
        this.loader = state
    }

    get shopsList() {
        if (this.shops.length){
            return this.shops;
        }
        else {
            return [];
        }
    }
    addShopData(data) {
        this.currentShopData = data;
    }
    get shopData() {
        return this.currentShopData;
    }
    clearShopData() {
        this.currentShopData = {};
    }

    deleteShop = (id) => {
        api.delete(`/admin_seller_shop/my-shop/${id}/delete/`).then(response => {

        });
    }

    updateShop = (id, data) => {
        api.put(`/admin_seller_shop/my-shop/${id}/update/`, data).then(response => {
        });
    }
}

const shopsStore = new ShopStore();
export default shopsStore;