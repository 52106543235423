import React from 'react';
import styles from './ErrorLogin.module.scss'
import {observer} from "mobx-react";
import {Alert, Stack} from "@mui/material";




const ErrorLogin = observer(({text}) => {

    return (
        <Stack className={styles.alert} sx={{ width: '100%' }} spacing={2}>
            <Alert severity="error">{text}</Alert>
        </Stack>
    );
});

export default ErrorLogin;