import React from 'react';
import styles from "./Category.module.scss"
import Card from "../Card/Card";

const Category = ({title, items, id}) => {

    return (
        <div className={styles.mainConteiner} id={`category${id}`}>
            <div>
                <div className={styles.headerBlock}>
                    <h2 className={styles.headerText}>{title}</h2>
                </div>
                <div className={styles.mainBlock}>
                    {
                        items.map((elem, key) =>
                            (<Card item={elem} key={key}/>)
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default Category;