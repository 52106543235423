import api from "../api";

export const getSupportUsers = async (search) => {
    try {
        if(search) {
            const users = await api.get(`/owner_site/verifications/users/all/?search=${search}`);
            return users.data;
        }
        const users = await api.get(`/owner_site/supports/worker/all/`);
        return users.data;
    } catch (error) {
        console.error("An error occurred:", error);
        throw error;
    }
}