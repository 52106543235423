import React, {useEffect} from 'react';
import WithAuthUser from "../../helpers/WithAuthUser";
import Box from "@mui/material/Box";
import styles from "./PersonalInfo.module.scss"
import StyledTab from "../../components/StyledMuiComponent/StyledTab";
import StyledTabs from "../../components/StyledMuiComponent/StyledTabs";
import PersonalHeader from "../../components/PersonalLab/PersonalHeader/PersonalHeader";
import useIsMobile from "../../helpers/useIsMobile";
import {Link, Outlet, useMatch, useNavigate} from "react-router-dom";
import PersonalMain from "./PersonalMain/PersonalMain";
import FaceVerification from "../../components/FaceVerification/FaceVerification";
import {t} from "i18next";

const PersonalInfo = () => {
    const [value, setValue] = React.useState(0);
    const isMobile = useIsMobile()
    const navivate = useNavigate()
    const currentPath = useMatch('/me')

    const isNewProfile = window.location.pathname === "/discovery/me";

    useEffect(() => {
        if (currentPath) navivate("/me/personal-info")
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangePage = (newValue) => {
        navivate(newValue)
    };
    return (
        <WithAuthUser>
            <div className={styles.mainContainer}>
                <div className={styles.fatherBlock}>
                    {!isMobile && (<PersonalHeader/>)}
                    <div className={styles.wrapper}>
                        {!isMobile && (<Box sx={{width: '100%', bgcolor: 'background.paper'}}>
                            <StyledTabs sx={{display: "flex", justifyContent: "space-between" }}
                                value={value}
                                onChange={handleChange}
                                orientation={isMobile ? 'vertical':'horizontal'}
                                centered
                                >
                                    <StyledTab sx={{width: "calc(100%/7.4)"}} onClick={() => handleChangePage("personal-info")}  label="Personal info"/>
                                    <StyledTab sx={{width: "calc(100%/7.4)"}} onClick={() => handleChangePage("payments")} label="Payment methods"/>
                                    <StyledTab sx={{width: "calc(100%/7.4)"}} onClick={() => handleChangePage("addresses")} label="Addresses"/>
                                    <StyledTab sx={{width: "calc(100%/7.4)"}} onClick={() => handleChangePage("order-history")} label="Order history"/>
                                    <StyledTab sx={{width: "calc(100%/7.4)"}} onClick={() =>handleChangePage("earn-credits")} label="Earn Wolt credits"/>
                                    <StyledTab sx={{width: "calc(100%/7.4)"}} onClick={() =>handleChangePage("redeem-code")} label="Redeem code"/>
                                    <StyledTab sx={{width: "calc(100%/7.4)"}} onClick={() =>handleChangePage("settings")} label="Settings"/>
                                </StyledTabs>
                            </Box>)
                        }
                        {!isMobile && <hr style={{
                            height: '1px',
                            background: '#BDBDBD',
                            border: 'none',
                            position: 'relative',
                            bottom: '1px',
                            zIndex: 1
                        }}/>}
                        {isMobile && isNewProfile && (<PersonalMain/>)}
                        <Outlet/>
                    </div>
                </div>
            </div>
        </WithAuthUser>

    );
};

export default PersonalInfo;