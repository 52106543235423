import { makeAutoObservable} from 'mobx';
import api from "../api/api";

class ProductStore {

    products = [];
    initialized = false;

    constructor() {
        makeAutoObservable(this);
        this.lazyInitialize();
    }

    lazyInitialize = () => {
        if (!this.initialized) {
            api.get('/products/').then(response => {
                this.products = response.data;
                this.initialized = true;
            });
        }
    }
    get productsList(){
        return this.products;
    }
    addProduct(newProduct) {
        this.products.push(newProduct);
    }
}

const productStore = new ProductStore();
export default productStore;
