import React, {useState, useRef, useEffect} from 'react';
import styles from './LanguageModal.module.scss';
import {observer} from "mobx-react";

import {useNavigate} from "react-router-dom";
import IconDown from "../Icons/IconDown";
import {useTranslation} from "react-i18next";
import rootStore from "../../store/RootStore";

const LANGUAGES_ARRAY = [
    { code: "en", name: "English" },
    { code: "ru", name: "Русский" },
    { code: "es", name: "Español" },
    { code: "he", name: "עברית" }
];

const LanguageModal = ({isOpen, setIsOpen}) => {
    const modalRef = useRef(null);
    const { i18n,t } = useTranslation();
    const { changeLanguage } = rootStore.userStore;
    const currentLanguage = useRef(null)
    const [isClosing, setIsClosing] = useState(false);
    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            setIsClosing(false);
            setIsOpen(false);
        }, 600);
    }


    useEffect(() => {
        currentLanguage.current = localStorage.getItem('i18nextLng')
    },[i18n.language])

    const changeLanguageHandler = (lng) => {
        i18n.changeLanguage(lng);
        changeLanguage(lng)
        localStorage.setItem('i18nextLng', lng);
        handleClose()
    };

    return (
        <div>
            {isOpen && (
                <div id="modalka" className={styles["modal-overlay"]}>
                    <div className={`${styles["modal-content"]} ${isClosing ? styles.fadeOutAnimation : ""}`}
                         ref={modalRef}>
                        <div className={styles.closeBtnConteiner}>
                            <button style={{
                                marginTop: "5px",
                                zIndex: 999999,
                            }} onClick={(ev) => handleClose(ev)}>
                                <IconDown stateClose={() => {}}></IconDown>
                            </button>
                        </div>
                        <div>
                            <h2 className={styles.titleBody}>{t("changeLanguage")}</h2>
                            <div className={styles.conteinerAdresses}>
                                {
                                    LANGUAGES_ARRAY.map(address => (
                                        <div className={styles.imagesBlock}
                                             onClick={() => changeLanguageHandler(address.code)}
                                             >
                                            <div className={styles.rowBlock}>
                                                <div className={styles.leftBlock}>
                                                    <div className={styles.textBlockImage}>
                                                        <span className={currentLanguage.current === address.code ? styles.topText__active : styles.topText}>{address.name}
                                                        </span>
                                                        <span className={styles.downText}>{address.city}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div onClick={() => setIsOpen(true)}></div>
        </div>
    );
};

export default observer(LanguageModal);
