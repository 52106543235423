import React, {useEffect, useState} from 'react';
import styles from "./ProductBody.module.scss"
import ProductHeader from "../ProductHeader/ProductHeader";
import ProductCard from "../ProductCard/ProductCard";
import rootStore from "../../../../store/RootStore";
import {observer} from "mobx-react";
import {getShopProducts} from "../../../../api/getContentsApi/getShopProduct";
import Box from "@mui/material/Box";
import {CircularProgress} from "@mui/material";
import {t} from "i18next";


const ProductBody = () => {
    const shop = rootStore.shopStore.shopData;
    const currentCategoryID = rootStore.categoriesStore.currentCategoryID;
    const {getLoader, setLoader} = rootStore.shopStore;
    const [currentProducts, setCurrentProducts] = useState();
    const [filteredProducts, setFilteredProducts] = useState();
    const {
        ShopID,
        ShopName,
        Description,
    } = shop;


    useEffect(() => {
        return rootStore.shopStore.clearShopData();
    }, []);

    useEffect(() => {
        const data = getShopProducts(ShopID)
            .then(response => {
                setLoader(false)
                return setCurrentProducts(response?.data?.products||[])
            })
            .catch(error => console.log(error));
        }, [ShopID,currentCategoryID]);

    useEffect(() => {
        if(currentCategoryID === 0 && currentProducts) {
            setFilteredProducts(currentProducts)
        }
        if (currentCategoryID !==0 && currentProducts) {
            const filteredProducts = currentProducts.filter(el => el.CategoryID === currentCategoryID);
            setFilteredProducts(filteredProducts)
        }
    }, [!currentCategoryID,currentCategoryID]);

    if (!shop) {
        return <div>Loading</div>;
    }

    return (
        <div className={styles.mainConteiner}>
            {
                getLoader ? (
                    <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh"}}>
                        <CircularProgress/>
                    </Box>) :
                    (
                        window.innerWidth < 640 ? "" : (<ProductHeader/>)
                    )
            }
            <div>
                <div>
                    <div className={styles.mainBlock}>
                        <div id="wolt-specials-0" data-test-id="wolt-specials-0" className={styles.fakeBlock}></div>
                        {
                            getLoader ? "" : (
                                <div className={styles.textBlock}>
                                <h2 className={styles.title}>{t("Most ordered")}</h2>
                                {/* <span className={styles.fakeText}>{Description}</span> */}
                                <button className={styles.btnMoreInfo} onClick={() => {}}>{t("See all")}</button>
                            </div>)
                        }
                        <div className={styles.categoryBlockUp}>
                            <div className={styles.categoryBlockMid}>
                                <div className={styles.categoryBlockDown}>
                                    {!filteredProducts && currentProducts && currentProducts.map((product) => (
                                        <ProductCard product={product} key={product.ProductID} isStorePage/>
                                    ))}
                                    {filteredProducts && filteredProducts.map((product) => (
                                        <ProductCard product={product} key={product.ProductID} isStorePage/>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(ProductBody);