import React, {useState} from 'react';
import {observer} from "mobx-react";
import ModeratorBodyMenuTable from "./ModeratorBodyMenuTable/ModeratorBodyMenuTable";
import ModeratorBodyMenuNavigate from "./ModeratorBodyMenuNavigate/ModeratorBodyMenuNavigate";
import CreateCategoryModal from "./CreateCategoryModal/CreateCategoryModal";
import CreateProductModal from "./CreateProductModal/CreateProductModal";
import EditProductModal from "./CreateProductModal/EditProductModal";
import rootStore from "../../../store/RootStore";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {styled} from "@mui/material";

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{children: <span className="MuiTabs-indicatorSpan"/>}}
    />
))({
    '& .MuiTabs-flexContainer': {
        justifyContent: 'space-around',
    },
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        width: '100%',
        backgroundColor: '#635ee7',
    },
});

const ModeratorBodyMenu = observer(() => {
    const {
        getIfOpenCreateCatalog,
        getIfOpenCreateProduct,
        getIsOpenEditProduct,
        getIsOpenSettingShop
    } = rootStore.moderatorPanelStore;
    const [state, setState] = useState(false);

    function CustomTabPanel(props) {
        const {children, value, index, ...other} = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <Box>{children}</Box>}
            </div>
        );
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <CustomTabPanel value={value} index={0}>
                {
                    !getIsOpenSettingShop && getIfOpenCreateProduct ?
                        (
                            ""
                        ) :
                        (
                            !getIsOpenSettingShop && !getIsOpenEditProduct ? <>
                                <ModeratorBodyMenuTable/>
                            </> : ""
                        )
                }
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                {
                    !getIsOpenSettingShop && getIfOpenCreateProduct ?
                        (
                            ""
                        ) :
                        (
                            !getIsOpenSettingShop && !getIsOpenEditProduct ? <>
                                <ModeratorBodyMenuNavigate state={state}/>
                            </> : ""
                        )
                }
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                Item Three
            </CustomTabPanel>
            {
                !getIsOpenSettingShop && getIfOpenCreateCatalog ?
                    (
                        <CreateCategoryModal setState={setState}/>
                    ) : ""
            }
            {!getIsOpenSettingShop && getIsOpenEditProduct ?
                <EditProductModal setState={setState}/> : ""
            }
            {
                !getIsOpenSettingShop && getIfOpenCreateProduct ?
                    (
                        <CreateProductModal/>
                    ) : ""
            }
        </>
    );
});

export default ModeratorBodyMenu;