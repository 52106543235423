import React from 'react';
import styles from "./ProductSearchCard.module.scss"
import { observer } from "mobx-react";
import ModalProduct from "../../ModalProduct/ModalProduct";
import rootStore from "../../../store/RootStore";
import IconBike from "../../Icons/IconBike";
import {getCurrencySymbol} from "../../../helpers/getCurrency";
import { useTheme } from '../../../helpers/ThemeContext';


const ProductSearchCard = observer(({ product, isOpenAll }) => {
    const { ProductID, product_images, ProductName, Description, Product_info, Price, unit } = product;
    const { themeMode } = useTheme();
    const { setIsClicked } = rootStore.cartOrderStore;
    const { addProductToCartOrder } = rootStore.cartOrderStore;
    const symbol = getCurrencySymbol();


    const handleAddProduct = (count) => {
        addProductToCartOrder(ProductID, count);
        setIsClicked(true)
    }


    return (
        <ModalProduct product={product}>
            <div className={`${styles.mainConteiner} ${isOpenAll && styles.mainRightT}`} >
                <div className={styles.mainBlock}>
                    <a role="button" aria-haspopup="dialog" aria-labelledby=":rvs:" className={styles.link}></a>
                    <div className={styles.mainContentBlock}>
                        <div className={styles.mainContentBlockImg}>
                            <img
                                src={process.env.REACT_APP_MEDIA_URL + product?.product_images[0]?.ImageUrl}
                                className={styles.mainImg} alt={product_images[0]?.AltText || 'Image'}/>
                        </div>
                    </div>
                    <div className={styles.downContBlock}>
                        <div className={styles.downContMain}>
                            <span className={styles.cost}>{symbol} {Price}</span>
                        </div>
                        <div className={styles.downDownContMain}>
                            <h3 data-test-id="ImageCentricProductCard.Title"
                                className={styles.titleDownBlock}>{ProductName}</h3>
                        </div>
                    </div>
                    <div className={styles.hr}></div>
                    <div className={styles.shopInfo}>
                        <div className={styles.shopName}>{product.shop.ShopName}</div>
                        <div className={styles.deliveryBlock}>
                            <div className={themeMode === 'dark' ? styles.carDeliveryDark : styles.carDelivery}><IconBike/>️ 0,00</div>
                            <div className={styles.timeDeliveryText}>{product.shop.Estimated_delivery_time} min</div>
                        </div>
                    </div>
                </div>
            </div>
        </ModalProduct>
    );
});

export default ProductSearchCard;