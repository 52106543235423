import { makeAutoObservable } from 'mobx';
import api from "../api/api";

class OrdersModerStore {
    orders = []
    currentOrder = {};
    isLoading = false;


    constructor() {
        makeAutoObservable(this);
    }
    get isLoadingOrders() {
        return this.isLoading;
    }

    get ordersList() {
        return this.orders;
    }

    get getCurrentOrder() {
        return this.currentOrder;
    }

    setOrders = (state) => {
        this.orders = state;
    }
    setCurrentOrder = (state) => {
        this.currentOrder = state;
    }

    loadOrders = (id) => {
        this.isLoading = true;
        api.get(`admin_seller_shop/my-shop/orders/${id}/one-shave-shop-orders/`
    ).then(response => {
            this.isLoading = false;
            this.setOrders(response.data)
        }).catch(() => {
            this.isLoading = false;
        });

    }

    confirmOrders = (shopId, orderId, geo, cost) => {
        api.post(`admin_seller_shop/my-shop/orders/${shopId}/approve-order/${orderId}/`,
        {
            "delivery_address_take": {
                "latitude": geo[0],
                "longitude": geo[1],
            },
            "courier_commission": cost
        }).then(response => {
        });
    }
    cancelModOrders = (shopId, orderId) => {
        api.post(`admin_seller_shop/my-shop/orders/${shopId}/cancel-order/${orderId}/`
        ).then(response => {
        });
    }
}

const ordersModerStore = new OrdersModerStore();
export default ordersModerStore;