import api from "../api";

export const addProduct = async (formData,id) => {
    if(formData) {
        try {
           const apiResponse = await api.post(`/admin_seller_shop/get-my-shop/${id}/products/create/`,formData).then(response => response);
           return apiResponse;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }
}