import api from "../api";

export const getOwnerShopsList = async (searchQuery) => {
    try {
        if(searchQuery) {
            const shops = await api.get(`/owner_site/shops/all/?search=${searchQuery}`);
            return shops.data;
        }
        const shops = await api.get(`/owner_site/shops/all/`);
        return shops.data;
    } catch (error) {
        console.error("An error occurred:", error);
        throw error;
    }
}