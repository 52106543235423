import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import styles from './PersonalBtn.module.scss'
import rootStore from "../../../store/RootStore";
import {observer} from "mobx-react";
import {getUser} from "../../../helpers";
import {t} from "i18next";

const PersonalBtn = observer(({text}) => {
    const {userData} = rootStore.userStore;
    const {getIsOpenPersonal, isOpenPersonal, setIsOpenPersonal} = rootStore.systemStore;
    const isSuperAdmin = rootStore.userStore.isSuperAdmin;
    let navigate = useNavigate();
    const {openCloseL, openCloseR} = rootStore.systemStore;
    const {user, setLogOut} = rootStore.userStore;

    const avatar = JSON.parse(getUser())?.profile.photo;

    const handleClick = () => {
        setIsOpenPersonal(!getIsOpenPersonal)
    };

    const handleClickProfile = () => {
        navigate(window.innerWidth > 640 ? 'discovery/me/personal-info' : 'discovery/me')
        setIsOpenPersonal(false)
    }

    const handleClickModerator = () => {
        navigate('/moderator')
        setIsOpenPersonal(false)
    }

    const handleClickL = () => {
        setIsOpenPersonal(false)
        openCloseL(true)
    }
    const handleClickR = () => {
        setIsOpenPersonal(false)
        openCloseR(true)
    }

    const logOut = () => {
        setLogOut()
        navigate('/')
        setIsOpenPersonal(false)
    }


    return (
        <div className={styles.container}>
            <button className={styles.containerBtn} onClick={handleClick}>
                {
                    avatar ? <img src={process.env.REACT_APP_MEDIA_URL + avatar} className={styles.leftImg} alt/>
                        : (
                            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={styles.leftImg}>
                                <path
                                    d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM5 18C4.91649 18.3102 4.84914 18.6227 4.79795 18.9376C6.61696 20.8255 9.17133 22 12 22C14.8286 22 17.3829 20.8256 19.2019 18.9377C19.1508 18.6227 19.0835 18.3101 19 18C18.7713 17.1507 18.5033 17.0136 17.25 16.5C16.4281 16.1632 15.0114 15.8351 13 15.5159V14.374C14.7252 13.9299 16 12.3638 16 10.5C16 8.29086 14.2091 6.5 12 6.5C9.79086 6.5 8 8.29086 8 10.5C8 12.3638 9.27477 13.9299 11 14.374V15.5123C9.34193 15.6905 7.92526 16.0197 6.75 16.5C5.49562 17.0126 5.2276 17.1546 5 18Z"></path>
                            </svg>
                        )
                }

                <svg viewBox="0 0 24 24" className={styles.rightImg}
                     style={{transform: getIsOpenPersonal ? 'rotate(180deg)' : ''}}>
                    <path
                        d="M12.1768 13.409C12.0791 13.5066 11.9209 13.5066 11.8232 13.409L6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L12.1768 13.409Z"></path>
                </svg>
            </button>
            {getIsOpenPersonal && (
                <div className={styles.dropdown}>
                    {
                        !user.authorized ? (
                            <>
                                <div className={styles.dropdownConteinerBtn}>
                                    <button className={styles.btn}
                                            onClick={() => handleClickL()}>{t("Log in")}
                                    </button>
                                </div>
                                <div className={styles.dropdownConteinerBtn}>
                                    <button className={styles.btn}
                                            onClick={() => handleClickR()}>{t("Sign up")}
                                    </button>
                                </div>
                            </>
                        ) : (
                            <div className={styles.dropdownConteinerBtn1}>
                                <button
                                    onClick={handleClickProfile}
                                    className={styles.btn}>{t("Profile")}
                                </button>
                            </div>
                        )
                    }
                    <div className={styles.dropdownConteinerBtn}>
                        <button className={styles.btn}>{t("Language")}</button>
                    </div>
                    {
                        isSuperAdmin ? (
                            <div className={styles.dropdownConteinerBtn}>
                                <button className={styles.btn}
                                        onClick={() => handleClickModerator()}>{t("Shop Panel")}</button>
                            </div>
                        ) : ""
                    }
                    <div className={styles.dropdownConteinerBtn}>
                        <button className={styles.btn}>{t("Support")}</button>
                    </div>
                    {
                        user.authorized ? (
                            <div className={styles.dropdownConteinerBtn}>
                                <button onClick={logOut} className={styles.btn}>{t("Log out")}</button>
                            </div>
                        ) : ""
                    }
                </div>
            )}
        </div>
    );
});

export default PersonalBtn;