import React, {useEffect, useState} from 'react';
import styles from "./VerificationPage2.module.scss"
import rootStore from "../../store/RootStore";
import WithAuthUser from "../../helpers/WithAuthUser";
import {useNavigate} from "react-router-dom";
import IconBack from "../../components/Icons/IconBack";

import {t} from "i18next";
import FaceVerification from "../../components/FaceVerification/FaceVerification";
import DocAuthentication from "../../components/DocAuthentication/DocAuthentication";

const VerificationPage2 = () => {

    const [photo, setPhote] = useState();
    const {  getProfile } = rootStore.userStore;

    const {sendVerificationDoc} = rootStore.userStore;

    const navigate = useNavigate();

    useEffect(() => {
    }, []);

    const handleSendVerifToServ = async () => {
        if (photo) {
            const forma= new FormData();
            forma.append("verification_pass_id", photo);
            await sendVerificationDoc(forma)
            setTimeout(async () => {
                await getProfile()
            },500)
            setTimeout(() => {
                navigate({pathname:"../verification3",relative: true})
            },1000)

        }
    }


    const goBack = () => {
        navigate("/discovery/me");
    }
    return (
        <WithAuthUser>
            <div style={{
                "position": "fixed",
                "width": "32px",
                "height": "32px",
                "top": "1%",
                "left": "3%",
                zIndex: 999999
            }}
                 onClick={goBack}
            >
                <IconBack size={20} pure={true} fill={"#009de0"} disabled={true}/>
                <h2 className={styles.mainTitle}>{t("Verification")}</h2>
            </div>
            <div className={styles.profileCard}>
                <div className={styles.downConteiner}>
                    <div className={styles.infoBlock}>
                        <DocAuthentication setAuthInput={setPhote}/>
                        <button className={`${styles.activeBtn} ${(photo) ? "" : styles.notActive}`} onClick={() => handleSendVerifToServ()}>{t("Verificate")}</button>
                    </div>
                </div>
            </div>
        </WithAuthUser>
    );
};

export default VerificationPage2;