import styles from "./SwipeButton.module.scss";
import { Typography } from "@mui/material";

import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

const SwipeButton = ({
  getInProcces = true,
  handlerAction,
  shopId,
  orderId,
  geo,
  cost,
  isModer = false,
  notNavigate = false,
  modalIsOpen = false,
}) => {
  const [position, setPosition] = useState(8);
  const [buttonInTheEnd, setButtonInTheEnd] = useState(false);
  const containerRef = useRef(null);
  const isDragging = useRef(false);
  const startPosition = useRef(8);
  const buttonRef = useRef(null);
  const containerWidth = useRef(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (!modalIsOpen) {
      setPosition(8);
    }
  }, [modalIsOpen]);

  useEffect(() => {
    containerWidth.current = containerRef.current.clientWidth - 74;
  }, [setPosition]);

  const handleDragStart = (e) => {
    isDragging.current = true;
    startPosition.current = e.touches ? e.touches[0].clientX : e.clientX;
  };

  const handleDrag = (e) => {
    if (!isDragging.current) return;

    const currentPosition = e.touches ? e.touches[0].clientX : e.clientX;
    const shift = currentPosition - startPosition.current;
    const newPosition = Math.max(
      0,
      Math.min(
        position + shift,
        containerRef.current.clientWidth - buttonRef.current.clientWidth
      )
    );

    setPosition(newPosition);
    startPosition.current = currentPosition;
  };

  const handleDragEnd = () => {
    if (isDragging.current) {
      isDragging.current = false;
      if (position > containerWidth.current * 0.8) {
        setPosition(containerWidth.current);
        setButtonInTheEnd(true);
      } else {
        setPosition(8);
        setButtonInTheEnd(false);
      }
    }
  };

  if (buttonInTheEnd) {
    if (notNavigate) {
      handlerAction();
      setButtonInTheEnd(false);
    } else if (isModer) {
      handlerAction(shopId, orderId, geo, cost);
      setTimeout(() => {
        navigate(-1);
      }, 500);
    } else {
      handlerAction(orderId);
      setTimeout(() => {
        navigate(-1);
      }, 500);
    }
  }
  return (
    <div
      ref={containerRef}
      className={styles.container}
      onMouseMove={getInProcces ? handleDrag : () => {}}
      onMouseUp={getInProcces ? handleDragEnd : () => {}}
      onMouseLeave={getInProcces ? handleDragEnd : () => {}}
      onTouchMove={getInProcces ? handleDrag : () => {}}
      onTouchEnd={getInProcces ? handleDragEnd : () => {}}
    >
      {buttonInTheEnd ? (
        <div className={styles.buttonText}>
          <div className={styles.spinner}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <div className={styles.buttonText}>
          {notNavigate ? (
            <Typography
              sx={{ fontWeight: "bold" }}
              variant="subtitle1"
              className={styles.linearWipe}
            >
              {getInProcces ? t("Success") : t("Success")}
            </Typography>
          ) : (
            <Typography
              sx={{ fontWeight: "bold" }}
              variant="subtitle1"
              className={styles.linearWipe}
            >
              {getInProcces ? "Order to me" : "Order discovered!"}
            </Typography>
          )}
        </div>
      )}
      <div
        ref={buttonRef}
        className={styles.button}
        style={{ left: `${position}px` }}
        onMouseDown={handleDragStart}
        onTouchStart={handleDragStart}
      ></div>
    </div>
  );
};

export default SwipeButton;
