import React, { useState, useRef } from 'react';
import styles from './AddressInfo.module.scss';
import {observer} from "mobx-react";
import {useTheme} from "../../../helpers/ThemeContext";
import ModalLocation from "../../../components/ModalLocation/ModalLocation";
import {TextField} from "@mui/material";
import rootStore from "../../../store/RootStore";
import {t} from "i18next";

const AddressInfo = ({isOpen, setIsOpen}) => {
    const modalRef = useRef(null);
    const { addressData, setAddress } = rootStore.checkoutStore;

    const [textB, setTextB] = useState("")
    const [textE, setTextE] = useState("")
    const [textF, setTextF] = useState("")
    const [textA, setTextA] = useState("")


    const {themeMode} = useTheme()

    const handleCloseModal = () => {
        setIsOpen(false);
    };

    const handleClose = (ev) => {
        if (ev.target.id === "modalka"){
            setIsOpen(false);
        }
    }

    const handleSave = () => {
        setAddress(textB, textE, textF, textA)
        setIsOpen(false);
    }

    return (
        <div>
            {isOpen && (
                <div id="modalka" className={styles["modal-overlay"]} onClick={(ev)=> handleClose(ev)}>

                    <div className={styles["modal-content"]} ref={modalRef}>
                        <div className={styles.closeBtnConteiner}>
                            <button className={`${styles.IconBack} ${styles.mainStyle}`} style={{
                                marginTop: "5px",
                                zIndex: 999999,
                            }} onClick={() => setIsOpen(false)}>
                                <div className={styles["IconBack__icon"]}>
                                    X
                                </div>
                            </button>
                        </div>
                        <div>
                            <h2 className={styles.titleBody}>{t("Address")}</h2>
                            <div className={styles.imagesBlock}>
                                <div className={styles.rowBlock}>
                                    <div className={styles.leftBlock}>
                                        <div className={styles.textBlockImage}>
                                            <span className={styles.topText}>Tel Aviv</span>
                                            <span className={styles.downText}>{t("Other")}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h2 className={styles.titleBody}>{t("Location type")}</h2>
                        <span>{t("The Location type helps us to find you better.")}</span>
                        <h2 className={styles.titleBody}>{t("Address details")}</h2>
                        <div className={styles.imgBlocksSearch}>
                            <TextField
                                id="outlined-helperText1"
                                label="Building name"
                                defaultValue=""
                                onChange={(e) => setTextB(e.target.value)}
                                sx={{width: "100%", borderRadius: "1rem"}}
                            />
                            <span className={styles.optionalText}>{t("Optional")}</span>
                            <TextField
                                id="outlined-helperText2"
                                label="Enterance / Staircase"
                                defaultValue=""
                                onChange={(e) => setTextE(e.target.value)}
                                sx={{width: "100%", borderRadius: "1rem", marginBottom: "1rem", marginTop: "1rem"}}
                            />
                            <span className={styles.optionalText2}>{t("Optional")}</span>
                            <div className={styles.downFields}>
                                <TextField
                                    id="outlined-helperText3"
                                    label="Floor"
                                    defaultValue=""
                                    onChange={(e) => setTextF(e.target.value)}
                                    sx={{borderRadius: "1rem", width: "48%"}}
                                />
                                <TextField
                                    id="outlined-helperText4"
                                    label="Apartment"
                                    defaultValue=""
                                    onChange={(e) => setTextA(e.target.value)}
                                    sx={{borderRadius: "1rem", width: "48%"}}
                                />
                            </div>
                        </div>
                        <button aria-disabled="false" role="button" type="button"
                                className={styles.footerBtn} onClick={() => handleSave()}>
                            <div className={styles.footerBtnMain}>{t("Save")}</div>
                        </button>
                    </div>
                </div>
            )}
            <div onClick={() => setIsOpen(true)}></div>
        </div>
    );
};

export default observer(AddressInfo);
