import React, {useEffect, useRef, useState} from "react";

import {t} from "i18next";
import {observer} from "mobx-react";

import {TextField} from "@mui/material";

import styles from "./SupportUserChat.module.scss";
import rootStore from "../../../store/RootStore";
import {getUser} from "../../../helpers";
import {useTheme} from "../../../helpers/ThemeContext";
import IconBack from "../../../components/Icons/IconBack";
import {useNavigate} from "react-router-dom";


const SupportUserChat = observer(() => {
    const ref = useRef();
    const user = JSON.parse(getUser());
    const {themeMode} = useTheme()
    const navigate = useNavigate();
    const linkInfo = window.location.pathname.split("/")
    const userChatId = window.location.pathname.includes("support-panel/chat") ? linkInfo[3] : JSON.parse(getUser()).profile.user_id
    const {connect, disconnect, closeChat, messages, getHistoryMessage, sendMessage } = rootStore.supportStore;

    const isSupport = JSON.parse(getUser()).profile.role === "support"
    const [message, setMessage] = useState("");

    const handleSendMessage = () => {
        if (message !== "") {
            sendMessage(message, userChatId);
            setMessage("");
        }
        setTimeout(() => {
            ref.current.scrollTo(0, 10000000);
        }, 200);
    };
    useEffect(() => {
        console.log('test')
        setTimeout(() => {
            ref.current.scrollTo(0, 10000000);
        }, 200);
    }, [messages.length])

    useEffect(() => {
        connect(userChatId);
        setTimeout(() => {
            getHistoryMessage(userChatId);
        }, 500);
        setTimeout(() => {
            ref.current.scrollTo(0, 10000000);
        }, 700);
        return () => {
            disconnect();
        };
    }, [
        connect,
        disconnect,
        getHistoryMessage,
    ]);

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            handleSendMessage();
        }
    };

    const messageTime = (message) => {
        if (message.timestamp) {
            return message.timestamp.split("T")[1].split(":")[0] +
            ":" +
            message.timestamp.split("T")[1].split(":")[1]
        } else {
            return ""
        }
    };
    const handlerCloseChat = () => {
        closeChat()
        navigate(-1)
    }

    return (
        <>
            <div
                className={styles.backer}
            >
                <IconBack size={20} pure={true} fill={"#009de0"} fantom={false} marg={"3px"}/>
            </div>
            <div className={styles.chatHeader}>
                <h3 className={styles.title}>{t("Chat")}</h3>
                {
                    isSupport ?
                        (<button className={styles.closeBtn} onClick={() => handlerCloseChat()}>{t("Close")}</button>) : <></>
                }
            </div>
            <div className={styles.chatWrapper}>
                <div className={styles.inputMessagePanelWrapper}>
                    <div className={styles.inputMessagePanel}>
                        <div className={styles.messageConteiner} ref={ref}>
                            {messages.map((message, index) => (
                                <div
                                    className={`${styles.conteinerRow} ${
                                        message.sender === user.profile.username
                                            ? styles.conteinerRowRight
                                            : styles.conteinerRowLeft
                                    }`}
                                    key={index}
                                >
                                    <div
                                        className={`${styles.messageBody} ${
                                            message.sender === user.profile.username
                                                ? styles.messageBodyRight
                                                : styles.messageBodyLeft
                                        }`}
                                    >
                                        <div className={styles.message}>
                                            <span>{message.message || message.content}</span>
                                        </div>
                                        <div className={styles.timeRow}>
                                            <span>{messageTime(message)}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className={styles.inputWrapper}>
                    <TextField
                        variant="outlined"
                        placeholder={t("Write a message...")}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyDown={handleKeyPress}
                        InputProps={{
                            sx: {
                                height: "40px",
                                "& .MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                },
                                "& .MuiInputBase-input": {
                                    caretColor: "#178bb8",
                                    caretWidth: "3px",
                                },
                                width: '100%',
                                padding: "0 16px",
                                borderRadius: "32px",
                                backgroundColor: themeMode === "dark" ? "#000" : "#ededed",
                            },
                        }}
                        sx={{width: "100%", height: "100%"}}
                    />
                </div>
            </div>
        </>
    );
});

export default SupportUserChat;
