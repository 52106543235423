import React from 'react';
import styles from "./Tags.module.scss"

const Tags = ({text}) => {
    return (
        <div className={styles.mainConteiner}>
            <div className={styles.conteiner}>
                <span className={styles.text}>{text}</span>
            </div>
        </div>
    );
};

export default Tags;