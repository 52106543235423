// src/hooks/useVariant.js

import {useEffect, useLayoutEffect, useRef} from 'react';

const useAnimateVariant = (location) => {
    const variant = useRef(null);

    useLayoutEffect(() => {
        if(location.pathname === '/discovery') {
            variant.current = {
                initial: {
                    x: '-50vw',
                    y: '50vh',
                    opacity: 1,
                },
                animate: {
                    x: '250vw',
                    y: '-150vh',
                    opacity: 0,
                    transition: { duration: 0.5, ease: 'easeInOut' },
                },
            };
        }
        if (location.pathname === '/discovery/me') {
            variant.current = {
                initial: {
                    x: '-150vw',
                    y: '80vh',
                    opacity: 1,
                },
                animate: {
                    x: '-150vw',
                    y: '-150vh',
                    opacity: 0,
                    transition: { duration: 0.5, ease: 'easeInOut' },
                },
            };
        }
        if(location.pathname === '/discovery/stores') {
            variant.current = {
                initial: {
                    x: '-50vw',
                    y: '80vh',
                    opacity: 0.8,
                },
                animate: {
                    x: '-80vw',
                    y: '-90vh',
                    opacity: 0,
                    transition: { duration: 0.5, ease: 'easeInOut' },
                },
            };
        }
        if(location.pathname === '/discovery/restaurants') {
            variant.current = {
                initial: {
                    x: '-50vw',
                    y: '50vh',
                    opacity: 1,
                },
                animate: {
                    x: '250vw',
                    y: '-150vh',
                    opacity: 0,
                    transition: { duration: 0.5, ease: 'easeInOut' },
                },
            };
        }
        if(location.pathname === '/discovery/search-store') {
            variant.current = {
                initial: {
                    x: '-150vw',
                    y: '50vh',
                    opacity: 1,
                },
                animate: {
                    x: '-80vw',
                    y: '-150vh',
                    opacity: 0,
                    transition: { duration: 0.5, ease: 'easeInOut' },
                },
            };
        }
    }, [location.pathname]);

    return variant;
};

export default useAnimateVariant;