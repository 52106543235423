import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {getUser} from "./helpers";

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'en', // default language
        debug: true,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },

        resources: {
            en: {
                translation: require('./locales/en/translation.json'),
            },
            es: {
                translation: require('./locales/es/translation.json'),
            },
            he: {
                translation: require('./locales/he/translation.json'),
            },
            ru: {
                translation: require('./locales/ru/translation.json'),
            },
        },

        supportedLngs: ['en', 'es', 'he', 'ru'], // list of supported languages

        // set the language to the one stored in the local storage, or fallback to the default language
        lng: localStorage.getItem('i18nextLng') || JSON.parse(getUser())?.profile?.language || 'en',

        // save the selected language to the local storage
        saveMissing: false,
        missingKeyHandler: (lng, ns, key) => {
            console.log("test", lng, JSON.parse(getUser())?.profile?.language)
            localStorage.setItem('i18nextLng', lng);
        },
    });

export default i18n;