import React, {useEffect, useRef, useState} from 'react';
import styles from "./CategoryHeader.module.scss"
import CategoryButton from "../CategoryButton/CategoryButton";
import CategorySearch from "../CategorySearch/CategorySearch";
import More from "../More/More";

const CategoryHeader = ({data}) => {
    const [isSticky, setIsSticky] = useState(false);
    const [isOpenMore, setIsOpenMore] = useState(false);
    const [overElem, setOverElem] = useState([]);


    const stickyRef = useRef(null);
    const conteinerBtn = useRef(null);

    useEffect(() => {
        const handleScrollCategory = () => {
            let { top } = stickyRef.current.getBoundingClientRect();
            setIsSticky(top === 70 && window.innerWidth > 641);
        };
        document.addEventListener('scroll', handleScrollCategory, { passive: true });
    }, []);

    return (
        <div className={`${styles.mainConteiner} 
                        ${isSticky ? styles.shadowUse : ""}
                        `} ref={stickyRef}>
            <div className={styles.mainBlock}>
                <div className={styles.mainRow}>
                    <div className={styles.upBody}>
                        <div className={styles.categorysConteiner} ref={conteinerBtn}>
                            {
                                data.map((item, key) => (
                                    <CategoryButton
                                        headerRef={stickyRef}
                                        parentRef={conteinerBtn}
                                        item={item}
                                        listEl={[overElem, setOverElem]}
                                        isMobile={false}
                                        key={item.id} />
                                ))
                            }
                        </div>
                        {
                            overElem.length ?
                                (<div className={styles.moreBlock}>
                                    <button
                                        className={styles.moreBtn}
                                        onClick={() => setIsOpenMore(!isOpenMore)}
                                    >{`More (${overElem.length})`}</button>
                                </div>) : ""
                        }
                        {isOpenMore && overElem.length ? (<More headerRef={stickyRef} overElem={overElem}/>) : ""}
                    </div>
                    <CategorySearch />
                </div>
            </div>
        </div>
    );
};

export default CategoryHeader;