import React, { useState } from "react";

import { t } from "i18next";
import { observer } from "mobx-react";

import styles from "./AcceptModal.module.scss";

import { CloseIcon } from "../../assets/icons";

const AcceptModal = ({
  handleClose,
  action = false,
  handleSubmit,
  handleCancel,
  cost = true,
  geo = true,
}) => {
  const [isCheck, setIsCheck] = useState(false);

  const isCurier = window.location.pathname.includes("curier-panel");

  const handleCheckboxChange = () => {
    setIsCheck(!isCheck);
  };

  const handleClick = () => {
    if (action) {
      handleSubmit();
    } else {
      handleCancel();
    }
    handleClose();
  };

  return (
    <div className={styles.modalContainer}>
      <div className={styles.modalContainerBlock}>
        <div className={styles.productsCategoryModal}>
          <div className={styles.productsCategoryTextBlock}>
            <span>{t("Please accept your actions")}</span>
            <span
              className={styles.productsCategoryTextImg}
              onClick={handleClose}
            >
              <CloseIcon fill={"#827789"} />
            </span>
          </div>
          <div className={styles.infoBlock}>
            <div className={styles.nameBlock}>
              <h2 className={styles.nameUser}>{}</h2>
            </div>
            {!cost && !isCurier && (
              <span className={styles.redAllert}>
                {t("You must input delivery for courier!")}
              </span>
            )}
            {!geo && !isCurier && (
              <span className={styles.redAllert}>
                {t("You must input geo place mark for product!")}
              </span>
            )}
            <div className={styles.checkBoxCont}>
              <input
                type="checkbox"
                id="scales"
                name="scales"
                checked={isCheck}
                onChange={handleCheckboxChange}
                className={styles.checkbox}
              />
              <label htmlFor="scales" className={styles.label}>
                {t("Accept my actions")}
              </label>
            </div>
            <button
              className={`${styles.createBtn} ${
                isCheck && (isCurier ? true : cost && geo)
                  ? ""
                  : styles.inactive
              }`}
              onClick={
                !isCurier ? (cost && geo && isCheck ? handleClick : null) : (isCheck ? handleClick : null)
              }
            >
              {t("Accept")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(AcceptModal);
