import React, {useState, useRef, useEffect} from 'react';
import styles from './ModalCurrency.module.scss';
import {observer} from "mobx-react";
import IconDown from "../Icons/IconDown";
import {useTranslation} from "react-i18next";
import rootStore from "../../store/RootStore";

const ModalCurrency = ({isOpen, setIsOpen}) => {
    const Currency_ARRAY = [
        { code: "USD", name: "Dollar $" },
        { code: "EUR", name: "Euro €" },
        { code: "ILS", name: "Shekel ₪" },
        { code: "RUB", name: "Ruble ₽" },
        { code: "MXN", name: "Peso  ₱" },
    ];
    const {currentCurrencyData: currentCurrency, updateCurrency} = rootStore.userStore
    const modalRef = useRef(null);
    const { i18n,t } = useTranslation();
    const [isClosing, setIsClosing] = useState(false);
    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            setIsClosing(false);
            setIsOpen(false);
        }, 500);
    }
    const changeCurrency = (currency) => {
        updateCurrency(currency)
        handleClose()
    };

    return (
        <div>
            {isOpen && (
                <div id="modalka" className={styles["modal-overlay"]}>
                    <div className={`${styles["modal-content"]} ${isClosing ? styles.fadeOutAnimation : ""}`}
                         ref={modalRef}>
                        <div className={styles.closeBtnConteiner}>
                            <button style={{
                                marginTop: "5px",
                                zIndex: 999999,
                            }} onClick={(ev) => handleClose(ev)}>
                                <IconDown></IconDown>
                            </button>
                        </div>
                        <div>
                            <h2 className={styles.titleBody}>{t("Change currency")}</h2>
                            <div className={styles.conteinerAdresses}>
                                {
                                    Currency_ARRAY.map(currency => (
                                        <div className={styles.imagesBlock}
                                             onClick={() => changeCurrency(currency.code)}
                                             >
                                            <div className={styles.rowBlock}>
                                                <div className={styles.leftBlock}>
                                                    <div className={styles.textBlockImage}>
                                                        <span className={currentCurrency === currency.code ? styles.topText__active : styles.topText}>{currency.name}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div onClick={() => setIsOpen(true)}></div>
        </div>
    );
};

export default observer(ModalCurrency);
