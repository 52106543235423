import React, { useRef } from 'react';
import styles from './MapModal.module.scss';
import {observer} from "mobx-react";
import rootStore from "../../../store/RootStore";
import {Map, Placemark, YMaps} from "@pbe/react-yandex-maps";
import {setCord} from "../../../helpers";



const MapModal = ({isOpen, setIsOpen, placemark, setPlacemark}) => {
    const modalRef = useRef(null);
    const { addressData, setAddress } = rootStore.checkoutStore;

    const handleCloseModal = () => {
        setIsOpen(false);
    };

    const handleClose = (ev) => {
        if (ev.target.id === "modalka"){
            setIsOpen(false);
        }
    }

    const handleSave = () => {
        setCord(placemark)
        setIsOpen(false);
    }

    const handleMapClick = (event) => {
        const coords = event.get('coords');
        setPlacemark(coords);
    };

    return (
        <div>
            {isOpen && (
                <div id="modalka" className={styles["modal-overlay"]} onClick={(ev)=> handleClose(ev)}>
                    <div className={styles["modal-content"]} ref={modalRef}>
                        <div className={styles.closeBtnConteiner}>
                            <button className={`${styles.IconBack} ${styles.mainStyle}`} style={{
                                marginTop: "5px",
                                zIndex: 999999,
                            }} onClick={() => setIsOpen(false)}>
                                <div className={styles["IconBack__icon"]}>
                                    X
                                </div>
                            </button>
                        </div>
                        <YMaps query={{lang: 'en_EU'}}>
                            <div className={styles.mapBlock}>
                                <Map defaultState={{center: placemark, zoom: 13}} height={"99vh"} width={"99vw"}
                                     onClick={handleMapClick}
                                >{placemark && <Placemark geometry={placemark} />}</Map>
                            </div>
                        </YMaps>
                        <button aria-disabled="false" role="button" type="button"
                                className={styles.footerBtn} onClick={() => handleSave()}>
                            <div className={styles.footerBtnMain}>Save</div>
                        </button>
                    </div>
                </div>
            )}
            <div onClick={() => setIsOpen(true)}></div>
        </div>
    );
};

export default observer(MapModal);
