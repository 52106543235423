import React from 'react';

const PlusIcon = ({size="30px",color="#202125"}) => {
    return (
        <div style={{
            height: size,
            width: size,
        }}>
            <svg viewBox="0 0 24 24" fill={color}>
                <path
                      d="M0 12a1.5 1.5 0 001.5 1.5h8.75a.25.25 0 01.25.25v8.75a1.5 1.5 0 003 0v-8.75a.25.25 0 01.25-.25h8.75a1.5 1.5 0 000-3h-8.75a.25.25 0 01-.25-.25V1.5a1.5 1.5 0 00-3 0v8.75a.25.25 0 01-.25.25H1.5A1.5 1.5 0 000 12z"></path>
            </svg>
        </div>
    );
};

export default PlusIcon;