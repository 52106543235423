import React from 'react';
import {styled, Tabs} from "@mui/material";

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span style={{width: 'calc(100% - 16px)', background:'#202125',zIndex:503}} className="MuiTabs-indicatorSpan" /> }}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        width: "auto",
        height:'2px',
        maxWidth: '100%',
        zIndex:504,
        backgroundColor: 'transparent',
    },
});

export default StyledTabs;