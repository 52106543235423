import React, { useState } from 'react';

import { t } from "i18next";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";

import WorkSharpIcon from '@mui/icons-material/WorkSharp';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalMallSharpIcon from '@mui/icons-material/LocalMallSharp';

import styles from './OrderCard.module.scss'
import IconBack from "../../components/Icons/IconBack";
import { getOrder } from "../../helpers";
import ModalReviewsShop from "../../components/ModalReviewsShop/ModalReviewsShop";
import { ORDER_STATUSES } from "../../constants";
import { getCurrencySymbol } from "../../helpers/getCurrency";

const OrderCard = () => {
    const navigate = useNavigate()


    const [isCheckout, setCheckout] = useState(false);
    const [isErrorCash, setErrorCash] = useState(false);

    const isCurier = window.location.pathname.includes('curier-panel')

    const [data, setData] = useState(JSON.parse(getOrder()));
    const showModalReviewed = data.status === ORDER_STATUSES.completed && !data?.reviewed;

    const {created_at: date, delivery_address_text: address} = data
    const dateLocale = new Date(date);
    const formattedDate = `${dateLocale.getDate()}.${dateLocale.getMonth() + 1}.${dateLocale.getFullYear()}, ${dateLocale.getHours()}:${('0' + dateLocale.getMinutes()).slice(-2)}`;
    const currencySymbol = getCurrencySymbol()

    // TODO: lazyInitialize, Судя по всему лишний запрос /checkout-preview/, закомментил
    // useEffect(() => {
    //     lazyInitialize()
    // }, []);

    if (isCheckout && !isCurier) {
        setTimeout(() => {
            navigate('/discovery/me/order-history');
        }, 2000);
    }
    if (isErrorCash) {
        setTimeout(() => {
            setErrorCash(false)
        }, 2000);
    }

    const dataAddress = address ? JSON.parse(address) : "You didn't provide an address";
    const items = JSON.parse(data?.items);
    const [reviewsModalOpen, setReviewsModalOpen] = useState(false)
    return (
        <>
            <div className={styles.backer}>
                <IconBack size={20} pure={true} fill={"#009de0"}/>
            </div>
            <main className={styles.mainPage}>
                <div className={styles.mainConteiner}>
                    <div className={styles.mainBoxBlock}>
                        <div className={styles.tabsContainer}>
                            <div className={styles.tabs}>
                                <button
                                    className={`${styles.tabButton} ${styles.active}`}
                                    data-testid="shopping-cart-tab"
                                >
                                    {t("Delivery")}
                                </button>
                            </div>
                            {/* Контент для каждого таба */}
                        </div>
                    </div>
                    <p className={styles.date}>{formattedDate}</p>
                    <p className={styles.shopName}>{data.shop_name}</p>
                    <h3 className={styles.title}>{t("Address")}</h3>
                    <div className={styles.description}>
                        <LocationOnIcon fontSize='small' color="var(--text-color)"/>
                        <span className={styles.value}>
                            {dataAddress?.country ? `${dataAddress?.country}, ${dataAddress?.district}` : dataAddress}
                        </span>
                    </div>

                    <h3 className={styles.title}>{t("Status order")}</h3>
                    <div className={styles.description}>
                        <LocalMallSharpIcon fontSize='small' color="var(--text-color)"/>
                        <span className={styles.value}> {data.status} in {formattedDate} </span>
                    </div>

                    <h3 className={styles.title}>{t("Total in")} {currencySymbol}</h3>
                    <div className={styles.description}>
                        <WorkSharpIcon fontSize='small' color="var(--text-color)"/>
                        <span className={styles.value}> {data?.total_price_converted||data?.total_price || ""} </span>
                    </div>

                    {/* <Drawer anchor={"bottom"}
                            open={reviewsModalOpen}>
                        {isMobile && 
                        <> 
                        </>}
                    </Drawer> */}
                    {showModalReviewed &&
                        <>
                            <button className={styles.buttonReviews}
                                onClick={() => setReviewsModalOpen(prevState => !prevState)}>
                                {t("Send reviews please")}
                            </button>
                            <ModalReviewsShop isOpen={reviewsModalOpen} openClose={setReviewsModalOpen} orderData={data} />
                        </>
                    }
                    {/* {showModalReviewed && */}
                    {/* } */}
                    <h2 style={{margin: "1rem 0", fontSize: "1.3rem",}}>{t("Your personal order")}</h2>
                    <h5 style={{margin: "0.7rem 0", fontSize: "1rem", fontWeight: "500"}}>{t("Products you ordered")}</h5>
                    {items.length > 0 && items.map((item, index) => (
                        <li className={styles.order} key={item.product.ProductID}>
                            <span>{item.product.ProductName}</span>{item.price}<span>x{item.quantity}</span><span>{item.total_price}</span>
                        </li>
                    ))}
                    <hr style={{opacity: 0.3, margin: "1rem 0", left: "-1rem", position: "relative", width: "108%"}}/>
                    <div className={styles["payment-type"]}>
                        <span>{data?.payment_type?.charAt(0).toUpperCase() + data?.payment_type?.slice(1)}</span>
                        <span>{data?.total_price_converted||data?.total_price || ""}</span></div>
                    <p className={styles.date}>{formattedDate}</p>
                    {/* <button aria-disabled="false" type="button"
                            className={`${styles.footerBtn}`}
                            onClick={() => handleReviews(true)}>
                        <div className={styles.footerBtnMain}>
                            {t("Leave review")}
                        </div>
                    </button> */}
                </div>
            </main>
        </>
    );
};

export default observer(OrderCard);