import React from 'react';
import styles from "./ProductHeader.module.scss"



const ProductHeader = () => {

    return (
        <div className={styles.mainConteiner}>
            <p className={styles.description}>
                This product offering is in Georgian. Would you like to view a machine translation in another language?
            </p>
            <div className={styles.mainBlock}>
                <button type="button"
                        className={styles.btn}>Translate
                </button>
            </div>
        </div>
    );
};

export default ProductHeader;