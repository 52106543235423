import React from "react";

import { t } from "i18next";
import { observer } from "mobx-react";

import styles from "./DeliveryCard.module.scss";

const DeliveryCard = ({ order }) => {
  const { order_name: shopName, earnings, completed_at: completedAt } = order;
  return (
    <div className={styles.cardWrapper}>
        <div className={styles.cardInfo}>
            <p className={styles.shopName}>{shopName}</p>
            <p className={styles.deliveryTime}>{completedAt}</p>
        </div>
        <div className={styles.cardInfo}>
            <p className={styles.distance}>{t("Payment for the order")} </p>
            <p className={styles.paymentForOrder}>{`${earnings} $`}</p>
        </div>
    </div>
  );
};

export default observer(DeliveryCard);
