import React from 'react';
import styles from './CardBanner.module.scss';

const CardBanner = ({text, link, linkImg}) => {
    return (
        <div className={styles.cardBlock}>
            <div className={styles.fakeBlock} style={{"background-image": `url(${linkImg})`}}></div>
            <div className={styles.contentTextCard}>
                <div className={styles.textCard}>{text}</div>
                <a href={link} className={styles.linkBlock}>Apply
                    now</a>
            </div>
        </div>
    );
};
export default CardBanner;