import React from 'react';
import styles from './UpBody.module.scss'
import LocationSearchBlock from "../LocationSearchBlock/LocationSearchBlock";
const UpBody = () => {
    return (
        <>
            <div className={styles.mainBlock}>
                <div className={styles.container}>
                    <div className={styles.imageContainer}>
                        <img
                            src="https://consumer-static-assets.wolt.com/frontpage-assets/hero-images/6_Saturday.jpg"
                            alt=""
                            className={styles.image}
                        />
                        <div className={styles.shadowsBlock}></div>
                    </div>
                    <div className={styles.fakeBlock}></div>
                    <div className={styles.contentBlock}>
                        <LocationSearchBlock />
                    </div>

                </div>
            </div>
        </>
    );
};

export default UpBody;