import React from 'react';
import styles from './RestoranBody.module.scss'
import RestoranHeader from "../../components/Restoran/RestoranHeader/RestoranHeader";
import RestoranBody from "../../components/Restoran/RestoranBody/RestoranBody";
import HeaderFantom from "../../components/HeaderFantom/HeaderFantom";

const MainPage = () => {

    return (
        <div className={styles.mainPage}>
            <RestoranHeader/>
            <RestoranBody/>
        </div>
    );
};

export default MainPage;