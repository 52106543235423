import {makeAutoObservable} from 'mobx';
import api from "../api/api";
import {checkAuthError} from "../api/handlerErrors/getShopData";



class StatisticStore {
    currentShopId = '';
    statisticsDataAll = [];
    currentDateData = {};
    currentOperation = {};

    constructor() {
        makeAutoObservable(this);
    }

    get getCurrentShopId() {
        return this.currentShopId;
    }
    get getStatisticsDataAll() {
        return this.statisticsDataAll;
    }
    get getCurrentDateData() {
        return this.currentDateData;
    }
    get getCurrentOperation() {
        return this.currentOperation;
    }

    setCurrentShopId = (data) => {
        this.currentShopId = data
    }
    setStatisticsDataAll = (data) => {
        this.statisticsDataAll = data
    }
    setCurrentDateData = (data) => {
        this.currentDateData = data
    }
    setCurrentOperation = (data) => {
        this.currentOperation = data
    }

    getStatisticDataAllF = async () => {
        api.get(`admin_seller_shop/my-shop/statistics/daily-all/`).then(response => {
            this.setStatisticsDataAll(response.data);
        }).catch(error => {
            console.log(error)
            checkAuthError(error.response.data)
            throw error.response.data;
        })
    }

    getStatisticDataCurrent = async (shop, date) => {
        api.get(`admin_seller_shop/my-shop/statistics/${shop}/daily/${date}/`).then(response => {
            this.setCurrentDateData(response.data);
        }).catch(error => {
            console.log(error)
            checkAuthError(error.response.data)
            throw error.response.data;
        })
    }

    getCurrentOperationF = async (shop, orderId) => {
        api.get(`admin_seller_shop/my-shop/statistics/${shop}/order/${orderId}/`).then(response => {
            this.setCurrentOperation(response.data);
        }).catch(error => {
            console.log(error)
            checkAuthError(error.response.data)
            throw error.response.data;
        })
    }

}

const statisticStore = new StatisticStore();
export default statisticStore;