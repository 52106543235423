import React from 'react';
import styles from './StatisticOperationRow.module.scss'
import {observer} from "mobx-react";
import rootStore from "../../../store/RootStore";
import {t} from "i18next";

const StatisticOperationRow = ({setIsOpen, order}) => {
    const data = order.created_at.split('T');
    const { getCurrentOperationF, currentDateData } = rootStore.statisticStore;

    const handleClickOpen = () => {
        getCurrentOperationF(currentDateData.shop_id, order.id)
        setIsOpen(true)
    }

    return (
        <div className={styles.mainConteiner} onClick={() => handleClickOpen()}>
            <div className={styles.leftBlock}>
                <span className={styles.topBlock}>{t("Send")}</span>
                <span className={styles.downBlock}>{order.id}</span>
            </div>
            <div className={styles.middleBlock}>
                {`${data[0]} ${data[1].split('.')[0]}`}
            </div>
            <div className={styles.rightBlock}>
                {order.total_price}$
            </div>
        </div>
    );
};

export default observer(StatisticOperationRow);