import React, {useEffect, useRef, useState} from 'react';
import styles from './SliderOrderRecomendation.module.scss'

import {observer} from "mobx-react";
import DiscoveryCard from "../Discovery/DiscoveryCard/DiscoveryCard";
import rootStore from "../../store/RootStore";
import ProductCard from "../Stores/StoreBody/ProductCard/ProductCard";

const SliderOrderRecomendation = ({names, categoryId}) => {
    const [currentShops, setCurrentShops] = useState([])

    const shops = rootStore?.shopStore?.shopsList;
    const { lazyInitialize, getCheckout } =  rootStore.checkoutStore;

    const sliderContainerRef = useRef(null);
    useEffect(() => {
        if (categoryId) {
            setCurrentShops(prev => prev.concat(...shops.filter((shop) => shop.CategoryID === categoryId)))
        } else {
            setCurrentShops(shops)
        }
        // TODO: lazyInitialize, Судя по всему лишний запрос /checkout-preview/, закомментил
        // lazyInitialize()
    }, [shops])

    const handleClickPrevious = () => {
        if (sliderContainerRef.current) {
            sliderContainerRef.current.scrollLeft -= 300; // Измените значение на ваше усмотрение
        }
    };
    const handleClickNext = () => {
        if (sliderContainerRef.current) {
            sliderContainerRef.current.scrollLeft += 300; // Измените значение на ваше усмотрение
        }
    };

    if (currentShops.length === 0 && categoryId) {
        return <></>
    }
    return (
        <div className={styles.mainConteiner} data-category-id={categoryId}>
            <div className={styles.mainBlock}>
                <div className={styles.panelConteiner}>
                    <div className={styles.titleBlock}>
                        <h2 className={styles.title}>Recomendation</h2>
                    </div>
                </div>
                <div className={styles.sliderBar}>
                    <div className={styles.sliderBarBlock}>
                        <div className={styles.sliderConteiner} ref={sliderContainerRef}>
                            {
                                getCheckout?.products?.length > 0 && getCheckout?.products.map((product) => (
                                    <ProductCard product={product} key={product.ProductID}/>
                                ))
                            }
                            <div className={styles.fakeElem}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(SliderOrderRecomendation);