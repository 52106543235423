import React, {useState} from 'react';
import styles from './MobileAccept.module.scss';

import {observer} from "mobx-react";
import {t} from "i18next";
import {useNavigate} from "react-router-dom";
import rootStore from "../../store/RootStore";

const MobileAccept = ({setState, phone}) => {
    const navigate = useNavigate();
    const [isCheck, setIsCheck] = useState(false);
    const { changePhoneNumber } = rootStore.userStore;

    const handleClose = () => {
        setState(false)
    };
    const handleSave = () => {
        if (isCheck) {
            changePhoneNumber(phone)
            navigate(-1)
            setState(false)
        }
    }

    return (
        <div className={styles.modalContainer}>
            <div className={styles.modalContainerBlock}>
                <div className={styles.productsCategoryModal}>
                    <div className={styles.productsCategoryTextBlock}>
                        <span>{t("Please accept your actions")}</span>
                        <span className={styles.productsCategoryTextImg} onClick={handleClose}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                            <path fill="#827789" d="M5.30963553,18.6903645 C4.8990815,18.2798104 4.90303703,17.6102153 5.30742781,17.2058245 L10.5132523,12 L5.30742781,6.79417547 C4.89809307,6.38484073 4.89621919,5.72305186 5.30963553,5.30963553 C5.72018956,4.8990815 6.38978469,4.90303703 6.79417547,5.30742781 L12,10.5132523 L17.2058245,5.30742781 C17.6151593,4.89809307 18.2769481,4.89621919 18.6903645,5.30963553 C19.1009185,5.72018956 19.096963,6.38978469 18.6925722,6.79417547 L13.4867477,12 L18.6925722,17.2058245 C19.1019069,17.6151593 19.1037808,18.2769481 18.6903645,18.6903645 C18.2798104,19.1009185 17.6102153,19.096963 17.2058245,18.6925722 L12,13.4867477 L6.79417547,18.6925722 C6.38484073,19.1019069 5.72305186,19.1037808 5.30963553,18.6903645 Z"></path>
                          </svg>
                        </span>
                    </div>
                    <div className={styles.infoBlock}>
                        <div className={styles.nameBlock}>
                            <h2>{t("Phone")}: </h2>
                            <h2 className={styles.nameUser}>{phone}</h2>
                        </div>
                        <div className={styles.checkBoxCont}>
                            <input type="checkbox"
                                   id="scales"
                                   name="scales"
                                   checked={isCheck}
                                   onClick={() => setIsCheck(!isCheck)}
                                   className={styles.checkbox}
                            />
                            <label htmlFor="scales" className={styles.label}>
                                {t("Accept my actions")}
                            </label>
                        </div>
                        <button className={`${styles.createBtn} ${isCheck ? "" : styles.inactive}`} onClick={() => handleSave()}>{t("Accept")}</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(MobileAccept);
