import React from "react";

import { useNavigate } from "react-router-dom";

import styles from "./HistoryCart.module.scss";

import rootStore from "../../store/RootStore";
import { useTheme } from "../../helpers/ThemeContext";
import { getCurrencySymbol } from "../../helpers/getCurrency";
import { setCord, setOrder } from "../../helpers";
import { ChevronRightIcon } from "../../assets/icons";

const HistoryCart = ({ data }) => {
  const { shop_name: shopName, created_at: date } = data;

  const { themeMode } = useTheme();
  const navigate = useNavigate();

  const { setTargetOrders, loadUserTargetOrder } = rootStore.curierStore;
  const { setOpenOrderAgine, setTargetOrderCheck } = rootStore.cartOrderStore;

  const isCurier = window.location.pathname.includes("curier-panel");
  const isModerator = window.location.pathname.includes("moderator");

  const handleClick = () => {
    setOrder(data);
    setCord([
      data.delivery_coordinates.latitude,
      data.delivery_coordinates.longitude,
    ]);
    if (
      !isCurier &&
      !isModerator &&
      !window.location.pathname.includes("/stores/") &&
      window.location.pathname !== "/discovery"
    ) {
      loadUserTargetOrder(data.id);
      setTimeout(() => {
        navigate(
          !isCurier ? `/discovery/me/order-history/${data.id}` : `${data.id}`
        );
      }, 250);
    } else if (isCurier) {
      setTargetOrders(data);
      navigate(
        !isCurier ? `/discovery/me/order-history/${data.id}` : `${data.id}`
      );
    } else if (isModerator) {
      setTargetOrders(data);
      navigate(`/moderator/order/${data.id}`);
    } else {
      setTargetOrderCheck(data);
      setOpenOrderAgine(true);
    }
  };

  const dateLocale = new Date(date);

  const formattedDate = `${dateLocale.getDate()}.${
    dateLocale.getMonth() + 1
  }.${dateLocale.getFullYear()}, ${dateLocale.getHours()}:${(
    "0" + dateLocale.getMinutes()
  ).slice(-2)}`;

  const currencySymbol = getCurrencySymbol();

  return (
    <div
      className={styles.shopInformationBlockWrapper}
      onClick={() => handleClick()}
    >
      <div className={styles.shopInformation}>
        <div className={styles.main}>
          <h2 className={styles.shopName}>{shopName}</h2>
          <p className={styles.orderDate}>{formattedDate}</p>
        </div>
        <div className={styles.status}>
          <span style={{ fontSize: "12px", marginLeft: "5px" }}>
            ({data?.status})
          </span>
        </div>
        <div className={styles.priceWrapper}>
          <p className={styles.price}>
            {data?.total_price_converted || data.total_price}{" "}
            {data?.currency || currencySymbol}
          </p>
        </div>
        <div className={styles.iconNextBlock}>
          <ChevronRightIcon themeMode={themeMode} className={styles.iconNext} />
        </div>
      </div>
    </div>
  );
};
export default HistoryCart;
