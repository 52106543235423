import React from 'react';
import styles from "./More.module.scss"

const CONST_SIZE_TOP_HEADER = 72

const More = ({headerRef, overElem}) => {

    const scrollToAnchorTwo = (anchorId, headerRef) => {
        const headerHeight = headerRef.current.offsetHeight;
        const anchorElement = document.getElementById(anchorId);

        if (anchorElement) {
            const yOffset = -headerHeight;
            const y = anchorElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y - CONST_SIZE_TOP_HEADER, behavior: 'smooth' });
        }
    };

    return (
        <div className={styles.mainConteiner}>
            <div className={styles.mainBlock}>
                {
                    overElem.map((el, key) => (
                        <a
                            key={key}
                            className={styles.elem}
                            onClick={() => scrollToAnchorTwo(`category${el.id}`, headerRef)}
                        >{el.title}</a>
                    ))
                }
            </div>
        </div>
    );
};

export default More;