import React from 'react';
import styles from "./ModalLimitedInformation.module.scss"
import {Modal} from "@mui/material";
import {observer} from "mobx-react";
import rootStore from "../../store/RootStore";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ModalLimitedInformation = observer(({isOpen, openClose}) => {
    const {shopData} = rootStore.shopStore;

    return (
        <div>
            <Modal
                open={isOpen}
                onClose={() => openClose()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className={styles.mainContainer}>
                    <div className={styles.mainBlock}>
                        <aside className={styles.container}>
                            <div className={styles.closeBtnConteiner}>
                                <button className={styles.buttonBlock} data-backdrop="transparent"
                                        data-size="medium" data-variant="neutral" type="button"
                                        aria-label="Close the dialog" data-test-id="modal-close-button"
                                        onClick={() => openClose()}>
                                    <div className={styles.btnFakeBlock}></div>
                                    <div className={styles.btnConteinerInclude}>
                                        <svg viewBox="0 0 24 24" className={styles.imgCloseBtn}>
                                            <path
                                                d="M2.44 19.44a1.5 1.5 0 002.121 2.121l7.262-7.26a.25.25 0 01.354 0l7.262 7.262a1.5 1.5 0 102.122-2.12l-7.26-7.266a.25.25 0 010-.354l7.262-7.262a1.5 1.5 0 00-2.124-2.12L12.177 9.7a.25.25 0 01-.354 0L4.561 2.44A1.5 1.5 0 002.44 4.56L9.7 11.823a.25.25 0 010 .354L2.44 19.44z"></path>
                                        </svg>
                                    </div>
                                </button>

                            </div>
                            <div className={styles.mainConteiner2}>
                                <div className={styles.header}></div>
                                <div className={styles.content}>
                                    <div className={styles.modalContentContainer}>
                                        <div className={styles.marketplaceInfoModal}>
                                            <h1 className={styles.title}>Limited delivery tracking</h1>
                                            <p className={styles.description}>Your order will be delivered by the
                                                venue’s own staff. We’ll forward your full name, address, phone and
                                                order details to the venue for delivery.</p>
                                            <div className={styles.howItWorks}>
                                                <h2 className={styles.subtitle}>How it works</h2>
                                                <ol className={styles.steps}>
                                                    <li className={styles.step}>You'll get a delivery time estimate as
                                                        soon as your order is accepted.
                                                    </li>
                                                    <li className={styles.step}>You’ll be notified when the order is on
                                                        its way. Unfortunately, live delivery tracking won’t be
                                                        available.
                                                    </li>
                                                    <li className={styles.step}>Listen out for a knock on your door, and
                                                        keep your phone close in case the venue contacts you during
                                                        delivery.
                                                    </li>
                                                    <li className={styles.step}>Didn’t get the order? We’ll put you in
                                                        touch directly with the venue to get it sorted.
                                                    </li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <footer className={`${styles.footer} ${styles.visible}`}>
                                <div className={styles.container2}>
                                    <div className={styles.innerContainer}>
                                        <button aria-busy="false" className={styles.button} data-size="large"
                                                data-stretch="" data-variant="primary" type="button"
                                                data-test-id="MarketplaceInfoModal.done-button"
                                                onClick={() => openClose()}>
                                            <div className={styles.background}>Done</div>
                                        </button>
                                    </div>
                                </div>
                            </footer>
                        </aside>
                    </div>
                </div>
            </Modal>
        </div>
    );
});

export default ModalLimitedInformation;