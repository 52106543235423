import api from "../api";

export const editProduct = async (formData,shopId,productId) => {
    if(formData) {
        try {
            const apiResponse = await api.put(`/admin_seller_shop/get-my-shop/${shopId}/products/${productId}/update/`,formData).then(response => response);
            return apiResponse;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }
}