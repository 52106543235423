import React, { useEffect, useState } from 'react';
import styles from "./ModeratorBodyMenuNavigate.module.scss";
import { observer } from "mobx-react";
import CategoryItem from "../CategoryItem/CategoryItem";
import rootStore from "../../../../store/RootStore";
import {getSuperUserShopCategoriesList} from "../../../../api/getContentsApi/getSuperUserShopCategoriesList";
import {t} from "i18next";

const defaultCategoryList = [{
    CategoryName: "ALL",
    CategoryID: 0,
    ParentCategoryID: null
}];


const ModeratorBodyMenuNavigate = observer(({ state }) => {
    const { setIfOpenCreateCatalog } = rootStore.moderatorPanelStore;
    const storedShopValue = localStorage.getItem("currentShop");
    let shopId = rootStore.superUserStore.currentShopId || JSON.parse(storedShopValue)?.ShopID;
    const [categoryListData, setCategoryListData] = useState(defaultCategoryList);

    useEffect(() => {
        const fetchSuperUserShopCategoriesList = async () => {
            if (shopId) {
                try {
                    const data = await getSuperUserShopCategoriesList(shopId);
                    if (data) {
                        setCategoryListData(prev=> [...data])
                    }
                } catch (error) {
                    console.error("An error occurred:", error);
                }
            }
        }
        fetchSuperUserShopCategoriesList();
    }, [shopId, state]);

    const [openCategories, setOpenCategories] = useState({});
    const [selectedCategoryID, setSelectedCategoryID] = useState(null);

    const toggleCategory = (categoryId,e) => {
        console.log(categoryId)
        e.stopPropagation()
        rootStore.categoriesStore.setCurrentCategoryID(categoryId)
        setSelectedCategoryID(categoryId);
        setOpenCategories(prevOpenCategories => ({
            ...prevOpenCategories,
            [categoryId]: !prevOpenCategories[categoryId]
        }));
    };

    const renderCategory = (categories, parentId = null, parentLevel = "",level = 0) => {
        return categories
            .filter(category => category.ParentCategoryID === parentId)
            .map((category, index) => (
                <ul className={styles.categoryList} key={category.CategoryID} style={{ '--indent': `${level + 8}px` }}>
                    <ul className={`${styles.categoryList} ${selectedCategoryID === category.CategoryID ? styles.selectedCategory : ''}  `}
                        onClick={(e) => toggleCategory(category.CategoryID,e)}>
                        {categoryHasChildren(category.CategoryID) && (
                            <span className={styles.arrowIcon}>
                               {openCategories[category.CategoryID] ? '▼' : '▶'}
                            </span>
                        )}
                        <CategoryItem category={category} level={parentLevel + (index + 1).toFixed(0) + "."}/>
                        {openCategories[category.CategoryID] && categoryHasChildren(category.CategoryID) && renderCategory(categories, category.CategoryID, parentLevel + (index + 1) + ".")}
                    </ul>
                </ul>
            ));
    };

    const categoryHasChildren = (categoryId) => {
        return categoryListData.some(category => category.ParentCategoryID === categoryId);
    };

    const handleChangeOpen = () => {
        setIfOpenCreateCatalog(true);
    }

    return (
        <div className={styles.mainConteiner}>
            <div className={styles.mainBlock}>
                <div className={styles.categoryImgBlock}>{t("Categories")}
                    <div className={styles.createCategoryBlock}>
                        <button
                            data-label="Создать категорию"
                            id="createProductsGroup"
                            className={styles.createCategoryBtn}
                            onClick={handleChangeOpen}>
                        <span className="dQaVw _8EVa2">
                            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                                <g fill="none">
                                    <path d="M0 0L24 0 24 24 0 24z"></path>
                                    <path
                                        d="M10,12 L12,12 L12,14 L10,14 L10,16 L8,16 L8,14 L6,14 L6,12 L8,12 L8,10 L10,10 L10,12 Z M20,6 C21.1,6 22,6.9 22,8 L22,18 C22,19.1 21.1,20 20,20 L4,20 C2.9,20 2 19.1 2,18 L2.01,6 C2.01,4.9 2.9,4 4,4 L10,4 L12,6 L20,6 Z M20,18 L20,8 L4,8 L4,18 L20,18 Z"
                                        fill="#02acf8"></path>
                                </g>
                            </svg>
                        </span>
                            <span>{t("Create new category")}</span>
                        </button>
                    </div>
                </div>
                <div className={styles.fakeBlock}></div>
                <div className={styles.bigCategoryBlock}>
                    {renderCategory(defaultCategoryList)}
                    {renderCategory(categoryListData)}
                </div>
                <div className={styles.fakeBlock}></div>
            </div>
        </div>
    );
});

export default ModeratorBodyMenuNavigate