import { makeAutoObservable } from "mobx";
import api from "../api/api";

class DiscoveryStore {
  discoverySliders = [];
  initialized = false;

  loader = true;
  constructor() {
    makeAutoObservable(this);
    this.lazyInitialize();
  }

  lazyInitialize = () => {
    if (!this.initialized) {
      api.get("/discovery/discovery-sliders/").then((response) => {
        this.discoverySliders = response.data;
        this.initialized = true;
      });
    }
  };
  get discoverySlidersList() {
    if (this.discoverySliders.length) {
      return this.discoverySliders;
    } else {
      return [];
    }
  }
}
const discoveryStore = new DiscoveryStore();
export default discoveryStore;
