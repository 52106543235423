import React from 'react';
import styles from './TransactionCard.module.scss'
import {useTheme} from "../../../helpers/ThemeContext";
import { getCurrencySymbol } from "../../../helpers/getCurrency";


const TransactionCard = ({ transaction, setOpen, setData }) => {
    const { status, amount } = transaction;

    const symbol = getCurrencySymbol();
    const {themeMode} = useTheme()

    const dateLocale = new Date(transaction.created_at);
    const formattedDate = `${dateLocale.getDate()}.${dateLocale.getMonth() + 1}.${dateLocale.getFullYear()}, ${dateLocale.getHours()}:${('0' + dateLocale.getMinutes()).slice(-2)}`;

    const handleOpenCard = () => {
        setOpen(true)
        setData(transaction)
    }

    return (
        <div className={styles.lvl2} onClick={() => handleOpenCard()}>
            <div className={styles.lvl3}>
                <div className={styles.emptyTabState}>
                    <div className={styles.content}>
                        <div >
                            <p className={styles.left}>{status}</p>
                            <p className={styles.date}>{formattedDate}</p>
                        </div>
                        <p className={styles.right}>{amount} $ <div className={styles.IconNextBlock}>
                            <svg viewBox="0 0 20 20" className={styles.IconNext}
                                 fill={themeMode === 'dark' ? "white" : "black"}>
                                <path
                                    d="M12.1768 13.409C12.0791 13.5066 11.9209 13.5066 11.8232 13.409L6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L12.1768 13.409Z"></path>
                            </svg>
                        </div></p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TransactionCard;