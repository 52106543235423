import React from 'react';
import styles from './FilterBudge.module.scss';
import {observer} from "mobx-react";
import {useTheme} from "../../helpers/ThemeContext";

const ModalFilters = ({name,active,toggleActive,id}) => {
    const {themeMode} = useTheme();
    const handleClick = (id) => {
        toggleActive(id, 'price',active)
    };
    return (
        <div className={styles.mainBlock}>
            <input id={`filter${name}`}
                   type="checkbox"
                   name="filter"
                   data-test-id="categories.american"
                   className={styles.filter}
                   value="american"/>
            <label for={`filter${name}`} data-test-id="categories.american.label"
                   onClick={() => handleClick(id)}
                   className={`${styles.label} ${active ? styles.label__active : ''} 
                   ${themeMode === 'dark' && styles.label_dark }
                   ${active && themeMode === 'dark' && styles.label_dark__active }
                  `}>{name}</label>
        </div>
    );
};

export default observer(ModalFilters);
