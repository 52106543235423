import React from 'react';

const StoresIcon = ({currentColor}) => {
    const isModerator = window.location.pathname.includes("moderator")

    return (
        <svg viewBox="0 0 550 550"
             width={isModerator ? "1.4rem" : "1.4rem"}
             height={isModerator ? "1.4rem" : "1.4rem"}
            fill={currentColor}>
            <g xmlns="http://www.w3.org/2000/svg" data-name="Layer 2" id="Layer_2">

                <g data-name="E425, History, log, manuscript" id="E425_History_log_manuscript">

                    <path className="cls-1"
                          d="M75.11,117h0A21.34,21.34,0,0,1,53.83,95.57V31.39A21.34,21.34,0,0,1,75.11,10h0A21.34,21.34,0,0,1,96.39,31.39V95.57A21.34,21.34,0,0,1,75.11,117Z"/>

                    <rect className="cls-1" height="64.17" width="319.22" x="96.39" y="31.39"/>

                    <rect className="cls-1" height="320.87" width="319.22" x="96.39" y="95.57"/>

                    <path className="cls-1"
                          d="M34.34,39.08H53.83a0,0,0,0,1,0,0v48.8a0,0,0,0,1,0,0H34.34A24.34,24.34,0,0,1,10,63.54v-.13A24.34,24.34,0,0,1,34.34,39.08Z"/>

                    <path className="cls-1"
                          d="M436.89,117h0a21.34,21.34,0,0,0,21.28-21.39V31.39A21.34,21.34,0,0,0,436.89,10h0a21.34,21.34,0,0,0-21.28,21.39V95.57A21.34,21.34,0,0,0,436.89,117Z"/>

                    <path className="cls-1"
                          d="M482.51,39.08H502a0,0,0,0,1,0,0v48.8a0,0,0,0,1,0,0H482.51a24.34,24.34,0,0,1-24.34-24.34v-.13a24.34,24.34,0,0,1,24.34-24.34Z"
                          transform="translate(960.17 126.96) rotate(-180)"/>

                    <path className="cls-1"
                          d="M75.11,395h0a21.34,21.34,0,0,0-21.28,21.39v64.18A21.34,21.34,0,0,0,75.11,502h0a21.34,21.34,0,0,0,21.28-21.39V416.43A21.34,21.34,0,0,0,75.11,395Z"/>

                    <rect className="cls-1" height="64.17" width="319.22" x="96.39" y="416.43"/>

                    <path className="cls-1"
                          d="M34.34,424.12H53.83a0,0,0,0,1,0,0v48.8a0,0,0,0,1,0,0H34.34A24.34,24.34,0,0,1,10,448.58v-.13A24.34,24.34,0,0,1,34.34,424.12Z"/>

                    <path className="cls-1"
                          d="M436.89,395h0a21.34,21.34,0,0,1,21.28,21.39v64.18A21.34,21.34,0,0,1,436.89,502h0a21.34,21.34,0,0,1-21.28-21.39V416.43A21.34,21.34,0,0,1,436.89,395Z"/>

                    <path className="cls-1"
                          d="M482.51,424.12H502a0,0,0,0,1,0,0v48.8a0,0,0,0,1,0,0H482.51a24.34,24.34,0,0,1-24.34-24.34v-.13a24.34,24.34,0,0,1,24.34-24.34Z"
                          transform="translate(960.17 897.04) rotate(-180)"/>

                    <line className="cls-1" x1="143.41" x2="256" y1="140.11" y2="140.11"/>

                    <line className="cls-1" x1="143.41" x2="371.26" y1="186.47" y2="186.47"/>

                    <line className="cls-1" x1="143.41" x2="371.26" y1="232.82" y2="232.82"/>

                    <line className="cls-1" x1="143.41" x2="371.26" y1="279.18" y2="279.18"/>

                    <line className="cls-1" x1="143.41" x2="371.26" y1="325.53" y2="325.53"/>

                    <line className="cls-1" x1="256" x2="371.26" y1="371.89" y2="371.89"/>

                </g>

            </g>
        </svg>);
};

export default StoresIcon;