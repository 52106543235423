import React from 'react';
import styles from "./RestoranMenu.module.scss"
import Category from "../Category/Category";

const RestoranMenu = ({data}) => {

    return (
        <div className={styles.mainConteiner}>
            <div className={styles.mainBlock}>
                <div className={styles.headerBlock}>
                    <p className={styles.descriptionHeader}>This menu is in Hebrew. Would you like to view a machine
                        translation in another language?</p>
                    <div className={styles.headerBtnBlock}>
                        <button type="button" className={styles.headerBtn}>Translate</button>
                    </div>
                </div>
                <div className={styles.mainMenu}>
                    {
                        data.map((element, key) =>
                           (<Category title={element.title} items={element.items} id={element.id} key={key} />)
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default RestoranMenu;