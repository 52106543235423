import React from 'react';
import styles from './SearchBudge.module.scss';
import {observer} from "mobx-react";

const SearchBudge = ({name, setText}) => {


    return (
        <div className={styles.mainBlock} onClick={() => setText(name)}>
            <input id={`filter${name}`}
                   type="checkbox"
                   name="filter"
                   data-test-id="categories.american"
                   className={styles.filter}
                   value="american"/>
            <label for={`filter${name}`} data-test-id="categories.american.label"
                   className={styles.label}>{name}</label>
        </div>
    );
};

export default observer(SearchBudge);
