import React, { useEffect } from 'react';

import { t } from "i18next";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";

import styles from './OrderHistory.module.scss'

import IconBack from "../../Icons/IconBack";
import rootStore from "../../../store/RootStore";
import HistoryCart from "../../HistoryCart/HistoryCart";

const OrderHistory = observer(() => {
    const navigate = useNavigate();

    const { historyList, getHistory } = rootStore.userStore;

    const goBack = () => {
        navigate('/discovery/me', { replace: true });
    };

    useEffect(() => {
        getHistory();
    }, []);

    return (
        <>
            <div className={styles.goBackButton} onClick={goBack}>
                <IconBack size={20} pure={true} fill={"#009de0"}/>
            </div>
            <h3 className={styles.title}>{t("Order History")}</h3>
            <div className={styles.lvl1}>
                <hr style={{opacity: 0.4}}/>
                {
                    historyList.length ?
                        (
                            historyList.map((el, index) => (
                                <HistoryCart data={el} index={index} key={el.id}/>
                            ))
                        ) :
                        (
                            <div className={styles.lvl2}>
                                <div className={styles.lvl3}>
                                    <div className={styles.emptyTabState}>
                                        <div className={styles.content}>
                                            <p className={styles.stateTitle} data-test-id="EmptyTabState.StateTitle">
                                                {t("You haven’t made any orders yet")}
                                            </p>
                                            <p className={styles.description}>
                                                {t("You’ll find all the details of your orders here")}
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )
                }
            </div>
            {/*{getOrderAgine && (<OrderAgine closeDrawer={setOpenOrderAgine}/>)}*/}
        </>
    );
});

export default OrderHistory;