export const sortStatisticsDataByDate = (data) => {
    return data.sort((a, b) => {
        if (Date.parse(new Date(a.date)) > Date.parse(new Date(b.date))) {
            return -1;
        } 
        if (Date.parse(new Date(a.date)) < Date.parse(new Date(b.date))) {
            return 1;
        }
        return 0;
    });
};