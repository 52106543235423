import React, {useEffect, useState} from 'react';
import {Link, Outlet, useLocation} from "react-router-dom";
import style from './SupportWrapper.module.scss'
import { observer, inject } from 'mobx-react';
import WithAuthUser from "../../helpers/WithAuthUser";
import {useTheme} from "../../helpers/ThemeContext";
import useFooterMargin from "../DiscoveryWrapper/useFootrerMargin";
import {t} from "i18next";
import BackIcon from "../CurierPanel/static/BackIcon";

const SupportWrapper = inject('rootStore')(observer(() => {
    const { themeMode, setLightTheme } = useTheme();

    useEffect(() => {
        setLightTheme()
    }, [])
    const {pathname} = useLocation();
    const [hidden, setHidden] = useState(false);

    const [activeLink, setActiveLink] = useState(() => {
        if (pathname.includes( '/restaurants')) {
            return 'link2';
        } else if (pathname.includes('/stores')) {
            return 'link3';
        } else {
            return 'link1';
        }
    });
    const handleButtonClick = (link) => {
        setActiveLink(link);
    };
    useEffect(() => {
        if(pathname.includes("curier-panel/actual")){
            setActiveLink('link1')
        } else if(pathname.includes("curier-panel/worked")) {
            setActiveLink('link2')
        } else if(pathname.includes("curier-panel/all")){
            setActiveLink('link3')
        }
    }, [window.location.pathname]);

    useEffect(() => {
        if(pathname.includes("actual/")){
            setHidden(true)
        } else if(pathname.includes("worked/")) {
            setHidden(true)
        } else if(pathname.includes("all/")){
            setHidden(true)
        }
        else if(pathname.includes("chat")){
            setHidden(true)
        }
        else {
            setHidden(false)
        }
    }, [window.location.pathname]);
    useFooterMargin();

    return (
        <WithAuthUser>
                <div className={style['nav-bar']} style={{display: hidden ? 'none' : 'flex'}}>
                    <Link
                        onClick={() => handleButtonClick('link1')}
                        className={`${themeMode === 'dark' ? style.dark : ''} ${style.link} ${activeLink === 'link1' ? style.active : ''} ${style.firstLink}`}
                        to={"new"}>
                        <svg viewBox="0 0 550 550"
                             width="1.4rem"
                             height="1.4rem"
                             fill="currentColor" version="1.1">
                            <g>
                                <g>
                                    <path
                                        d="M480.5,56H31.3C20,56,10.9,65.2,10.9,76.5v359c0,11.3,9.1,20.5,20.4,20.5h449.2c11.3,0,20.4-9.2,20.4-20.5v-359    C500.9,65.2,491.7,56,480.5,56z M432.4,97L256.3,276.2L80.2,97H432.4z M51.7,415V126.5l190,193.3c10.3,10.9,18.6,9,29.1,0    l189.3-192.5V415H51.7z"/>
                                </g>
                            </g>
                        </svg>
                        <span>
                           {t("New")}
                       </span>
                    </Link>
                    <Link
                        onClick={() => handleButtonClick('link2')}
                        className={`${themeMode === 'dark' ? style.dark : ''} ${style.link} ${activeLink === 'link2' ? style.active : ''}`}
                        to={"active"}>
                        <svg viewBox="0 0 550 550"
                             width="1.4rem"
                             height="1.4rem"
                             fill="currentColor" version="1.1">
                            <g>
                                <g>
                                    <path xmlns="http://www.w3.org/2000/svg"
                                          d="M500.599,157.899l-230.4-153.6c-8.6-5.731-19.801-5.731-28.401,0l-230.4,153.6C4.279,162.647,0,170.641,0,179.2v307.2    C0,500.538,11.462,512,25.6,512h460.8c14.138,0,25.6-11.462,25.6-25.6V179.2C512,170.641,507.721,162.647,500.599,157.899z     M256,56.367l184.62,123.08L256,302.068L71.38,179.447L256,56.367z M460.8,460.8H51.2V227.508l190.636,126.616    c4.291,2.85,9.226,4.275,14.164,4.275s9.873-1.425,14.164-4.275L460.8,227.508V460.8z"/>
                                </g>
                            </g>
                        </svg>
                        {t("Active")}
                    </Link>
                    <Link
                        onClick={() => handleButtonClick('link4')}
                        className={`${themeMode === 'dark' ? style.dark : ''} ${style.link} ${activeLink === 'link4' ? style.active : ''}`}
                        to={"/discovery/me"}>
                        <BackIcon/>
                        {t("Back")}
                    </Link>
                </div>
            <Outlet/>
        </WithAuthUser>
    );
}));

export default observer(SupportWrapper);