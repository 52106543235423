import React, {useEffect} from 'react';
import styles from "./ModeratorPanel.module.scss"
import ModeratorNavbar from "../../components/ModeratorNavbar/ModeratorNavbar";

import WithSuperAdminUser from "../../helpers/WithSuperAdminUser";
import ModeratorBody from "../../components/ModeratorBody/ModeratorBody";
import {useTheme} from "../../helpers/ThemeContext";
import {useMatch, useNavigate} from "react-router-dom";

const ModeratorPanel = () => {
    const {setLightTheme} = useTheme()

    const navigate = useNavigate();
    const owner = useMatch("/moderator")

    useEffect(() => {
        if(owner) navigate("/moderator/menu")
        setLightTheme()
    }, [])
    return (
        <WithSuperAdminUser>
            <div className={styles.pageContent}>
                <ModeratorBody/>
                <ModeratorNavbar/>
            </div>
        </WithSuperAdminUser>
    );
};

export default ModeratorPanel;