import React, {useEffect, useState} from 'react';

import {t} from "i18next";
import {observer} from 'mobx-react';

import styles from "./CreateProductModal.module.scss";

import rootStore from "../../../../store/RootStore";
import {addProduct} from "../../../../api/addContent/addProduct";
import {videoSizeLimit} from "./constants";
import {getModeratorCategoryList} from "../../../../api/getContentsApi/getModeratorCategoryList";
import SuccessModal from "./SuccessModal";

const CreateProductModal = observer(() => {
    const [isSending, setIsSending] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const {setIfOpenCreateProtuct} = rootStore.moderatorPanelStore;
    const [input1, setInput1] = useState('');
    const [input2, setInput2] = useState('');
    const [input3, setInput3] = useState('');
    const [input4, setInput4] = useState('');
    const [input5, setInput5] = useState('');
    const [description, setDescription] = useState('');
    const [file, setFile] = useState(null);
    const [video, setVideo] = useState(null);
    const [productInfo, setProductInfo] = useState('');
    const [errors, setErrors] = useState({});
    const [categoryListData, setCategoryListData] = useState([]);

    const [sales, setSales] = useState([]);
    const [inputFields, setInputFields] = useState([{ count: "", price: "" }]);

    const storedShopValue = localStorage.getItem('currentShop');
    let shopId = rootStore.superUserStore.currentShopId || JSON.parse(storedShopValue)?.ShopID;

    useEffect(() => {
        const fetchSuperUserShopCategoriesList = async () => {
            if (shopId) {
                try {
                    const data = await getModeratorCategoryList();
                    if (data?.length) {
                        setCategoryListData(data)
                    }
                } catch (error) {
                    console.error('An error occurred:', error);
                }
            }
        }
        fetchSuperUserShopCategoriesList();
        return () => setCategoryListData([]);
    }, [shopId]);

    const handleClose = () => {
        setIfOpenCreateProtuct(false);
    };

    const validateInputs = () => {
        const newErrors = {};
        if (!input1.trim()) newErrors.input1 = t("Product name is required");
        if (!input2.trim()) newErrors.input2 = t("Category is required");
        if (!input3.trim()) newErrors.input3 = t("Unit is required");
        if (!input4.trim() || isNaN(input4)) newErrors.input4 = t("Price must be a number");
        if (!input5.trim() || isNaN(input5)) newErrors.input5 = t("Quantity must be a number");
        if (!description.trim()) newErrors.description = t("Description is required");
        if (!productInfo.trim()) newErrors.productInfo = t("Product info is required");
        if (!file) {
            newErrors.file = t("Image is required");
        } else {
            if (!['image/jpeg', 'image/png', 'image/gif'].includes(file.type)) {
                newErrors.file = t("File must be an image (jpeg, png, gif)")
            }
            if (file.size > videoSizeLimit) {
                newErrors.file = t("File size must not exceed 50MB");
            }
        }
        if (video) {
            if (video.size > videoSizeLimit) {
                newErrors.video = t("Video size must not exceed 50MB");
            }
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFile(file);
    };

    const handleVideoChange = (event) => {
        setVideo(null);
        const video = event.target.files[0];
        setVideo(video);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateInputs() || !shopId) return;
        const productData = new FormData();
        productData.append('ProductName', input1);
        productData.append('CategoryID', input2);
        productData.append('unit', input3);
        productData.append('Price', parseFloat(input4));
        productData.append('quantity', input5);
        productData.append('Description', description);
        productData.append('Product_info', productInfo);
        productData.append('ImageUrl', file);
        productData.append('AltText', file.name);

        if (video) {
            productData.append('VideoUrl', video);
            productData.append('VideoAltText', video.name);
        }
        if(sales.length > 0) {
            productData.append('salles',JSON.stringify(sales));
        }
        try {
            setIsSending(true);
            const response = await addProduct(productData, shopId);
            if (response.status === 201) {
                setIsSending(false);
                setShowSuccessModal(true);
                setInput1('');
                setInput2('');
                setInput3('');
                setInput4('');
                setInput5('');
                setDescription('');
                setProductInfo('');
                setFile(null);
                setVideo(null);
                setInputFields([{ count: "", price: "" }]);
                setTimeout(() => {
                    setIfOpenCreateProtuct(false);
                }, 2000);
            } else {
                setIsSending(false);
                console.error('Error sending data to the server');
            }
        } catch (error) {
            setIsSending(false);
            console.error('Network error:', error);
        }
    };

    const handleRemoveField = (index) => {
        const updatedFields = inputFields.filter((_, i) => i !== index);
        setInputFields(updatedFields);

        const updatedSales = updatedFields
            .filter(field => parseInt(field.count, 10) > 0 && parseFloat(field.price) > 0)
            .map(field => ({
                count: parseInt(field.count, 10),
                price: parseFloat(parseFloat(field.price).toFixed(2)), // Два знака для price
            }));
        setSales(updatedSales);
    };

    const handleInputChange = (index, field, value) => {
        if (field === "count") {
            // Проверяем, чтобы count был целым числом
            if (/^\d*$/.test(value)) {
                const updatedFields = [...inputFields];
                updatedFields[index][field] = value;
                setInputFields(updatedFields);
            }
        } else if (field === "price") {
            // Проверяем, чтобы price был числом с максимум двумя знаками после запятой
            if (/^\d*\.?\d{0,2}$/.test(value)) {
                const updatedFields = [...inputFields];
                updatedFields[index][field] = value;
                setInputFields(updatedFields);
            }
        }
    };

    const handleAddField = (event) => {
        event.preventDefault();

        const lastField = inputFields[inputFields.length - 1];
        const isUniqueCount =  !sales.some(
            field => parseInt(field.count, 10) === parseInt(lastField.count, 10)
        );

        if (
            parseInt(lastField.count, 10) > 0 &&
            parseFloat(lastField.price) > 0 &&
            isUniqueCount
        ) {
            setInputFields([...inputFields, { count: "", price: "" }]);

            const formattedSales = inputFields.map(field => ({
                count: parseInt(field.count, 10),
                price: parseFloat(parseFloat(field.price).toFixed(2))
            }));
            const discount = formattedSales.sort((a, b) => Number(a.count) - Number(b.count));
            setSales(discount);
        }
    };


    return (
        <>
            <SuccessModal open={showSuccessModal} onClose={() => setShowSuccessModal(false)}/>
            <div className={styles.block1}>
                <div className={styles.block2}>
                    <div className={styles.block3}>
                        <div className={styles.block4}>
                            <div className={styles.block5} data-testid="backButton" onClick={handleClose}>
                                <span className={styles.block6}>
                                    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path
                                        d="M8.41421356,13 L13.7071068,18.2928932 L12.2928932,19.7071068 L4.58578644,12 L12.2928932,4.29289322 L13.7071068,5.70710678 L8.41421356,11 L20,11 L20,13 L8.41421356,13 Z"
                                        fill="#716E7A"></path></svg>
                                </span>
                            </div>
                            <div className={styles.block7}>
                                <span className={styles.block8}>{t("Products")}</span>
                                <div className={styles.block9}></div>
                                <span className={styles.block0}>{t("New Product")}</span>
                            </div>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className={styles.conteiner1}>
                            <div className={styles.conteiner2}>
                                <div className={styles.conteiner3}>
                                    <div className={styles.conteiner4}>
                                        <div>
                                            <div className={styles.conteiner5}>
                                                <label className={styles.conteiner6} htmlFor="file">
                                                    <input
                                                        id="file"
                                                        type="file"
                                                        className={styles.conteiner7}
                                                        onChange={handleFileChange}
                                                    />
                                                </label>
                                                <img className={file ? styles.preview : styles.conteiner8}
                                                     src={file ? URL.createObjectURL(file) : "https://cloud.sigma.ru/svg/restaurant.svg"}
                                                     alt=""/>

                                            </div>
                                            {errors.file && <div className={styles.error}>{errors.file}</div>}
                                            <div className={styles.conteiner9}>
                                                {t("A product with a photo is easier to find at the checkout")}
                                            </div>

                                        </div>
                                        <div>
                                            <div className={styles.conteiner5}>
                                                <label className={styles.conteiner6} htmlFor="video">
                                                    <input
                                                        id="video"
                                                        type="file"
                                                        className={styles.conteiner7}
                                                        onChange={handleVideoChange}
                                                    />
                                                </label>

                                                {video ? (<video className={styles.preview} controls>
                                                        <source src={URL.createObjectURL(video)} type={video.type}/>
                                                        {t("Your browser does not support the video tag.")}
                                                    </video>) : <img className={styles.conteiner8}
                                                                     src={"https://cloud.sigma.ru/svg/restaurant.svg"}
                                                                     alt=""/>}
                                            </div>
                                            {errors.video && <div className={styles.error}>{errors.video}</div>}
                                            <div className={styles.conteiner9}>
                                                {t("A product with a video can attract more customers.")}
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div className={styles.main1}>
                                            <div className={styles.main2}>
                                                <div data-label="Product Name" data-value="" data-focus="false"
                                                     data-error="false" id="name"
                                                     className={`${styles.input1} ${styles.input}`} data-status="false">
                                                    <label className={styles.main4} htmlFor="9075">
                                                        {t("Product name")}
                                                    </label>
                                                    <div className={styles.main5}>
                                                        <input
                                                            autoComplete="off"
                                                            data-testid="nameProduct"
                                                            type="text"
                                                            className={styles.main6}
                                                            placeholder={t("Enter product name or scan barcode")}
                                                            data-value=""
                                                            value={input1}
                                                            onChange={(e) => setInput1(e.target.value)}
                                                        />
                                                    </div>
                                                    {errors.input1 &&
                                                        <span className={styles.error}>{errors.input1}</span>}
                                                </div>
                                                <div data-label="Category" data-value="" data-focus="false"
                                                     data-error="false" id="name"
                                                     className={`${styles.input2} ${styles.input}`} data-status="false">
                                                    <label className={styles.main4}
                                                           htmlFor="9075">{t("Category")}</label>
                                                    <div className={styles.main5}>
                                                        <select
                                                            autoComplete="off"
                                                            data-testid="nameProduct"
                                                            className={styles.main6}
                                                            value={input2}
                                                            onChange={(e) => setInput2(e.target.value)}
                                                        >
                                                            <option value="">{t("Select category...")}</option>
                                                            {categoryListData.length && categoryListData.map((category) => (
                                                                <option key={category.CategoryID}
                                                                        value={category.CategoryID}>{category.CategoryName}</option>))}
                                                        </select>
                                                    </div>
                                                    {errors.input2 &&
                                                        <span className={styles.error}>{errors.input2}</span>}
                                                </div>
                                                <div data-label="Description" data-value="" data-focus="false"
                                                     data-error="false" id="name"
                                                     className={`${styles.input6} ${styles.input}`} data-status="false">
                                                    <label className={styles.main4} htmlFor="9075">
                                                        {t("Description")}
                                                    </label>
                                                    <div className={styles.main5}>
                                                        <textarea
                                                            autoComplete="off"
                                                            data-testid="nameProduct"
                                                            type="text"
                                                            className={styles.main6}
                                                            placeholder="Enter product description"
                                                            data-value=""
                                                            value={description}
                                                            onChange={(e) => setDescription(e.target.value)}
                                                        />
                                                    </div>
                                                    {errors.description &&
                                                        <span className={styles.error}>{errors.description}</span>}
                                                </div>
                                                <div data-label="Product Info" data-value="" data-focus="false"
                                                     data-error="false" id="name"
                                                     className={`${styles.input7} ${styles.input}`} data-status="false">
                                                    <label className={styles.main4} htmlFor="productInfo">
                                                        {t("Product Info")}
                                                    </label>
                                                    <div className={styles.main5}>
                                                        <textarea
                                                            autoComplete="off"
                                                            data-testid="productInfo"
                                                            type="text"
                                                            className={styles.main6}
                                                            placeholder={t("Enter product info")}
                                                            data-value=""
                                                            value={productInfo}
                                                            onChange={(e) => setProductInfo(e.target.value)}
                                                        />
                                                    </div>
                                                    {errors.productInfo &&
                                                        <span className={styles.error}>{errors.productInfo}</span>}
                                                </div>

                                                <div data-label="Unit" data-value="" data-focus="false"
                                                     data-error="false" id="name"
                                                     className={`${styles.input3} ${styles.input}`} data-status="false">
                                                    <label className={styles.main4} htmlFor="9075">Unit</label>
                                                    <div className={styles.main5}>
                                                        <input
                                                            autoComplete="off"
                                                            data-testid="nameProduct"
                                                            type="text"
                                                            className={styles.main6}
                                                            placeholder="pcs"
                                                            data-value=""
                                                            value={input3}
                                                            onChange={(e) => setInput3(e.target.value)}
                                                        />
                                                    </div>
                                                    {errors.input3 &&
                                                        <span className={styles.error}>{errors.input3}</span>}
                                                </div>
                                                <div data-label="Price" data-value="" data-focus="false"
                                                     data-error="false" id="name"
                                                     className={`${styles.input4} ${styles.input}`} data-status="false">
                                                    <label className={styles.main4} htmlFor="9075">
                                                        {t("Price")}
                                                    </label>
                                                    <div className={styles.main5}>
                                                        <input
                                                            autoComplete="off"
                                                            data-testid="nameProduct"
                                                            type="text"
                                                            className={styles.main6}
                                                            placeholder={t("Price")}
                                                            data-value=""
                                                            value={input4}
                                                            onChange={(e) => setInput4(e.target.value)}
                                                        />
                                                    </div>
                                                    {errors.input4 &&
                                                        <span className={styles.error}>{errors.input4}</span>}
                                                </div>
                                                <div data-label="Quantity" data-value="" data-focus="false"
                                                     data-error="false" id="name"
                                                     className={`${styles.input5} ${styles.input}`} data-status="false">
                                                    <label className={styles.main4} htmlFor="quantity">
                                                        {t("Quantity")}
                                                    </label>
                                                    <div className={styles.main5}>
                                                        <input
                                                            autoComplete="off"
                                                            data-testid="quantity"
                                                            type="text"
                                                            className={styles.main6}
                                                            placeholder={t("Enter quantity")}
                                                            data-value=""
                                                            value={input5}
                                                            onChange={(e) => setInput5(e.target.value)}
                                                        />
                                                    </div>
                                                    {errors.input5 &&
                                                        <span className={styles.error}>{errors.input5}</span>}
                                                </div>
                                                <div className={styles.discountBlock}>
                                                    <h5 style={{fontWeight: 400}}>{t("Discount")}</h5>
                                                    {inputFields.map((field, index) => (
                                                        <div
                                                            key={index}
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                gap: "10px",
                                                                marginBottom: "5px",
                                                            }}
                                                        >
                                                            <div data-label="sales" className={`${styles.input10} ${styles.input}`}>
                                                                <label className={styles.main4}>{t("Count")}</label>
                                                                <div className={styles.main5}>
                                                                    <input
                                                                        autoComplete="off"
                                                                        type="text"
                                                                        disabled={index !== inputFields.length - 1 }
                                                                        className={styles.main6}
                                                                        placeholder={t("Enter count")}
                                                                        value={field.count}
                                                                        onChange={(e) => handleInputChange(index, 'count', e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div data-label="sales" className={`${styles.input10} ${styles.input}`}>
                                                                <label className={styles.main4}>{t("price")}</label>
                                                                <div className={styles.main5}>
                                                                    <input
                                                                        autoComplete="off"
                                                                        type="text"
                                                                        disabled={index !== inputFields.length - 1 }
                                                                        className={styles.main6}
                                                                        placeholder={t("Enter price")}
                                                                        value={field.price}
                                                                        onChange={(e) => handleInputChange(index, 'price', e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                {index === inputFields.length - 1 ? (
                                                                    <button className={styles.addButton} onClick={handleAddField}>+</button>
                                                                ) : (
                                                                    <button className={styles.removeButton} onClick={() => handleRemoveField(index)}>-</button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.btnBlock}>
                            <button type="submit" className={styles.btnMain} disabled={isSending}>
                                {t("Submit")}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>);
});

export default CreateProductModal;
