import React, {useEffect, useState} from 'react';
import styles from "./LoginRegister.module.scss"
import {observer} from "mobx-react";
import rootStore from "../../store/RootStore";
import ErrorLogin from "./ErrorLogin/ErrorLogin";
import SuccessLogin from "./SuccessLogin/SuccessLogin";
import {useNavigate} from "react-router-dom";
import NewPassword from "./NewPassword/NewPassword";
import {t} from "i18next";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const LoginRegister = observer(callback => {
    const {isLogin, setLogin, setRegister, openCloseL, openCloseR, setNavBarHidden} = rootStore.systemStore;
    const {login, register, errorLogin, user, errorRequest, getSuccessLogin} = rootStore.userStore;
    const {lazyInitialize} = rootStore.checkoutStore;
    const {mainInitialyze} = rootStore.cartOrderStore;
    const navigate = useNavigate();
    const [errorPassLength, setErrorPassLength] = useState('');
    const [activeTab, setActiveTab] = useState(0);
    const [openNewPass, setOpenNewPass] = useState(false);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordTry, setPasswordTry] = useState('');
    const [telegramId, setTelegramId] = useState('');

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleInputFocus = () => {
        setNavBarHidden(true);
    }

    const handleInputBlur = () => {
        setNavBarHidden(false);
    }

    const handleChange = (target) => {
        setUsername(target.target.value)
    }
    const handleChangePass = (target) => {
        setPassword(target.target.value)
    }
    const handleChangePassTry = (target) => {
        setPasswordTry(target.target.value)
    }
    const handleFake = () => {
        navigate("/discovery/me")
        // TODO: lazyInitialize, Судя по всему лишний запрос /checkout-preview/, закомментил
        // lazyInitialize()
        mainInitialyze()
    }

    const handleSubmit = async () => {
        if (password.length < 8) {
            setErrorPassLength("Your password must be 8+ chars")
            setTimeout(() => {
                setErrorPassLength('');
            }, 3000);
        } else {
            if (isLogin) {
                await login(`${username}@gmail.com`, password, handleFake)
            } else {
                if (password === passwordTry) {
                    await register(`${username}@gmail.com`, username, password, passwordTry, telegramId, handleFake)
                } else {
                    setErrorPassLength("Password mismatch!")
                    setTimeout(() => {
                        setErrorPassLength('');
                    }, 3000);
                }
            }
        }
    }
    const handleTabClick = (elem) => {
        setActiveTab(elem);
        if (elem === 0) {
            setRegister()
        } else {
            setLogin()
        }
    }
    useEffect(() => {
        if (windowWidth <= 640) {
            setRegister()
        }
        try {
            var tg = window.Telegram.WebApp;
            tg.ready()
            setTelegramId(tg.initDataUnsafe.user.id)
        } catch (e) {
        }
    }, []);

    const handleClose = (ev) => {
        if (ev.target.id === "modalka") {
            openCloseL(false);
        }
    }

    const handleOpenInitNew = (e) => {
        e.preventDefault();
        setOpenNewPass(true);
    }

    return (
        <div>
            <div id="modalka" className={styles.mainContainer} onClick={(ev) => handleClose(ev)}>
                <div className={styles.mainBlock}>
                    <aside className={styles.container}>
                        <div className={styles.closeBtnConteiner}>
                            <button className={styles.buttonBlock} data-backdrop="transparent"
                                    data-size="medium" data-variant="neutral" type="button"
                                    aria-label="Close the dialog" data-test-id="modal-close-button"
                                    onClick={() => openCloseL()}>
                                <div className={styles.fakeBtnBlock}></div>
                                <div className={styles.closeImgBtn}>
                                    <svg viewBox="0 0 24 24" className={styles.closeImg}>
                                        <path
                                            d="M2.44 19.44a1.5 1.5 0 002.121 2.121l7.262-7.26a.25.25 0 01.354 0l7.262 7.262a1.5 1.5 0 102.122-2.12l-7.26-7.266a.25.25 0 010-.354l7.262-7.262a1.5 1.5 0 00-2.124-2.12L12.177 9.7a.25.25 0 01-.354 0L4.561 2.44A1.5 1.5 0 002.44 4.56L9.7 11.823a.25.25 0 010 .354L2.44 19.44z"></path>
                                    </svg>
                                </div>
                            </button>
                        </div>
                        <div className={styles.mainBoxBlock}>
                            <div className={styles.tabsContainer}>
                                <div className={styles.tabs}>
                                    {/* Кнопки табов */}
                                    <button
                                        className={`${styles.tabButton} ${activeTab === 0 ? styles.active : ''}`}
                                        data-testid="shopping-cart-tab"
                                        onClick={() => handleTabClick(0)}
                                    >
                                        {t("Log in")}
                                    </button>
                                    <button
                                        className={`${styles.tabButton} ${activeTab === 1 ? styles.active : ''}`}
                                        data-testid="order-again-tab"
                                        onClick={() => handleTabClick(1)}
                                    >
                                        {t("Register")}
                                    </button>
                                </div>
                                {/* Контент для каждого таба */}
                            </div>
                        </div>
                        <div className={styles.block}>
                            <header className={styles.blockHeader}></header>
                            <div className={styles.downBlock}>
                                <div>
                                    <div className={styles.parentBlock}>
                                        <div className={styles.contentBlock}>
                                            <div className={styles.upB}>
                                                <h2 className={styles.title}>Create an account or log in</h2>
                                                <div
                                                    className={styles.description}>{t("Log in below or create a new account.")}
                                                </div>
                                            </div>
                                            <div className={styles.upD}>
                                                <form 
                                                    className={styles.formaBlock}
                                                    onKeyDown={(event) => {
                                                        if (event.key === "Enter") {
                                                            event.preventDefault();
                                                            handleSubmit(event);
                                                        }
                                                    }}
                                                >
                                                    <div className={styles.fieldEmail}>
                                                        <div className={styles.fieldCont}>
                                                            <input type="username"
                                                                   name="username" id="method-select-username"
                                                                   placeholder=" "
                                                                   autoComplete="off" spellCheck="false"
                                                                   className={styles.fieldInp}
                                                                   value={username}
                                                                   onFocus={handleInputFocus}
                                                                   onBlur={handleInputBlur}
                                                                   onChange={handleChange}/>

                                                            {
                                                                username ? "" : (
                                                                    <label
                                                                        className={styles.lable}>{t("userName")}</label>)
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className={styles.fieldEmail}>
                                                        <div className={styles.fieldCont}>
                                                            <input type="password"
                                                                   name="password" id="method-select-password"
                                                                   placeholder=" "
                                                                   autoComplete="off" spellCheck="false"
                                                                   className={styles.fieldInp}
                                                                   value={password}
                                                                   onFocus={handleInputFocus}
                                                                   onBlur={handleInputBlur}
                                                                   onChange={handleChangePass}/>
                                                            {
                                                                password ? "" : (
                                                                    <label
                                                                        className={styles.lable}>{t("password")}</label>)
                                                            }
                                                        </div>
                                                    </div>
                                                    {
                                                        !isLogin ?
                                                            (
                                                                <div className={styles.fieldEmail}>
                                                                    <div className={styles.fieldCont}>
                                                                        <input type="password"
                                                                               name="passwordTry"
                                                                               id="method-select-password"
                                                                               placeholder=" "
                                                                               autoComplete="off" spellCheck="false"
                                                                               className={styles.fieldInp}
                                                                               value={passwordTry}
                                                                               onFocus={handleInputFocus}
                                                                               onBlur={handleInputBlur}
                                                                               onChange={handleChangePassTry}/>
                                                                        {
                                                                            passwordTry ? "" : (
                                                                                <label className={styles.lable}>
                                                                                    {t("Password try")}
                                                                                </label>)
                                                                        }
                                                                    </div>
                                                                </div>
                                                            ) : ""
                                                    }
                                                    {
                                                        errorPassLength ? (
                                                            <ErrorLogin text={errorPassLength}/>) : ""
                                                    }
                                                    {
                                                        errorRequest ? (<ErrorLogin text={errorRequest}/>) : ""
                                                    }
                                                    {
                                                        getSuccessLogin ? (<SuccessLogin text={"Success!"}/>) : ""
                                                    }
                                                    <button 
                                                        type="button"
                                                        className={styles.initPass}
                                                        onClick={(e) => handleOpenInitNew(e)}>{t("Forgot password?")}</button>
                                                    <button aria-disabled="false" type="button"
                                                            onClick={handleSubmit}
                                                            className={styles.submitBtn}>
                                                        <div className={styles.fakeSub}></div>
                                                        <div className={styles.fakeSubTwo}></div>
                                                        <div
                                                            className={styles.textSub}>{isLogin ? t("Log in") : t("Register")}</div>
                                                    </button>
                                                </form>
                                                <span className={styles.info}>{t("Please visit Privacy Statement")}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </aside>
                </div>
            </div>
            {openNewPass && (<NewPassword setState={setOpenNewPass}/>)}
        </div>
    );
});

export default LoginRegister;