import React from 'react';
import styles from "./ProductRow.module.scss"
import {t} from "i18next";

const ProductRow = ({product,currency}) => {
    return (
        <div>
            <div data-test-id="CartItem" data-value="65f446dbfe4b1adaf8302374" className={styles.mainContainer}>
                <button className={styles.mainBtn}>
                    <div className={styles.mainBtnBlock}>
                        <div className={styles.mainBtnCont}>
                            <img loading="lazy" decoding="async"
                                src={`${process.env.REACT_APP_MEDIA_URL}${product.product.product_images.length ? product?.product?.product_images[0]?.ImageUrl : ""}`}
                                 alt="" draggable="true"
                                 className={styles.mainImg}
                            />
                            <noscript></noscript>
                        </div>
                    </div>
                    <div data-test-id="cartItemInfo-65f446dbfe4b1adaf8302374-0" className={styles.downContainer}>
                        <span data-test-id="CartItemName"
                              className={styles.downContainerText}>{product.product.ProductName}</span>
                        <div className={styles.downDownContainer}>
                            <span
                                className={styles.downDownContainerText}>GEL&nbsp;{product?.total_price_converted || product.product.Price * product.quantity} {currency||"$"} </span>
                            <ul className={styles.downDownList}>
                                <li style={{marginBottom: "0"}}>
                                    <div className={styles.downDownItem}>
                                        <span className={styles.downDownText}>18+</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </button>
                <div className={styles.downContent}>
                    <div className={styles.downContentBlock} width="normal" tabIndex="-1">
                        <label htmlFor=":rk:" className={styles.downContentLabel}>{t("Count 1")}</label>
                        <input id=":rk:" type="number"
                               min="0" step="1"
                               data-test-id="CartItemStepper"
                               aria-hidden="true"
                               className={styles.downContentInput}
                               value="1"/>
                        <div width="normal" className={styles.downContentDownBlock}>
                            <div className={styles.boxShadow}>
                                <div type="button" tabIndex="-1" width="normal" aria-hidden="true"
                                        className={styles.secondBtn}
                                >
                                    <div width="normal" className={styles.secondBtnBlock}>
                                        <div width="normal" className={styles.inSecondBtnBlock}>{product.quantity}</div>
                                    </div>
                                    <div width="normal" className={styles.secondUpBtnBlock}>
                                        <div>
                                            <span data-test-id="CartItemStepperValue"
                                                  aria-hidden="true"
                                                  className={styles.secondDownBtnText}>{product.quantity}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductRow;