import api from "../api";

export const unVerifitateModerator = async (name) => {
    if (name) {
        try {
            const apiResponse = await api.post(`/owner_site/moderators/${name}/remove/`).then(response => response);
            return apiResponse;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }
}