import * as React from "react";

export function IconCaretUpOutline(props) {
    return (
        <svg
            viewBox="0 0 512 512"
            fill="grey"
            height="2em"
            width="2em"
            {...props}
        >
            <path d="M414 321.94L274.22 158.82a24 24 0 00-36.44 0L98 321.94c-13.34 15.57-2.28 39.62 18.22 39.62h279.6c20.5 0 31.56-24.05 18.18-39.62z" />
        </svg>
    );
}
