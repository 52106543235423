import api from "../api";

export const addCategory = async (data,id) => {
    if(data) {
    try {
        const parentCategoryID = data.ParentCategoryID==="null" ? null: data.ParentCategoryID ;
        const formData = new FormData();
        formData.append('AltText', data.AltText);
        formData.append('ImageUrl', data.image);
        if (parentCategoryID){
            formData.append('ParentCategoryID', parentCategoryID);
        }
        formData.append('CategoryName', data.CategoryName);
        await api.post(`/admin_seller_shop/get-my-shop/${id}/categories/create/`,formData);
    } catch (error) {
        console.error("An error occurred:", error);
        throw error;
    }
    }
}