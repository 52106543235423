import React, {useState, useRef} from 'react';
import styles from './ModalAddMessage.module.scss';
import {observer} from "mobx-react";
import {useNavigate} from "react-router-dom";
import {t} from "i18next";
import TextField from "@mui/material/TextField";

const ModalAddMessage = ({isOpen, setIsOpen}) => {
    const navigate = useNavigate();
    const modalRef = useRef(null);
    const [mess, setMess] = useState("")

    const handleCloseModal = () => {
        setIsOpen(false);
    };

    const handleClose = (ev) => {
        if (ev.target.id === "modalka") {
            setIsOpen(false);
        }
    }
    const isAccPage = window.location.pathname.includes('account-mobile')

    return (
        <div className={styles.mainContBlock}>
            {isOpen && (
                <div id="modalka" className={`${styles["modal-overlay"]}`} onClick={(ev) => handleClose(ev)}>
                    <div className={`${styles["modal-content"]} ${isAccPage && styles.bigModal}`} ref={modalRef}>
                        <div className={styles.rowBlockCont}>
                            <div className={styles.closeBtnConteiner}>
                                <button className={styles.buttonBlock} data-backdrop="transparent"
                                        data-size="medium" data-variant="neutral" type="button"
                                        aria-label="Close the dialog" data-test-id="modal-close-button"
                                        onClick={handleCloseModal}>Done
                                </button>
                            </div>
                        </div>
                        <div className={styles.conteinerAddress}>
                            <span className={styles.addTitleMess}>Add message</span>
                            <span className={styles.middleBlock}>Please note that your message to the venue may also be seen by the courier partner delivering your order</span>
                            <TextField id="filled-basic" label={`Write your message (allergies, dietary restriction... ${mess.length}/400`} variant="filled" className={styles.downBlock} value={mess} onChange={(e) => setMess(e.target.value)}/>
                        </div>
                    </div>
                </div>
            )}
            <div onClick={() => setIsOpen(true)}></div>
        </div>
    );
};

export default observer(ModalAddMessage);
