import React, {useEffect, useState} from 'react';
import styles from './NotFound.module.scss'

const NotFount = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <div className={styles.blockNotFound}>
                {
                    windowWidth < 700
                        ? (<div className={styles.imageMobile}/>)
                        : (<div className={styles.image}/>)
                }
            </div>
        </>

    );
};

export default NotFount;