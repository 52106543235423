import React from 'react';
import {observer} from "mobx-react";
import rootStore from "../store/RootStore";
import {useNavigate} from "react-router-dom";

const WithAuthUser = ({children}) => {
    const authUser = rootStore.userStore.user.authorized
    const navigate = useNavigate()
    // if (!authUser){
    //     navigate("/")
    //     alert("You not auth")
    // }
    return (
        <>
            {children}
        </>
    );
};

export default observer(WithAuthUser);