import React, { useEffect } from 'react';

import { t } from "i18next";
import { observer } from "mobx-react";

import styles from "./ActualHistory.module.scss"

import rootStore from "../../../store/RootStore";
import HistoryCart from "../../../components/HistoryCart/HistoryCart";
import DiscoveryParent from "../../../components/DiscoveryParent/DiscoveryParent";

const ActualHistory =() => {
    const {getActualOrders, loadActualOrders} = rootStore.curierStore;
    // TODO: Добавить запрос новых orders и вливать их в текущие на стороне фронта.
    useEffect(() => {
        loadActualOrders();
    }, []);

    const arr = [...getActualOrders];
    const sortedArr = arr.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    return (
        <DiscoveryParent>
            <div className={styles.mainBlock}>
                <h1 className={styles.titleMenu}>{t("Actual orders")}</h1>
                <div className={styles.lvl1}>
                    {
                        sortedArr?.length ? sortedArr.map((el, index) => (
                            <HistoryCart data={el} index={index} key={el.id}/>
                        )) :
                            (
                                <div className={styles.blockMainNotFound}>
                                    <h1 className={styles.titleNotFound}>Orders not found</h1>
                                    <div className={styles.notFound}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="70%" height="70%"
                                             viewBox="0 0 1024 1024" className="icon" version="1.1">
                                            <path
                                                d="M512.002 512.002m-491.986 0a491.986 491.986 0 1 0 983.972 0 491.986 491.986 0 1 0-983.972 0Z"
                                                fill="#FDDF6D"/>
                                            <path
                                                d="M617.43 931.354c-271.716 0-491.984-220.268-491.984-491.984 0-145.168 62.886-275.632 162.888-365.684C129.058 155.124 20.018 320.826 20.018 511.998c0 271.716 220.268 491.984 491.984 491.984 126.548 0 241.924-47.794 329.096-126.298-67.104 34.312-143.126 53.67-223.668 53.67z"
                                                fill="#FCC56B"/>
                                            <path
                                                d="M517.966 1003.908V464.212c-18.406-29.424-50.99-46.864-85.222-45.466-49.322 2.012-75.232 42.026-77.376 45.466v514.298M774.198 418.746c-49.322 2.012-75.232 42.026-77.376 45.466v503.838c61.322-24.876 116.502-61.736 162.596-107.71V464.212c-18.402-29.424-50.986-46.862-85.22-45.466z"
                                                fill="#3FA9F5"/>
                                            <path
                                                d="M885.912 501.848a20.006 20.006 0 0 1-17.504-10.28c-18.174-32.624-52.634-52.886-89.928-52.886-36.346 0-71.204 20.476-90.966 53.44-5.682 9.478-17.978 12.556-27.458 6.874-9.482-5.684-12.558-17.976-6.874-27.458 26.956-44.958 74.966-72.888 125.3-72.888 51.806 0 99.662 28.14 124.896 73.44 5.38 9.656 1.914 21.846-7.744 27.222a19.926 19.926 0 0 1-9.722 2.536zM538.346 501.848a20 20 0 0 1-17.504-10.28c-18.17-32.624-52.63-52.886-89.928-52.886-36.346 0-71.204 20.476-90.966 53.44-5.684 9.478-17.978 12.556-27.458 6.874-9.482-5.684-12.558-17.976-6.874-27.458 26.956-44.958 74.966-72.888 125.3-72.888 51.806 0 99.664 28.14 124.896 73.44 5.38 9.656 1.91 21.846-7.744 27.222a19.92 19.92 0 0 1-9.722 2.536z"
                                                fill="#7F184C"/>
                                            <path
                                                d="M607.404 742.544m-89.428 0a89.428 89.428 0 1 0 178.856 0 89.428 89.428 0 1 0-178.856 0Z"
                                                fill="#7F184C"/>
                                            <path
                                                d="M660.06 498.998c9.478 5.678 21.774 2.604 27.458-6.874 19.762-32.966 54.618-53.44 90.966-53.44 37.296 0 71.754 20.266 89.928 52.886a20.004 20.004 0 0 0 27.226 7.748c9.658-5.38 13.124-17.568 7.744-27.222-25.234-45.3-73.09-73.44-124.896-73.44-50.332 0-98.346 27.93-125.3 72.888-5.686 9.478-2.608 21.77 6.874 27.454zM305.618 471.54c-5.684 9.482-2.608 21.774 6.874 27.458 9.482 5.678 21.774 2.604 27.458-6.874 19.762-32.966 54.618-53.44 90.966-53.44 37.296 0 71.756 20.266 89.928 52.886a20 20 0 0 0 27.226 7.748c9.656-5.38 13.124-17.568 7.744-27.222-25.234-45.3-73.09-73.44-124.896-73.44-50.336-0.002-98.346 27.928-125.3 72.884zM537.98 552.028c0-11.056-8.962-20.014-20.014-20.014-11.054 0-20.014 8.958-20.014 20.014v317.566c0 11.056 8.962 20.014 20.014 20.014 11.054 0 20.014-8.958 20.014-20.014v-42.512c18.902 15.548 43.084 24.896 69.414 24.896 26.328 0 50.512-9.348 69.414-24.896v4.88c0 11.056 8.962 20.014 20.014 20.014s20.014-8.958 20.014-20.014V552.028c0-11.056-8.962-20.014-20.014-20.014s-20.014 8.958-20.014 20.014v105.958c-18.902-15.548-43.084-24.896-69.414-24.896-26.328 0-50.512 9.348-69.414 24.896v-105.958z m69.414 121.092c38.276 0 69.414 31.14 69.414 69.416 0 38.276-31.138 69.414-69.414 69.414s-69.414-31.138-69.414-69.414c0-38.276 31.14-69.416 69.414-69.416z"
                                                fill=""/>
                                            <path
                                                d="M875.582 872.116C967.276 779.546 1024 652.28 1024 512.002c0-206.234-122.744-391.41-312.698-471.754-10.186-4.302-21.926 0.46-26.232 10.634-4.304 10.18 0.456 21.926 10.638 26.23C870.82 151.18 983.972 321.884 983.972 512c0 111.928-39.188 214.862-104.532 295.854V552.03c0-11.056-8.962-20.014-20.014-20.014-11.054 0-20.014 8.958-20.014 20.014v299.574c-84.928 81.908-200.378 132.368-327.404 132.368-47.488 0-93.348-7.08-136.614-20.188V552.028c0-11.056-8.962-20.014-20.014-20.014-11.054 0-20.014 8.958-20.014 20.014v397.614C162.372 879.568 40.03 709.816 40.03 511.998 40.03 251.754 251.756 40.028 512 40.028c11.054 0 20.014-8.958 20.014-20.014S523.052 0 511.998 0C229.682 0 0 229.68 0 511.998c0 224.724 145.53 416.088 347.294 484.818a19.832 19.832 0 0 0 3.6 1.19c50.68 16.844 104.842 25.992 161.104 25.992 139.772 0 266.616-56.318 359.1-147.428a20.114 20.114 0 0 0 4.484-4.454z"
                                                fill=""/>
                                            <path
                                                d="M696.83 898.71m-20.014 0a20.014 20.014 0 1 0 40.028 0 20.014 20.014 0 1 0-40.028 0Z"
                                                fill=""/>
                                            <path
                                                d="M517.952 935.084m-20.014 0a20.014 20.014 0 1 0 40.028 0 20.014 20.014 0 1 0-40.028 0Z"
                                                fill=""/>
                                            <path
                                                d="M631.182 35.768m-20.014 0a20.014 20.014 0 1 0 40.028 0 20.014 20.014 0 1 0-40.028 0Z"
                                                fill=""/>
                                        </svg>
                                    </div>
                                </div>
                            )
                    }
                </div>
            </div>
        </DiscoveryParent>
    );
};

export default observer(ActualHistory);