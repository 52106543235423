import React from 'react';
import styles from './OperationInfoModal.module.scss';

import {observer} from "mobx-react";
import rootStore from "../../../store/RootStore";
import {t} from "i18next";


const OperationInfoModal = ({setState}) => {
    const { getCurrentOperation } = rootStore.statisticStore;
    const data = getCurrentOperation?.created_at ? getCurrentOperation?.created_at.split('T') : "";

    const items = getCurrentOperation?.items ? JSON.parse(getCurrentOperation?.items) : [];

    return (
        <div className={styles.modalContainer}>
            <div className={styles.modalContainerBlock}>
                <div className={styles.productsCategoryModal}>
                    <div className={styles.productsCategoryTextBlock}>
                        <span>{t("Operation number")}: </span>
                        <span className={styles.productsCategoryTextImg} onClick={() => setState(false)}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                            <path fill="#827789"
                                  d="M5.30963553,18.6903645 C4.8990815,18.2798104 4.90303703,17.6102153 5.30742781,17.2058245 L10.5132523,12 L5.30742781,6.79417547 C4.89809307,6.38484073 4.89621919,5.72305186 5.30963553,5.30963553 C5.72018956,4.8990815 6.38978469,4.90303703 6.79417547,5.30742781 L12,10.5132523 L17.2058245,5.30742781 C17.6151593,4.89809307 18.2769481,4.89621919 18.6903645,5.30963553 C19.1009185,5.72018956 19.096963,6.38978469 18.6925722,6.79417547 L13.4867477,12 L18.6925722,17.2058245 C19.1019069,17.6151593 19.1037808,18.2769481 18.6903645,18.6903645 C18.2798104,19.1009185 17.6102153,19.096963 17.2058245,18.6925722 L12,13.4867477 L6.79417547,18.6925722 C6.38484073,19.1019069 5.72305186,19.1037808 5.30963553,18.6903645 Z"></path>
                          </svg>
                        </span>
                    </div>
                    <div className={styles.infoBlock}>
                        <div className={styles.nameBlock}>
                            <h2 className={styles.infoTitle}>{t("Information of operation")}:</h2>
                            <div className={styles.blockInfoOp}>
                                <span className={styles.blockInfoOpTop}>{t("Amount of payment")}:</span>
                                <span className={styles.blockInfoOpDown}>{getCurrentOperation.total_price}$</span>
                            </div>
                            <div className={styles.blockInfoOp}>
                                <span className={styles.blockInfoOpTop}>{t("Discount/surcharge amount")}:</span>
                                <span className={styles.blockInfoOpDown}>0$</span>
                            </div>
                            <div className={styles.blockInfoOp}>
                                <span className={styles.blockInfoOpTop}>{t("Date and time")}:</span>
                                <span className={styles.blockInfoOpDown}>{data ? `${data[0]} ${data[1].split('.')[0]}` : ''}</span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.productsCategoryTextBlock2}>
                        <span>{t("Positions in checks")}: </span>
                        <div className={styles.tableHeaderCont}>
                            <span className={styles.nameColumnTable1}>{t("Product name")}</span>
                            <span className={styles.nameColumnTable2}>{t("Count")}</span>
                            <span className={styles.nameColumnTable3}>{t("Cash")}</span>
                        </div>
                    </div>
                    <div className={styles.tableBody}>
                        {
                            items.map((item) => (
                                <div className={styles.tableHeaderContMain}>
                                    <span className={styles.nameColumnTable1}>{item.product.ProductName}</span>
                                    <span className={styles.nameColumnTable2}>{item.product.quantity}</span>
                                    <span className={styles.nameColumnTable34}>{item.price}</span>
                                </div>
                            ))
                        }
                    </div>
                    <div className={styles.mainFooterCash}>
                        <span className={styles.mainFooterCashLeft}>{t("Total")}:</span>
                        <span className={styles.mainFooterCashRight}>{getCurrentOperation.total_price}$</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(OperationInfoModal);
