import React from 'react';
import styles from "./CategoryBaner.module.scss"
import BanerBotton from "../BanerBotton/BanerBotton";

const CategoryBaner = () => {

    return (
        <div className={styles.mainContainer}>
            <div className={styles.mainBlock}>
                <div>
                    <div className={styles.upConteiner}>
                        <h2 className={styles.upConteinerTitle}>Discounts</h2>
                    </div>
                    <div className={styles.downConteiner}>
                        <BanerBotton text={"-30% on Selected Items"}/>
                        <BanerBotton text={"New users enjoy 14 days of no delivery fees!"}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CategoryBaner;