import React from "react";

function IconVerification(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            {...props}
        >
            <path
                d="M2.727 3.333c-.753 0-1.363.597-1.363 1.334v10.666c0 .737.61 1.334 1.363 1.334h14.546c.753 0 1.363-.597 1.363-1.334V4.667c0-.737-.61-1.334-1.363-1.334H2.727zM17.273 2C18.779 2 20 3.194 20 4.667v10.666C20 16.806 18.779 18 17.273 18H2.727C1.221 18 0 16.806 0 15.333V4.667C0 3.194 1.221 2 2.727 2h14.546zM8.167 13.133H4a.674.674 0 00-.682.667c0 .368.305.667.682.667h4.167a.674.674 0 00.681-.667.674.674 0 00-.681-.667zm5.583-7.8c-1.524 0-2.765 1.191-2.765 2.667 0 .773.34 1.468.884 1.955-1.03.608-1.717 1.703-1.717 2.954 0 .357.056.708.165 1.043.115.35.499.544.857.432a.664.664 0 00.442-.838 2.043 2.043 0 01-.1-.637c0-1.175.997-2.133 2.234-2.133s2.235.958 2.235 2.133c0 .173-.021.342-.063.506a.666.666 0 00.497.808.683.683 0 00.826-.486c.069-.27.103-.547.103-.828 0-1.251-.687-2.346-1.717-2.956.544-.485.884-1.18.884-1.953 0-1.476-1.24-2.667-2.765-2.667zm-5.583 4.3H4a.674.674 0 00-.682.667c0 .368.305.667.682.667h4.167a.674.674 0 00.681-.667.674.674 0 00-.681-.667zm5.583-2.966c.777 0 1.402.6 1.402 1.333 0 .734-.625 1.333-1.402 1.333-.777 0-1.402-.6-1.402-1.333 0-.734.625-1.333 1.402-1.333zm-5.583-.534H4a.674.674 0 00-.682.667c0 .368.305.667.682.667h4.167a.674.674 0 00.681-.667.674.674 0 00-.681-.667z"
            ></path>
        </svg>
    );
}

export default IconVerification;
