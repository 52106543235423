import React, {useEffect, useRef, useState} from 'react';
import styles from "./MobileCategoryHeader.module.scss"
import MobileSearchHeader from "../MobileSearchHeader/MobileSearchHeader";
import CategoryButton from "../CategoryButton/CategoryButton";


const MobileCategoryHeader = ({data}) => {
    const [isStickyMobile, setIsStickyMobile] = useState(false);
    const stickyRefMobile = useRef(null);
    const conteinerBtn = useRef(null);

    useEffect(() => {
        const handleScrollCategoryMobile = () => {
            const { top } = stickyRefMobile.current ? stickyRefMobile.current.getBoundingClientRect() : null;
            setIsStickyMobile(top === 0);
        };
        document.addEventListener('scroll', handleScrollCategoryMobile, { passive: true });
    }, []);

    return (
        <div className={styles.mainConteiner} ref={stickyRefMobile}>
            <div className={`${styles.mainBlock} ${isStickyMobile ? styles.shadow :""}`}>
                <div className={styles.conteiner}>
                    <MobileSearchHeader/>
                </div>
                {
                    isStickyMobile ?
                        (
                            <div className={`${styles.categorysConteiner} ${isStickyMobile ? styles.shadow : ""}`}>
                                <div className={styles.categorysBlock} ref={conteinerBtn}>
                                    {
                                        data.map((item, key) => (
                                            <CategoryButton
                                                headerRef={stickyRefMobile}
                                                parentRef={conteinerBtn}
                                                item={item}
                                                listEl={[]}
                                                isMobile={true}
                                                key={key}/>
                                        ))
                                    }
                                </div>
                            </div>
                        ) : ""
                }
            </div>
        </div>
    );
};

export default MobileCategoryHeader;