import React, { useEffect, useState } from 'react';

import { t } from "i18next";
import { observer } from "mobx-react";
import { Link, useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import { CircularProgress, FormControlLabel, Switch } from "@mui/material";

import styles from "./PersonalMain.module.scss"

import rootStore from "../../../store/RootStore";
import { getUser } from "../../../helpers";
import { useTheme } from "../../../helpers/ThemeContext";
import WithAuthUser from "../../../helpers/WithAuthUser";
import LanguageModal from "../../../components/LanguageModal/LanguageModal";
import { getCurrencySymbol } from "../../../helpers/getCurrency";


const PersonalMain = () => {
    let navigate = useNavigate();
    const { themeMode, toggleTheme } = useTheme();

    const [openLocation, setOpenLocation] = useState(false);

    const { getProfile, userData, changePhote, setLogOut, isSuperAdmin, isCurier, getIsLoader, isOwner, isSupport } = rootStore.userStore;

    const symbol = getCurrencySymbol();

    useEffect(() => {
        getProfile();
    }, []);

    const avatar = JSON.parse(getUser())?.profile.photo;

    const handleChangeAvatar = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData();
        formData.append('photo', file);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };

        await changePhote(formData, config)
    }

    const handleGoToUser = () => {
        navigate('account-mobile')
    }
    const verificationStatus = userData?.profile.verification_status?.status_step;
    const handleGoToVerification = () => {
        if (verificationStatus === 0) {
            navigate('verification')
        }

        if (verificationStatus === 1) {
            navigate('verification2')
        }

        if (verificationStatus === 2 || verificationStatus === 3 || verificationStatus === 4) {
            navigate('verification3')
        }
    }

    const handleGoToTokens = () => {
        navigate('payments')
    }

    const handleGoToFriends = () => {
        navigate('earn-credits')
    }

    const handleGoToPromo = () => {
        navigate('redeem-code')
    }

    const handleGoToCredits = () => {
        navigate('credits-tokens')
    }

    const handleGoToHistory = () => {
        navigate('order-history')
    }
    const handleGoToBalancePage = () => {
        navigate('balance')
    }
    const handleGoToAddress = () => {
        navigate('my-addreses')
    }

    const hangleLogOut = () => {
        setLogOut()
    }
    const [checked, setChecked] = React.useState(true);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };
    const handleClickLocation = () => {
        setOpenLocation(true)
    }

    function ucFirst(str) {
        if (str === 'superadminshop') {
            return 'Moderator';
        }
        if (!str) return str;

        return str[0].toUpperCase() + str.slice(1);
    }

    return (
        <WithAuthUser>
            {
                getIsLoader ?
                    (
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100vh"}}>
                            <Box sx={{display: 'flex'}}>
                                <CircularProgress/>
                            </Box>
                        </div>
                    ) :
                    (
                        <div className={styles.profileCard}>
                            <h2 className={styles.mainTitle}>{userData.profile.username ? `${t("hi")} ${userData.profile.username}!` : t("Hi user")}</h2>
                            <div className={styles.container}>
                                <div style={{opacity: 1}}>
                                    <div className={styles.mainBlock}>
                                        <div className={styles.imageContainer}>
                                            <div className={styles.imageWrapper}>
                                                <div
                                                    className={`${styles.profileImage} ${userData.profile.is_verified ? styles.verifImg : styles.notVerifImg}`}>
                                                    <input type="file" name="file" id="file"
                                                           className={styles.uploadInput}
                                                           accept="image/*" value=""
                                                           onChange={(e) => handleChangeAvatar(e)}/>
                                                    <img
                                                        src={`${avatar ? process.env.REACT_APP_MEDIA_URL + avatar : "https://consumer-user.wolt.com/s/_wt8B-lIufWCfSysvGahB6Q4vravvbuUB8kWumSIjK0/CH/g"}`}
                                                        alt="Profile" className={styles.avatar}/>
                                                </div>
                                            </div>
                                            <div className={styles.userInfo} onClick={() => handleGoToUser()}>
                                                <div className={styles.userDetails}>
                                                    <div className={styles.detail}>
                                                        <p className={styles.upDescription}>{userData.profile.role ? ucFirst(userData.profile.role) : t("User")}</p>
                                                    </div>
                                                    <div className={styles.detail}>
                                                        <h4 className={styles.downTitle}>{userData.profile.is_verified ? t("Verified") : t("You are not verified")}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={styles.IconNextBlock}>
                                                <svg viewBox="0 0 20 20" className={styles.IconNext}
                                                     fill={themeMode === 'dark' ? "white" : "black"}>
                                                    <path
                                                        d="M12.1768 13.409C12.0791 13.5066 11.9209 13.5066 11.8232 13.409L6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L12.1768 13.409Z"></path>
                                                </svg>
                                            </div>
                                        </div>
                                        {
                                            !userData.profile.is_verified &&
                                            (
                                                <div className={`${styles.cardContainerBlock}`}
                                                     onClick={() => userData.profile.is_verified ? console.log("verif") : handleGoToVerification()}>
                                                    <span className={styles.cardText}>{t("Verification")}</span>
                                                    <div className={styles.tokensMain}>
                                                        <div className={styles.IconNextBlock}>
                                                            {
                                                                userData.profile.is_verified ? ""
                                                                    :
                                                                    (
                                                                        <svg viewBox="0 0 20 20"
                                                                             className={styles.IconNext}
                                                                             fill={themeMode === 'dark' ? "white" : "black"}>
                                                                            <path
                                                                                d="M12.1768 13.409C12.0791 13.5066 11.9209 13.5066 11.8232 13.409L6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L12.1768 13.409Z"></path>
                                                                        </svg>
                                                                    )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        <div className={styles.cardContainerBlock}>
                                            <span className={styles.cardText}>{t("Theme")}</span>
                                            <div className={styles.tokensMain}>
                                                <div className={styles.moneyCountBlock}>
                                                    <FormControlLabel
                                                        control={<Switch sx={{right: '-25px'}}
                                                                         checked={themeMode === 'dark'}
                                                                         onChange={toggleTheme}/>}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                        {/*<div className={styles.cardContainerBlock}>*/}
                                        {/*    <span className={styles.cardText}>Tokens</span>*/}
                                        {/*    <div className={styles.tokensMain}>*/}
                                        {/*        <div className={styles.moneyCountBlock}>*/}
                                        {/*            <span className={styles.countMoney}>{userData.profile.wolt_tokens}</span>*/}
                                        {/*            <svg viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg"*/}
                                        {/*                 className={styles.imgWolt}>*/}
                                        {/*                <g fill="none" fill-rule="evenodd">*/}
                                        {/*                    <path fill="none" d="M-6-6h72v72H-6z"></path>*/}
                                        {/*                    <path*/}
                                        {/*                        d="M30 0C13.458 0 0 13.458 0 30c0 16.545 13.458 30 30 30s30-13.455 30-30C60 13.458 46.542 0 30 0"*/}
                                        {/*                        fill="#FBBE13"></path>*/}
                                        {/*                    <path*/}
                                        {/*                        d="M30 57C15.111 57 3 44.889 3 30 3 15.114 15.111 3 30 3s27 12.114 27 27c0 14.889-12.111 27-27 27zm0-3C16.765 54 6 43.235 6 30 6 16.768 16.765 6 30 6s24 10.768 24 24c0 13.235-10.765 24-24 24z"*/}
                                        {/*                        fill="#F78E1F"></path>*/}
                                        {/*                    <path*/}
                                        {/*                        d="M41.056 21.108a1.497 1.497 0 00-1.947.834L34.498 33.46 29.89 21.942a1.496 1.496 0 00-1.947-.834 1.495 1.495 0 00-.834 1.947l1.275 3.195-2.886 7.212-4.608-11.52a1.498 1.498 0 00-2.781 1.113l6 15 .009.01c.099.242.273.443.489.605.054.042.105.075.162.111.063.03.105.084.174.111.18.072.372.108.555.108.186 0 .375-.036.558-.108.066-.027.11-.08.174-.11.057-.037.108-.07.162-.112a1.45 1.45 0 00.489-.606l.009-.009 3.108-7.767 3.11 7.767a1.49 1.49 0 001.39.945c.615 0 1.167-.372 1.392-.945l6-15a1.495 1.495 0 00-.834-1.947"*/}
                                        {/*                        fill="#F78E1F"></path>*/}
                                        {/*                </g>*/}
                                        {/*            </svg>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div className={styles.cardContainerBlock}
                                             onClick={() => handleGoToBalancePage()}>
                                            <span className={styles.cardText}>{t("Balance")}</span>
                                            <div className={styles.tokensMain}>
                                                <div className={styles.moneyCountBlock}>
                                                    <span
                                                        className={styles.countMoney}>{userData.profile.balance} {symbol}</span>
                                                </div>
                                                <div className={styles.IconNextBlock}>
                                                    <svg viewBox="0 0 20 20" className={styles.IconNext}
                                                         fill={themeMode === 'dark' ? "white" : "black"}>
                                                        <path
                                                            d="M12.1768 13.409C12.0791 13.5066 11.9209 13.5066 11.8232 13.409L6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L12.1768 13.409Z"></path>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.downConteiner}>
                                <div className={styles.infoBlock}>
                                    <h2>{t("Quick links")}</h2>
                                    {isOwner &&
                                        <Link className={styles.infoRowLvl} style={{color: 'var(--text-color)'}}
                                              to="/owner">
                                            <span className={styles.cardText}>{t("Owner page")}</span>
                                            <div className={styles.tokensMain}>
                                                <div className={styles.IconNextBlock}>
                                                    <svg viewBox="0 0 20 20" className={styles.IconNext}
                                                         fill={themeMode === 'dark' ? "white" : "black"}>
                                                        <path
                                                            d="M12.1768 13.409C12.0791 13.5066 11.9209 13.5066 11.8232 13.409L6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L12.1768 13.409Z"></path>
                                                    </svg>
                                                </div>
                                            </div>
                                        </Link>}
                                    {isSuperAdmin &&
                                        <Link className={styles.infoRowLvl} style={{color: 'var(--text-color)'}}
                                              to="/moderator">
                                            <span className={styles.cardText}>{t("Moderator page")}</span>
                                            <div className={styles.tokensMain}>
                                                <div className={styles.IconNextBlock}>
                                                    <svg viewBox="0 0 20 20" className={styles.IconNext}
                                                         fill={themeMode === 'dark' ? "white" : "black"}>
                                                        <path
                                                            d="M12.1768 13.409C12.0791 13.5066 11.9209 13.5066 11.8232 13.409L6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L12.1768 13.409Z"></path>
                                                    </svg>
                                                </div>
                                            </div>
                                        </Link>}
                                    {isCurier &&
                                        <Link className={styles.infoRowLvl} style={{color: 'var(--text-color)'}}
                                              to="/curier-panel/actual">
                                            <span className={styles.cardText}>{t("Curier page")}</span>
                                            <div className={styles.tokensMain}>
                                                <div className={styles.IconNextBlock}>
                                                    <svg viewBox="0 0 20 20" className={styles.IconNext}
                                                         fill={themeMode === 'dark' ? "white" : "black"}>
                                                        <path
                                                            d="M12.1768 13.409C12.0791 13.5066 11.9209 13.5066 11.8232 13.409L6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L12.1768 13.409Z"></path>
                                                    </svg>
                                                </div>
                                            </div>
                                        </Link>}
                                    {isSupport &&
                                        <Link className={styles.infoRowLvl} style={{color: 'var(--text-color)'}}
                                              to="/support-panel/new">
                                            <span className={styles.cardText}>{t("Support")}</span>
                                            <div className={styles.tokensMain}>
                                                <div className={styles.IconNextBlock}>
                                                    <svg viewBox="0 0 20 20" className={styles.IconNext}
                                                         fill={themeMode === 'dark' ? "white" : "black"}>
                                                        <path
                                                            d="M12.1768 13.409C12.0791 13.5066 11.9209 13.5066 11.8232 13.409L6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L12.1768 13.409Z"></path>
                                                    </svg>
                                                </div>
                                            </div>
                                        </Link>}
                                    {!isSupport && <Link className={styles.infoRowLvl} style={{color: 'var(--text-color)'}}
                                          to="/user-support-panel">
                                        <span className={styles.cardText}>{t("Support")}</span>
                                        <div className={styles.tokensMain}>
                                            <div className={styles.IconNextBlock}>
                                                <svg viewBox="0 0 20 20" className={styles.IconNext}
                                                     fill={themeMode === 'dark' ? "white" : "black"}>
                                                    <path
                                                        d="M12.1768 13.409C12.0791 13.5066 11.9209 13.5066 11.8232 13.409L6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L12.1768 13.409Z"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </Link>}
                                    <div className={styles.infoRowLvl} onClick={() => handleGoToFriends()}>
                                        <span className={styles.cardText}>{t("Invite friends")}</span>
                                        <div className={styles.tokensMain}>
                                            <div className={styles.IconNextBlock}>
                                                <svg viewBox="0 0 20 20" className={styles.IconNext}
                                                     fill={themeMode === 'dark' ? "white" : "black"}>
                                                    <path
                                                        d="M12.1768 13.409C12.0791 13.5066 11.9209 13.5066 11.8232 13.409L6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L12.1768 13.409Z"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.infoRowLvl} onClick={() => handleGoToPromo()}>
                                        <span className={styles.cardText}>{t("Enter promo code")}</span>
                                        <div className={styles.tokensMain}>
                                            <div className={styles.IconNextBlock}>
                                                <svg viewBox="0 0 20 20" className={styles.IconNext}
                                                     fill={themeMode === 'dark' ? "white" : "black"}>
                                                    <path
                                                        d="M12.1768 13.409C12.0791 13.5066 11.9209 13.5066 11.8232 13.409L6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L12.1768 13.409Z"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.infoRowLvl} onClick={() => handleGoToHistory()}>
                                        <span className={styles.cardText}>{t("Order History")}</span>
                                        <div className={styles.tokensMain}>
                                            <div className={styles.IconNextBlock}>
                                                <svg viewBox="0 0 20 20" className={styles.IconNext}
                                                     fill={themeMode === 'dark' ? "white" : "black"}>
                                                    <path
                                                        d="M12.1768 13.409C12.0791 13.5066 11.9209 13.5066 11.8232 13.409L6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L12.1768 13.409Z"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.infoBlock}>
                                    <h2>{t("Settings")}</h2>
                                    <div className={styles.infoRowLvl} onClick={() => handleGoToUser()}>
                                        <span className={styles.cardText}>{t("Account")}</span>
                                        <div className={styles.tokensMain}>
                                            <div className={styles.IconNextBlock}>
                                                <svg viewBox="0 0 20 20" className={styles.IconNext}
                                                     fill={themeMode === 'dark' ? "white" : "black"}>
                                                    <path
                                                        d="M12.1768 13.409C12.0791 13.5066 11.9209 13.5066 11.8232 13.409L6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L12.1768 13.409Z"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.infoRowLvl} onClick={() => handleGoToCredits()}>
                                        <span className={styles.cardText}>{t("Credits and Tokens")}</span>
                                        <div className={styles.tokensMain}>
                                            <div className={styles.IconNextBlock}>
                                                <svg viewBox="0 0 20 20" className={styles.IconNext}
                                                     fill={themeMode === 'dark' ? "white" : "black"}>
                                                    <path
                                                        d="M12.1768 13.409C12.0791 13.5066 11.9209 13.5066 11.8232 13.409L6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L12.1768 13.409Z"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.infoRowLvl} onClick={() => handleClickLocation()}>
                                        <span className={styles.cardText}>{t("languages")}</span>
                                        <div className={styles.tokensMain}>
                                            <div className={styles.IconNextBlock}>
                                                <svg viewBox="0 0 20 20" className={styles.IconNext}
                                                     fill={themeMode === 'dark' ? "white" : "black"}>
                                                    <path
                                                        d="M12.1768 13.409C12.0791 13.5066 11.9209 13.5066 11.8232 13.409L6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L12.1768 13.409Z"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    {/*<div className={styles.infoRowLvl} onClick={() => handleGoToTokens()}>*/}
                                    {/*    <span className={styles.cardText}>Payment methods</span>*/}
                                    {/*    <div className={styles.tokensMain}>*/}
                                    {/*        <div className={styles.IconNextBlock}>*/}
                                    {/*            <svg viewBox="0 0 20 20" className={styles.IconNext} fill={themeMode === 'dark' ? "white":"black"}>*/}
                                    {/*                <path*/}
                                    {/*                    d="M12.1768 13.409C12.0791 13.5066 11.9209 13.5066 11.8232 13.409L6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L12.1768 13.409Z"></path>*/}
                                    {/*            </svg>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className={styles.infoRowLvl} onClick={() => handleGoToAddress()}>
                                        <span className={styles.cardText}>{t("My addresses")}</span>
                                        <div className={styles.tokensMain}>
                                            <div className={styles.IconNextBlock}>
                                                <svg viewBox="0 0 20 20" className={styles.IconNext}
                                                     fill={themeMode === 'dark' ? "white" : "black"}>
                                                    <path
                                                        d="M12.1768 13.409C12.0791 13.5066 11.9209 13.5066 11.8232 13.409L6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L12.1768 13.409Z"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.logOutBlock} onClick={() => hangleLogOut()}>
                                    <button className={styles.logOut} onClick={() => hangleLogOut()}>
                                        {t("Log out")}
                                    </button>
                                </div>
                            </div>
                            <LanguageModal isOpen={openLocation} setIsOpen={setOpenLocation}/>
                        </div>
                    )
            }
        </WithAuthUser>
    );
};

export default observer(PersonalMain);