import React, {useEffect, useRef} from 'react';
import styles from "./DiscoveryHeader.module.scss"
import DiscoverySlider from "../DiscoverySlider/DiscoverySlider";
import SliderDiscovery from "../SliderDiscovery/SliderDiscovery";
import CategorySmallSlider from "../../CategorySmallSlider/CategorySmallSlider";
import {observer} from "mobx-react";
import rootStore from "../../../store/RootStore";
import {t} from "i18next";
import Box from "@mui/material/Box";
import {CircularProgress} from "@mui/material";

const DiscoveryHeader = () => {
    const categories = rootStore.categoriesStore.categoriesList;
    const scrollRef = useRef(null);

    const handleClickCategory = (id) => {
        if (scrollRef.current) {
            const element = scrollRef.current.querySelector(`[data-category-id="${id}"]`);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }
    if (!categories || !categories.length) {
        return (
            <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh"}}>
                <CircularProgress/>
            </Box>
        )
    }
    return (
        <div className={styles.mainCont} ref={scrollRef}>
            <div className={styles.mainConteiner}>
                <div className={styles.mainBlock}>
                    <div className={styles.mainContent}>
                        <div>
                            <CategorySmallSlider handleClickCategory={handleClickCategory}/>
                            <DiscoverySlider/>
                            {categories.map((category) => (
                                <SliderDiscovery key={category.CategoryID} names={category.CategoryName} categoryId={category.CategoryID}/>
                            ))}
                            <SliderDiscovery names={t("All stores")}/>
                            {/*<SliderDiscovery names={'New restaurants on Wolt'}/>*/}
                            {/*<CategorySliderParent names={'Top categorys'}/>*/}
                            {/*<SliderDiscovery names={'New stores on Wolt'}/>*/}
                            {/*<CategorySliderParent names={'Categorys for you'}/>*/}
                            {/*<SliderDiscovery names={'Offers near you'}/>*/}
                            {/*<SliderDiscovery names={'Vegan & vegetarian friendly'}/>*/}
                            {/*<SliderDiscovery names={'Popular right now'}/>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(DiscoveryHeader);