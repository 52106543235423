import React, {useEffect, useState} from 'react';
import LocalMallSharpIcon from '@mui/icons-material/LocalMallSharp';
import styles from './TransactionMainCard.module.scss'
import {useNavigate} from "react-router-dom";
import IconBack from "../../../components/Icons/IconBack";
import {observer} from "mobx-react";
import useIsMobile from "../../../helpers/useIsMobile";
import rootStore from "../../../store/RootStore";
import {Alert} from "@mui/material";
import {t} from "i18next";
import {getCurrencySymbol} from "../../../helpers/getCurrency";

const OrderCard = ({ data, setOpen }) => {
    const symbol = getCurrencySymbol();
    const { checkDeposite, getDepositeStatus } = rootStore.userStore;

    const dateLocale = new Date(data.created_at);
    const formattedDate = `${dateLocale.getDate()}.${dateLocale.getMonth() + 1}.${dateLocale.getFullYear()}, ${dateLocale.getHours()}:${('0' + dateLocale.getMinutes()).slice(-2)}`;

    const handleClickPay = () => {
        const tg = window.Telegram.WebApp;
        const backButton = tg.BackButton;
        backButton.show();
    
        const botUrl = "https://t.me/crm_seller_bot/doctormmc";
    
        backButton.onClick(function() {
            tg.showAlert("onClick!");
            // if (tg.openTelegramLink) {
            //     tg.openTelegramLink(botUrl);
            // } else {
            //     window.location.href = botUrl;
            // }
            backButton.hide();
        });
    
        tg.onEvent('backButtonClicked', function() {
            tg.showAlert("clicked!");
            if (tg.openTelegramLink) {
                tg.openTelegramLink(botUrl);
            } else {
                window.location.href = botUrl;
            }
            // backButton.hide();
        });
    
        window.location.href = data.pay_url;
    }

    const handleClickCheck = () => {
        checkDeposite(data.invoice_id)
    }

    return (
        <>
            <div className={styles.backer}
                 onClick={() => setOpen(false)}>
                <IconBack size={20} pure={true} fill={"#009de0"}/>
            </div>
            <main className={styles.mainPage}>
                <div className={styles.mainConteiner}>
                    <div className={styles.mainBoxBlock}>
                        <div className={styles.tabsContainer}>
                            <div className={styles.tabs}>
                                <button
                                    className={`${styles.tabButton} ${styles.active}`}
                                    data-testid="shopping-cart-tab"
                                >
                                    {t("Delivery")}
                                </button>
                            </div>
                        </div>
                    </div>
                    <p className={styles.date}>{formattedDate}</p>
                    <h3 className={styles.title}>{t("Status transactional")}:</h3>
                    <div className={styles.description}>
                        <span className={styles.valueStatus}>{data.status}</span>
                    </div>
                    <div className={styles.description}>
                        <LocalMallSharpIcon fontSize='small' color="var(--text-color)"/>
                        <span className={styles.value}>ID: {data?.invoice_id}</span>
                    </div>
                    {data.status === "Created" &&
                        <>
                            <button className={styles.btnActive} onClick={() => handleClickPay()}>{t("Pay")}</button>
                            <button className={styles.btnActive} onClick={() => handleClickCheck()}>{t("Check payment")}</button>
                        </>
                    }

                    {getDepositeStatus === 1 && (
                        <Alert severity="success">{t("The transaction was successfully paid")}</Alert>)}
                    {getDepositeStatus === 2 && (<Alert severity="info">{t("We are waiting for payment")}</Alert>) }

                    <hr style={{opacity: 0.3, margin: "1rem 0", left: "-1rem", position: "relative", width: "108%"}}/>
                    <div className={styles["payment-type"]}>
                        <span>Refill</span>
                        <span>{data?.amount} $</span></div>
                    <p className={styles.date}>{data.order_id}</p>
                </div>
            </main>
        </>
    );
};

export default observer(OrderCard);