import React from 'react';
import styles from "./CategoryItem.module.scss"
import {observer} from "mobx-react";

const CategoryItem = observer(({category,level={level}}) => {
    return (
        <div className={styles.mainConteiner} data-id="149316" data-name="Главный экран" data-has-children="false">
            <div className={styles.mainBlock}>
                {/*<span data-name="count" className={styles.itemCount}>0</span>*/}
                <span data-name="arrow" data-s
                      status="closed" className={styles.itemImg}>

                </span>

                <p>
                    {category.CategoryName !== "ALL" && <span style={{fontSize: "12px"}}>{level}</span>}
                    {category.CategoryName}
                </p>
            </div>
        </div>
    );
});

export default CategoryItem;