import * as React from "react";

function IconArrowBack(props)  {
    return (
        <svg
            baseProfile="tiny"
            viewBox="0 0 22 22"
            fill="var(--grey-text-color)"
            height="1.4rem"
            width="1.4rem"
            {...props}
        >
            <path
                d="M12 9.059V6.5a1.001 1.001 0 00-1.707-.708L4 12l6.293 6.207a.997.997 0 001.414 0A.999.999 0 0012 17.5v-2.489c2.75.068 5.755.566 8 3.989v-1c0-4.633-3.5-8.443-8-8.941z"/>
        </svg>
    );
}

export default IconArrowBack;