import api from "../api";

export const getShopProducts = async (id) => {
    try {
        if (id){
            return await api.get(`/shops/${id}/products/`)
        }
    } catch (error) {
        console.error("An error occurred:", error);
        throw error;
    }
}