import React from 'react';
import styles from "./DiscoveryBody.module.scss"

const DiscoveryBody = () => {
    return (
        <div>

        </div>
    );
};

export default DiscoveryBody;