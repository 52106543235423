import {makeAutoObservable} from 'mobx';
import api from "../api/api";
import {checkAuthError} from "../api/handlerErrors/getShopData";
import {setOrder} from "../helpers";

class CurierStore {
    allOrders = [];
    allCuriers = [];
    targetOrder = {};
    actualOrders = [];
    workedOrders = [];
    userTargetOrder = {};
    courierEarnings = {};
    courierEarningsForDate = {};

    constructor() {
        makeAutoObservable(this);
    }

    get getTargetOrder() {
        return this.targetOrder;
    }
    get getActualOrders() {
        return this.actualOrders;
    }
    get getWorkedOrders() {
        return this.workedOrders;
    }
    get getAllOrders() {
        return this.allOrders;
    }
    get getCourierEarnings() {
        return this.courierEarnings;
    };
    get getCourierEarningsForDate() {
        return this.courierEarningsForDate;
    };
    get getAllCuriers() { 
        return this.allCuriers;
    }
    get getUserTargetOrder() {
        return this.userTargetOrder;
    }

    setTargetOrders = (data) => {
        setOrder(data)
        this.targetOrder = data
    }
    setActualOrders = (data) => {
        this.actualOrders = data
    }
    setWorkedOrders = (data) => {
        this.workedOrders = data
    }
    setAllOrders = (data) => {
        this.allOrders = data
    }
    setCourierEarnings = (data) => {
        this.courierEarnings = data;
    };
    setCourierEarningsForDate = (data) => {
        this.courierEarningsForDate = data;
    };
    setAllCuriers = (data) => {
        this.allCuriers = data
    }
    setUserTargetOrder = (data) => {
        setOrder(data)
        this.userTargetOrder = data
    }

    loadActualOrders = async () => {
        api.get('courier/free-orders/').then(response => {
            this.setActualOrders(response.data)
        }).catch(error => {
            console.log(error)
            checkAuthError(error.response.data)
            throw error.response.data;
        })
    }
    acceptOrder = async (id) => {
        api.post(`courier/take-order/${id}/`).then(response => {
        }).catch(error => {
            console.log(error)
            checkAuthError(error.response.data)
            throw error.response.data;
        })
    }
    acceptCourierOrder = async (id) => {
        api.post(`courier/accept-order/${id}/`).then(response => {
        }).catch(error => {
            console.log(error)
            checkAuthError(error.response.data)
            throw error.response.data;
        })
    }
    finishOrder = async (id) => {
        api.post(`courier/complete-order/${id}/`).then(response => {
        }).catch(error => {
            console.log(error)
            checkAuthError(error.response.data)
            throw error.response.data;
        })
    }
    cancelOrder = async (id) => {
        api.post(`courier/cancel-order/${id}/`).then(response => {
        }).catch(error => {
            console.log(error)
            checkAuthError(error.response.data)
            throw error.response.data;
        })
    }

    loadWorkedOrders = async() => {
        api.get('courier/active-orders/').then(response => {
            this.setWorkedOrders(response.data)
        }).catch(error => {
            console.log(error)
            checkAuthError(error.response.data)
            throw error.response.data;
        })
    }

    // Заменил на loadCourierEarnings в рамках задачи по замене компонента AllHistory на CourierFinances

    // loadAllOrders = async() => {
    //     api.get('courier/order-history/').then(response => {
    //         this.setAllOrders(response.data)
    //     }).catch(error => {
    //         console.log(error)
    //         checkAuthError(error.response.data)
    //         throw error.response.data;
    //     })
    // }

    loadCourierEarnings = async() => {
        api.get('courier/earnings/').then(response => {
            this.setCourierEarnings(response.data)
        }).catch(error => {
            checkAuthError(error.response?.data)
            throw error.response?.data;
        })
    }

    loadCourierEarningsForDate = async(date) => {
        api.get(`courier/daily-earnings/?date=${date}`).then(response => {
            this.setCourierEarningsForDate(response.data)
        }).catch(error => {
            checkAuthError(error.response?.data)
            throw error.response?.data;
        })
    }

    loadAllCurierShop = async(shopId) => {
        api.get(`my-shop/courier/${shopId}/list/`).then(response => {
            this.setAllCuriers(response.data)
        }).catch(error => {
            console.log(error)
            checkAuthError(error.response.data)
            throw error.response.data;
        })
    }

    createCurier = async(shopId, name) => {
        api.post(`admin_seller_shop/my-shop/courier/${shopId}/assign/`, {username: name}).then(response => {
        }).catch(error => {
            console.log(error)
            checkAuthError(error.response.data)
            throw error.response.data;
        })
    }

    deleteCurier = async(shopId, name) => {
        api.delete(`admin_seller_shop/my-shop/courier/${shopId}/remove/${name}/`).then(response => {
        }).catch(error => {
            console.log(error)
            checkAuthError(error.response.data)
            throw error.response.data;
        })
    }

    loadUserTargetOrder = async(id) => {
        api.get(`check-order/${id}/`).then(response => {
            this.setUserTargetOrder(response.data)
        }).catch(error => {
            console.log(error)
            checkAuthError(error.response.data)
            throw error.response.data;
        })
    }

}

const curierStore = new CurierStore();
export default curierStore;