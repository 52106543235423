import React, {useState} from 'react';
import styles from './OrderItem.module.scss'
import rootStore from "../../../store/RootStore";
import {observer} from "mobx-react";
import {useTheme} from "../../../helpers/ThemeContext";
import {getCurrencySymbol} from "../../../helpers/getCurrency";


const OrderItem =  observer(({product, setListItem, listItem, isAccess=true}) => {
    const [isOpen, setOpen] = useState(false);

    const {themeMode} = useTheme();
    const {addProductToCartOrder, minProductToCartOrder, delProductToCartOrder} = rootStore.cartOrderStore;
    const symbol = getCurrencySymbol();

    const handleAddProductTocart = () => {
        addProductToCartOrder(product.product.ProductID, 1)
    }
    const handleMinProductTocart = () => {
        minProductToCartOrder(product.product.ProductID, 1)
    }
    const handleDellProductTocart = () => {
        delProductToCartOrder(product.product.ProductID)
        setListItem(listItem.filter(el => el.product.ProductID != product.product.ProductID))
    }

    return (
        <div>
            <div data-test-id="CartItem" data-value="65f446dbfe4b1adaf8302374" className={styles.mainContainer}>
                <div className={styles.downContent}>
                    <div className={styles.downContentBlock} width="normal" tabIndex="-1">
                        <label htmlFor=":rk:" className={styles.downContentLabel}>Count 1</label>
                        <input id=":rk:" type="number"
                               min="0" step="1"
                               data-test-id="CartItemStepper"
                               aria-hidden="true"
                               className={styles.downContentInput}
                               value="1"/>
                        <div width="normal" className={styles.downContentDownBlock}>
                            <div className={styles.boxShadow}>
                                {
                                    isOpen && isAccess ? (
                                            <div className={styles.panelBtn}
                                                 style={themeMode === "dark" ? {boxShadow: "none"} : {}}>

                                                <button className={styles.panelBtnItem}
                                                        onClick={() => handleMinProductTocart()}>
                                                    <svg viewBox="0 0 24 24" className={styles.svgBtn}>
                                                        <path
                                                            d="M1.5 13.5h21a1.5 1.5 0 000-3h-21a1.5 1.5 0 000 3z"></path>
                                                    </svg>
                                                </button>
                                                <button className={styles.panelBtnItem}
                                                        onClick={() => setOpen(!isOpen)}>
                                                    <span className={styles.textBtnContent}>{product.quantity}</span>
                                                </button>
                                                <button className={styles.panelBtnItem}
                                                        onClick={() => handleAddProductTocart()}>
                                                    <svg viewBox="0 0 24 24" className={styles.svgBtn}>
                                                        <path
                                                            d="M0 12a1.5 1.5 0 001.5 1.5h8.75a.25.25 0 01.25.25v8.75a1.5 1.5 0 003 0v-8.75a.25.25 0 01.25-.25h8.75a1.5 1.5 0 000-3h-8.75a.25.25 0 01-.25-.25V1.5a1.5 1.5 0 00-3 0v8.75a.25.25 0 01-.25.25H1.5A1.5 1.5 0 000 12z"></path>
                                                    </svg>
                                                </button>
                                                <button className={styles.panelBtnItem}
                                                        onClick={() => handleDellProductTocart()}>
                                                    <svg viewBox="0 0 24 24" className={styles.deleteBtnImg}>
                                                        <path
                                                            d="M22 4h-4.75a.25.25 0 01-.25-.25V2.5A2.5 2.5 0 0014.5 0h-5A2.5 2.5 0 007 2.5v1.25a.25.25 0 01-.25.25H2a1 1 0 000 2h20a1 1 0 100-2zM9 3.75V2.5a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v1.25a.25.25 0 01-.25.25h-5.5A.25.25 0 019 3.75zM4.5 7.5h15a.5.5 0 01.5.5v14a2 2 0 01-2 2H6a2 2 0 01-2-2V8a.5.5 0 01.5-.5zm5 13.75a.75.75 0 00.75-.75v-9a.75.75 0 00-1.5 0v9c0 .414.336.75.75.75zm5.75-.75a.75.75 0 01-1.5 0v-9a.75.75 0 011.5 0v9z"></path>
                                                    </svg>
                                                </button>
                                            </div>
                                        ) :
                                        (
                                            <button type="button" tabIndex="-1" width="normal" aria-hidden="true"
                                                    className={styles.secondBtn}
                                                    onClick={() => setOpen(!isOpen)}
                                            >
                                                <div width="normal" className={styles.secondBtnBlock}>
                                                    <div width="normal"
                                                         className={styles.inSecondBtnBlock}>{product.quantity}</div>
                                                </div>
                                                <div width="normal" className={styles.secondUpBtnBlock}>
                                                    <div>
                                            <span data-test-id="CartItemStepperValue"
                                                  aria-hidden="true"
                                                  className={styles.secondDownBtnText}>{product.quantity}</span>
                                                    </div>
                                                </div>
                                            </button>
                                        )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                    <button className={styles.mainBtn}>
                        <div className={styles.mainBtnBlock}>
                            <div className={styles.mainBtnCont}>
                                <img loading="lazy" decoding="async"
                                     src={`${process.env.REACT_APP_MEDIA_URL}${product.product.product_images.length ? product?.product?.product_images[0]?.ImageUrl : ""}`}
                                     alt="" draggable="true"
                                     className={styles.mainImg}/>
                                <noscript></noscript>
                            </div>
                        </div>
                        <div data-test-id="cartItemInfo-65f446dbfe4b1adaf8302374-0"
                             className={styles.downContainer}>
                            <span data-test-id="CartItemName"
                                  className={styles.downContainerText}>{product.product.ProductName}</span>
                                    <div className={styles.downDownContainer}>
                            <span className={styles.downDownContainerText}>
                                {symbol} {product.total_price_converted}
                            </span>
                            </div>
                        </div>
                    </button>
            </div>
        </div>
    );
});

export default OrderItem;