import api from "../api";

export const getSupportChats = async (id) => {
    try {

        const users = await api.get(`owner_site/supports/worker/${id}/chats-by-support/`);
        return users.data;
    } catch (error) {
        console.error("An error occurred:", error);
        throw error;
    }
}