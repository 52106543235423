import {makeAutoObservable} from 'mobx';
import UserStore from './UserStore';
import ProductStore from './ProductStore';
import SystemStore from "./SystemStore";
import CategoriesStore from "./CategoriesStore";
import ShopStore from "./ShopStore";
import CartOrderStore from "./CartOrderStore";
import ModeratorPanelStore from "./ModeratorPanelStore";
import SuperUserStore from "./SuperUserStore";
import CheckoutStore from "./CheckoutStore";
import SearchStore from "./SearchStore";
import CurierStore from "./CurierStore";
import StatisticStore from "./StatisticStore";
import AddressStore from "./AddressStore";
import OrdersModerStore from "./OrdersModerStore";
import ChatStore from "./ChatStore";
import SupportStore from "./SupportStore";
import DiscoveryStore from "./DiscoveryStore";

class RootStore {
  userStore = UserStore;
  productStore = ProductStore;
  superUserStore = SuperUserStore;
  systemStore = SystemStore;
  categoriesStore = CategoriesStore;
  shopStore = ShopStore;
  cartOrderStore = CartOrderStore;
  moderatorPanelStore = ModeratorPanelStore;
  checkoutStore = CheckoutStore;
  searchStore = SearchStore;
  curierStore = CurierStore;
  statisticStore = StatisticStore;
  addressStore = AddressStore;
  ordersModerStore = OrdersModerStore;
  chatStore = ChatStore;
  supportStore = SupportStore;
  discoveryStore = DiscoveryStore;

  constructor() {
    makeAutoObservable(this);
  }
}

const rootStore = new RootStore();
export default rootStore;