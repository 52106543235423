import React, {useEffect, useState} from 'react';
import styles from './CurierSettingPanel.module.scss'
import {observer} from "mobx-react";
import SearchField from "../Header/SearchField/SearchField";
import rootStore from "../../store/RootStore";
import CreateCurierModal from "./CreateCurierModal/CreateCurierModal";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import CurierTab from "./CurierTab/CurierTab";
import CurierInfoModal from "./CurierInfoModal/CurierInfoModal";
import {t} from "i18next";


const CurierSettingPanel = ({names}) => {
    const shops = rootStore.superUserStore.superUserShopsList;
    const setCurrentShopId = rootStore.superUserStore.setCurrentShopId;
    const setCurrentShopData = rootStore.superUserStore.setCurrentData;
    const {getSearchText} = rootStore.searchStore;

    const [openCreate, setCreate] = useState(false);
    const [openInfo, setOpenInfo] = useState(false);
    const [curierName, setCurierName] = useState();
    const [value, setValue] = React.useState(() => {
        const storedValue = localStorage.getItem('selectedTab');
        return storedValue ? parseInt(storedValue) : 0;
    });

    useEffect(() => {
        if (!localStorage.getItem("currentShop")) {
            handleChange('test', 0)
        }
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        localStorage.setItem('selectedTab', newValue.toString());
        localStorage.setItem('currentShop', JSON.stringify(shops[newValue]));
        setCurrentShopId(shops[newValue]?.ShopID);
        setCurrentShopData(shops[newValue]);
        // setShopData(shops[newValue]);
    };
    function a11yProps(index) {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    }
    const handleOpenInfo = (name) => {
        setCurierName(name)
        setOpenInfo(true)
    }

    return (
        <div className={styles.mainConteiner}>
            <button className={styles.createBtn} onClick={() => setCreate(true)}>{t("Create courier")}</button>
                <SearchField textSearch={t("Search for a courier….")} />
            <Box
                sx={{flexGrow: 1, bgcolor: 'background.paper', display: 'flex',flexDirection: true ? "column" : "row", height: "100%",marginTop: "20px"}}
            >
                <Tabs
                    orientation={true ? "horizontal": "vertical"}
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{borderRight: 1, borderColor: 'divider', minWidth: true ? 256 : 300}}
                >
                    {shops.map((shop, index) => (
                        <Tab label={shop.ShopName} {...a11yProps(shop.ShopID)} key={shop.ShopID}/>
                    ))}
                </Tabs>
                {shops[value]?.couriers && shops[value]?.couriers.filter(el => el.username.includes(getSearchText)).map((curier, index) => (
                    <div className={styles.curierBig} onClick={() => handleOpenInfo(curier.username)}>
                        <CurierTab curier={curier}/>
                    </div>
                ))}
            </Box>
            <div className={styles.contentBlock}>

            </div>
            {openCreate && <CreateCurierModal setState={setCreate}/>}
            {openInfo && <CurierInfoModal setState={setOpenInfo} username={curierName}/>}
        </div>
    );
};

export default observer(CurierSettingPanel);