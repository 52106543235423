import React, {useState, useRef, useEffect} from 'react';
import styles from './ModalFilters.module.scss';
import {observer} from "mobx-react";
import FilterBudge from "./FilterBudge";
import PriceBudge from "./PriceBudge";
import SortBudge from "./SortBudge";
import {t} from "i18next";
import {getCurrencySymbol} from "../../helpers/getCurrency";




const ModalFilters = ({ children }) => {
    const symbol = getCurrencySymbol();
    const [currency, setCurrency] = useState('')
    useEffect(()=>{
        setCurrency(pev=> symbol)
    },[symbol])

    const DATA = [
        { id: 1, name: "Tests", active: false },
        { id: 2, name: "Filter", active: false },
        { id: 3, name: "Ty", active: false },
        { id: 4, name: "any", active: false },
        { id: 5, name: "Alko1", active: false },
        { id: 6, name: "Qi", active: false },
        { id: 7, name: "BBQ", active: false },
        { id: 8, name: "Many", active: false },
        { id: 9, name: "any", active: false },
        { id: 10, name: "Alko2", active: false },
        { id: 11, name: "burger", active: false },
        { id: 12, name: "Filter2", active: false },
        { id: 13, name: "Many", active: false },
        { id: 14, name: "any", active: false },
        { id: 15, name: "Alko3", active: false },
        { id: 16, name: "terst3", active: false }
    ];

    const DATA_PRICE = [
        { id: 1, name: currency, active: false },
        { id: 2, name: currency+currency, active: false },
        { id: 3, name: currency+currency+currency, active: false },
        { id: 4, name: currency+currency+currency+currency, active: false }
    ];

    const DATA_SORT = [
        { id: 1, name: t("Recommended"), active: false },
        { id: 2, name: t("Delivery price"), active: false },
        { id: 3, name: t("Rating"), active: false },
        { id: 4, name: t("Delivery time"), active: false },
        { id: 5, name: t("Distance"), active: false }
    ];
    const [isOpen, setIsOpen] = useState(false);

    const modalRef = useRef(null);
    const [selectedData, setSelectedData] = useState(() => DATA);
    const [selectedDataPrice, setSelectedDataPrice] = useState(() => DATA_PRICE);
    const [selectedDataSort, setSelectedDataSort] = useState(() =>DATA_SORT);

    const handleCloseModal = () => {
        setIsOpen(false);
    };

    const handleClose = (ev) => {
        if (ev.target.id === "modalka"){
            setIsOpen(false);
        }
    }
    const toggleActive = (id, type, currentActive) => {

        if (type === 'filter') {
            setSelectedData(selectedData.map(item => item.id === id ? { ...item, active: !currentActive } : item)
            );
        }
        if (type === 'price') {
            setSelectedDataPrice(prev =>
                prev.map(item => item.id === id ? { ...item, active: !currentActive } : item)
            );
        }
        if (type === 'sort') {
            setSelectedDataSort(prev =>
                prev.map(item => item.id === id ? { ...item, active: !currentActive } : item)
            );
        }
    };

    return (
        <div>
            {isOpen && (
                <div id="modalka" className={styles["modal-overlay"]} onClick={(ev)=> handleClose(ev)}>
                    <div className={styles["modal-content"]} ref={modalRef}>
                        <div className={styles.closeBtnConteiner}>
                            <button className={styles.buttonBlock} data-backdrop="transparent"
                                    data-size="medium" data-variant="neutral" type="button"
                                    aria-label="Close the dialog" data-test-id="modal-close-button"
                                    onClick={handleCloseModal}>
                                <div className={styles.fakeBtnBlock}></div>
                                <div className={styles.closeImgBtn}>
                                    <svg viewBox="0 0 24 24" className={styles.closeImg}>
                                        <path
                                            d="M2.44 19.44a1.5 1.5 0 002.121 2.121l7.262-7.26a.25.25 0 01.354 0l7.262 7.262a1.5 1.5 0 102.122-2.12l-7.26-7.266a.25.25 0 010-.354l7.262-7.262a1.5 1.5 0 00-2.124-2.12L12.177 9.7a.25.25 0 01-.354 0L4.561 2.44A1.5 1.5 0 002.44 4.56L9.7 11.823a.25.25 0 010 .354L2.44 19.44z"></path>
                                    </svg>
                                </div>
                            </button>
                        </div>
                        <div>
                            <h2 className={styles.titleBody}>{t("Filter")}</h2>
                            <div className={styles.filtersBlock}>
                                <div className={styles.filters}>
                                    {
                                        selectedData.map((e, index) => (<FilterBudge id={e.id} toggleActive={toggleActive} active={e.active} name={e.name} key={e.id}/>))
                                    }
                                </div>
                            </div>
                            <div>
                                <h3 className={styles.priceTitle}>{t("Price")}</h3>
                                <div className={styles.priceBody}>
                                    <div className={styles.priceParent}>
                                        {
                                            DATA_PRICE.map((e, index) => (<PriceBudge id={e.id} toggleActive={toggleActive} active={e.active} name={e.name} key={e.id}/>))
                                        }
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h3 className={styles.sortTitle}>{t("Sort by")}</h3>
                                <div className={styles.sortBody}>
                                    <div className={styles.sortParent}>
                                        {
                                            selectedDataSort.map((e, index) => (<SortBudge id={e.id} toggleActive={toggleActive} active={e.active} name={e.name} key={e.id}/>))
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={styles.closeBtnBlock} onClick={() => setIsOpen(false)}>
                                <div className={styles.closeBtnConteiners}>
                                    <button aria-busy="false" className={styles.closeBtn} data-size="large"
                                            data-stretch="" data-variant="secondary" type="button"
                                            data-test-id="closeFilterButton"
                                            onClick={() => setIsOpen(false)}>Close
                                        <div className={styles.closeBtnBlock1}></div>
                                        <div className={styles.closeBtnBlock2}>{t("Close")}</div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div onClick={() => setIsOpen(true)}> {children}</div>
        </div>
    );
};

export default observer(ModalFilters);
