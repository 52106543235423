import api from "../api";

export const getUserInfo = async (id) => {
    try {
        if(id) {
            const users = await api.get(`/owner_site/verifications/users/${id}/`);
            return users.data;
        }

    } catch (error) {
        console.error("An error occurred:", error);
        throw error;
    }
}