import React, {useEffect, useState} from 'react';
import {
    Box,
    CircularProgress,
    Paper, styled,
    Table,
    TableBody, TableCell,
    TableContainer,
    TableHead, TablePagination,
    TableRow, TextField,
} from "@mui/material";
import {t} from "i18next";
import {Link} from "react-router-dom";

import debounce from 'lodash.debounce';
import {getAllUser} from "../../api/getContentsApi/getAllUser";
import {StyledSearchInput, StyledTextField} from "../StyledSearchInput/StyledSearchInput";

const StyledTableCell = styled(TableCell)(({ theme }) => ({

    padding: '7px',
}));

const FiltredModeratorTable = () => {
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                setLoading(true);
                const data = await getAllUser(searchQuery); // Передаем query параметр в запрос
                setUsers(data);
            } catch (error) {
                console.error("An error occurred while fetching users:", error);
            } finally {
                setLoading(false);
            }
        };
        const debouncedFetchUsers = debounce(fetchUsers, 700);
        debouncedFetchUsers();

        return () => {
            debouncedFetchUsers.cancel();
        };

    }, [searchQuery]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    return (
        <>
            <Paper sx={{width: '100%', overflow: 'hidden'}}>
                <StyledSearchInput
                    variant="outlined"
                    label={t("Search users")}
                    value={searchQuery}
                    onChange={handleSearchChange}
                    sx={{width: '100%', marginBottom: '10px'}}
                />
                {loading ? <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh"}}>
                        <CircularProgress/>
                    </Box> :
                    <>

                        <TableContainer sx={{maxHeight: "45vh"}}>
                            <Table aria-label="users table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell sx={{width: 100}}>{t("User name")}</StyledTableCell>
                                        <StyledTableCell sx={{width: 100}}>{t("Active")}</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => (
                                        <TableRow key={user.id}>
                                            <StyledTableCell sx={{maxWidth: 150, wordWrap: 'break-word'}}>
                                                <Link to={`/owner/verification/moderator/${user.id}`}>
                                                    {user.username}
                                                </Link>
                                            </StyledTableCell>
                                            <StyledTableCell sx={{maxWidth: 150, wordWrap: 'break-word'}}>
                                                {user.is_active ? t('Yes') : t('No')}
                                            </StyledTableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            sx={{padding: '0'}}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            component="div"
                            count={users.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </>}

            </Paper>
        </>

    );
};

export default FiltredModeratorTable;