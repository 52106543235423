import * as React from "react";

function IconImage(props) {
    return (
        <div style={{display: "flex", alignItems: "center"}}>
            <svg
                viewBox="0 0 24 24"
                fill="#2990c3"
                height="20px"
                width="20px"
                {...props}
            >
                <path
                    d="M14 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V8l-6-6m-.5 14v3h-3v-3H8l4-4 4 4h-2.5M13 9V3.5L18.5 9H13z"/>
            </svg>
            <span style={{marginLeft: "10px"}}>Add image</span>
        </div>

    );
}

export default IconImage;