export const parseTimeString = (timeString) => {
  const timeParts = timeString.split(".").map(Number).reverse();

  let seconds = 0;

  if (timeParts[0]) seconds += timeParts[0];
  if (timeParts[1]) seconds += timeParts[1] * 60;
  if (timeParts[2]) seconds += timeParts[2] * 3600;

  return seconds;
};

export const formatDuration = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return `${hours > 0 ? hours.toString().padStart(2, "0") + ":" : ""}${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};
