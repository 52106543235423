import React from 'react';

const RestaurantsIcon = ({currentColor = 'currentColor'}) => {
    return (
        <svg viewBox="0 0 24 24"
             width="1.25rem"
             height="1.25rem"
             fill={currentColor}>
            <path
                  d="M10 1a1 1 0 112 0v5a4.009 4.009 0 01-2.667 3.772.5.5 0 00-.333.471V23a1 1 0 11-2 0V10.243a.5.5 0 00-.333-.471A4.009 4.009 0 014 6V1a1 1 0 112 0v5c0 .522.205 1.025.571 1.398A.251.251 0 007 7.223V1a1 1 0 112 0v6.225a.251.251 0 00.429.175c.367-.374.572-.877.571-1.4V1zM20.5.75a.75.75 0 00-.75-.75C17.418 0 15.064 6.055 15 13.243v.021c.004.686.563 1.24 1.25 1.236H18a.5.5 0 01.5.5v8a1 1 0 102 0V.75z"></path>
        </svg>
    );
};

export default RestaurantsIcon;