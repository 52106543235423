import api from "../api";

export const getActiveChats = async () => {
    try {

        const users = await api.get(`/support/active_chats/`);
        return users.data;
    } catch (error) {
        console.error("An error occurred:", error);
        throw error;
    }
}