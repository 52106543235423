import React, {useEffect, useState} from 'react';
import styles from "./RestoranBody.module.scss"
import RestoranMenu from "./RestoranMenu/RestoranMenu";
import CategoryHeader from "./CategoryHeader/CategoryHeader";
import CategoryBaner from "./CategoryBaner/CategoryBaner";
import MobileCategoryHeader from "./MobileCategoryHeader/MobileCategoryHeader";

const MOCK_DATA = [
    {
        id: 1,
        title: "It's a Deal!",
        items: [
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: true,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: true,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: true,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: true,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: true,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: true,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: true,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: true,
                oldCost: "€7.20",
                newCost: "€6"
            }
        ]
    },
    {
        id: 2,
        title: "NUDELN & REIS",
        items: [
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            }
        ]
    },
    {
        id: 3,
        title: "NUDELN & REIS",
        items: [
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            }
        ]
    },
    {
        id: 4,
        title: "NUDELN & REIS",
        items: [
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            }
        ]
    }
]

const RestoranBody = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if(window.innerWidth <= 641){
            setIsMobile(true)
        }
        const handleResizer = () => {
            setIsMobile(window.innerWidth <= 641)
        };
        window.addEventListener('resize', handleResizer);
        return () => {
            window.removeEventListener('resize', handleResizer);
        };
    }, []);

    return (
        <main className={styles.mainConteiner}>
            {
                isMobile
                    ? (<MobileCategoryHeader data={MOCK_DATA}/>)
                    : (<CategoryHeader data={MOCK_DATA}/>)
            }
            <CategoryBaner/>
            <RestoranMenu data={MOCK_DATA}/>
        </main>
    );
};

export default RestoranBody;