import api from "../api";

export const getOwnerSlidesList = async () => {
    try {

        const shops = await api.get(`/owner_site/shops/sliders/all/`);
        return shops.data;
    } catch (error) {
        console.error("An error occurred:", error);
        throw error;
    }
}