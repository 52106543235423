import React from 'react';
import styles from './CheckoutBody.module.scss'
import CheckoutLeft from "../../components/CheckoutBody/CheckoutLeft/CheckoutLeft";
import CheckoutRight from "../../components/CheckoutBody/CheckoutRight/CheckoutRight";
import {useNavigate} from "react-router-dom";


const MainPage = () => {
    const navigate = useNavigate()


    return (
        <main className={styles.mainPage}>
            <div className={styles.firstBlock}></div>
            <div className={styles.secondBlock}></div>
            <div className={styles.treeBlock}></div>
            <div className={styles.mainBlock}>
                <div className={styles.upBlock}>
                    <div className={styles.upMainConteiner}>
                        <div className={styles.upMainBlock}></div>
                        <div className={styles.downMainBlock}>
                            <div className={styles.downContentBlock}>
                                <div className={styles.downContentBtn}>
                                    <button className={styles.backBtnBlock} data-backdrop="transparent"
                                            data-size="medium" data-variant="neutral" type="button" aria-label=""
                                            aria-labelledby=":r210:"
                                            onClick={() => navigate('/')} >
                                        <div className={styles.backBtnFake}></div>
                                        <div className={styles.backBtnImgBlock}>
                                            <svg viewBox="0 0 24 24" className={styles.backBtnImg}>
                                                <path
                                                    d="M22.533 10.526H5.422a.251.251 0 01-.165-.438l4.637-3.6a1.44 1.44 0 00-1.9-2.162L.813 10.165a2.4 2.4 0 000 3.6l7.179 5.837a1.44 1.44 0 001.9-2.161l-4.637-3.6a.251.251 0 01.165-.438h17.113a1.44 1.44 0 000-2.88v.003z"></path>
                                            </svg>
                                        </div>
                                    </button>
                                    <div id=":r210:" className={styles.backTextBtnBlock}>Back</div>
                                </div>
                                <div className={styles.downContentTextBlock}>
                                    <h1 className={styles.titleUpBlock}>
                                        <span className={styles.titleUpBlockText}>Checkout</span></h1>
                                    <h4 className={styles.downTitleUpBlock}>Carrefour Zugdidi</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.downBlock}>
                    <CheckoutLeft/>
                    <CheckoutRight/>
                </div>
            </div>
        </main>
    );
};

export default MainPage;