import React, { useState, useRef } from 'react';
import styles from './AddressInfo.module.scss';
import {observer} from "mobx-react";
import {useTheme} from "../../../helpers/ThemeContext";
import { TextField } from "@mui/material";
import rootStore from "../../../store/RootStore";
import stylesA from "../../AddresPage/AddressInfo.module.scss";
import {t} from "i18next";



const AddressInfo = ({isOpen, setIsOpen, dataAddress}) => {
    const modalRef = useRef(null);
    const { addressData, setAddress } = rootStore.checkoutStore;

    const [textB, setTextB] = useState("")
    const [textE, setTextE] = useState("")
    const [textF, setTextF] = useState("")
    const [textA, setTextA] = useState("")

    const isCurier = window.location.pathname.includes('curier-panel')
    const isModerator = window.location.pathname.includes('moderator')

    const {themeMode} = useTheme()

    const handleCloseModal = () => {
        setIsOpen(false);
    };

    const handleClose = (ev) => {
        if (ev.target.id === "modalka"){
            setIsOpen(false);
        }
    }

    const handleSave = () => {
        setAddress(textB, textE, textF, textA)
        setIsOpen(false);
    }

    return (
        <div>
            {isOpen && (
                <div id="modalka" className={styles["modal-overlay"]} onClick={(ev)=> handleClose(ev)}>
                    <div className={styles["modal-content"]} ref={modalRef}>
                        <div className={styles.closeBtnConteiner}>
                            <button className={`${styles.IconBack} ${styles.mainStyle}`} style={{
                                marginTop: "5px",
                                zIndex: 999999,
                            }} onClick={() => setIsOpen(false)}>
                                <div className={styles["IconBack__icon"]}>
                                    X
                                </div>
                            </button>
                        </div>
                        <div>
                            <h2 className={styles.titleBody}>{t("Address")}</h2>
                            <div className={styles.imagesBlock}>
                                <div className={styles.rowBlock}>
                                    <div className={styles.leftBlock}>
                                        <div className={styles.textBlockImage}>
                                            <span className={styles.topText}>{dataAddress?.city}</span>
                                            <span className={styles.downText}>{dataAddress?.district}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.blockMainInfo}>
                            <h2 className={stylesA.titleBody}>{t("District")}</h2>
                            <span>{t("The district type helps us to find you better")}.</span>
                            <TextField
                                disabled={true}
                                id="outlined-helperText1"
                                label={t("District name")}
                                defaultValue={dataAddress?.district}
                                sx={{width: "100%", borderRadius: "1rem", marginTop: "0.5rem"}}
                            />
                            <h2 className={stylesA.titleBody}>{t("Address details")}</h2>
                            <div className={stylesA.imgBlocksSearch}>
                                <TextField
                                    disabled={true}
                                    id="outlined-helperText1"
                                    label={t("Street name")}
                                    defaultValue={dataAddress?.street}
                                    sx={{width: "100%", borderRadius: "1rem"}}
                                />
                                <span className={stylesA.optionalText2}>{("House info")}</span>
                                <div className={stylesA.downFields}>
                                    <TextField
                                        disabled={true}
                                        id="outlined-helperText3"
                                        label={t("House number")}
                                        defaultValue={dataAddress?.house_number}
                                        sx={{borderRadius: "1rem", width: "48%"}}
                                    />
                                    <TextField
                                        disabled={true}
                                        id="outlined-helperText4"
                                        label={t("Entrance number")}
                                        defaultValue={dataAddress?.entrance_number}
                                        sx={{borderRadius: "1rem", width: "48%"}}
                                    />
                                </div>
                            </div>
                        </div>
                        </div>
                        {
                            !isCurier && !isModerator && (
                                <button aria-disabled="false" role="button" type="button"
                                        className={styles.footerBtn} onClick={() => handleSave()}>
                                    <div className={styles.footerBtnMain}>{t("Save")}</div>
                                </button>
                            )
                        }
                    </div>
            )}
            <div onClick={() => setIsOpen(true)}></div>
        </div>
    );
};

export default observer(AddressInfo);
