import React, { useEffect, useState, useRef, useCallback } from "react";

import { observer } from "mobx-react";

import styles from "./CircleBlock.module.scss";

import rootStore from "../../../store/RootStore";
import { useTheme } from "../../../helpers/ThemeContext";
import { parseTimeString, formatDuration } from "./helpers";
import {
  FULL_DASH_ARRAY,
  START_OFFSET,
  CIRCLE_DASHARRAY,
  LIGHT_THEME_CIRCLE_COLORS,
  DARK_THEME_CIRCLE_COLORS,
} from "./constants";

const CircleBlock = () => {
  const { themeMode } = useTheme();
  const { getTargetOrderCheck } = rootStore.cartOrderStore;

  const circleColors = themeMode
      ? LIGHT_THEME_CIRCLE_COLORS
      : DARK_THEME_CIRCLE_COLORS;

  const [counterTime, setCounterTime] = useState(0);
  const [remainingPathColor, setRemainingPathColor] = useState(
      circleColors.RED
  );
  const timeStringMain = getTargetOrderCheck?.small_order_surcharge_limit;
  const initialTimeMain = parseTimeString(timeStringMain) - 40;

  const [circleDashoffset, setCircleDashoffset] = useState(
      FULL_DASH_ARRAY - START_OFFSET
  );

  const checkpoints = useRef(null);
  const timerRef = useRef(null);
  const getRemainingPathColor = useCallback(
      (timeLeft) => {
        if (timeLeft >= checkpoints.current.orangeCheckpoint)
          return circleColors.RED;
        if (timeLeft >= checkpoints.current.greenCheckpoint)
          return circleColors.ORANGE;
        if (timeLeft >= checkpoints.current.blueCheckpoint)
          return circleColors.GREEN;
        return circleColors.BLUE;
      },
      [circleColors]
  );

  const startTimer = useCallback(
      (initialTime) => {
        if (timerRef.current) clearInterval(timerRef.current);

        timerRef.current = setInterval(() => {
          setCounterTime((prevTime) => {
            if (prevTime === 0) {
              clearInterval(timerRef.current);
              return 0;
            }

            const newTime = prevTime - 1;
            const fraction = calculateTimeFraction(newTime, initialTime);

            setCircleDashoffset(
                (FULL_DASH_ARRAY - START_OFFSET) * (1 - fraction)
            );
            setRemainingPathColor(getRemainingPathColor(initialTimeMain + 40 - newTime));
            return newTime;
          });
        }, 1000);
      },
      [getRemainingPathColor]
  );

  useEffect(() => {
    const timeString = getTargetOrderCheck?.small_order_surcharge_limit;

    if (timeString) {
      const initialTime = parseTimeString(timeString);
      checkpoints.current = {
        orangeCheckpoint: initialTime * 0.75,
        greenCheckpoint: initialTime * 0.5,
        blueCheckpoint: initialTime * 0.25,
      };

      setCounterTime(initialTime);
      startTimer(initialTime);
    }

    return () => clearInterval(timerRef.current);
  }, [getTargetOrderCheck?.small_order_surcharge_limit, startTimer]);

  const calculateTimeFraction = (timeLeft, totalTime) =>
      1 - timeLeft / totalTime;

  const shopName = getTargetOrderCheck?.shop_name;

  return (
      <div className={styles.containerCircle}>
        <div className={styles.mainBlock}>
          <div className={styles.baseTimer}>
            <svg
                className={styles.baseTimerSvg}
                viewBox="0 0 100 100"
                xmlns="http://www.w3.org/2000/svg"
            >
              <g className={styles.baseTimerCircle}>
                <circle
                    className={styles.baseTimerPathElapsed}
                    strokeDasharray={`${
                        FULL_DASH_ARRAY - START_OFFSET
                    } ${FULL_DASH_ARRAY}`}
                    strokeLinecap="round"
                    cx="50"
                    cy="50"
                    r="45"
                />
                <path
                    id={styles.baseTimerPathRemaining}
                    strokeDasharray={CIRCLE_DASHARRAY}
                    strokeDashoffset={initialTimeMain-circleDashoffset}
                    stroke={remainingPathColor}
                    className={styles.baseTimerPathRemaining}
                    d="M 50, 50 m -45, 0 a 45,45 0 1,0 90,0 a 45,45 0 1,0 -90,0"
                />
              </g>
            </svg>
          </div>
          <div className={styles.circleContent}>
            <div className={styles.contentBlock}>
            <span className={styles.deliveryTime}>
              {formatDuration(counterTime)}
            </span>
              <span className={styles.deliveryText}>{shopName}</span>
            </div>
          </div>
        </div>
      </div>
  );
};

export default observer(CircleBlock);