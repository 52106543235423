import React from 'react';
import {observer} from "mobx-react";
import styles from "./SupportChat.module.scss"
import {useNavigate} from "react-router-dom";

const SupportChat = ({chat}) => {
    const navigate = useNavigate();
    const dateMain = chat.created_at.split("T")
    const date = dateMain[0].split("-")[1] + "." + dateMain[0].split("-")[2] + " " + dateMain[1].split(':')[0] + ":" + dateMain[1].split(':')[1]

    return (
        <button className={styles.chat} onClick={() => navigate(`/support-panel/chat/${chat.user}`)}>
            <div className={styles.leftChatBlock}>
                <span className={styles.username}>Room chat {chat.user}</span>
                <span className={styles.textContent}>{chat.last_message_content}</span>
            </div>
            <div className={styles.dateBlock}>
                <span className={styles.dateRow}>{date}</span>
            </div>
        </button>
    );
};

export default observer(SupportChat);