
import api from "../api";

export const getModeratorCategoryList = async () => {
    try {
        const response = await api.get(`/admin_seller_shop/categories/all/`);
        return response.data
    } catch (error) {
        console.error("An error occurred:", error);
        throw error;
    }
}