import React, {useEffect, useState} from 'react';
import rootStore from "../../../store/RootStore";
import styles from "./CategorySmallItem.module.scss";
import {Link} from "react-router-dom";

const CategorySmallItem = ({category,handleClickCategory}) => {
    const {setSearchCategory} = rootStore.searchStore;

    const [imageUrl, setImageUrl] = useState(null);
    const [altText,setAltText] = useState(null)
    const categoryId = category?.CategoryID;
    const [router,setRouter] = useState('')
    useEffect(() => {
        if(window.location.pathname.includes("/restaurants")){
            setRouter('restaurants')
        } else if(window.location.pathname.includes("/stores")) {
            setRouter('stores')
        } else if(window.location.pathname.includes("/discovery")){
            setRouter('discovery')
        }
    }, [window.location.pathname]);

    useEffect(() => {
        if (category && categoryId) {
            setImageUrl(category.category_images[0]?.ImageUrl);
            setAltText(category.category_images[0]?.AltText)
        }
    }, [category]);

    const handleSelectTarget = () => {
        handleClickCategory(category?.CategoryID)
        setSearchCategory({"id": category?.CategoryID, "name": category?.CategoryName},router)
    }

    const isDiscovery = window.location.pathname.includes('/discovery');

    return (
        <div className={isDiscovery ? `${styles.mainConteiner} ${styles.mainConteinerSmall}` : styles.mainConteiner}>
            <Link className={styles.card} onClick={() => handleSelectTarget()}>
                <div>
                    <div tabIndex={"-1"} className={styles.mainCardBlock}>
                        <div className={styles.imgConteiner}>
                            <div className={styles.imgBlock}>
                                <div className={styles.image}>
                                    {imageUrl &&
                                        <img className={styles.img} src={process.env.REACT_APP_MEDIA_URL + imageUrl}
                                             alt={altText}/>}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Link>
            <div className={styles.descriptionBlock}>
                <div className={styles.description}>
                    <h3 className={styles.title}>{category?.CategoryName}</h3>
                </div>
            </div>
        </div>
    )
};

export default CategorySmallItem;