import React from 'react';
import styles from './ErrorCheckout.module.scss'
import {observer} from "mobx-react";
import {Alert, Stack} from "@mui/material";




const ErrorCheckout = observer(callback => {

    return (
        <Stack className={styles.alert} sx={{ width: '100%' }} spacing={2}>
            <Alert severity="error">Insufficient balance</Alert>
        </Stack>
    );
});

export default ErrorCheckout;