import { makeAutoObservable } from "mobx";
import api from "../api/api";
import { checkAuthError } from "../api/handlerErrors/getShopData";

class CheckoutStore {
  checkout = {};
  initialized = false;
  isClicked = false;
  location = "Tel aviv";
  address = {
    buildingName: "",
    enterance: "",
    floor: "",
    apartment: "",
  };
  errorText = "";
  completedOrder = null;

  constructor() {
    makeAutoObservable(this);
  }

  get addressData() {
    return this.address;
  }

  get getIsClicked() {
    return this.isClicked;
  }

  get getCheckout() {
    return this.checkout;
  }

  get locationData() {
    return this.location;
  }

  get getErrorText() {
    return this.errorText;
  }

  get getCompletedOrder() {
    return this.completedOrder;
  }

  setAddress = (textB, textE, textF, textA) => {
    this.address = {
      buildingName: textB,
      enterance: textE,
      floor: textF,
      apartment: textA,
    };
  };

  setIsClicked = () => {
    this.isClicked = !this.isClicked;
  };
  setErrorText = (data) => {
    this.errorText = data;
  };

  // TODO: lazyInitialize, Возможно устаревший запрос, уточнить
  lazyInitialize = (setListItem) => {
    api.post("/checkout-preview/").then((response) => {
      this.checkout = response.data;
      this.initialized = true;
    });
  };

  sendCheckout = async (data, setSuccess, setErrorCash) => {
    api
      .post("/checkout/", data)
      .then((response) => {
        this.completedOrder = response.data;
        checkAuthError(response.data);
        setSuccess(true);
      })
      .catch((error) => {
        this.setErrorText(error?.response?.data?.error);
        setErrorCash(true);
        throw error?.response?.data;
      });
  };
}

const checkoutStore = new CheckoutStore();
export default checkoutStore;