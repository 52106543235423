import React from 'react';
import styles from "./PopularButton.module.scss"

const PopularButton = () => {

    return (
        <div className={styles.mainConteiner}>
            <div className={styles.mainBlock}>
                <span className={styles.text}>
                    Popular
                </span>
            </div>
        </div>
    );
};

export default PopularButton;