import React from 'react';
import {styled, Tab} from "@mui/material";
import useIsMobile from "../../helpers/useIsMobile";

const StyledTab = styled((props) => {
    const isMobile = useIsMobile()
    return (
        <>
            <Tab disableRipple {...props} />
            {isMobile &&<hr style={{
                height: '1px',
                background: '#BDBDBD',
                border: 'none',
                position: 'relative',
                bottom: '1px',
                zIndex: 1,
                margin:  isMobile ? "0 15px" : "0 30px",
            }}/>}
        </>

    )
})(
    ({theme}) => ({
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(1),
        height: '5.2vw',
        fontSize: '1.1vw',
        color: 'theme.palette.text.primary',
        width: "auto",

        '@media (max-width: 1020px)': {
            fontSize: '16px',
            alignItems: "flex-start",
            margin: "0 30px",
            width: "auto",
        },
        '@media (max-width: 640px)': {
            fontSize: '12px',
            margin: "0 15px"
        },
        '&.Mui-selected': {
            color: 'theme.palette.text.primary',
            fontWeight: '700',
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
    }),
);

export default StyledTab;