import React, {useEffect, useState} from 'react';

import {
    Box,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    styled,
    TextField,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Dialog,
    Slide,
} from "@mui/material";
import {t} from "i18next";
import styles from "./AllUserTable.module.scss"
import {getAllUser} from "../../../api/getContentsApi/getAllUser";
import {setSupported} from "../../../api/getContentsApi/setSupported";
import {useNavigate} from "react-router-dom";


const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 20px !important;
  }
`;
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AllUserTable = () => {
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [open, setOpen] = useState(false);
    const [isOpenModel, setIsOpenModel] = useState("");
    const [targetUser, setTargetUser] = useState(null);
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const [searchQuery, setSearchQuery] = useState('');

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                setLoading(true);
                const data = await getAllUser(); // Передаем query параметр в запрос
                setUsers(data);
            } catch (error) {
                console.error("An error occurred while fetching users:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchUsers();
    }, []);


    // Пагинация данных
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({

        padding: '7px',
    }));
    const handleCloseConfirm = () => {
        setOpen(false);
    };
    const handleSetSupported = async () => {
       await setSupported(targetUser)
       handleCloseConfirm()
        window.location.reload()
    }
    const handleClose = (e) => {
        if (e) {
            if (e === isOpenModel) {
            } else {
                setIsOpenModel(e)
            }
        } else {
            setIsOpenModel(e)
        }
    }
    const handleOpenModal = (id) => {
        setTargetUser(id)
        setOpen(true)
    }

    return (
        <Paper sx={{width: '100%'}}>
            <TextField
                variant="outlined"
                label={t("Search users")}
                value={searchQuery}
                onChange={handleSearchChange}
                sx={{width: '100%', marginBottom: '10px'}}
            />
            { loading? <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh"}}>
                    <CircularProgress/>
                </Box>:
                <>
                    <TableContainer sx={{maxHeight: "60vh"}}>
                        <Table sx={{maxWidth: 370}} aria-label="users table" >
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell sx={{width: 100}}>{t("User name")}</StyledTableCell>
                                    <StyledTableCell sx={{width: 80}}>{t("Status")}</StyledTableCell>
                                    <StyledTableCell sx={{width: 80}}>{t("Actions")}</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users.filter(user => user.username.includes(searchQuery)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => (
                                    <TableRow key={user.id}>
                                        <StyledTableCell sx={{maxWidth: 150, wordWrap: 'break-word'}}>
                                            <div className={styles.userName}>
                                                {user.username}
                                            </div>
                                        </StyledTableCell>
                                        <StyledTableCell sx={{maxWidth: 150, wordWrap: 'break-word'}}>
                                            {user.is_verified ? t('Verified') : t('Not verified')}
                                        </StyledTableCell>
                                        <StyledTableCell onClick={() => handleOpenModal(user.id)} sx={{maxWidth: 150, wordWrap: 'break-word', backgroundColor: "green", borderRadius: "10px", color: "white", display: "flex", justifyContent: "center", padding: "2px", margin: "5px"}}>
                                            {t("Assign")}
                                        </StyledTableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <StyledDialog
                        open={open}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleClose}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle>{t("Warning")}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                {isOpenModel === "1" ? t("Are you sure you want to verify the user?") : t("Are you sure you want to delete verification?")}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseConfirm}>{t("Cancel")}</Button>
                            <Button onClick={handleSetSupported}>{t("Confirm")}</Button>
                        </DialogActions>
                    </StyledDialog>
                    <TablePagination
                        rowsPerPageOptions={[5,10, 25, 50, 100]}
                        component="div"
                        count={users.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>}
        </Paper>
    );
};

export default AllUserTable;
