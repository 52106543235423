import React from 'react';
import styles from './LocationSearchBlock.module.scss';
import CountrySelect from "../CountrySelect/CountrySelect";
import ButtonShared from "../Buttons/ButtonShared/ButtonShared";

const LocationSearchBlock = () => {
    return (
        <>
            <div>
                <div className={styles.titleBlock}>
                    <h1 className={styles.title}>Text for example on this block</h1>
                </div>
                <CountrySelect/>
                <div className={styles.btnContainer}>
                    <ButtonShared/>
                </div>
            </div>
        </>
    );
};

export default LocationSearchBlock;