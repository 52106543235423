import React from 'react';
import styles from "./BanerBotton.module.scss"

const BanerBotton = ({text, maxSize = false}) => {

    return (
        <button className={`${styles.mainConteiner} ${maxSize && styles.maxSize}`}>
            <div className={styles.mainBlock}>
                <div className={styles.upBlock}>
                    <div className={`${styles.description} ${maxSize && styles.maxSizeText}`}>{text}</div>
                </div>
                <div className={styles.downBlock}>
                    <div className={styles.fakeBlock}></div>
                    <img
                        src="https://imageproxy.wolt.com/static-assets/venue-content/campaigns/banners/deal.png?transparent="
                        alt=""
                        className={styles.img}/>
                </div>
            </div>
        </button>
    );
};

export default BanerBotton;