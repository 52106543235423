import React, {useEffect, useState} from 'react';
import styles from "./VerificationPage.module.scss"
import rootStore from "../../store/RootStore";
import WithAuthUser from "../../helpers/WithAuthUser";
import {useNavigate} from "react-router-dom";
import IconBack from "../../components/Icons/IconBack";

import {t} from "i18next";
import FaceVerification from "../../components/FaceVerification/FaceVerification";

const VerificationPage = () => {
    const [name, setName] = useState("None")
    const [photo, setPhote] = useState();
    const [video, setVideo] = useState("None");
    const {  getProfile } = rootStore.userStore;

    const {sendVerification} = rootStore.userStore;

    const navigate = useNavigate();

    useEffect(() => {
    }, []);

    const handleSendVerifToServ = async () => {
        if (name && photo && video) {
            const forma= new FormData();
            forma.append("verification_name", name);
            forma.append("verification_photo_face", photo);
            forma.append("verification_video_face", video);
            await sendVerification(forma)
            setTimeout(async () => {
                await getProfile()
            },50)
            setTimeout(() => {
                navigate({pathname:"/discovery/me/verification2",})
            },50)

        }
    }

    return (
        <WithAuthUser>
            <div style={{
                "position": "fixed",
                "width": "32px",
                "height": "32px",
                "zIndex": 999999,
                "top": "1%",
                "left": "3%",
            }}
                 ><IconBack onClick={() => navigate("/discovery/me", { replace: true })} size={20} pure={true} fill={"#009de0"}/>
                <h2 className={styles.mainTitle}>{t("Verification")}</h2></div>
            <div className={styles.profileCard}>
                <div className={styles.downConteiner}>
                    <div className={styles.infoBlock}>
                        <FaceVerification setAuthInput={setPhote}/>
                        <button className={`${styles.activeBtn} ${(name && photo && video) ? "" : styles.notActive}`} onClick={() => handleSendVerifToServ()}>{t("Verificate")}</button>
                    </div>
                </div>
            </div>
        </WithAuthUser>
    );
};

export default VerificationPage;