import React from 'react';
import styles from './Button.module.scss'
import rootStore from "../../../store/RootStore";
import {observer} from "mobx-react";

const Button = observer(({ text, isUpRestHeader }) => {
    const isLogin = text === "Log in";
    const { openCloseL } = rootStore.systemStore;

    const handleClickLogin = () => {
        openCloseL(isLogin)
    }

    return (
        <button className={
            isUpRestHeader
                ?
            (isLogin ? styles.buttonLoginUp : styles.buttonSignupUP)
                :
            (isLogin ? styles.buttonLogin : styles.buttonSignup)
        }
        onClick={() => handleClickLogin()}>
            {text}
        </button>
    );
});

export default Button;