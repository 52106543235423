import React, {useEffect, useState} from 'react';
import {Modal} from "@mui/material";
import styles from "./ModalReviewsShop.module.scss"
import RadioGroupRating from "../RadioGroupRating/RadioGroupRating";
import {getShopData} from "../../api/getContentsApi/getShopData";
import {sendReviewShop} from "../../api/addContent/sendReviewShop";
import Animation from "./wolt-animation.gif";

const ModalReviewsShop = ({ isOpen, openClose, orderData }) => {
    const { shop:shopId, id:orderId } = orderData;

    const [shopData, setShopData] = useState([])
    const [ratingValue, setRatingValue] = useState(null);
    const [isReviewVisible, setIsReviewVisible] = useState(false);
    
    useEffect(() => {
        if (isOpen) {
            const reviewTimer = setTimeout(() => {
                setIsReviewVisible(true);
            }, 300);

            return () => {
                clearTimeout(reviewTimer);
            };
        } else {
            setIsReviewVisible(false);
        }
    }, [isOpen]);
    
    useEffect(() => {
        getShopData(shopId).then(res=>setShopData(res.data));
    },[shopId])

    if(shopData.length === 0) {
        return null
    }
    const handleRatingValue = (value) => {
        setRatingValue(value)
    }
    const handleSubmit = async() => {
        if(ratingValue) {
            await sendReviewShop({
                "order_id": orderId,
                "rating": ratingValue,
            })
            localStorage.setItem('targetOrder', JSON.stringify(orderData.reviewed = true));
            openClose()
        }
    }
    return (
        <div>
            <Modal
                open={isOpen}
                onClose={() => openClose()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className={styles.mainContainer}>
                    <div className={styles.mainBlock}>
                        <aside className={styles.container}>
                            <div className={styles.closeBtnConteiner}>
                                <button className={styles.buttonBlock} data-backdrop="transparent"
                                        data-size="medium" data-variant="neutral" type="button"
                                        aria-label="Close the dialog" data-test-id="modal-close-button"
                                        onClick={() => openClose()}>
                                    <div className={styles.btnFakeBlock}></div>
                                    <div className={styles.btnConteinerInclude}>
                                        <svg viewBox="0 0 24 24" className={styles.imgCloseBtn}>
                                            <path
                                                d="M2.44 19.44a1.5 1.5 0 002.121 2.121l7.262-7.26a.25.25 0 01.354 0l7.262 7.262a1.5 1.5 0 102.122-2.12l-7.26-7.266a.25.25 0 010-.354l7.262-7.262a1.5 1.5 0 00-2.124-2.12L12.177 9.7a.25.25 0 01-.354 0L4.561 2.44A1.5 1.5 0 002.44 4.56L9.7 11.823a.25.25 0 010 .354L2.44 19.44z"></path>
                                        </svg>
                                    </div>
                                </button>

                            </div>
                            <div className={styles.mainConteiner}>
                                <div className={styles.mainInfoConteiner}>
                                    <div className={styles.modalContentContainer}>
                                        <div className={styles.content}>
                                            <div className={styles.imageBlock}>
                                                <img loading="lazy" className={styles.img}
                                                     src={Animation}
                                                     alt={shopData?.shop_images[0]?.AltText} draggable={true}/>
                                            </div>
                                            <div className={!isReviewVisible ? `${styles.reviewBlock}` : `${styles.reviewBlockVisible}`}>
                                                <h1>Review</h1>
                                                <h3>{shopData?.ShopName}</h3>
                                                <h6>Please set a review</h6>
                                                <RadioGroupRating handleRatingValue={handleRatingValue}/>
                                            </div>
                                        </div>
                                        <button className={styles.buttonReviews}
                                                onClick={handleSubmit}>Send
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </aside>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ModalReviewsShop;