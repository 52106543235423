import api from "../api";

export const getOwnerSliderInfo = async (id) => {

    try {
        const users = await api.get(`/owner_site/shops/sliders/${id}/`);
        return users.data;
    } catch (error) {
        console.error("An error occurred:", error);
        throw error;
    }
}