import React, {memo} from "react";


const SvgComponent = (props) => (
    <div
        style={{
            paddingTop: "2px",
            position: "absolute",
            right: "22px",
            top: "10px",
            width: '15px',
            height:'15px',
            borderRadius: '50%',
            backgroundColor: "black",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }}
    >
        <span style={{
            textAlign: "center",
            fontSize: '11px',
            color: "white",
        }}>
            {props.content}
        </span>
    </div>
)
const Badge = memo(SvgComponent)
export default Badge