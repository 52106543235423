import React, { useEffect, useMemo } from "react";

import { t } from "i18next";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";

import styles from "./CourierDeliveries.module.scss";

import IconBack from "../../../../../components/Icons/IconBack";
import rootStore from "../../../../../store/RootStore";
import DiscoveryParent from "../../../../../components/DiscoveryParent/DiscoveryParent";
import DeliveryCard from "../DeliveryCard/DeliveryCard";

const CourierDeliveries = () => {
    const { date } = useParams()
    const { getCourierEarningsForDate, loadCourierEarningsForDate } = rootStore.curierStore;

    const { date: courierEarningsdate, total_earnings: totalEarning, orders } = useMemo(() => getCourierEarningsForDate, [getCourierEarningsForDate]);

    useEffect(() => {
        loadCourierEarningsForDate(date);
    }, []);

  return (
    <DiscoveryParent>
        <div className={styles.backButton}>
            <IconBack size={20} pure={true} fill={"#009de0"} marg={0}/>
        </div>
        <div className={styles.deliveriesWrapper}>
            <h1 className={styles.title}>{t("Deliveries").toUpperCase()}</h1>
            <h2 className={styles.totalEarnings}>{`${totalEarning} $`}</h2>
            <h3 className={styles.earningsDate}>{courierEarningsdate}</h3>
            <div className={styles.deliveryCardsWrapper}>
                {orders?.map((order, index) => (
                    <DeliveryCard order={order} key={index} />
                ))}
            </div>
        </div>
    </DiscoveryParent>
  );
};

export default observer(CourierDeliveries);
