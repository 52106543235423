import { makeAutoObservable} from 'mobx';
import api from "../api/api";

class SearchStore {
    searchData = [];
    searchText = "";
    refresh = true;

    searchCategoryDiscoveryName = ""
    searchCategoryStoreRestaurants = ""
    searchCategoryStoreName = ""

    searchCategoryData = []

    get getSearchData(){
        return this.searchData;
    }
    get getSearchText(){
        return this.searchText;
    }
    get getSearchCategoryStoreName(){
        return this.searchCategoryStoreName;
    }
    get getSearchCategoryStoreRestaurants(){
        return this.searchCategoryStoreRestaurants;
    }

    constructor() {
        makeAutoObservable(this);
    }

    setSearchData = (data) => {
        this.searchData = data;
    }

    setSearchText = (text) => {
        this.searchText = text;
        if(this.searchText === ""){
            this.searchData = []
        } else {
            this.getSearchDataAPI()
        }
    }

    destroySearchCategory = (route) => {
        if(route === "restaurants") {
            localStorage.removeItem("searchCategoryRestaurants")
            this.searchCategoryStoreRestaurants = ""
        }
        else if(route === "stores") {
            localStorage.removeItem("searchCategoryStores")
            this.searchCategoryStoreName = ""
        }
        else if(route === "discovery") {
            localStorage.removeItem("searchCategoryDiscovery")
            this.searchCategoryDiscoveryName = ""
        }
    }

    setSearchCategory = (text, route= '') => {
        this.refresh = false
        if(route === "restaurants") {
            localStorage.setItem("searchCategoryRestaurants", JSON.stringify({id:text.id,name:text.name}))
            this.searchCategoryStoreRestaurants = text
        }
        else if(route === "stores") {
            localStorage.setItem("searchCategoryStores", JSON.stringify({id:text.id,name:text.name}))
            this.searchCategoryStoreName = text
        }
        else if(route === "discovery") {
            localStorage.setItem("searchCategoryDiscovery", JSON.stringify({id:text.id,name:text.name}))
            this.searchCategoryDiscoveryName = text
        }
    }

    getSearchDataAPI = async() => {
        api.get('/search-products/?q=' + this.getSearchText).then(response => {
            this.setSearchData(response.data)
        }).catch(error => {
            console.log(error)
            throw error.response.data;
        })
    }
}

const searchStore = new SearchStore();
export default searchStore;
