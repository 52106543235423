import axios from "axios";

import { getToken } from "../helpers/index";

const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

api.interceptors.request.use(
    (config) => {
        const { headers } = config;
        const token = getToken();
        if (token) {
            headers.Authorization = `Bearer ${token.replace(/"/g, " ")}`;
        }
        headers['Accept'] = '*/*';
        return config;
    },
    (error) => Promise.reject(error)
);

export default api;
