import api from "../api";

export const sendReviewShop = async (formData) => {
    if(formData) {
        try {
            const apiResponse = await api.post(`/add-review/`,formData).then(response => response);
            return apiResponse;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }
}