import api from "../api";

export const getProductData = async (id) => {
    if(id) {
        try {
            const apiResponse = await api.get(`products/${id}/`).then(response => response);
            return apiResponse;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }
}