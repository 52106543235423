import api from "../api";

export const addOwnerSlide = async (formData,id) => {
    if (formData && id) {
        try {
            const apiResponse = await api.patch(
                `/owner_site/shops/sliders/${id}/edit/`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );
            return apiResponse;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }
    else {
        try {
            const apiResponse = await api.post(
                `/owner_site/shops/sliders/create/`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );
            return apiResponse;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }
};
