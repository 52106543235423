import React, { useState, useMemo } from 'react';

import { t } from "i18next";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { Map, Placemark, YMaps } from "@pbe/react-yandex-maps";

import styles from './OrderCardWorked.module.scss';

import IconBack from "../../components/Icons/IconBack";
import rootStore from "../../store/RootStore";
import ItemsModal from "./ItemsModal/ItemsModal";
import AddressInfo from "./AddressInfo/AddressInfo";
// import AcceptModal from "../../components/AcceptModal/AcceptModal";
import { getOrder } from "../../helpers";
import SwipeButton from "../../components/SwipeButton/SwipeButton";
import OrderCardMain from "./OrderCardMain";
import DeliveryTimes from "./DeliveryTimes/DeliveryTimes";
import LocationAdress from "./LocationAdress/LocationAdress";
import PromoCodeModal from "./PromoCodeModal/PromoCodeModal";
import PaymentMethods from "./PaymentMethods/PaymentMethods";
import { ORDER_STATUSES } from "../../constants";
import { getCurrencySymbol } from "../../helpers/getCurrency";
import { IconCaretUpOutline } from "../../components/Icons/IconCaretUpOutline";
import { ChatIcon, PersonIcon } from "../../assets/icons";

const OrderCardWorked = () => {
    const navigate = useNavigate();

    const [mobile, setMobile] = useState('');
    const [isHidden, setIsHidden] = useState(false);
    const [openItems, setOpenItems] = useState(false);
    const [openPromo, setOpenPromo] = useState(false);
    const [isCheckout, setCheckout] = useState(false);
    const [isErrorCash, setErrorCash] = useState(false);
    const [openPayment, setOpenPayment] = useState(false);
    const [openLocation, setOpenLocation] = useState(false);
    const [openDelivery, setOpenDelivery] = useState(false);
    const [checkedItems, setCheckedItems] = useState({});

    // TODO: openAcceptModal никогда не будет true, закомментил
    // const [openAcceptModal, setOpenAcceptModal] = useState(false);

    const { lazyInitialize, addressData, locationData} = rootStore.checkoutStore;
    const {confirmOrders, cancelModOrders} = rootStore.ordersModerStore;
    const {acceptOrder, finishOrder, cancelOrder} = rootStore.curierStore;

    const isWorked = window.location.pathname.includes('worked');
    const isCurier = window.location.pathname.includes('curier-panel');
    const isActual = window.location.pathname.includes('actual');
    const isModerator = window.location.pathname.includes('moderator');

    const toggleVisibility = () => {
        setIsHidden(!isHidden);
    };

    const currentOrder = getOrder();
    const order = useMemo(() => JSON.parse(currentOrder), [currentOrder]);

    // TODO: lazyInitialize, Вероятно лишний вызов /checkout-preview/, вызывает ошибку
    // useEffect(() => {
    //     lazyInitialize();
    // }, []);

    const handleSubmit = async () => {
        if (mobile) {
            const order = {
                address: `${locationData} ${addressData.buildingName} ${addressData.enterance} ${addressData.floor} ${addressData.Apartment}`,
                phone_number: mobile,
            };
        }
        if (isActual) {
            acceptOrder(order.id);
            setCheckout(true);
            setTimeout(() => {
                navigate('/curier-panel/worked');
            }, 500);
        }
        if (isWorked) {
            finishOrder(order.id);
            setCheckout(true);
            setTimeout(() => {
                navigate('/curier-panel/all');
            }, 500);
        }
        if (isModerator) {
            confirmOrders(order.shop, order.id);
            navigate(-1);
        }
    };

    const handleCancelSubmit = async () => {
        if (!isModerator) {
            cancelOrder(order.id);
            navigate('/curier-panel/all');
        } else {
            cancelModOrders(order.shop, order.id);
            navigate(-1);
        }
    };

    if (isCheckout && !isCurier) {
        setTimeout(() => {
            navigate('/discovery/me/order-history');
        }, 2000);
    }
    if (isErrorCash) {
        setTimeout(() => {
            setErrorCash(false);
        }, 2000);
    }

    const handleCheckboxChange = (productId) => {
        setCheckedItems((prevState) => ({
            ...prevState,
            [productId]: !prevState[productId],
        }));
    };

    const dataAddress = JSON.parse(order?.delivery_address_text);
    const items = JSON.parse(order?.items);

    const allItemsChecked = useMemo(() => {
        return items.every((item) => checkedItems[item.product.ProductID]);
    }, [checkedItems, items]);

    const currency = order?.currency;

    // TODO: openAcceptModal никогда не будет true, закомментил
    // const handleCloseAcceptModal = () => {
    //     setOpenAcceptModal(false);
    // }

    const handleOpenChat = () => {
        setTimeout(() => {
          navigate(`/discovery/me/order-chat`)
      }, 250)
    };

    return (
        <>
            {
                order?.status === ORDER_STATUSES.taking ?
                    (<OrderCardMain />) :
                    (<div className={styles.mainPage}>
                        <YMaps query={{lang: 'en_EU'}} height={"10000px"}>
                            <div className={styles.mapBlock}>
                                <div className={styles.backer}>
                                    <IconBack size={20} pure={true} fill={"#009de0"} marg={0}/>
                                </div>
                                <Map
                                    defaultState={{
                                        center: [order?.delivery_coordinates.latitude, order?.delivery_coordinates.longitude],
                                        zoom: 15
                                    }}
                                    width={"100%"}
                                    height={"100vh"}
                                >
                                    <Placemark
                                        properties={{iconContent: "D"}}
                                        options={{iconColor: "green"}}
                                        geometry={[order?.delivery_coordinates.latitude, order?.delivery_coordinates.longitude]}
                                    />
                                    <Placemark
                                        properties={{iconContent: "P"}}
                                        options={{iconColor: "red"}}
                                        geometry={[order?.delivery_address_take?.latitude, order?.delivery_address_take?.longitude]}
                                    />
                                </Map>
                            </div>
                        </YMaps>
                        <div className={`${styles.mainConteiner} ${isHidden ? styles.hidden : ''}`}>
                            <div style={{textAlign: "center", display: "flex", justifyContent: "center"}}
                                 onClick={toggleVisibility}>
                                <span className={styles.lineClose}></span>
                            </div>
                            <div className={styles.shopNamspan}>
                            <span className={styles["shopName__title"]}>
                                {t("Delivery at")}
                            </span>
                                <span className={styles["shopName__description"]}>  {order.shop_name}</span>
                            </div>
                            <div className={styles.statusBadge}>{order?.status}</div>
                            <span className={styles.titleDetail}>Order details:</span>
                            <div className={styles.boxHashTag}>
                                <PersonIcon stroke={"#1C274C"}/>
                                <span className={styles.tagTitle}>#{order?.id}</span>
                            </div>
                            <div className={styles.bodyCarts}>
                                {items.map((e) => (
                                    <div className={styles.rowInputItem} key={e.product.ProductID}>
                                <span className={styles.itemCard}>
                                    {e.product.ProductName} x {e.product.quantity.split('.')[0]} x {e.product.Price}{' '}
                                    {getCurrencySymbol()}
                                </span>
                                        <input
                                            className={styles.bigBox}
                                            type="checkbox"
                                            checked={checkedItems[e.product.ProductID] || false}
                                            onChange={() => handleCheckboxChange(e.product.ProductID)}
                                        />
                                    </div>
                                ))}
                            </div>
                            <span className={styles.detailTitle}>Problem with delivery?</span>
                            <button className={styles.downBtnChat}>
                                <span className={styles.titleBtnChat}>Without this delivery</span>
                            </button>
                            <button className={styles.downBtnChat2} onClick={handleOpenChat}>
                                <ChatIcon stroke={"#02acf8"}/>
                                <span className={styles.titleBtnChat2}>Connect with support</span>
                            </button>
                            <hr style={{opacity: 0.3, margin: "1rem 0", position: "relative", width: "100%"}}/>
                            {order.status === ORDER_STATUSES.storeConfirmations ||
                            (isCurier &&
                                (order.status === ORDER_STATUSES.confirmed ||
                                    order.status === ORDER_STATUSES.taking)) ? (
                                <div className={styles.swipeButton}>
                                    <SwipeButton
                                        getInProcces={allItemsChecked}
                                        handlerAction={acceptOrder}
                                        orderId={order?.id}
                                    />
                                </div>
                            ) : null}
                        </div>

                        <PromoCodeModal isOpen={openPromo} setIsOpen={setOpenPromo}/>
                        <PaymentMethods isOpen={openPayment} setIsOpen={setOpenPayment}/>
                        <LocationAdress isOpen={openLocation && !isCurier && !isModerator} setIsOpen={setOpenLocation}/>
                        <AddressInfo isOpen={openLocation && (isCurier || isModerator)} setIsOpen={setOpenLocation}
                                     dataAddress={dataAddress}/>
                        <DeliveryTimes isOpen={openDelivery} setIsOpen={setOpenDelivery}/>
                        <ItemsModal isOpen={openItems} setIsOpen={setOpenItems} items={items} currency={currency}/>

                        {/*
                            TODO: openAcceptModal никогда не будет true, закомментил
                            {openAcceptModal && (
                            <AcceptModal
                                handleClose={handleCloseAcceptModal}
                                handleSubmit={handleSubmit}
                                handleCancel={handleCancelSubmit}
                            />
                        )} */}
                    </div>)
            }
            {isHidden &&
                <div style={{position: "absolute", left: "7vw", top: "93vh"}} onClick={toggleVisibility}>
                    <IconCaretUpOutline/>
                </div>
            }
        </>
    );
};

export default observer(OrderCardWorked);
