import React, {useEffect, useState} from 'react';

import {t} from "i18next";
import {observer} from "mobx-react";

import {Drawer} from "@mui/material";
import {makeStyles} from "@mui/styles";

import styles from "./StoreBody.module.scss"

import Basket from "../../Basket/Basket";
import rootStore from "../../../store/RootStore";
import { useTheme } from "../../../helpers/ThemeContext";
import ProductBody from "./ProductBody/ProductBody";
import CategoryBaner from "../../Restoran/RestoranBody/CategoryBaner/CategoryBaner";
import CategoryStore from "./CategoryStore/CategoryStore";
import { getCategoryList } from "../../../api/getContentsApi/getCategoryList";
import MobileCategoryStore from "./MobileCategoryStore/MobileCategoryStore";
import { getCurrencySymbol } from "../../../helpers/getCurrency";

const MOCK_DATA = [
    {
        id: 1,
        title: "It's a Deal!",
        items: [
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: true,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: true,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: true,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: true,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: true,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: true,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: true,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: true,
                oldCost: "€7.20",
                newCost: "€6"
            }
        ]
    },
    {
        id: 2,
        title: "NUDELN & REIS",
        items: [
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            }
        ]
    },
    {
        id: 3,
        title: "NUDELN & REIS",
        items: [
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            }
        ]
    },
    {
        id: 4,
        title: "NUDELN & REIS",
        items: [
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            },
            {
                name: "Chao Shou 🌶️",
                description: "5 Stück Gefüllte Teigtaschen, Faschiertes, Lauch, Chiliöl, Koriander, Erdnüsse",
                isPopular: false,
                oldCost: "€7.20",
                newCost: "€6"
            }
        ]
    }
]

const StoreBody = observer(() => {
    const { sizeWidth, setIsOpenPersonal } = rootStore.systemStore;
    const { cartsList, getIsClicked, getIsBasketOpen: basketOpen, setBasketOpen } = rootStore.cartOrderStore;

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const {themeMode} = useTheme()
    const useStyles = makeStyles({
        paperBackground: {
            '& .MuiDrawer-paper ': {
                backgroundColor: `${themeMode === 'dark' ? '#1c1c1c' : 'white'}`,
                backgroundImage: "none",
            },
        },
    });
    const classes = useStyles();

    const shop = rootStore.shopStore.shopData;
    const [categoryList, setCategoryList] = useState(null);
    const { ShopID } = shop;

    const isOrder = window.location.pathname.includes('/stores/') && getIsClicked || cartsList.cart_total_items;
    const currencySymbol = getCurrencySymbol();

    const toggleDrawer = (newOpen) => () => {
        setBasketOpen(newOpen);
        setIsOpenPersonal(false)
    };
    const closeDrawer = () => {
        setBasketOpen(false);
    };

    useEffect(() => {
        if(ShopID) {
           getCategoryList(ShopID)
           .then(response=> setCategoryList(response?.data?.categories))
           .catch(error => console.log(error));
        }
    }, [ShopID]);
    return (
        <main className={styles.mainConteiner}>
            {
                sizeWidth <= 641
                    ? (<MobileCategoryStore categoryList={categoryList ?? []} data={MOCK_DATA}/>)
                    : ''
            }
            {window.innerWidth < 640 ? "" : <CategoryBaner/>}
            <div className={styles.mainBlock}>
                <div className={styles.mainContent}>
                    {
                        sizeWidth >= 641 ? (<CategoryStore categoryList={categoryList ?? []}/>) : ""
                    }
                    <ProductBody/>
                </div>
                <div className={styles.fakeBlock}></div>
            </div>
            <div className={`${styles.infoViewBlock} ${isOrder ? styles.show : ""}`} onClick={()=> setBasketOpen(true)}>
                <div>
                    {cartsList.cart_total_items > 0 && <button aria-disabled="false" type="button" data-test-id="cart-view-button"
                            className={styles.viewOrderBlock1}>
                        <div className={styles.viewOrderBlock2}></div>
                        <div className={styles.viewOrderBlock3}></div>
                        <div className={styles.viewOrderBlock4}>
                            <div className={styles.viewOrderMain}>
                                <div className={styles.viewOrderInfo}>{cartsList.cart_total_items}</div>
                                <div className={styles.viewOrderText}>{t("View order")}</div>
                                <div className={styles.viewOrderCost}>{currencySymbol}&nbsp;{cartsList.cart_total_price_converted}</div>
                            </div>
                        </div>
                    </button>}
                </div>
            </div>
            <Drawer anchor={windowWidth > 830 ? "right" : "bottom"}
                    open={basketOpen}
                    className={classes.paperBackground}
                    onClose={toggleDrawer(false)}>
                <Basket closeDrawer={closeDrawer} nextStep={true}></Basket>
            </Drawer>
        </main>
    );
});

export default StoreBody;