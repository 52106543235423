import React, {useEffect, useState} from 'react';
import styles from "./ModalLogin.module.scss"
import {Modal} from "@mui/material";
import {observer} from "mobx-react";
import rootStore from "../../store/RootStore";
import ErrorLogin from "./ErrorLogin/ErrorLogin";
import SuccessLogin from "./SuccessLogin/SuccessLogin";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ModalLogin = observer(callback => {
    const { isLogin, isOpenLoginModal, openCloseL, openCloseR } = rootStore.systemStore;
    const { login, register, errorLogin, user, errorRequest, getSuccessLogin } = rootStore.userStore;
    const [errorPassLength , setErrorPassLength] = useState('');

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordTry, setPasswordTry] = useState('');

    const handleChange = (target) => {
        setUsername(target.target.value)
    }
    const handleChangePass = (target) => {
        setPassword(target.target.value)
    }
    const handleChangePassTry = (target) => {
        setPasswordTry(target.target.value)
    }
    const handleSubmit = async () => {
        if (password.length < 8){
            setErrorPassLength("Your password must be 8+ chars")
            setTimeout(() => {
                setErrorPassLength('');
            }, 3000);
        } else {
            if (isLogin) {
                await login(`${username}@gmail.com`, password, openCloseL)
            } else {
                if (password === passwordTry){
                    await register(`${username}@gmail.com`, username, password, passwordTry, openCloseR)
                } else {
                    setErrorPassLength("Password mismatch!")
                    setTimeout(() => {
                        setErrorPassLength('');
                    }, 3000);
                }
            }
        }
    }

    const handleClose = (ev) => {
        if (ev.target.id === "modalka"){
            openCloseL(false);
        }
    }

    return (
        <div>
            <Modal
                open={isOpenLoginModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div id="modalka" className={styles.mainContainer} onClick={(ev) => handleClose(ev)}>
                    <div className={styles.mainBlock}>
                        <aside className={styles.container}>
                            <div className={styles.closeBtnConteiner}>
                                <button className={styles.buttonBlock} data-backdrop="transparent"
                                        data-size="medium" data-variant="neutral" type="button"
                                        aria-label="Close the dialog" data-test-id="modal-close-button"
                                onClick={() => openCloseL()}>
                                    <div className={styles.fakeBtnBlock}></div>
                                    <div className={styles.closeImgBtn}>
                                        <svg viewBox="0 0 24 24" className={styles.closeImg}>
                                            <path
                                                d="M2.44 19.44a1.5 1.5 0 002.121 2.121l7.262-7.26a.25.25 0 01.354 0l7.262 7.262a1.5 1.5 0 102.122-2.12l-7.26-7.266a.25.25 0 010-.354l7.262-7.262a1.5 1.5 0 00-2.124-2.12L12.177 9.7a.25.25 0 01-.354 0L4.561 2.44A1.5 1.5 0 002.44 4.56L9.7 11.823a.25.25 0 010 .354L2.44 19.44z"></path>
                                        </svg>
                                    </div>
                                </button>
                            </div>
                            <div className={styles.block}>
                                <header className={styles.blockHeader}></header>
                                <div className={styles.downBlock}>
                                    <div>
                                        <div className={styles.parentBlock}>
                                            <div className={styles.contentBlock}>
                                                <div className={styles.upB}>
                                                    <h2 className={styles.title}>Create an account or log in</h2>
                                                    <div className={styles.description}>Log in below or create a new
                                                        Wolt account.
                                                    </div>
                                                </div>
                                                <div className={styles.upD}>
                                                    <form className={styles.formaBlock}>
                                                        <div className={styles.fieldEmail}>
                                                            <div className={styles.fieldCont}>
                                                                <input type="username"
                                                                       name="username" id="method-select-username"
                                                                       placeholder=" "
                                                                       autoComplete="off" spellCheck="false"
                                                                       className={styles.fieldInp}
                                                                       value={username}
                                                                       onChange={handleChange}/>
                                                                {
                                                                    username ? "" : (
                                                                        <label className={styles.lable}>Username</label>)
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className={styles.fieldEmail}>
                                                            <div className={styles.fieldCont}>
                                                                <input type="password"
                                                                       name="password" id="method-select-password"
                                                                       placeholder=" "
                                                                       autoComplete="off" spellCheck="false"
                                                                       className={styles.fieldInp}
                                                                       value={password}
                                                                       onChange={handleChangePass}/>
                                                                {
                                                                    password ? "" : (
                                                                        <label className={styles.lable}>Password</label>)
                                                                }
                                                            </div>
                                                        </div>
                                                        {
                                                            !isLogin ?
                                                                (
                                                                    <div className={styles.fieldEmail}>
                                                                        <div className={styles.fieldCont}>
                                                                            <input type="password"
                                                                                   name="passwordTry"
                                                                                   id="method-select-password"
                                                                                   placeholder=" "
                                                                                   autoComplete="off" spellCheck="false"
                                                                                   className={styles.fieldInp}
                                                                                   value={passwordTry}
                                                                                   onChange={handleChangePassTry}/>
                                                                            {
                                                                                passwordTry ? "" : (
                                                                                    <label
                                                                                        className={styles.lable}>Password try</label>)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                ) : ""
                                                        }
                                                        {
                                                            errorPassLength ? (<ErrorLogin text={errorPassLength}/>) : ""
                                                        }
                                                        {
                                                            errorRequest ? (<ErrorLogin text={errorRequest}/>) : ""
                                                        }
                                                        {
                                                            getSuccessLogin ? (<SuccessLogin text={"Success!"}/>) : ""
                                                        }
                                                        <button aria-disabled="false" type="button" onClick={handleSubmit}
                                                                className={styles.submitBtn}>
                                                            <div className={styles.fakeSub}></div>
                                                            <div className={styles.fakeSubTwo}></div>
                                                            <div className={styles.textSub}>{isLogin ? "Log in" : "Register"}</div>
                                                        </button>
                                                    </form>
                                                    <span className={styles.info}>Please visit Wolt Privacy Statement to learn more about personal data processing at Wolt. You can access the local Privacy Statement related to your Wolt account after you have provided the country and language preferences applicable to you during registration. This site is protected by hCaptcha. The hCaptcha Privacy Policy and Terms of Service apply..</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </aside>
                    </div>
                </div>
            </Modal>
        </div>
    );
});

export default ModalLogin;