import React, {useState, useRef} from 'react';
import styles from './PromoCodeModal.module.scss';
import {observer} from "mobx-react";
import {t} from "i18next";

const PromoCodeModal = ({isOpen, setIsOpen}) => {
    const modalRef = useRef(null);
    const [text, setText] = useState("")

    const handleCloseModal = () => {
        setIsOpen(false);
    };

    const handleClose = (ev) => {
        if (ev.target.id === "modalka") {
            setIsOpen(false);
        }
    }

    return (
        <div>
            {isOpen && (
                <div id="modalka" className={styles["modal-overlay"]} onClick={(ev) => handleClose(ev)}>
                    <div className={styles["modal-content"]} ref={modalRef}>
                        <div className={styles.closeBtnConteiner}>
                            <button className={styles.buttonBlock} data-backdrop="transparent"
                                    data-size="medium" data-variant="neutral" type="button"
                                    aria-label="Close the dialog" data-test-id="modal-close-button"
                                    onClick={handleCloseModal}>
                                <div className={styles.closeImgBtn}>
                                    {t("Submit")}
                                </div>
                            </button>
                        </div>
                        <div>
                            <h2 className={styles.titleBody}>{t("Promo code")}</h2>
                            <div className={styles.filtersBlock}>
                                <div className={styles.filters}>

                                </div>
                            </div>
                            <div className={styles.description}>
                                <span>{t("If you have a Wolt promo code or gift card, enter it below to claim your benefits.")}</span>
                            </div>
                            <div className={styles.imgBlocksSearch}>
                                <input className={styles.searchForm} type="text"
                                       placeholder={t("Add promo code")}
                                       aria-label={"Wolt promo code"}
                                       name="name"
                                       value={text}
                                       onChange={(e) => setText(e.target.value)}
                                />
                                <button className={styles.clearBtn} onClick={() => setText("")}>X</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div onClick={() => setIsOpen(true)}></div>
        </div>
    );
};

export default observer(PromoCodeModal);
