import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import styles from './NavBarDeskTop.module.scss';
import useIsMobile from "../../helpers/useIsMobile";
import DiscoveryIcon from "../../pages/DiscoveryWrapper/static/DiscoveryIcon";
import RestaurantsIcon from "../../pages/DiscoveryWrapper/static/RestaurantsIcon";
import StoresIcon from "../../pages/DiscoveryWrapper/static/StoresIcon";

const NavBarDeskTop = () => {
    const {pathname} = useLocation();
    const isMobile = useIsMobile()

    if(isMobile) return null;

    const isDiscovery = pathname === "/discovery";
    const isRestaurants = pathname.includes('restaurants');
    const isStores = pathname.includes('/stores');

    return (
        <div style={!isDiscovery ? {marginTop: "2rem"} : {}}>
            <nav className={styles.navbar} >
                <Link
                    to="/discovery"
                    className={(isDiscovery ? styles['active'] : styles['nav-link'])}
                >
                    <DiscoveryIcon currentColor={isDiscovery && "var(--background-color)"}/>
                    Discovery
                </Link>
                <Link
                    to="/discovery/restaurants"
                    className={(isRestaurants ? styles['active'] : styles['nav-link'])}
                >
                    <RestaurantsIcon currentColor={isRestaurants && "var(--background-color)"}/>
                    Restaurants
                </Link>
                <Link
                    to="/discovery/stores"
                    className={(isStores ? styles['active'] : styles['nav-link'])}
                >
                    <StoresIcon currentColor={isStores && "var(--background-color)"}/>
                    Stores
                </Link>
            </nav>
        </div>
    );
};

export default NavBarDeskTop;