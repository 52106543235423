import React from 'react';
import styles from './DownBanner.module.scss';
import CardBanner from "./CardBanner/CardBanner";

let CONTENT_CARD_DATA = [
    {
        text: "Get paid as a courier partner",
        link: "http://77.238.237.203:8080/",
        linkImg: "https://consumer-static-assets.wolt.com/frontpage-assets/courier-card-image.jpg"
    },
    {
        text: "Serve more people as a restaurant partner",
        link: "http://77.238.237.203:8080/",
        linkImg: "https://consumer-static-assets.wolt.com/frontpage-assets/restaurant-card-image.jpg"
    },
    {
        text: "Enter a new chapter and find a job at Wolt",
        link: "http://77.238.237.203:8080/",
        linkImg: "https://consumer-static-assets.wolt.com/frontpage-assets/jobs-card-image.jpg"
    }
]

const DownBanner = () => {
    return (
        <div className={styles.downRootBlock}>
            <h2 className={styles.rootTitle}>Hungry for more than food?</h2>
                <div className={styles.downRootConteiner}>
                    {
                        CONTENT_CARD_DATA.map((elem, key) => (
                            <CardBanner text={elem.text} link={elem.link} linkImg={elem.linkImg} key={key}/>
                        ))
                    }
                </div>
        </div>
    );
};
export default DownBanner;