import React, {useState} from 'react';
import styles from './CurrentShopPage.module.scss'
import {observer} from "mobx-react";
import StatisticOperationRow from "../StatisticOperationRow/StatisticOperationRow";
import rootStore from "../../../store/RootStore";
import MENU from "../../../constants/constantMenu";
import OperationInfoModal from "../OperationInfoModal/OperationInfoModal";
import {t} from "i18next";
import {toJS} from "mobx";
import {useNavigate} from "react-router-dom";

const CurrentShopPage = () => {
    const { setCurrentShopId } = rootStore.statisticStore;
    const { setActivePanel } = rootStore.moderatorPanelStore;
    const { getCurrentDateData } = rootStore.statisticStore;

    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false);

    const handleClickBack = () => {
        setCurrentShopId('')
        setActivePanel(MENU.Statistics)
        navigate(-1)
    }
    return (
        <div className={styles.mainConteiner}>
            <div className={styles.navigateHeader}>
                <svg viewBox="0 0 24 24"
                     className={styles.mainSvg} onClick={() => handleClickBack()}>
                    <path className={styles.mainSvg2}
                          d="M22.533 10.526H5.422a.251.251 0 01-.165-.438l4.637-3.6a1.44 1.44 0 00-1.9-2.162L.813 10.165a2.4 2.4 0 000 3.6l7.179 5.837a1.44 1.44 0 001.9-2.161l-4.637-3.6a.251.251 0 01.165-.438h17.113a1.44 1.44 0 000-2.88v.003z"
                    ></path>
                </svg>
                <span className={styles.shopName}>{getCurrentDateData.shop_name}</span>
            </div>
            <div className={styles.profitBlock}>
                <div className={styles.topProfitBlock}>
                    <span className={styles.leftContTop}>{t("Revenue")}</span>
                    <span className={styles.rightContTop}>{getCurrentDateData.total_revenue}$</span>
                </div>
                <div className={styles.downProfitBlock}>
                    <span className={styles.leftContDown}>{t("Cash in register")}:</span>
                    <span className={styles.rightContDown}>{getCurrentDateData.total_revenue}$</span>
                </div>
            </div>
            {/*<div className={styles.profitBlock2}>*/}
            {/*    <div className={styles.topProfitBlock}>*/}
            {/*    <span className={styles.leftContTop}>Receipts</span>*/}
            {/*        <span className={styles.rightContTop}>49 120,00$</span>*/}
            {/*    </div>*/}
            {/*    <div className={styles.downProfitBlock2}>*/}
            {/*        <div className={styles.row}>*/}
            {/*            <span className={styles.leftContDown}>Cash:</span>*/}
            {/*            <span className={styles.rightContDown}>49 120,00$</span>*/}
            {/*        </div>*/}
            {/*        <div className={styles.row}>*/}
            {/*            <span className={styles.leftContDown}>none-Cash:</span>*/}
            {/*            <span className={styles.rightContDown}>0$</span>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className={styles.phaseFant}></div>
            <div className={styles.tableStats}>
                <div className={styles.headerTable}>
                    <span className={styles.headBlock1}>{t("Operations")}</span>
                    <span className={styles.headBlock2}>{t("Date")}</span>
                    <span className={styles.headBlock3}>{t("Cash")}</span>
                </div>
                <div className={styles.fantomCont}></div>
                {
                    getCurrentDateData?.orders ? getCurrentDateData?.orders.map(order => (
                        <StatisticOperationRow key={order.id}  setIsOpen={setIsOpen} order={order}/>
                    )) : ""
                }
            </div>
            {
                isOpen && (<OperationInfoModal setState={setIsOpen}/>)
            }
        </div>
    );
};

export default observer(CurrentShopPage);