import React from 'react';
import styles from './TitleTextBlock.module.scss'; // Подключаем файл со стилями

const TitleTextBlock = () => {
    return (
        <div className={styles.titleTextRoot}>
            <div className={styles.titleTextContent}>
                <h3 className={styles.title}>Did you know?</h3>
                <p className={styles.description}>Getting home-delivered sushi is more than your life made easy. When you order with Wolt, you help thousands of hard-working restaurant and store owners and couriers make a living.</p>
            </div>
        </div>
    );
};

export default TitleTextBlock;