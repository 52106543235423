import React, {useEffect, useRef, useState} from "react";

import {t} from "i18next";
import {observer} from "mobx-react";
import {TextField} from "@mui/material";

import styles from "./ChatCurier.module.scss";
import IconBack from "../../Icons/IconBack";
import rootStore from "../../../store/RootStore";
import {getOrder, getUser} from "../../../helpers";
import {useTheme} from "../../../helpers/ThemeContext";


const ChatCurier = observer(() => {
    const ref = useRef();
    const user = JSON.parse(getUser());
    const {themeMode} = useTheme()

    const {
        connect,
        sendMessage,
        messages,
        disconnect,
        getHistoryMessage,
        clearMessages,
    } = rootStore.chatStore;
    const {getTargetOrderCheck} = rootStore.cartOrderStore;

    const [message, setMessage] = useState("");

    const handleSendMessage = () => {
        if (message !== "") {
            sendMessage(message, getTargetOrderCheck?.id);
            setMessage("");
        }
        setTimeout(() => {
            ref.current.scrollTo(0, 10000000000);
        }, 200);
    };

    useEffect(() => {
        connect(JSON.parse(getOrder())?.id || getTargetOrderCheck?.id);
        setTimeout(() => {
            getHistoryMessage(JSON.parse(getOrder())?.id || getTargetOrderCheck?.id);
        }, 500);
        setTimeout(() => {
            ref.current.scrollTo(0, 10000000000);
        }, 700);
        return () => {
            disconnect();
            clearMessages();
        };
    }, [
        connect,
        disconnect,
        getTargetOrderCheck,
        clearMessages,
        getHistoryMessage,
    ]);

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            handleSendMessage();
        }
    };

    const messageTime = (message) => {
        return (
            message.timestamp.split("T")[1].split(":")[0] +
            ":" +
            message.timestamp.split("T")[1].split(":")[1]
        );
    };

    return (
        <>
            <div
                className={styles.backer}
            >
                <IconBack size={20} pure={true} fill={"#009de0"}/>
            </div>
            <div className={styles.chatHeader}>
                <h3 className={styles.title}>{t("Chat")}</h3>
            </div>
            <div className={styles.chatWrapper}>
                <div className={styles.inputMessagePanelWrapper}>
                    <div className={styles.inputMessagePanel}>
                        <div className={styles.messageConteiner} ref={ref}>
                            {messages.map((message, index) => (
                                <div
                                    className={`${styles.conteinerRow} ${
                                        message.sender === user.profile.username
                                            ? styles.conteinerRowRight
                                            : styles.conteinerRowLeft
                                    }`}
                                    key={index}
                                >
                                    <div
                                        className={`${styles.messageBody} ${
                                            message.sender === user.profile.username
                                                ? styles.messageBodyRight
                                                : styles.messageBodyLeft
                                        }`}
                                    >
                                        <div className={styles.message}>
                                            <span>{message.message}</span>
                                        </div>
                                        <div className={styles.timeRow}>
                                            <span>{messageTime(message)}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className={styles.inputWrapper}>
                    <TextField
                        variant="outlined"
                        placeholder={t("Write a message...")}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyDown={handleKeyPress}
                        InputProps={{
                            sx: {
                                height: "40px",
                                "& .MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                },
                                "& .MuiInputBase-input": {
                                    caretColor: "#178bb8",
                                    caretWidth: "3px",
                                },
                                padding: "0 16px",
                                borderRadius: "32px",
                                backgroundColor: themeMode === "dark" ? "#000" : "#ededed",
                            },
                        }}
                        fullWidth
                    />
                </div>
            </div>
        </>
    );
});

export default ChatCurier;
