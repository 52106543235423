import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react";
import rootStore from "../store/RootStore";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import api from "../api/api";

const WithOwnerUser = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    useEffect(() => {
        const fetchData = async () => {
            await api.get('user/profile/').then(response => {
                const res = response.data[0]
                if (res?.role !== "owner") {
                    navigate('/');
                    alert("You are not a owner admin");
                }
            });
            await rootStore.superUserStore.lazyInitialize();
            setIsLoading(false);
        };
        fetchData();
    }, []);

    return (
        isLoading ? (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
                <CircularProgress />
            </Box>
        ) : (
            <>
                {children}
            </>
        )
    );
};

export default observer(WithOwnerUser);